/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./components/tableStyle.css";
import React, { useState, useCallback, useEffect } from "react";
import { AuthContext } from "./components/auth-context";

//Bhavin
import Apitable from "./Api_log/Apitable";
import Accounts from "./components/Accounts/Account";
import AddAccount from "./components/Accounts/AddAccount";
import EditAccount from "./components/Accounts/EditAccount";

import Contracted_Templates from "./components/Templates/Contracted_Templates";
import Triggers from "./components/Triggers/Triggers";
import IotSensorMasters from "./components/IOT Sensor Master/IotSensorMaster";
import Total_Batteries from "./components/IOT Sensor Master/total_batteries";
import Taps from "./components/IOT Sensor Master/taps";
import Nameplate from "./components/IOT Sensor Master/nameplate";
import SensorFields from "./components/IOT Sensor Master/sensor_fields";
import CalculatedFields from "./components/IOT Sensor Master/calculated_fields";
import AddIotSensorMaster from "./components/IOT Sensor Master/AddIotSensorMaster";
import EditIotSensorMaster from "./components/IOT Sensor Master/EditIotSensorMaster";
import SalesDashboard from "./components/Dashboard/SalesDashboard";
import ApiLogs from "./components/Api Logs/ApiLogs";
import Example from "./components/Accounts/Example";
import Alert from "./components/Alert/Alert";
import Sidebar from "./components/Sidebar";
import SideMenu, { menuItems } from "./Sidebar/SideMenu";
import Mdts from "./components/Mdts/mdts";
import Add_mdts_template from "./components/Mdts/add_mdts_template";
import Edit_mdts_template from "./components/Mdts/edit_mdts_template";

// kalpana
import Repo_apimaster from "./Repo_api-master/Api_master"
import Repo_asset_category from "./Repo_asset_category/Repo_asset_category";
import Repo_asset_manufacturer from "./Repo_asset_manufacturer/Repo_asset_manufacturer";
import Repo_assets from "./Repo_assets/Repo_assets";
import Repo_department from "./Repo_department/Repo_department";
import Repo_location from "./Repo_location/Repo_location";
import Repo_sensor_manufacturer from "./Repo_sensor_manufacturer/Repo_sensor_manufacturer";
import Repo_electrical_symbol from "./Repo_electrical_symbol/Repo_electrical_symbol";
import Repo_sensor_type from "./Repo_sensor_type/Repo_sensor_type";
import Repo_oil_type from "./Repo_oil_type/Repo_oil_type";
import Repo_oil_vendor from "./Repo_oil_vendor/Repo_oil_vendor";
import Repo_oil_standard from "./Repo_oil_standard/Repo_oil_standard";
import ServiceLog from "./Service_log/Service_log";
//import Downtime from "./Downtime/Downtime";
import Mqtt_broker from "./Mqtt_broker/Mqtt_broker";
import Repo_industry from "./Repo_industry/Repo_industry";
import Admin_user from "./Admin_user/Admin_user";
import Account_user from "./Account_user/Account_user";
//import Analytics from "./Analytics/Analytics";
import Admin_user_login from "./Admin_user_login/Admin_user_login";
import Asset_test_type from "./Repo_asset_test_type/Repo_asset_test-type";
import Iot_solution_master from "./Iot_solution_master/Iot_solution_master";
import Data_field from "./Iot_solution_master/Data_fields";
import Param_Alert_Asset from "./Iot_solution_master/Param_alert_array";

import Err_codes from "./Iot_solution_master/Error_codes";

import AQI from "./Iot_solution_master/AQI";
import Setup from "./Setup/Setup";
import Audit_log from "./Audit_log/Audit_log";
import Remove_Trends from "./Iot_solution_master/Remove_Trends";
import Remove_Alerts from "./Iot_solution_master/Remove_Alert";
import Asset_Types from "./Iot_solution_master/Asset_Types";
import SuperAdminDashboard from "./SuperAdmin_Dashboard/SuperAdmin_Dashboard";
import ApiLog from "./Api_log/ApiLog";


function App() {
 let  apiResponse={};
  const [inactive, setInactive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(false);

  const login = useCallback((uid) => {
    setIsLoggedIn(true);
    setUserId(uid);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUserId(null);
  }, []);

  useEffect(() => {
    const data = window.sessionStorage.getItem("login");
    setIsLoggedIn(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("login", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  let routes;
  if (isLoggedIn) {
    routes = (
      <Router>
        <SideMenu
          onCollapse={(inactive) => {
            // console.log(inactive);
            setInactive(inactive);
          }}
        />

        <Switch>
          <div className="hold-transition sidebar-mini layout-fixed">
            <div className={`wrapper ${inactive ? "inactive" : ""}`}>
              {/* improvememt, not recorded in video, its just looping through menuItems
        instead of hard coding all the routes */}
              {menuItems.map((menu, index) => (
                <>
                  <Route key={menu.name} exact={menu.exact} path={menu.to}>
                    {/* <h1>{menu.name}</h1> */}
                  </Route>
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route key={subMenu.name} path={subMenu.to}>
                          {/* <h1>{subMenu.name}</h1> */}
                        </Route>
                      ))
                    : null}
                </>
              ))}

              {/* <Sidebar /> */}
              {/* <SIdebar_test /> */}

              {/* Bhavin */}

              <Route path="/mdts_templates">
                <Mdts />
              </Route>

              <Route
                exact
                path="/edit_mdts_template/:id"
                component={Edit_mdts_template}
              />

              <Route path="/add_mdts_template">
                <Add_mdts_template />
              </Route>

              <Route path="/example">
                <Example />
              </Route>

              <Route path="/sales_dashboard">
                <SalesDashboard />
              </Route>

              <Route path="/accounts" exact>
                <Accounts />
              </Route>

              <Route path="/add_account" exact>
                <AddAccount />
              </Route>
              <Route exact path="/edit_account/:id" component={EditAccount} />

              <Route path="/contracted_templates" exact>
                <Contracted_Templates />
              </Route>

              <Route path="/triggers" exact>
                <Triggers />
              </Route>

              <Route path="/iot_sensor_masters" exact>
                <IotSensorMasters />
              </Route>

              <Route path="/add_iot_sensor_master" exact>
                <AddIotSensorMaster />
              </Route>

              <Route
                exact
                path="/edit_iot_sensor_master/:id"
                component={EditIotSensorMaster}
              />

              <Route path="/total_batteries/:id">
                <Total_Batteries />
              </Route>

              <Route path="/taps/:id">
                <Taps />
              </Route>

              <Route path="/nameplates/:id">
                <Nameplate />
              </Route>

              <Route path="/sensor_fields/:id">
                <SensorFields />
              </Route>

              <Route path="/calculated_fields/:id">
                <CalculatedFields />
              </Route>

              <Route path="/alerts" exact>
                <Alert />
              </Route>

              <Route path="/api_logs" exact>
                <ApiLogs />
              </Route>

              <Route path="/api_log_summary" exact>
                <ApiLog />
              </Route>

              {/* kalpana */}

              <Route path="/dashboard" exact>
                <SuperAdminDashboard />
              </Route>

              <Route path="/audit_logs" exact>
                <Audit_log />
              </Route>
              <Route path="/setup" exact>
                <Setup />
              </Route>
              <Route path="/data_field/:id" exact>
                <Data_field />
              </Route>
              <Route path="/asset_type/:id" exact>
                <Asset_Types />
              </Route>

              <Route path="/err_codes/:id" exact>
                <Err_codes />
              </Route>
              <Route path="/aqi/:id" exact>
                <AQI />
              </Route>
              <Route path="/remove_trends/:id" exact>
                <Remove_Trends />
              </Route>
              <Route path="/remove_alert/:id" exact>
                <Remove_Alerts />
              </Route>
              <Route path="/iot_solution_masters" exact>
                <Iot_solution_master />
              </Route>
              <Route path="/asset_test_type" exact>
                <Asset_test_type />
              </Route>
              <Route path="/param_alert_array/:id" exact>
                <Param_Alert_Asset />
              </Route>
              <Route path="/industry" exact>
                <Repo_industry />
              </Route>
              {/* <Route path="/analytics" exact>
                <Analytics />
              </Route> */}
              <Route path="/account_user" exact>
                <Account_user />
              </Route>
              <Route path="/asset_category" exact>
                <Repo_asset_category />
              </Route>
              <Route path="/mqtt_broker" exact>
                <Mqtt_broker />
              </Route>
              <Route path="/admin_user" exact>
                <Admin_user />
              </Route>
              <Route path="/asset_manufacturer" exact>
                <Repo_asset_manufacturer />
              </Route>
              <Route path="/assets" exact>
                <Repo_assets />
              </Route>
              <Route path="/api_master" exact>
                <Repo_apimaster />
              </Route>
              <Route path="/department" exact>
                <Repo_department />
              </Route>
              <Route path="/location" exact>
                <Repo_location />
              </Route>
              <Route path="/electrical_symbol" exact>
                <Repo_electrical_symbol />
              </Route>
              <Route path="/sensor_manufacturer" exact>
                <Repo_sensor_manufacturer />
              </Route>
              <Route path="/sensor_type" exact>
                <Repo_sensor_type />
              </Route>
              <Route path="/oil_type" exact>
                <Repo_oil_type />
              </Route>
              <Route path="/oil_vendor" exact>
                <Repo_oil_vendor />
              </Route>
              <Route path="/oil_standard" exact>
                <Repo_oil_standard />
              </Route>
              <Route path="/service_logs" exact>
                <ServiceLog />
              </Route>
              <Route path="/apitable_log" exact>
               <Apitable />
             </Route>

              {/* <Redirect to="/" /> */}
            </div>
          </div>
        </Switch>
      </Router>
    );
  } else {
    routes = (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Admin_user_login />
          </Route>
          {/* <Redirect to="/" exact /> */}
        </Switch>
      </Router>
    );
  }
  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: isLoggedIn,
          userId: userId,
          login: login,
          logout: logout,
        }}
      >
        <main>{routes}</main>
      </AuthContext.Provider>
    </>
  );
}

export default App;
