/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import DataTable from "react-data-table-component";
// import Button from "react-bootstrap/Button";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { tableCustomStyles } from "../tableStyle.jsx";

function Alert() {
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [alert, setAlert] = useState([]);
  const [Status, setStatus] = useState("disable");

  // useEffect(() => {
  //   fetch("https://localhost:5000/Alert/getAllAlerts")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setAlert(data);
  //       setTotal(data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, []);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Alert/getAllAlerts`
      );

      setAlert(response.data);
      setTotal(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.alert_name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setAlert(result);
  }, [search]);

  const [inpval, setINP] = useState({
    device_uuid: "",
    sensor_uuid: "",
    iot_sol: "",
    subdomain: "",
    assset: "",
    department: "",
    location: "",
    subaccount: "",
    parameter_name: "",
    parameter_value: "",
    alert_name: "",
    message: "",
    created_at: "",
    updated_at: "",
  });

  function handleEdit(e) {
    // setINP({inpval: e.target.value})
    setINP({ ...inpval, [e.alert.name]: e.target.value });
    // console.log(Status);
  }

  // const history = useHistory("");
  const updateAlert = async (_id) => {
    let id = inpval._id;
    // console.log(id);
    const res2 = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Alert/updateAlert/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inpval),
      }
    );

    if (res2.status === 201) {
      // console.log("successful");
      alert("alert has been edited");
    } else {
      console.log("err");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      device_uuid,
      sensor_uuid,
      iot_sol,
      subdomain,
      assset,
      department,
      location,
      subaccount,
      parameter_name,
      parameter_value,
      alert_name,
      message,
      created_at,
      updated_at,
    } = inpval;

    let res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Alert/createAlert`,
      {
        method: "POST",
        body: JSON.stringify({
          device_uuid,
          sensor_uuid,
          iot_sol,
          subdomain,
          assset,
          department,
          location,
          subaccount,
          parameter_name,
          parameter_value,
          alert_name,
          message,
          created_at,
          updated_at,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );

    const data = await res.json();
    // console.log(data);

    if (res.status === 422 || !data) {
      alert("please insert data");
    } else {
      setINP(data);
      alert("Alert successfully added");
    }
  };

  const columns = [
    {
      name: "Alert Name",
      selector: (row) => <div>{row.alert_name}</div>,
      sortable: true,
    },
    {
      name: "Device Uuid",
      selector: (row) => <div>{row.device_uuid}</div>,
      sortable: true,
    },
    {
      name: "Sensor UUID",
      selector: (row) => <div>{row.sensor_uuid}</div>,
      sortable: true,
    },
    {
      name: "Iot Sol",
      selector: (row) => <div>{row.iot_sol}</div>,
      sortable: true,
    },
    {
      name: "Subdomain",
      selector: (row) => <div>{row.subdomain}</div>,
      sortable: true,
    },

    {
      name: "Asset",
      selector: (row) => <div>{row.assset}</div>,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => <div>{row.department}</div>,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => <div>{row.location}</div>,
      sortable: true,
    },
    {
      name: "Subaccount",
      selector: (row) => <div className="bigtext">{row.subaccount}</div>,
      sortable: true,
    },
    {
      name: "Parameter Name",
      selector: (row) => <div>{row.parameter_name}</div>,
      sortable: true,
    },
    {
      name: "Parameter Value",
      selector: (row) => <div>{row.parameter_value}</div>,
      sortable: true,
    },
    {
      name: "Alert Name",
      selector: (row) => <div>{row.alert_name}</div>,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => <div className="bigtext">{row.message}</div>,
      sortable: true,
    },
    {
      name: "Created At ",
      selector: (row) => <div className="bigtext">{row.created_at}</div>,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => <div className="bigtext">{row.updated_at}</div>,
      sortable: true,
    },
  ];

  return (
    <>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Alerts</h1>
                </div>
                {/* <!-- /.col --> */}
                {/* <div class="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <a
                          href="addNewAssets.html"
                          type="button"
                          class="btn btn-c-outline-primary"
                          data-toggle="modal"
                          data-target="#addalert"
                        >
                          {" "}
                          + Add Alert{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => setSerach(e.target.value)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={alert}
                      pagination
                      noDataComponent
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                    {alert.length === 0 && <div class="loader"></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Alert;
