
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { useFormik } from "formik";
import { FormSchema } from "./Admin_User_FormSchema.js";

const Admin_user = () => {
  const [error1, setError1] = useState({});
  const [LoadedAdminUser, setLoadedAdminUser] = useState([]);

  const [email, setEmail] = useState("");

  const [AUEdit, setAUEdit] = useState({});
  const [repo_access, setRepo_access] = useState(AUEdit.repo_access);
  const [add_account_access, setAdd_account_access] = useState(
    AUEdit.add_account_access
  );
  const [status, setStatus] = useState(AUEdit.status);
  const [Status, set1Status] = useState({});
  const [edit, setEdit] = useState(false);

  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/admin_user/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.admin_users;
      var strAscending = [...json].sort((a, b) =>
        a.full_name > b.full_name ? 1 : -1
      );
      setLoadedAdminUser(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.full_name?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.role?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.email?.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.full_name > b.full_name ? 1 : -1
    );
    setLoadedAdminUser(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedAdminUser.reverse.filter((test) => {
      return (
        test.full_name?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.role?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.email?.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.full_name > b.full_name ? 1 : -1
    );

    setLoadedAdminUser(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const formInitialValues = {
    full_name: "",
    email: "",
    decrypted_password: "",
    department: "",
    role: "",
  };
  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        console.log(values);
        let domain = "motwane.com";
        console.log(domain);

        return await fetch(`${process.env.REACT_APP_VARIABLE}/api/admin_user`, {
          method: "POST",
          body: JSON.stringify({
            full_name1: values.full_name,
            email: values.email + "@" + domain,
            decrypted_password: values.decrypted_password,
            department: values.department,
            repo_access: repo_access,
            add_account_access: add_account_access,
            role: values.role,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            alert("Admin User added successfully");
            values.full_name = "";
            values.email = "";
            values.decrypted_password = "";
            values.department = "";
            values.role = "";
            fetchData();
          })
          .catch((error) => {
            alert(
              error.message +
                ": Email already exist, could not create Admin User."
            );
            values.full_name = "";
            values.email = "";
            values.decrypted_password = "";
            values.department = "";
            values.role = "";
          });
      },
    });

  const editAUid = async (_id) => {
    let auid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/admin_user/get/${auid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAUEdit(res.adminUsers);
    var mailArr = res.adminUsers.email.split("@");
    setEmail(mailArr[0]);
    console.log(AUEdit.repo_access);
    console.log(AUEdit.add_account_access);
  };
console.log("AUEdit",AUEdit);
  const FormInitialValues = {
    full_name: AUEdit.full_name,
    email: email,
    decrypted_password: AUEdit.decrypted_password,
    department: AUEdit.department,
    role: AUEdit.role,
  };

  console.log(FormInitialValues);
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      // console.log(values);
      let domain = "motwane.com";
      console.log(domain);
      let auid = AUEdit._id;

      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/admin_user/${auid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            full_name1: values.full_name,
            decrypted_password: values.decrypted_password,
            email: values.email + "@" + domain,
            department: values.department,
            repo_access: repo_access,
            add_account_access: add_account_access,
            sign_in_count: AUEdit.sign_in_count,
            role: values.role,
          }),
        }
      );
      const res = await response.json();
      if (response.status === 200) {
        alert(" Admin Users updated successfully");
        fetchData();
      } else {
        alert(res.message);
      }
    },
  });
  console.log(formik.initialValues);
  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    let auid = AUEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/admin_user/${auid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );
    const res = await response.json();
    if (res) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }

      alert(" Admin Users " + StatusText + " successfully");

      fetchData();
    } else {
      alert(res.message);
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => <div>{row.full_name}</div>,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => <div>{row.role}</div>,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => <div className="bigtext">{row.email}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a
          href={row._id}
          onClick={() => {
            editAUid(row._id);
          }}
        >
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status ? "enable" : "disable"}
                  onChange={(e) => {
                    set1Status(e.target.value);
                  }}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status ? "enable" : "disable"}
                  onChange={(e) => {
                    set1Status(e.target.value);
                  }}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => {
                    setStatus("enable");
                  }}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => {
                    setStatus("disable");
                  }}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },

    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editAUid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editadminuser"
          ></i>
        </a>
      ),
    },
  ];
  const handleCancel = (e) => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Admin Users</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addadminuser.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addadminuser"
                      >
                        {" "}
                        + Add Admin User{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <div id="example_filter" className="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedAdminUser}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Name</b>
                                      </th>
                                      <th>
                                        <b>Role</b>
                                      </th>
                                      <th>
                                        <b>Email</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={6}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addadminuser"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addadminuser"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Admin User
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="sector" className="col-form-label">
                              Full Name *
                            </label>
                            <input
                              type="text"
                              name="full_name"
                              value={values.full_name}
                              onBlur={handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={handleChange}
                              placeholder="Enter Full Name"
                            />
                            {errors.full_name && touched.full_name ? (
                              <span style={{ color: "red" }}>
                                {errors.full_name}
                              </span>
                            ) : null}{" "}
                          </div>

                          <div className="form-group row">
                            <label for="contactNo" className="col-form-label">
                              Email * enter motwane email
                            </label>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                              <input
                                type="text"
                                name="email"
                                value={values.email}
                                onBlur={handleBlur}
                                className="form-control"
                                id="locName"
                                onChange={handleChange}
                                placeholder="Enter email"
                              />
                              {errors.email && touched.email ? (
                                <span style={{ color: "red" }}>
                                  {errors.email}
                                </span>
                              ) : null}{" "}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-1">
                              <h3>@</h3>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-5">
                              <input
                                type="text"
                                value="motwane.com"
                                readOnly
                                className="form-control"
                                id="locName"
                                // onLoad={(e) => setDomain("motwane.com")}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Password *
                            </label>
                            <input
                              type="text"
                              name="decrypted_password"
                              value={values.decrypted_password}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Password"
                            />{" "}
                            {errors.decrypted_password &&
                            touched.decrypted_password ? (
                              <span style={{ color: "red" }}>
                                {errors.decrypted_password}
                              </span>
                            ) : null}{" "}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Department *
                            </label>
                            <input
                              type="text"
                              name="department"
                              value={values.department}
                              onBlur={handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={handleChange}
                              placeholder="Enter Department"
                            />{" "}
                            {errors.department && touched.department ? (
                              <span style={{ color: "red" }}>
                                {errors.department}
                              </span>
                            ) : null}{" "}
                          </div>
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Role *
                            </label>

                            <div className="col-sm-10 col-md-10 col-lg-12">
                              {" "}
                              <select
                                id="ak"
                                name="role"
                                value={values.role}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Role
                                </option>

                                <option value="admin">admin</option>
                                <option value="tester">tester</option>
                              </select>
                              {errors.role && touched.role ? (
                                <span style={{ color: "red" }}>
                                  {errors.role}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="switchList">
                            <p for="" className="">
                              Repo Access
                            </p>
                            <div className="switch">
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  name="status"
                                  value={Status ? "enable" : "disable"}
                                  onChange={(e) => set1Status(e.target.value)}
                                />

                                <span>
                                  <span
                                    id="enable"
                                    onClick={(e) => setRepo_access("enable")}
                                  >
                                    Enable{" "}
                                  </span>
                                  <span
                                    id="disable"
                                    onClick={(e) => setRepo_access("disable")}
                                  >
                                    Disable{" "}
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                            <div className="clearfix mb10"></div>
                          </div>
                          <div className="switchList">
                            <p for="" className="">
                              Add Account Access
                            </p>
                            <div className="switch">
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  name="status"
                                  value={Status ? "enable" : "disable"}
                                  onChange={(e) => set1Status(e.target.value)}
                                />

                                <span>
                                  <span
                                    id="enable"
                                    onClick={(e) =>
                                      setAdd_account_access("enable")
                                    }
                                  >
                                    Enable{" "}
                                  </span>
                                  <span
                                    id="disable"
                                    onClick={(e) =>
                                      setAdd_account_access("disable")
                                    }
                                  >
                                    Disable{" "}
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                            <div className="clearfix mb10"></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit}
                          data-dismiss={
                            values.full_name &&
                            values.email &&
                            values.department &&
                            values.role &&
                            values.decrypted_password
                              ? "modal"
                              : ""
                          }
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editadminuser"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editadminuser"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Admin User
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hi8dden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="sector" className="col-form-label">
                              Full Name *
                            </label>
                            <input
                              type="text"
                              name="full_name"
                              value={formik.values.full_name}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={formik.handleChange}
                              placeholder="Enter Full Name"
                            />{" "}
                            {formik.errors.full_name &&
                            formik.touched.full_name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.full_name}
                              </span>
                            ) : null}{" "}
                          </div>

                          <div className="form-group row">
                            <label for="contactNo" className="col-form-label">
                              Email * enter motwane email
                            </label>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                              <input
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                onChange={formik.handleChange}
                                placeholder="Enter Email"
                              />
                              {formik.errors.email && formik.touched.email ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.email}
                                </span>
                              ) : null}{" "}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-1">
                              <h3>@</h3>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-5">
                              <input
                                type="text"
                                value="motwane.com"
                                readOnly
                                className="form-control"
                                id="locName"
                                // onLoad={(e) => setDomain("motwane.com")}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Password *
                            </label>
                            <input
                              type="text"
                              name="decrypted_password"
                              value={formik.values.decrypted_password}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={formik.handleChange}
                              placeholder="Enter Password"
                            />{" "}
                            {formik.errors.decrypted_password &&
                            formik.touched.decrypted_password ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.decrypted_password}
                              </span>
                            ) : null}{" "}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Department *
                            </label>
                            <input
                              type="text"
                              name="department"
                              value={formik.values.department}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={formik.handleChange}
                              placeholder="Enter Department"
                            />{" "}
                            {formik.errors.department &&
                            formik.touched.department ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.department}
                              </span>
                            ) : null}{" "}
                          </div>
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Role *
                            </label>
                            <input
                              type="text"
                              name="role"
                              value={formik.values.role}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={formik.handleChange}
                              placeholder="Enter Role"
                            />{" "}
                            {formik.errors.role && formik.touched.role ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.role}
                              </span>
                            ) : null}{" "}
                          </div>
                          <div className="switchList">
                            <p for="" className="">
                              Repo Access
                            </p>
                            <div className="switch">
                              {" "}
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  value={Status ? "enable" : "disable"}
                                  onChange={(e) => set1Status(e.target.value)}
                                  checked={AUEdit.repo_access === "disable"}
                                />

                                <span>
                                  <span
                                    id="enable"
                                    onClick={(e) => setRepo_access("enable")}
                                  >
                                    Enable{" "}
                                  </span>
                                  <span
                                    id="disable"
                                    onClick={(e) => setRepo_access("disable")}
                                  >
                                    Disable{" "}
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                            <div className="clearfix mb10"></div>
                          </div>
                          <div className="switchList">
                            <p for="" className="">
                              Add Account Access
                            </p>
                            <div className="switch">
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  //  name="add_account_access"
                                  value={Status ? "enable" : "disable"}
                                  onChange={(e) => set1Status(e.target.value)}
                                  checked={
                                    AUEdit.add_account_access === "disable"
                                  }
                                />

                                <span>
                                  <span
                                    id="enable"
                                    onClick={(e) =>
                                      setAdd_account_access("enable")
                                    }
                                  >
                                    Enable{" "}
                                  </span>
                                  <span
                                    id="disable"
                                    onClick={(e) =>
                                      setAdd_account_access("disable")
                                    }
                                  >
                                    Disable{" "}
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                            <div className="clearfix mb10"></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={formik.handleSubmit}
                          data-dismiss={
                            formik.values.full_name &&
                            formik.values.email &&
                            formik.values.decrypted_password &&
                            formik.values.department &&
                            formik.values.role
                              ? "modal"
                              : ""
                          }
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Admin User?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Admin User?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Admin_user;
