/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function EditIotSensorMaster() {
  const [inpval, setINP] = useState({});
  const { state } = useLocation();

  const [Status, set1Status] = useState({});

  const [batteryfield, setbatteryfield] = useState([{}]);

  const [total_batteries, settotal_batteries] = useState(
    state.IotEdit.total_batteries
  );

  const handleBatteriesChange = (event, index) => {
    const newStrings = [...total_batteries];
    newStrings[index] = event.target.value;
    settotal_batteries(newStrings);
  };

  /////// tapsfield//////
  const [taps, settaps] = useState(state.IotEdit.taps);

  const addInputtaps = (e) => {
    e.preventDefault();
    settaps([...taps, {}]);
  };

  const removetap = (e, index) => {
    e.preventDefault();
    const rows = [...taps];
    rows.splice(index, 1);
    settaps(rows);
  };

  const handletap = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...taps];
    list[index][name] = value;
    settaps(list);
  };

  //////// add nameplate///////

  const handlenameplate = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...nameplate];
    list[index][name] = value;
    setnameplate(list);
  };

  const [nameplate, setnameplate] = useState(state.IotEdit.nameplate);
  const [hv_connection, sethv_connection] = useState(
    state.IotEdit.nameplate.hv_connection
  );
  const [voltage_unbalance_limit, setvoltage_unbalance_limit] = useState(
    state.IotEdit.nameplate.voltage_unbalance_limit
  );
  const [current_unbalance_limit, setcurrent_unbalance_limit] = useState(
    state.IotEdit.nameplate.voltage_unbalance_limit
  );
  const [energy_rate_rs, setenergy_rate_rs] = useState(
    state.IotEdit.nameplate.energy_rate_rs
  );
  const [designed_efficiency, setdesigned_efficiency] = useState(
    state.IotEdit.nameplate.designed_efficiency
  );
  const [last_hotspot, setlast_hotspot] = useState(
    state.IotEdit.nameplate.last_hotspot
  );
  const [winding_constant, setwinding_constant] = useState(
    state.IotEdit.nameplate.winding_constant
  );
  const [eddy_loss_per_unit, seteddy_loss_per_unit] = useState(
    state.IotEdit.nameplate.eddy_loss_per_unit
  );
  const [last_actual_life_hours, setlast_actual_life_hours] = useState(
    state.IotEdit.nameplate.last_actual_life_hours
  );
  const [last_standard_life_hours, setlast_standard_life_hours] = useState(
    state.IotEdit.nameplate.last_standard_life_hours
  );
  const [lv2_connection, setlv2_connection] = useState(
    state.IotEdit.nameplate.lv2_connection
  );
  const [amb_temp, setamb_temp] = useState(state.IotEdit.nameplate.amb_temp);
  const [lv1_connection, setlv1_connection] = useState(
    state.IotEdit.nameplate.lv1_connection
  );
  const [phase, setphase] = useState(state.IotEdit.nameplate.phase);
  const [winding_material, setwinding_material] = useState(
    state.IotEdit.nameplate.winding_material
  );
  const [
    winding_temp_at_winding_resistance,
    setwinding_temp_at_winding_resistance,
  ] = useState(state.IotEdit.nameplate.winding_temp_at_winding_resistance);
  const [tap_position, settap_position] = useState(
    state.IotEdit.nameplate.tap_position
  );
  const [secondary_side, setsecondary_side] = useState(
    state.IotEdit.nameplate.secondary_side
  );
  const [primary_side, setprimary_side] = useState(
    state.IotEdit.nameplate.primary_side
  );
  const [
    max_wind_temp_rise_above_amb_temp,
    setmax_wind_temp_rise_above_amb_temp,
  ] = useState(state.IotEdit.nameplate.max_wind_temp_rise_above_amb_temp);
  const [
    max_oil_temp_rise_above_amb_temp,
    setmax_oil_temp_rise_above_amb_temp,
  ] = useState(state.IotEdit.nameplate.max_oil_temp_rise_above_amb_temp);
  const [rated_hotspot, setrated_hotspot] = useState(
    state.IotEdit.nameplate.rated_hotspot
  );
  const [rated_top_oil_temp, setrated_top_oil_temp] = useState(
    state.IotEdit.nameplate.rated_top_oil_temp
  );
  const [full_load_loss_kw, setfull_load_loss_kw] = useState(
    state.IotEdit.nameplate.full_load_loss_kw
  );
  const [no_load_loss_kw, setno_load_loss_kw] = useState(
    state.IotEdit.nameplate.no_load_loss_kw
  );
  const [rated_copper_loss_kw, setrated_copper_loss_kw] = useState(
    state.IotEdit.nameplate.rated_copper_loss_kw
  );
  const [Rating_va, setRating_va] = useState(state.IotEdit.nameplate.Rating_va);
  const [lv_rated_current, setlv_rated_current] = useState(
    state.IotEdit.nameplate.lv_rated_current
  );
  const [lv_connection, setlv_connection] = useState(
    state.IotEdit.nameplate.lv_connection
  );
  const [designed_load_percent, setdesigned_load_percent] = useState(
    state.IotEdit.nameplate.designed_load_percent
  );
  const [load_variation, setload_variation] = useState(
    state.IotEdit.nameplate.load_variation
  );
  const [
    prev_cumulative_active_energy_lv,
    setprev_cumulative_active_energy_lv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_active_energy_lv);
  const [
    prev_cumulative_reactive_energy_lv,
    setprev_cumulative_reactive_energy_lv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_reactive_energy_lv);
  const [
    prev_cumulative_apparent_energy_lv,
    setprev_cumulative_apparent_energy_lv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_apparent_energy_lv);
  const [
    prev_cumulative_active_energy_lv1,
    setprev_cumulative_active_energy_lv1,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_active_energy_lv1);
  const [
    prev_cumulative_reactive_energy_lv1,
    setprev_cumulative_reactive_energy_lv1,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_reactive_energy_lv1);
  const [
    prev_cumulative_apparent_energy_lv1,
    setprev_cumulative_apparent_energy_lv1,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_apparent_energy_lv1);
  const [
    prev_cumulative_active_energy_lv2,
    setprev_cumulative_active_energy_lv2,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_active_energy_lv2);
  const [
    prev_cumulative_reactive_energy_lv2,
    setprev_cumulative_reactive_energy_lv2,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_reactive_energy_lv2);
  const [
    prev_cumulative_apparent_energy_lv2,
    setprev_cumulative_apparent_energy_lv2,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_apparent_energy_lv2);
  const [
    prev_cumulative_active_energy_hv,
    setprev_cumulative_active_energy_hv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_active_energy_hv);
  const [
    prev_cumulative_reactive_energy_hv,
    setprev_cumulative_reactive_energy_hv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_reactive_energy_hv);
  const [
    prev_cumulative_apparent_energy_hv,
    setprev_cumulative_apparent_energy_hv,
  ] = useState(state.IotEdit.nameplate.prev_cumulative_apparent_energy_hv);
  const [lv_cum_energy, setlv_cum_energy] = useState(
    state.IotEdit.nameplate.lv_cum_energy
  );
  const [hv_cum_energy, sethv_cum_energy] = useState(
    state.IotEdit.nameplate.hv_cum_energy
  );
  const [cooling_type, setcooling_type] = useState(
    state.IotEdit.nameplate.cooling_type
  );
  const [oil, setoil] = useState(state.IotEdit.nameplate.oil);
  const [voltage_ratio, setvoltage_ratio] = useState(
    state.IotEdit.nameplate.voltage_ratio
  );

  ///////// add sensor ///////
  // const [sensor_fields, setsensor_fields] = useState(
  //   state.IotEdit.sensor_fields
  // );
  // const addsensorFields = (e) => {
  //   e.preventDefault();
  //   setsensor_fields([...sensor_fields, {}]);
  // };

  // const removesensorFields = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...sensor_fields];
  //   rows.splice(index, 1);
  //   setsensor_fields(rows);
  // };

  // const handlesensorFields = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...sensor_fields];
  //   list[index][name] = value;
  //   setsensor_fields(list);
  // };

  ///////calculated fields ///////

  // const [calculated_fields, setcalculated_fields] = useState(
  //   state.IotEdit.calculated_fields
  // );

  // const handleChange = (key, field, value) => {
  //   setcalculated_fields((prevObject) => ({
  //     ...prevObject,
  //     [key]: {
  //       ...prevObject[key],
  //       [field]: value,
  //     },
  //   }));
  // };

  // const addcalculatedFields = (e) => {
  //   e.preventDefault();
  //   setcalculated_fields([...calculated_fields, {}]);
  // };

  // const removecalculatedFields = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...calculated_fields];
  //   rows.splice(index, 1);
  //   setcalculated_fields(rows);
  // };

  // const handlecalculatedFields = (index, evn) => {
  //   const { name, value } = evn.target;
  //   const list = [...calculated_fields];
  //   list[index][name] = value;
  //   setcalculated_fields(list);
  // };

  // function handleEdit(e) {
  //   setINP({ ...inpval, [e.target.name]: e.target.value });
  //   setcalculated_fields({
  //     ...calculated_fields,
  //     [e.target.name]: e.target.value,
  //   });
  //   set1Status(e.target.value);
  // }
  // for edit

  const [device_status, setdevice_status] = useState(
    state.IotEdit.device_status
  );
  const [mqtt, setmqtt] = useState(state.IotEdit.mqtt);
  const [pf_correction, setpf_correction] = useState(
    state.IotEdit.pf_correction
  );
  const [calculation, setcalculation] = useState(state.IotEdit.calculation);
  // const [device_battery_status, setdevice_battery_status] = useState(
  //   state.IotEdit.device_battery_status
  // );

  const [iot_sol, setiot_sol] = useState(state.IotEdit.iot_sol);
  const [device_uuid, setdevice_uuid] = useState(state.IotEdit.device_uuid);
  const [sensor_uuid, setsensor_uuid] = useState(state.IotEdit.sensor_uuid);
  const [iot_subaccount, setiot_subaccount] = useState(
    state.IotEdit.iot_subaccount
  );
  const [iot_location, setiot_location] = useState(state.IotEdit.iot_location);
  const [iot_department, setiot_department] = useState(
    state.IotEdit.iot_department
  );
  const [iot_asset, setiot_asset] = useState(state.IotEdit.iot_asset);
  const [latitude, setlatitude] = useState(state.IotEdit.latitude);
  const [longitude, setlongitude] = useState(state.IotEdit.longitude);
  const [data_freq_min, setdata_freq_min] = useState(
    state.IotEdit.data_freq_min
  );

  const [iot_asset_category, setiot_asset_category] = useState(
    state.IotEdit.iot_asset_category
  );
  const [iot_asset_manufacturer, setiot_asset_manufacturer] = useState(
    state.IotEdit.iot_asset_manufacturer
  );
  const [iot_sr_no, setiot_sr_no] = useState(state.IotEdit.iot_sr_no);
  const [iot_manufacturing_yr, setiot_manufacturing_yr] = useState(
    state.IotEdit.iot_manufacturing_yr
  );
  const [seq_id, setseq_id] = useState(state.IotEdit.seq_id);
  const [seq_tag, setseq_tag] = useState(state.IotEdit.seq_tag);
  const [sub_asset, setsub_asset] = useState(state.IotEdit.sub_asset);
  const [ups_bank, setups_bank] = useState(state.IotEdit.ups_bank);
  const [efficiency, setefficiency] = useState(state.IotEdit.efficiency);
  const [name, setname] = useState(state.IotEdit.name);

  // const [battery, setbatteries] = useState([]);

  const [total_fields, settotal_fields] = useState(state.IotEdit.total_fields);
  const [total_sub_assets, settotal_sub_assets] = useState(
    state.IotEdit.total_sub_assets
  );

  const [company_name, setcompany_name] = useState("");
  const [accounts, setAccounts] = useState({});

  // const [subaccount_company_name, setsubaccount_company_name] = useState("");

  const [subaccount_name, setsubaccount_name] = useState("");
  const [subaccounts, setsubaccount] = useState({});

  const [iot_solution, setiot_solution] = useState("");
  const [IotsolutionMasters, setIotsolutionMasters] = useState({});

  const [RepoCategory, setRepoCategory] = useState("");
  const [categories, setRepoCategories] = useState({});

  const [RepoManufacturer, setRepoManufacturer] = useState("");
  const [manufacturers, setmanufacturers] = useState({});

  const [assets, setAssets] = useState({});
  const [asset_name, setasset_name] = useState("");

  const [location, setlocation] = useState("");
  const [locations, setlocations] = useState({});

  const [department_name, setdepartment_name] = useState("");
  const [departments, setdepartments] = useState({});

  const [subdomain, setsubdomain] = useState("");
  const [subdomains, setsubdomains] = useState({});

  const [asset_type, setasset_type] = useState("");
  const [asset_types, setasset_types] = useState({});

  const FormSchema = Yup.object({
    company_name: Yup.string().required("Please Enter company name!"),
    subaccount_name: Yup.string().required("Please Enter subaccount name!"),
    iot_solution: Yup.string().required("Please Enter iot solution!"),
    RepoCategory: Yup.string().required("Please Enter RepoCategory!"),
    RepoManufacturer: Yup.string().required("Please Enter RepoManufacturer!"),
    asset_name: Yup.string().required("Please Enter asset name!"),
    location: Yup.string().required("Please Enter location!"),
    department_name: Yup.string().required("Please Enter department name!"),

    device_uuid: Yup.string().required("Please Enter device uuid!"),
    sensor_uuid: Yup.string().required("Please Enter sensor uuid!"),
    subdomain: Yup.string().required("Please Enter subdomain!"),
    // asset_type: Yup.string().required("Please Enter asset type!"),
    seq_id: Yup.string().required("Please Enter seq id!"),
    seq_tag: Yup.string().required("Please Enter seq tag!"),
    sub_asset: Yup.string().required("Please Enter sub asset!"),
    latitude: Yup.string().required("Please Enter latitude!"),
    longitude: Yup.string().required("Please Enter longitude!"),
    data_freq_min: Yup.string().required("Please Enter data freq min!"),
    ups_bank: Yup.string().required("Please Enter ups bank!"),
    efficiency: Yup.string().required("Please Enter efficiency!"),
    name: Yup.string().required("Please Enter name!"),
    iot_sr_no: Yup.string().required("Please Enter iot sr no!"),
    iot_manufacturing_yr: Yup.string().required(
      "Please Enter iot manufacturing yr!"
    ),
    iot_asset_category: Yup.string().required(
      "Please Enter iot asset category!"
    ),
    iot_asset_manufacturer: Yup.string().required(
      "Please Enter iot asset manufacturer!"
    ),
  });

  let FormInitialValues = {
    device_uuid: state.IotEdit.device_uuid,
    sensor_uuid: state.IotEdit.sensor_uuid,
    subdomain: state.IotEdit.subdomain,
    // asset_type: state.IotEdit.asset_type,
    seq_id: state.IotEdit.seq_id,
    seq_tag: state.IotEdit.seq_tag,
    sub_asset: state.IotEdit.sub_asset,
    latitude: state.IotEdit.latitude,
    longitude: state.IotEdit.longitude,
    data_freq_min: state.IotEdit.data_freq_min,
    ups_bank: state.IotEdit.ups_bank,
    efficiency: state.IotEdit.efficiency,
    name: state.IotEdit.name,
    iot_sr_no: state.IotEdit.iot_sr_no,
    iot_manufacturing_yr: state.IotEdit.iot_manufacturing_yr,
    iot_asset_category: state.IotEdit.iot_asset_category,
    iot_asset_manufacturer: state.IotEdit.iot_asset_manufacturer,

    company_name: state.IotEdit.company_name,
    subaccount_name: state.IotEdit.subaccount_name,
    iot_solution: state.IotEdit.iot_solution,
    RepoCategory: state.IotEdit.RepoCategory,
    RepoManufacturer: state.IotEdit.RepoManufacturer,
    asset_name: state.IotEdit.asset_name,
    location: state.IotEdit.location,
    department_name: state.IotEdit.department_name,
  };

  // const updateIot = async (e) => {
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let _id = state.IotEdit.id;
      // console.log(_id);
      // e.preventDefault();
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/updateIotSensorMaster/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            device_uuid: values.device_uuid,
            sensor_uuid: values.sensor_uuid,
            subdomain: values.subdomain,
            asset_type,
            seq_id: values.seq_id,
            seq_tag: values.seq_tag,
            sub_asset: values.sub_asset,
            latitude: values.latitude,
            longitude: values.longitude,
            data_freq_min: values.data_freq_min,
            ups_bank: values.ups_bank,
            efficiency: values.efficiency,
            name: values.name,
            iot_sr_no: values.iot_sr_no,
            iot_manufacturing_yr: values.iot_manufacturing_yr,
            iot_asset_category: values.iot_asset_category,
            iot_asset_manufacturer: values.iot_asset_manufacturer,

            company_name: values.company_name,
            // subaccount_company_name,
            subaccount_name: values.subaccount_name,
            iot_solution: values.iot_solution,
            RepoCategory: values.RepoCategory,
            RepoManufacturer: values.RepoManufacturer,
            asset_name: values.asset_name,
            location: values.location,
            department_name: values.department_name,

            device_status: device_status,
            iot_subaccount: iot_subaccount,
            iot_location: iot_location,
            iot_department: iot_department,
            iot_asset: iot_asset,

            taps: taps,
            nameplate: nameplate,
            total_batteries: total_batteries,
            // sensor_fields: sensor_fields,
            // calculated_fields: calculated_fields,
            pf_correction: pf_correction,
            calculation: calculation,

            // updated_at,
            // created_at,
            // device_battery_status,
            total_fields,
            total_sub_assets,
          }),
        }
      );

      const data = await res.json();
      // console.log(data);

      if (res.status === 200 || !data) {
        setINP(data);
        alert("successfully Edited");
        window.location = "/iot_sensor_masters";
      } else {
        alert("please insert data");
      }
    },
  });

  // update status

  // account api
  useEffect(() => {
    const getAllAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllAccounts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAccounts(res);
      // console.log(inpval);
    };
    getAllAccounts();
  }, []);
  const AccountData = accounts.accounts;

  // asset api
  useEffect(() => {
    const getAllAssets = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllAssets`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAssets(res);
      // console.log(inpval);
    };
    getAllAssets();
  }, []);
  const AssetData = assets.assets;

  //isl
  useEffect(() => {
    const getAllIotSolutionMaster = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllIotSolutionMasters`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setIotsolutionMasters(res);
      // console.log(inpval);
    };
    getAllIotSolutionMaster();
  }, []);
  const IotsolM = IotsolutionMasters.IotsolutionMasters;

  useEffect(() => {
    const getAllSubAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllSubAccounts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setsubaccount(res);
      // console.log(inpval);
    };
    getAllSubAccounts();
  }, []);
  const subData = subaccounts.subaccounts;

  const getAllLocations = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllLocations`,
      {
        method: "POST",
        body: JSON.stringify({
          subaccount_name: formik.values.subaccount_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setlocations(res);
    // console.log(inpval);
  };

  const locationData = locations.Repo_location;

  useEffect(() => {
    const getAllDepartments = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllDepartment`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setdepartments(res);
      // console.log(inpval);
    };
    getAllDepartments();
  }, []);
  const departmentData = departments.departments;

  useEffect(() => {
    const getAllcategories = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllcategories`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setRepoCategories(res);
      // console.log(inpval);
    };
    getAllcategories();
  }, []);
  const categoryData = categories.categories;

  useEffect(() => {
    const getAllmanufacturers = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllmanufacturers`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setmanufacturers(res);
      // console.log(inpval);
    };
    getAllmanufacturers();
  }, []);
  const manufaturerData = manufacturers.manufacturers;

  const getAllSubdomains = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllSubdomain`,
      {
        method: "POST",
        body: JSON.stringify({
          company_name: formik.values.company_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setsubdomains(res);
    // console.log(inpval);
  };

  const subdomainData = subdomains.Repo_subdomain;

  const getAllAsset = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllAsset`,
      {
        method: "POST",
        body: JSON.stringify({
          iot_sol: formik.values.iot_solution,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setasset_types(res);
    // console.log(inpval);
  };

  const assetDataS = asset_types.asset_type;

  return (
    <>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-sm-6">
                  <h1 className="pageTitle">Edit Iot Sensor Master</h1>
                </div>
              </div>

              <form className="">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="compName" className="col-form-label">
                        Device Status
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={device_status === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdevice_status("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdevice_status("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Iot Solution Master Name
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="iot_solution"
                          value={formik.values.iot_solution}
                          onBlur={formik.onBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true" value={iot_solution}>
                            {state.IotEdit.solution_master.iot_sol}
                          </option>
                          {IotsolM &&
                            IotsolM.map((am, index) => (
                              <option key={index} value={am.iot_sol}>
                                {am.iot_sol}
                              </option>
                            ))}
                        </select>
                        {formik.errors.iot_solution &&
                        formik.touched.iot_solution ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.iot_solution}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Account Name
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="company_name"
                          value={formik.values.company_name}
                          onBlur={formik.onBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true" value={company_name}>
                            {state.IotEdit.subaccount.name}
                          </option>
                          {AccountData &&
                            AccountData.map((am, index) => (
                              <option key={index} value={am.company_name}>
                                {am.company_name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.company_name &&
                        formik.touched.company_name ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.company_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Subdomain
                      </label>{" "}
                      <div>
                        <span onClick={getAllSubdomains}>
                          <select
                            id="ak"
                            name="subdomain"
                            value={formik.values.subdomain}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className="js-states form-control "
                            class="form-select"
                          >
                            <option disabled selected="true" value={subdomain}>
                              {state.IotEdit.subdomain}
                            </option>
                            {subdomainData &&
                              subdomainData.map((am, index) => (
                                <option key={index} defaultValue={am.subdomain}>
                                  {am.subdomain}
                                </option>
                              ))}
                            {AccountData &&
                              AccountData.map((am, index) => (
                                <option key={index} value={am.subdomain}>
                                  {am.subdomain}
                                </option>
                              ))}
                            {formik.errors.subdomain &&
                            formik.touched.subdomain ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.subdomain}
                              </span>
                            ) : null}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Subaccount
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="subaccount_name"
                          value={formik.values.subaccount_name}
                          onBlur={formik.onBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option
                            disabled
                            selected="true"
                            value={subaccount_name}
                          >
                            {state.IotEdit.subaccount.company_name}
                          </option>
                          {subData &&
                            subData.map((am, index) => (
                              <option key={index} value={am.name}>
                                {am.name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.subaccount_name &&
                        formik.touched.subaccount_name ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.subaccount_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Location
                      </label>
                      <div>
                        <span onClick={getAllLocations}>
                          <select
                            id="ak"
                            name="location"
                            value={formik.values.location}
                            onBlur={formik.onBlur}
                            onChange={formik.handleChange}
                            className="js-states form-control "
                            class="form-select"
                          >
                            <option disabled selected="true" value={location}>
                              {/* {formik.values.location} */}
                              {state.IotEdit.Repo_location.name}
                            </option>
                            {locationData &&
                              locationData.map((am, index) => (
                                <option key={index} value={am.location}>
                                  {am.location}
                                </option>
                              ))}
                          </select>
                          {formik.errors.location && formik.touched.location ? (
                            <span style={{ color: "red" }}>
                              {formik.errors.location}
                            </span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Department
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="department_name"
                          value={formik.values.department_name}
                          onBlur={formik.onBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option
                            disabled
                            selected="true"
                            value={department_name}
                          >
                            {state.IotEdit.Repo_department.name}
                          </option>
                          {departmentData &&
                            departmentData.map((am, index) => (
                              <option key={index} value={am.name}>
                                {am.name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.department_name &&
                        formik.touched.department_name ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.department_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Asset Name
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="asset_name"
                          value={formik.values.asset_name}
                          onBlur={formik.onBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true" value={asset_name}>
                            {state.IotEdit.repo_asset.name}
                          </option>
                          {AssetData &&
                            AssetData.map((am, index) => (
                              <option key={index} value={am.name}>
                                {am.name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.asset_name &&
                        formik.touched.asset_name ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.asset_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Device Battery Status
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? 1 : 0}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={device_battery_status === 0}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdevice_battery_status(1)}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdevice_battery_status(0)}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div> */}
                    {/* <div className="form-group">
                      <label htmlFor="compName" className="col-form-label">
                        Iot Sol
                      </label>
                      <input
                        value={iot_sol}
                        onChange={(e) => setiot_sol(e.target.value)}
                        type="text"
                        name="iot_sol"
                        className="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div> */}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Device Uuid
                      </label>
                      <input
                        type="text"
                        name="device_uuid"
                        value={formik.values.device_uuid}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.device_uuid &&
                      formik.touched.device_uuid ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.device_uuid}
                        </span>
                      ) : null}
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Location
                      </label>
                      <input
                        value={iot_location}
                        onChange={(e) => setiot_location(e.target.value)}
                        type="text"
                        name="iot_location"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Sensor Uuid
                      </label>
                      <input
                        type="text"
                        name="sensor_uuid"
                        value={formik.values.sensor_uuid}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.sensor_uuid &&
                      formik.touched.sensor_uuid ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.sensor_uuid}
                        </span>
                      ) : null}
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="compName" className="col-form-label">
                        Iot Department
                      </label>
                      <input
                        value={iot_department}
                        onChange={(e) => setiot_department(e.target.value)}
                        type="text"
                        name="iot_department"
                        className="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div> */}
                  </div>
                  {/* <div className="col-md-3"> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Asset
                      </label>
                      <input
                        value={iot_asset}
                        onChange={(e) => setiot_asset(e.target.value)}
                        type="text"
                        name="iot_asset"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                  {/* </div> */}
                  <div className="col-md-3">
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Subaccount
                      </label>
                      <input
                        value={iot_subaccount}
                        onChange={(e) => setiot_subaccount(e.target.value)}
                        type="text"
                        name="iot_subaccount"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}

                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Asset Type
                      </label>
                      <input
                        type="text"
                        name="asset_type"
                        value={formik.values.asset_type}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.asset_type && formik.touched.asset_type ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.asset_type}
                        </span>
                      ) : null}
                    </div> */}
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Asset Type
                      </label>
                      <div>
                        <span onClick={getAllAsset}>
                          <select
                            id="ak"
                            name="asset_type"
                            value={formik.values.asset_type}
                            // onBlur={handleBlur}
                            // onChange={handleChange}
                            onChange={(e) => setasset_type(e.target.value)}
                            className="js-states form-control "
                            class="form-select"
                          >
                            {/* <option></option> */}
                            <option
                              // disabled
                              //  selected="true"
                              value={asset_type}
                            >
                              {state.IotEdit.asset_type}
                            </option>

                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[0]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[1]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[2]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[3]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[4]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[5]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[6]}
                                </option>
                              ))}
                            {assetDataS &&
                              assetDataS.map((am, index) => (
                                <option
                                  key={index}
                                  defaultValue={am.asset_types}
                                >
                                  {am.asset_types[7]}
                                </option>
                              ))}
                          </select>
                          {/* {errors.asset_type && touched.asset_type ? (
                            <span style={{ color: "red" }}>
                              {errors.asset_type}
                            </span>
                          ) : null} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Seq Id
                      </label>
                      <input
                        type="number"
                        name="seq_id"
                        value={formik.values.seq_id}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.seq_id && formik.touched.seq_id ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.seq_id}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Seq Tag
                      </label>
                      <input
                        type="text"
                        name="seq_tag"
                        value={formik.values.seq_tag}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.seq_tag && formik.touched.seq_tag ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.seq_tag}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Sub Asset
                      </label>
                      <input
                        type="text"
                        name="sub_asset"
                        value={formik.values.sub_asset}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.sub_asset && formik.touched.sub_asset ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.sub_asset}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Latitude
                      </label>
                      <input
                        type="number"
                        name="latitude"
                        value={formik.values.latitude}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.latitude && formik.touched.latitude ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.latitude}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Longitude
                      </label>
                      <input
                        type="number"
                        name="longitude"
                        value={formik.values.longitude}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.longitude && formik.touched.longitude ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.longitude}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Data Frequency Min
                      </label>
                      <input
                        type="number"
                        name="data_freq_min"
                        value={formik.values.data_freq_min}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.data_freq_min &&
                      formik.touched.data_freq_min ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.data_freq_min}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label ">
                        Mqtt
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "Yes" : "No"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={mqtt === "No"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setmqtt("yes")}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Yes
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setmqtt("No")}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  No
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Ups Bank
                      </label>
                      <input
                        type="text"
                        name="ups_bank"
                        value={formik.values.ups_bank}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.ups_bank && formik.touched.ups_bank ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.ups_bank}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Efficiency
                      </label>
                      <input
                        type="number"
                        name="efficiency"
                        value={formik.values.efficiency}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.efficiency && formik.touched.efficiency ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.efficiency}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.name && formik.touched.name ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.name}
                        </span>
                      ) : null}
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Sensor Fields
                      </label>
                      <span data-toggle="modal" data-target="#sensorfields">
                        <input
                          value={state.IotEdit.sensor_fields}
                          onChange={(e) => setsensor_fields(sensor_fields)}
                          type="text"
                          name="sensor_fields"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                  </div>
                  <div className="col-md-3">
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Taps
                      </label>
                      <span data-toggle="modal" data-target="#addtaps">
                        <input
                          value={state.IotEdit.taps}
                          onChange={(e) => settaps(state.IotEdit.taps.tap_no)}
                          type="text"
                          name="taps"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Repo Asset Category
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="RepoCategory"
                          value={formik.values.RepoCategory}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true" value={RepoCategory}>
                            {state.IotEdit.RepoAssetCategory.name}
                          </option>
                          {categoryData &&
                            categoryData.map((am, index) => (
                              <option key={index} value={am.name}>
                                {am.name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.RepoCategory &&
                        formik.touched.RepoCategory ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.RepoCategory}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Repo Asset Manufacturer
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="RepoManufacturer"
                          value={formik.values.RepoManufacturer}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option
                            disabled
                            selected="true"
                            value={RepoManufacturer}
                          >
                            {state.IotEdit.RepoAssetManufacturer.name}
                          </option>
                          {manufaturerData &&
                            manufaturerData.map((am, index) => (
                              <option key={index} value={am.name}>
                                {am.name}
                              </option>
                            ))}
                        </select>
                        {formik.errors.RepoManufacturer &&
                        formik.touched.RepoManufacturer ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.RepoManufacturer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Nameplate
                      </label>
                      <span data-toggle="modal" data-target="#editnameplate">
                        <input
                          value={nameplate}
                          onChange={(e) => setnameplate(nameplate)}
                          type="text"
                          name="nameplate"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div>
                  </div>
                  {/* <div className="col-md-3"> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Total Batteries
                      </label>
                      <span data-toggle="modal" data-target="#addbattery">
                        <input
                          value={state.IotEdit.total_batteries}
                          onChange={(e) =>
                            setbatteryfield(state.IotEdit.total_batteries)
                          }
                          type="text"
                          name="total_batteries"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Calculated Fields
                      </label>
                      <span data-toggle="modal" data-target="#calculatedfields">
                        <input
                          value={state.IotEdit.calculated_fields}
                          onChange={(e) =>
                            setcalculated_fields(calculated_fields)
                          }
                          type="text"
                          name="calculated_fields"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                  {/* </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Iot Sr No
                      </label>
                      <input
                        type="text"
                        name="iot_sr_no"
                        value={formik.values.iot_sr_no}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.iot_sr_no && formik.touched.iot_sr_no ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.iot_sr_no}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Iot Manufacturing Year
                      </label>
                      <input
                        type="text"
                        name="iot_manufacturing_yr"
                        value={formik.values.iot_manufacturing_yr}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.iot_manufacturing_yr &&
                      formik.touched.iot_manufacturing_yr ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.iot_manufacturing_yr}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Iot Asset Category
                      </label>
                      <input
                        type="text"
                        name="iot_asset_category"
                        value={formik.values.iot_asset_category}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.iot_asset_category &&
                      formik.touched.iot_asset_category ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.iot_asset_category}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Iot Asset Manufacturer
                      </label>
                      <input
                        type="text"
                        name="iot_asset_manufacturer"
                        value={formik.values.iot_asset_manufacturer}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="locName"
                        placeholder=""
                        onChange={formik.handleChange}
                      />
                      {formik.errors.iot_asset_manufacturer &&
                      formik.touched.iot_asset_manufacturer ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.iot_asset_manufacturer}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Pf Correction
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={pf_correction === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setpf_correction("enable")}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setpf_correction("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label ">
                        Calculation
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={calculation === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setcalculation("enable")}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setcalculation("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label ">
                        Total Fields
                      </label>
                      <input
                        value={total_fields}
                        onChange={(e) => settotal_fields(e.target.value)}
                        type="number"
                        name="total_fields"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Total Sub Assets
                      </label>
                      <input
                        value={total_sub_assets}
                        onChange={(e) => settotal_sub_assets(e.target.value)}
                        type="number"
                        name="total_sub_assets"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center mt20 mb10">
                  <Link exact to={"/iot_sensor_masters"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                    data-dismiss={
                      formik.values.device_uuid &&
                      formik.values.sensor_uuid &&
                      formik.values.subdomain &&
                      // formik.values.asset_type &&
                      formik.values.seq_id &&
                      formik.values.seq_tag &&
                      formik.values.sub_asset &&
                      formik.values.latitude &&
                      formik.values.longitude &&
                      formik.values.data_freq_min &&
                      formik.values.ups_bank &&
                      formik.values.efficiency &&
                      formik.values.name &&
                      formik.values.iot_sr_no &&
                      formik.values.iot_manufacturing_yr &&
                      formik.values.iot_asset_category &&
                      formik.values.iot_asset_manufacturer &&
                      formik.values.company_name &&
                      formik.values.subaccount_name &&
                      formik.values.iot_solution &&
                      formik.values.RepoCategory &&
                      formik.values.RepoManufacturer &&
                      formik.values.asset_name &&
                      formik.values.location &&
                      formik.values.department_name
                        ? "modal"
                        : ""
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            {/* popups */}
            {/* total_batteries */}
            <div
              className="modal fade"
              id="addbattery"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addbattery"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Total Batteries
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      {/* <div className="row">
                        <div className="col-md-12"> */}
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label"
                        ></label>
                        {/* <div className="container">
                              <div className="row"> */}
                        <div>
                          <ul>
                            {total_batteries?.map((item, index) => (
                              <li style={{ margin: "20px" }}>
                                <input
                                  key={index}
                                  type="text"
                                  value={item}
                                  onChange={(event) =>
                                    handleBatteriesChange(event, index)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* </div>
                            </div> */}
                      </div>
                      {/* </div>
                      </div> */}
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) => settotal_batteries(total_batteries)}
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* taps */}
            <div
              className="modal fade"
              id="addtaps"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Edit Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div className="container">
                                  {state.IotEdit.taps &&
                                    state.IotEdit.taps?.map((object, index) => (
                                      <span
                                        data-toggle="modal"
                                        data-target="#subtaps"
                                      >
                                        <input
                                          value={index}
                                          onChange={(e) => settaps(object)}
                                          type="text"
                                          name="taps"
                                          className="form-control"
                                          id="contactNo"
                                          placeholder=""
                                        />
                                      </span>
                                    ))}

                                  <div className="col-sm-4"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) => settaps(taps)}
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* sub taps */}
            <div
              className="modal fade"
              id="subtaps"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="subtaps"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">edit Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  {/*  */}
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {state.IotEdit.taps.map((data, index) => {
                                    const tap_no = data.tap_no;
                                    const hv_phase_resistance =
                                      data.hv_phase_resistance;
                                    const lv_phase_resistance =
                                      data.lv_phase_resistance;
                                    const lv1_phase_resistance =
                                      data.lv1_phase_resistance;
                                    const lv2_phase_resistance =
                                      data.lv2_phase_resistance;
                                    const lv3_phase_resistance =
                                      data.lv3_phase_resistance;
                                    const rated_hv_voltage =
                                      data.rated_hv_voltage;
                                    const rated_lv_voltage =
                                      data.rated_lv_voltage;
                                    const rated_lv1_voltage =
                                      data.rated_lv1_voltage;
                                    const rated_lv2_voltage =
                                      data.rated_lv2_voltage;
                                    const rated_lv3_voltage =
                                      data.rated_lv3_voltage;
                                    const hv_rated_current =
                                      data.hv_rated_current;
                                    const turns_ratio = data.turns_ratio;
                                    const turns_ratio_1 = data.turns_ratio_1;
                                    const turns_ratio_2 = data.turns_ratio_2;
                                    const turns_ratio_3 = data.turns_ratio_3;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Tap No
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={tap_no}
                                              name="tap_no"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Hv Phase Resistance
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={hv_phase_resistance}
                                              name="hv_phase_resistance"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Lv Phase Resistance
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={lv_phase_resistance}
                                              name="lv_phase_resistance"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Lv1 Phase Resistance
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={lv1_phase_resistance}
                                              name="lv1_phase_resistance"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Lv2 Phase Resistance
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={lv2_phase_resistance}
                                              name="lv2_phase_resistance"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Lv3 Phase Resistance
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={lv3_phase_resistance}
                                              name="lv3_phase_resistance"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Rated Hv Voltage
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={rated_hv_voltage}
                                              name="rated_hv_voltage"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Rated Lv Voltage
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={rated_lv_voltage}
                                              name="rated_lv_voltage"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Rated Lv1 Voltage
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={rated_lv1_voltage}
                                              name="rated_lv1_voltage"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Rated Lv2 Voltage
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={rated_lv2_voltage}
                                              name="rated_lv2_voltage"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Rated Lv3 Voltage
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={rated_lv3_voltage}
                                              name="rated_lv3_voltage"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Hv Rated Current
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={hv_rated_current}
                                              name="hv_rated_current"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Turns Ratio
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={turns_ratio}
                                              name="turns_ratio"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Turns Ratio 1
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={turns_ratio_1}
                                              name="turns_ratio_1"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Turns Ratio 2
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={turns_ratio_2}
                                              name="turns_ratio_2"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label
                                              htmlFor="contactNo"
                                              className="col-form-label"
                                            >
                                              Turns Ratio 3
                                            </label>
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={turns_ratio_3}
                                              name="turns_ratio_3"
                                              className="form-control"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>

                                        {/* <div className="col-md-2">
                                          {taps.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div> */}
                                      </div>
                                    );
                                  })}

                                  {/* <div className="row">
                                    <div className="col-sm-12">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) => settaps(taps)}
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* nameplate */}
            <div
              className="modal fade"
              id="editnameplate"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editnameplate"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Nameplate
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        {/* <div className="col-md-12"> */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Hv Connection
                            </label>
                            <input
                              value={hv_connection}
                              onChange={(e) => sethv_connection(e.target.value)}
                              type="text"
                              name="hv_connection"
                              className="form-control"
                              id="contactNo"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Lv Connection
                            </label>
                            <input
                              value={lv_connection}
                              onChange={(e) => setlv_connection(e.target.value)}
                              type="text"
                              name="lv_connection"
                              className="form-control"
                              id="contactNo"
                              placeholder=""
                            />
                          </div>
                        </div>

                        {/* // */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Lv Rated Current
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setlv_rated_current(e.target.value)
                              }
                              value={lv_rated_current}
                              name="lv_rated_current"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Rating Va
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setRating_va(e.target.value)}
                              value={Rating_va}
                              name="Rating_va"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Rated Copper Loss Kw
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setrated_copper_loss_kw(e.target.value)
                              }
                              value={rated_copper_loss_kw}
                              name="rated_copper_loss_kw"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              No Load Loss Kw
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setno_load_loss_kw(e.target.value)
                              }
                              value={no_load_loss_kw}
                              name="no_load_loss_kw"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              full load Loss Kw
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setfull_load_loss_kw(e.target.value)
                              }
                              value={full_load_loss_kw}
                              name="full_load_loss_kw"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Rated Top Oil Temp
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setrated_top_oil_temp(e.target.value)
                              }
                              value={rated_top_oil_temp}
                              name="rated_top_oil_temp"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Rated Hotspot
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setrated_hotspot(e.target.value)}
                              value={rated_hotspot}
                              name="rated_hotspot"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Max Oil Temp Rise Above Amb Temp
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setmax_oil_temp_rise_above_amb_temp(
                                  e.target.value
                                )
                              }
                              value={max_oil_temp_rise_above_amb_temp}
                              name="max_oil_temp_rise_above_amb_temp"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Max Wind Temp Rise Above Amb Temp
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setmax_wind_temp_rise_above_amb_temp(
                                  e.target.value
                                )
                              }
                              value={max_wind_temp_rise_above_amb_temp}
                              name="max_wind_temp_rise_above_amb_temp"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Primary Side
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setprimary_side(e.target.value)}
                              value={primary_side}
                              name="primary_side"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Secondary Side
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setsecondary_side(e.target.value)
                              }
                              value={secondary_side}
                              name="secondary_side"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Tap Position
                            </label>
                            <input
                              type="text"
                              onChange={(e) => settap_position(e.target.value)}
                              value={tap_position}
                              name="tap_position"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Winding Temp At Winding Resistance
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setwinding_temp_at_winding_resistance(
                                  e.target.value
                                )
                              }
                              value={winding_temp_at_winding_resistance}
                              name="winding_temp_at_winding_resistance"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              winding_material
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setwinding_material(e.target.value)
                              }
                              value={winding_material}
                              name="winding_material"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Phase
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setphase(e.target.value)}
                              value={phase}
                              name="phase"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Lv1 Connection
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setlv1_connection(e.target.value)
                              }
                              value={lv1_connection}
                              name="lv1_connection"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Amb Temp
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setamb_temp(e.target.value)}
                              value={amb_temp}
                              name="amb_temp"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Lv2 Connection
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setlv2_connection(e.target.value)
                              }
                              value={lv2_connection}
                              name="lv2_connection"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Last Standard Life Hours
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setlast_standard_life_hours(e.target.value)
                              }
                              value={last_standard_life_hours}
                              name="last_standard_life_hours"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Last Actual Life Hours
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setlast_actual_life_hours(e.target.value)
                              }
                              value={last_actual_life_hours}
                              name="last_actual_life_hours"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Eddy Loss Per Unit
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                seteddy_loss_per_unit(e.target.value)
                              }
                              value={eddy_loss_per_unit}
                              name="eddy_loss_per_unit"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Winding Constant
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setwinding_constant(e.target.value)
                              }
                              value={winding_constant}
                              name="winding_constant"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Last Hotspot
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setlast_hotspot(e.target.value)}
                              value={last_hotspot}
                              name="last_hotspot"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Designed Efficiency
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setdesigned_efficiency(e.target.value)
                              }
                              value={designed_efficiency}
                              name="designed_efficiency"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Energy Rate Rs
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setenergy_rate_rs(e.target.value)
                              }
                              value={energy_rate_rs}
                              name="energy_rate_rs"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Current Unbalance Limit
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setcurrent_unbalance_limit(e.target.value)
                              }
                              value={current_unbalance_limit}
                              name="current_unbalance_limit"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Voltage Unbalance Limit
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setvoltage_unbalance_limit(e.target.value)
                              }
                              value={voltage_unbalance_limit}
                              name="voltage_unbalance_limit"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Designed Load Percent
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setdesigned_load_percent(e.target.value)
                              }
                              value={designed_load_percent}
                              name="designed_load_percent"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Load Variation
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setload_variation(e.target.value)
                              }
                              value={load_variation}
                              name="load_variation"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Active Energy Lv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_active_energy_lv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_active_energy_lv}
                              name="prev_cumulative_active_energy_lv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Reactive Energy Lv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_reactive_energy_lv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_reactive_energy_lv}
                              name="prev_cumulative_reactive_energy_lv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Apparent Energy Lv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_apparent_energy_lv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_apparent_energy_lv}
                              name="prev_cumulative_apparent_energy_lv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Active Energy Lv1
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_active_energy_lv1(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_active_energy_lv1}
                              name="prev_cumulative_active_energy_lv1"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Reactive Energy Lv1
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_reactive_energy_lv1(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_reactive_energy_lv1}
                              name="prev_cumulative_reactive_energy_lv1"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Apparent Energy Lv1
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_apparent_energy_lv1(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_apparent_energy_lv1}
                              name="prev_cumulative_apparent_energy_lv1"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Active Energy Lv2
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_active_energy_lv2(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_active_energy_lv2}
                              name="prev_cumulative_active_energy_lv2"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Reactive Energy Lv2
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_reactive_energy_lv2(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_reactive_energy_lv2}
                              name="prev_cumulative_reactive_energy_lv2"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Apparent Energy Lv2
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_apparent_energy_lv2(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_apparent_energy_lv2}
                              name="prev_cumulative_apparent_energy_lv2"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Active Energy Hv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_active_energy_hv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_active_energy_hv}
                              name="prev_cumulative_active_energy_hv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Reactive Energy Hv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_reactive_energy_hv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_reactive_energy_hv}
                              name="prev_cumulative_reactive_energy_hv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Prev Cumulative Apparent Energy Hv
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setprev_cumulative_apparent_energy_hv(
                                  e.target.value
                                )
                              }
                              value={prev_cumulative_apparent_energy_hv}
                              name="prev_cumulative_apparent_energy_hv"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Lv Cum Energy
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setlv_cum_energy(e.target.value)}
                              value={lv_cum_energy}
                              name="lv_cum_energy"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Hv Cum Energy
                            </label>
                            <input
                              type="text"
                              onChange={(e) => sethv_cum_energy(e.target.value)}
                              value={hv_cum_energy}
                              name="hv_cum_energy"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Cooling Type
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setcooling_type(e.target.value)}
                              value={cooling_type}
                              name="cooling_type"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Oil
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setoil(e.target.value)}
                              value={oil}
                              name="oil"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Voltage Ratio
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setvoltage_ratio(e.target.value)}
                              value={voltage_ratio}
                              name="voltage_ratio"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) =>
                            setnameplate({
                              hv_connection,
                              lv_connection,
                              lv_rated_current,
                              Rating_va,
                              rated_copper_loss_kw,
                              no_load_loss_kw,
                              full_load_loss_kw,
                              rated_top_oil_temp,
                              rated_hotspot,
                              max_oil_temp_rise_above_amb_temp,
                              max_wind_temp_rise_above_amb_temp,
                              primary_side,
                              secondary_side,
                              tap_position,
                              winding_temp_at_winding_resistance,
                              winding_material,
                              phase,
                              lv1_connection,
                              amb_temp,
                              lv2_connection,
                              last_standard_life_hours,
                              last_actual_life_hours,
                              eddy_loss_per_unit,
                              winding_constant,
                              last_hotspot,
                              designed_efficiency,
                              energy_rate_rs,
                              current_unbalance_limit,
                              voltage_unbalance_limit,
                              designed_load_percent,
                              load_variation,
                              prev_cumulative_active_energy_lv,
                              prev_cumulative_reactive_energy_lv,
                              prev_cumulative_apparent_energy_lv,
                              prev_cumulative_active_energy_lv1,
                              prev_cumulative_reactive_energy_lv1,
                              prev_cumulative_apparent_energy_lv1,
                              prev_cumulative_active_energy_lv2,
                              prev_cumulative_reactive_energy_lv2,
                              prev_cumulative_apparent_energy_lv2,
                              prev_cumulative_active_energy_hv,
                              prev_cumulative_reactive_energy_hv,
                              prev_cumulative_apparent_energy_hv,
                              lv_cum_energy,
                              hv_cum_energy,
                              cooling_type,
                              oil,
                              voltage_ratio,
                            })
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* sensor_fields */}

            {/* calculated_fields */}
            {/* <div
              className="modal fade"
              id="calculatedfields"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="calculatedfields"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Calculated Fields
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      {/* <div className="row">
                        <div className="col-md-12"> 
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label"
                        ></label>
                        <div>
                          <div>
                            {Object.entries(calculated_fields).map(
                              ([key, value]) => (
                                <div key={key}>
                                  <>{key}</>
                                  <p>
                                    Param Name
                                    <input
                                      type="text"
                                      value={value.para_name}
                                      onChange={(e) =>
                                        handleChange(
                                          key,
                                          "para_name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </p>
                                  <p>
                                    Data Type
                                    <input
                                      type="text"
                                      value={value.data_type}
                                      onChange={(e) =>
                                        handleChange(
                                          key,
                                          "data_type",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </p>
                                </div>
                              )
                            )}
                          </div>

                          <div className="col-sm-4"></div>
                        </div>
                      </div>
                     
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          onClick={(e) =>
                            setcalculated_fields(calculated_fields)
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default EditIotSensorMaster;
