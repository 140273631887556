/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function EditAccount(props) {
  const [inpval, setINP] = useState({});
  const { state } = useLocation();

  const [Status, set1Status] = useState({});

  const [addOilCustomer, setaddOilCustomer] = useState([{}]);
  const [AcEdit, setAcEdit] = useState({});

  ///// addOilCustomer ///////
  const addInputField = (e) => {
    e.preventDefault();
    setaddOilCustomer([...addOilCustomer, " "]);
  };

  const removeaddOilCustomer = (e, index) => {
    e.preventDefault();
    const rows = [...addOilCustomer];
    rows.splice(index, 1);
    setaddOilCustomer(rows);
  };

  const handleChange = (index, e) => {
    // console.log("added");
    const { value } = e.target;
    const list = [...addOilCustomer];
    list[index] = value;
    setaddOilCustomer(list);
  };

  const [test_service_custom, settest_service_custom] = useState([{}]);

  const [testing_service_customer, settesting_service_customer] = useState();

  const handleInputChange = (event, index) => {
    const newStrings = [...testing_service_customer];
    newStrings[index] = event.target.value;
    settesting_service_customer(newStrings);
  };

  const [oil_customer, setoil_customer] = useState();

  const handleOilChange = (event, index) => {
    const newStrings = [...oil_customer];
    newStrings[index] = event.target.value;
    setoil_customer(newStrings);
  };

  ///// addOilCustomer ///////
  const addtesting_service_customer = (e) => {
    e.preventDefault();
    settesting_service_customer([...testing_service_customer, " "]);
  };

  const removetesting_service_customer = (e, index) => {
    e.preventDefault();
    const rows = [...testing_service_customer];
    rows.splice(index, 1);
    settesting_service_customer(rows);
  };

  const handletesting_service_customer = (index, e) => {
    // console.log("added");
    const { value } = e.target;
    const list = [...testing_service_customer];
    list[index] = value;
    settesting_service_customer(list);
  };

  function handleEdit(e) {
    setINP({ ...inpval, [e.target.name]: e.target.value });
  }

  // const [subdomain, setsubdomain] = useState(state.AcEdit?.subdomain);
  // const [id, setid] = useState(state.AcEdit.id);
  // const [company_name, setcompany_name] = useState(AcEdit?.company_name);
  /// const [email, setemail] = useState(AcEdit?.email);
  // const [address, setaddress] = useState(AcEdit?.address);

  const [contact_no, setcontact_no] = useState("");
  const handlePhoneChange = (value, country) => {
    setcontact_no(value);
  };

  // const [sector, setsector] = useState(AcEdit?.sector);

  // const [contact_person_name, setcontact_person_name] = useState(
  //   AcEdit?.contact_person_name
  // );

  const [contact_person_contact_no, setcontact_person_contact_no] = useState(
    AcEdit?.contact_person_contact_no
  );
  const handlecontact_noChange = (value, country) => {
    setcontact_person_contact_no(value);
  };

  // const [db_name, setdb_name] = useState(AcEdit?.db_name);
  // const [db_url, setdb_url] = useState(AcEdit?.db_url);
  // const [logo_url, setlogo_url] = useState(AcEdit?.logo_url);
  // const [landing_url, setlanding_url] = useState(AcEdit?.landing_url);

  // const [email_pack, setemail_pack] = useState(AcEdit?.email_pack);
  // const [sms_pack, setsms_pack] = useState(AcEdit?.sms_pack);
  // const [download_range, setdownload_range] = useState(AcEdit?.download_range);

  const [ble_module, setble_module] = useState("");
  const [complaints_module, setcomplaints_module] = useState(
    AcEdit?.complaints_module
  );
  const [sensor_module, setsensor_module] = useState(AcEdit?.sensor_module);
  const [addon, setaddon] = useState("");

  const [advanced_analysis, setadvanced_analysis] = useState(
    AcEdit?.advanced_analysis
  );
  const [asset_breakdown_addon, setasset_breakdown_addon] = useState("");
  const [asset_components, setasset_components] = useState("");

  const [chiller_device, setchiller_device] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [components, setcomponents] = useState("");
  const [conduct_test, setconduct_test] = useState("");
  const [custom_report_builder_addon, setcustom_report_builder_addon] =
    useState("");
  const [dashboard, setdashboard] = useState("");
  const [departmnet_components, setdepartmnet_components] = useState("");

  const [mdts, setMDTS] = useState("");
  const [download_device_excel, setdownload_device_excel] = useState("");
  const [device_sensors, setdevice_sensors] = useState("");
  const [email_service, setemail_service] = useState("");
  const [ems_device, setems_device] = useState("");
  const [location_components, setlocation_components] = useState("");
  const [mis_report_analysis, setmis_report_analysis] = useState("");
  const [motor_device, setmotor_device] = useState("");
  const [pivot_report_analysis, setpivot_report_analysis] = useState("");
  const [report_anaysis, setreport_anaysis] = useState("");
  const [setup, setsetup] = useState("");
  const [sms_service, setsms_service] = useState("");
  const [subaccount_components, setsubaccount_components] = useState("");
  const [test_repoprt_analysis, settest_repoprt_analysis] = useState("");
  const [transformer_device, settransformer_device] = useState("");
  const [air, setair] = useState("");

  const [ems_addon, setems_addon] = useState(AcEdit?.ems_addon);
  const [eqipment_components, seteqipment_components] = useState("");
  const [reports, setreports] = useState("");

  const [template_components, settemplate_components] = useState("");
  const [water, setwater] = useState("");
  const [iot, setiot] = useState("");

  const [oiltest_lab, setoiltest_lab] = useState("");
  const [oil_admin, setoil_admin] = useState("");

  const [gst_no, setgst_no] = useState(AcEdit?.gst_no);
  const [testing_service, settesting_service] = useState("");
  const [testing_service_customer_flag, settesting_service_customer_flag] =
    useState("");
  const [testing_service_admin, settesting_service_admin] = useState("");

  const [forward_integration, setforward_integration] = useState(
    AcEdit?.forward_integration
  );
  const [status, setstatus] = useState(AcEdit?.status);
  const [is_address_mandatory, setis_address_mandatory] = useState("");
  const [device_sensor, setdevice_sensor] = useState(AcEdit?.device_sensor);
  const editACid = async (_id) => {
    let id = state.AcEdit._id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/getAcId/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAcEdit(res.account1);

    console.log(AcEdit.account1);
  };
  useEffect(() => {
    editACid();
  }, []);
  useEffect(() => {
    setis_address_mandatory(AcEdit?.is_address_mandatory);
    settesting_service_customer(AcEdit?.testing_service_customer);
    setoil_customer(AcEdit?.oil_customer);
    setble_module(AcEdit?.ble_module);
    setaddon(AcEdit?.addon);
    setasset_breakdown_addon(AcEdit?.asset_breakdown_addon);
    setasset_components(AcEdit?.asset_components);
    setchiller_device(AcEdit?.chiller_device);
    setcomponents(AcEdit?.components);
    setconduct_test(AcEdit?.conduct_test);
    setcustom_report_builder_addon(AcEdit?.custom_report_builder_addon);
    setdashboard(AcEdit?.dashboard);
    setdepartmnet_components(AcEdit?.departmnet_components);
    setMDTS(AcEdit?.mdts);
    setdownload_device_excel(AcEdit?.download_device_excel);
    setdevice_sensors(AcEdit?.device_sensors);
    setemail_service(AcEdit?.email_service);
    setems_device(AcEdit?.ems_device);
    setlocation_components(AcEdit?.location_components);
    setmis_report_analysis(AcEdit?.mis_report_analysis);
    setmotor_device(AcEdit?.motor_device);
    setpivot_report_analysis(AcEdit?.pivot_report_analysis);
    setreport_anaysis(AcEdit?.report_anaysis);
    setsetup(AcEdit?.setup);
    setsms_service(AcEdit?.sms_service);
    setsubaccount_components(AcEdit?.subaccount_components);
    settest_repoprt_analysis(AcEdit?.test_repoprt_analysis);
    settransformer_device(AcEdit?.transformer_device);
    setair(AcEdit?.air);
    seteqipment_components(AcEdit?.eqipment_components);
    setreports(AcEdit?.reports);
    settemplate_components(AcEdit?.template_components);
    setwater(AcEdit?.water);
    setiot(AcEdit?.iot);
    setoiltest_lab(AcEdit?.oiltest_lab);
    setoil_admin(AcEdit?.oil_admin);
    settesting_service(AcEdit?.testing_service);
    settesting_service_customer_flag(AcEdit?.testing_service_customer_flag);
    settesting_service_admin(AcEdit?.testing_service_admin);
    setcontact_no(AcEdit?.contact_no);
    setcontact_person_contact_no(AcEdit?.contact_person_contact_no);
  }, [AcEdit?.is_address_mandatory]);
  console.log("ABC", email_service);
  console.log(
    "ismandatory",
    is_address_mandatory,
    AcEdit?.is_address_mandatory
  );
  console.log(" state.AcEdit", state.AcEdit);
  const FormSchema = Yup.object({
    subdomain: Yup.string().required("Please Enter subdomain!"),
    company_name: Yup.string().required("Please Enter company name!"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please Enter email!"),
    address: Yup.string().required("Please Enter address!"),
    contact_no: Yup.string().required("Please Enter contact no!"),
    contact_person_name: Yup.string().required(
      "Please Enter contact person name!"
    ),
    contact_person_contact_no: Yup.string().required(
      "Please Enter contact person contact no!"
    ),
    db_name: Yup.string().required("Please Enter db name!"),
    db_url: Yup.string().required("Please Enter db url!"),
    download_range: Yup.string().required("Please Enter download range!"),
    // email_pack: Yup.string().required("Please Enter email pack!"),
    email_pack: Yup.number()
      .integer("Please Enter a valid integer value for email pack!")
      .required("Please Enter email pack!"), //kv
    // sms_pack: Yup.string().required("Please Enter sms pack!"),
    sms_pack: Yup.number()
      .integer("Please Enter a valid integer value for SMS pack!")
      .required("Please Enter SMS pack!"), //kv...
  });

  let FormInitialValues = {
    subdomain: AcEdit?.subdomain,
    company_name: AcEdit?.company_name,
    email: AcEdit?.email,
    address: AcEdit?.address,
    contact_no: contact_no,
    contact_person_name: AcEdit?.contact_person_name,
    contact_person_contact_no: contact_person_contact_no,
    db_name: AcEdit?.db_name,
    db_url: AcEdit?.db_url,
    download_range: AcEdit?.download_range,
    landing_url: AcEdit?.landing_url,
    email_pack: AcEdit?.email_pack,
    sms_pack: AcEdit?.sms_pack,
    sector: AcEdit?.sector,
    is_address_mandatory: is_address_mandatory,
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    validationSchema: FormSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      let account_id = AcEdit._id;
      console.log(" AcEdit.id", AcEdit.id);
      const formData = new FormData();
      if (selectedImage) {
        formData.append("image", selectedImage);
      } else {
        formData.append("image", AcEdit.logo_url);
      }

      formData.append("subdomain", values.subdomain);
      formData.append("company_name", values.company_name);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("contact_person_name", values.contact_person_name);
      formData.append("contact_person_contact_no", contact_person_contact_no);
      formData.append("db_name", values.db_name);
      formData.append("download_range", values.download_range);
      formData.append("sector", values.sector);
      formData.append("sms_pack", values.sms_pack);
      formData.append("contact_no", contact_no);
      formData.append("db_url", values.db_url);
      formData.append("email_pack", values.email_pack);
      formData.append("landing_url", values.landing_url);
      formData.append("is_address_mandatory", is_address_mandatory);
      formData.append("ble_module", ble_module);
      formData.append("addon", addon);
      formData.append("asset_breakdown_addon", asset_breakdown_addon);
      formData.append("asset_components", asset_components);
      formData.append("components", components);
      formData.append("departmnet_components", departmnet_components);
      formData.append("device_sensors", device_sensors);
      formData.append("dashboard", dashboard);
      formData.append("mdts", mdts);
      formData.append(
        "custom_report_builder_addon",
        custom_report_builder_addon
      );
      formData.append("conduct_test", conduct_test);
      formData.append("chiller_device", chiller_device);
      formData.append("motor_device", motor_device);
      formData.append("mis_report_analysis", mis_report_analysis);
      formData.append("location_components", location_components);
      formData.append("ems_device", ems_device);
      formData.append("download_device_excel", download_device_excel);
      formData.append("email_service", email_service);
      formData.append("pivot_report_analysis", pivot_report_analysis);
      formData.append("setup", setup);
      formData.append("report_anaysis", report_anaysis);
      formData.append("sms_service", sms_service);
      formData.append("subaccount_components", subaccount_components);
      formData.append("test_repoprt_analysis", test_repoprt_analysis);
      formData.append("transformer_device", transformer_device);
      formData.append("air", air);
      formData.append("eqipment_components", eqipment_components);
      formData.append("reports", reports);
      formData.append("template_components", template_components);
      formData.append("water", water);
      formData.append("iot", iot);
      formData.append("oiltest_lab", oiltest_lab);
      formData.append("oil_admin", oil_admin);
      //  formData.append("gst_no", gst_no);
      formData.append("oil_customer", oil_customer);
      formData.append("testing_service_admin", testing_service_admin);
      formData.append(
        "testing_service_customer_flag",
        testing_service_customer_flag
      );
      formData.append("testing_service", testing_service);
      formData.append("testing_service_customer", testing_service_customer);
      // console.log(account_id);
      const res2 = await fetch(
        `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAccount/${account_id}`,
        {
          method: "PATCH",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: formData,
        }
      );
      // console.log(res2);
      if (res2.status === 200) {
        // console.log("successful");
        alert("account has been edited");
        window.location = "/accounts";
      } else {
        console.log("err");
      }
    },
  });

  return (
    <>
      <div class="content-wrapper">
        {/* <!-- Main content --> */}
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12 stickyDiv">
              <div class="col-sm-12">
                <h1 class="pageTitle">Edit Account</h1>
              </div>

              <div class="col-md-12">
                {/* <div class="row" style={{ position: "sticky", top: "0px", zIndex: "6" }} > */}
                <div class="col-md-12 col-sm-12">
                  <div class="card pr-card mb30">
                    <div class="card-body">
                      <div class="col-md-12">
                        <h6>
                          <b>Account details</b>
                        </h6>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Subdomain:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>Account 1</b></p> --> */}
                              <input
                                type="text"
                                name="subdomain"
                                value={formik.values.subdomain}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.subdomain &&
                              formik.touched.subdomain ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.subdomain}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Company Name:
                            </label>
                            <div class="col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>Tata</b></p> --> */}
                              <input
                                type="text"
                                name="company_name"
                                value={formik.values.company_name}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.company_name &&
                              formik.touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Address
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>Mumbai </b></p> --> */}
                              <input
                                type="text"
                                name="address"
                                value={formik.values.address}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.address &&
                              formik.touched.address ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.address}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Email:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                              <input
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.email && formik.touched.email ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.email}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Db Name:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                              <input
                                type="text"
                                name="db_name"
                                value={formik.values.db_name}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.db_name &&
                              formik.touched.db_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.db_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Contact No.:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>98123456786</b></p> --> */}
                              <PhoneInput
                                country={"in"}
                                name="contact_no"
                                value={contact_no}
                                // onBlur={formik.handleBlur}
                                id="locName"
                                placeholder=""
                                // onChange={formik.handleChange}
                                onChange={handlePhoneChange}
                              />
                              {/* {formik.errors.contact_no &&
                              formik.touched.contact_no ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.contact_no}
                                </span>
                              ) : null} */}
                              {/* <input
                                      type="number"
                                      name="contact_no"
                                      value={formik.values.contact_no}
                                      onBlur={formik.handleBlur}
                                      className="form-control"
                                      id="locName"
                                      placeholder=""
                                      onChange={formik.handleChange}
                                    /> */}
                              {/* {formik.errors.contact_no &&
                                    formik.touched.contact_no ? (
                                      <span style={{ color: "red" }}>
                                        {formik.errors.contact_no}
                                      </span>
                                    ) : null} */}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Contact Person:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>adsejr jnkjnjnk</b></p> --> */}
                              <input
                                type="text"
                                name="contact_person_name"
                                value={formik.values.contact_person_name}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.contact_person_name &&
                              formik.touched.contact_person_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.contact_person_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Contact Person No.:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>9923456789</b></p> --> */}
                              <PhoneInput
                                country={"in"}
                                // value={phoneNumber}
                                name="contact_person_contact_no"
                                value={contact_person_contact_no}
                                id="compName"
                                // onChange={(e) =>
                                //   setcontact_person_contact_no(e.target.value)
                                // }
                                // onBlur={formik.handleBlur}
                                placeholder=""
                                onChange={handlecontact_noChange}
                              />
                              {/* {formik.errors.contact_person_contact_no &&
                              formik.touched.contact_person_contact_no ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.contact_person_contact_no}
                                </span>
                              ) : null} */}
                              {/* <input
                                      type="number"
                                      name="contact_person_contact_no"
                                      value={
                                        formik.values.contact_person_contact_no
                                      }
                                      onBlur={formik.handleBlur}
                                      className="form-control"
                                      id="locName"
                                      placeholder=""
                                      onChange={formik.handleChange}
                                    />
                                    {formik.errors.contact_person_contact_no &&
                                    formik.touched.contact_person_contact_no ? (
                                      <span style={{ color: "red" }}>
                                        {
                                          formik.errors
                                            .contact_person_contact_no
                                        }
                                      </span>
                                    ) : null} */}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Db Url:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                              <input
                                type="text"
                                name="db_url"
                                value={formik.values.db_url}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.db_url && formik.touched.db_url ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.db_url}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label"
                            >
                              Landing Page:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                              {/* <input
                                value={landing_url}
                                onChange={(e) => setlanding_url(e.target.value)}
                                type="text"
                                name="landing_url"
                                className="form-control"
                                id="contactNo"
                                placeholder=""
                              /> */}
                              <select
                                id="ak"
                                name="landing_url"
                                value={formik.values.landing_url}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control "
                              >
                                <option
                                  value={formik.values.landing_url}
                                  disabled
                                  selected="true"
                                >
                                  {formik.values.landing_url
                                    ? formik.values.landing_url
                                    : "Select Landing Page"}
                                </option>

                                <option value="Dashboard">Dashboard</option>
                                <option value="Setup">Setup</option>
                                <option value="Conduct Test">
                                  Conduct Test
                                </option>
                                <option value="Test Reports">
                                  Test Reports
                                </option>
                                <option value="MIS Reports">MIS Reports</option>
                                <option value="Test Analysis">
                                  Test Analysis
                                </option>
                                <option value="Pivot Tables">
                                  Pivot Tables
                                </option>
                                <option value="Download">Download</option>
                                <option value="Transformer">Transformer</option>
                                <option value="Chiller Device">
                                  Chiller Device
                                </option>
                                <option value="Motor Device">
                                  Motor Device
                                </option>
                                <option value="EMS Device">EMS Device</option>
                                <option value="Subaccounts">Subaccounts</option>
                                <option value="Locations">Locations</option>
                                <option value="Departments">Departments</option>
                                <option value="Assets">Assets</option>
                                <option value="Asset Breakdowns">
                                  Asset Breakdowns
                                </option>
                                <option value="Equipments">Equipments</option>
                                <option value="Templates">Templates</option>
                                <option value="IoT">IoT</option>
                                <option value="MDTS">MDTS</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Download Range: <br /> (in days)
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>98123456786</b></p> --> */}
                              <input
                                type="number"
                                name="download_range"
                                value={formik.values.download_range}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.download_range &&
                              formik.touched.download_range ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.download_range}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Email Pack:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>adsejr jnkjnjnk</b></p> --> */}
                              <input
                                type="number"
                                name="email_pack"
                                value={formik.values.email_pack}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.email_pack &&
                              formik.touched.email_pack ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.email_pack}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label required-field"
                            >
                              Sms Pack:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              <input
                                type="number"
                                name="sms_pack"
                                value={formik.values.sms_pack}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="locName"
                                placeholder=""
                                onChange={formik.handleChange}
                              />
                              {formik.errors.sms_pack &&
                              formik.touched.sms_pack ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.sms_pack}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label "
                            >
                              Sector:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                              <input
                                type="text"
                                name="sector"
                                value={formik.values.sector}
                                className="form-control"
                                id="contactNo"
                                onChange={formik.handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for=""
                              class="col-md-12 col-lg-4 col-form-label"
                            >
                              Upload Logo:
                            </label>
                            <div class="readOnly col-md-12 col-lg-8">
                              <input type="file" onChange={handleImageUpload} />
                              {AcEdit.logo_url && (
                                <span>Uploaded Logo:{AcEdit.logo_url}</span>
                              )}

                              {/* <input
                                value={logo_url}
                                onChange={(e) => setlogo_url(e.target.value)}
                                type="text"
                                name="logo_url"
                                className="form-control"
                                id="contactNo"
                                placeholder=""
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* status */}

            <div class="switchListWpr">
              <div class="col-lg-12 col-md-12">
                <div class="row">
                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Status</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={status === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setstatus("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setstatus("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Email Service</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={email_service === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setemail_service("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setemail_service("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Sms Service</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={sms_service === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setsms_service("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setsms_service("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Is Address Mandatory</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? true : false}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={is_address_mandatory === false}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setis_address_mandatory(true)}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  True
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setis_address_mandatory(false)
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  False
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Dashboard</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={dashboard === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdashboard("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdashboard("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Setup</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={setup === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setsetup("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setsetup("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Conduct Test</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={conduct_test === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setconduct_test("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setconduct_test("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setcomponents("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setcomponents("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Subaccount Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={subaccount_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setsubaccount_components("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setsubaccount_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Location Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={location_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setlocation_components("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setlocation_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Departmnet Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={departmnet_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setdepartmnet_components("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setdepartmnet_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Asset Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={asset_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setasset_components("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setasset_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Eqipment Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={eqipment_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    seteqipment_components("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    seteqipment_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Template Components</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={template_components === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    settemplate_components("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    settemplate_components("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Reports</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={reports === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setreports("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setreports("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Report Analysis</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={report_anaysis === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setreport_anaysis("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setreport_anaysis("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Test Repoprt Analysis</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={test_repoprt_analysis === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    settest_repoprt_analysis("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    settest_repoprt_analysis("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Mis Report Analysis</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={mis_report_analysis === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setmis_report_analysis("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setmis_report_analysis("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Pivot Report Analysis</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={pivot_report_analysis === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setpivot_report_analysis("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setpivot_report_analysis("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Addon</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={addon === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setaddon("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setaddon("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Asset Breakdown Addon</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={asset_breakdown_addon === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setasset_breakdown_addon("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setasset_breakdown_addon("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Custom Report Builder Addon</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={
                                  custom_report_builder_addon === "disable"
                                }
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setcustom_report_builder_addon("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setcustom_report_builder_addon("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Ble Module</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={ble_module === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setble_module("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setble_module("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Iot</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={iot === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setiot("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setiot("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Device Sensors</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={device_sensors === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdevice_sensors("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdevice_sensors("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Transformer Device</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={transformer_device === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    settransformer_device("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    settransformer_device("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Motor Device</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={motor_device === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setmotor_device("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setmotor_device("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Chiller Device</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={chiller_device === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setchiller_device("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setchiller_device("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Ems Device</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={ems_device === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setems_device("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setems_device("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Air</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={air === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setair("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setair("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Water</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={water === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setwater("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setwater("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>MDTS</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={mdts === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setMDTS("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setMDTS("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Download Device Excel</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={download_device_excel === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setdownload_device_excel("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setdownload_device_excel("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Oiltest Lab</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={oiltest_lab === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setoiltest_lab("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setoiltest_lab("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Oil Admin</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={oil_admin === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setoil_admin("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setoil_admin("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body row addCustWpr">
                        <div class="col-md-4 col-sm-12">
                          <p>Oil Customer</p>
                        </div>
                        <div
                          class="col-md-8 col-sm-12"
                          data-toggle="modal"
                          data-target="#addoilcustomer"
                        >
                          <input
                            value={AcEdit.oil_customer}
                            onChange={(e) =>
                              setaddOilCustomer(AcEdit.oil_customer)
                            }
                            type="text"
                            name="total_batteries"
                            className="form-control"
                            id="contactNo"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Testing Service customer Flag</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={
                                  testing_service_customer_flag === "disable"
                                }
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    settesting_service_customer_flag("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    settesting_service_customer_flag("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Testing Service Admin</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={testing_service_admin === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    settesting_service_admin("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    settesting_service_admin("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body row addCustWpr">
                        <div class="col-md-4 col-sm-12">
                          <p>Testing Service Customer</p>
                        </div>
                        <div
                          class="col-md-8 col-sm-12"
                          data-toggle="modal"
                          data-target="#testing_service_customer"
                        >
                          <input
                            value={AcEdit.testing_service_customer}
                            onChange={(e) =>
                              settesting_service_customer(
                                testing_service_customer
                              )
                            }
                            type="text"
                            name="testing_service_customer"
                            className="form-control"
                            id="contactNo"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Testing Service</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                checked={testing_service === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => settesting_service("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => settesting_service("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Advanced Analysis</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={advanced_analysis === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setadvanced_analysis("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setadvanced_analysis("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Forward Integration</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          forward_integration === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setforward_integration("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setforward_integration("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}

                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Complaints Module</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={complaints_module === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) =>
                                    setcomplaints_module("enable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) =>
                                    setcomplaints_module("disable")
                                  }
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Sensor Module</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={sensor_module === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setsensor_module("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setsensor_module("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Device Sensor</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={device_sensor === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdevice_sensor("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdevice_sensor("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4 col-sm-12">
                    <div class="card pr-card">
                      <div class="card-body switchList">
                        <p>Ems Addon</p>
                        <a>
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={Status ? "enable" : "disable"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={ems_addon === "disable"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setems_addon("enable")}
                                  data-toggle="modal"
                                  // data-target="#enableconfirmation"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setems_addon("disable")}
                                  data-toggle="modal"
                                  // data-target="#disableconfirmation"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Gst No</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={gst_no === "disable"}
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setgst_no("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setgst_no("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}
                </div>
              </div>
            </div>
            <div className="text-center mt20 mb10">
              <Link exact to={"/accounts"}>
                <button
                  type="button"
                  className="btn btn-secondary mr10 mb10"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </Link>
              <button
                onClick={formik.handleSubmit}
                type="button"
                className="btn btn-primary mb10"
                data-dismiss={
                  formik.values.subdomain &&
                  formik.values.company_name &&
                  formik.values.email &&
                  formik.values.address &&
                  formik.values.contact_no &&
                  formik.values.contact_person_name &&
                  formik.values.contact_person_contact_no &&
                  formik.values.db_name &&
                  formik.values.db_url &&
                  formik.values.download_range &&
                  formik.values.email_pack &&
                  formik.values.sms_pack
                    ? "modal"
                    : ""
                }
              >
                Submit
              </button>
            </div>

            {/* <!-- /.col-12 --> */}
          </div>

          {/* popup */}
          {/* oil customer */}
          <div
            className="modal fade"
            id="addoilcustomer"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addoilcustomer"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content" style={{ width: "1250px" }}>
                <div className="modal-header">
                  <img
                    src="assets/images/adminLogo.png"
                    alt="Motware Logo"
                    width="40px"
                    className="brand-image"
                  />
                  <h5 className="modal-title w-100 text-center">
                    Edit Oil Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="">
                    {/* <div className="row">
                        <div className="col-md-12"> */}
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label"
                      ></label>
                      {/* <div className="container">
                              <div className="row"> */}
                      <div>
                        <ul>
                          {oil_customer &&
                            oil_customer.map((item, index) => (
                              <li style={{ margin: "20px" }}>
                                <input
                                  key={index}
                                  type="text"
                                  value={item}
                                  onChange={(event) =>
                                    handleOilChange(event, index)
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                      {/* </div>
                            </div> */}
                    </div>
                    {/* </div>
                      </div> */}
                    <div className="text-center mt20 mb10">
                      <button
                        type="button"
                        className="btn btn-primary mr10"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={(e) => setoil_customer(oil_customer)}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* testing service */}
          <div
            className="modal fade"
            id="testing_service_customer"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="testing_service_customer"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content" style={{ width: "1250px" }}>
                <div className="modal-header">
                  <img
                    src="assets/images/adminLogo.png"
                    alt="Motware Logo"
                    width="40px"
                    className="brand-image"
                  />
                  <h5 className="modal-title w-100 text-center">
                    Edit Testing Service Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="">
                    {/* <div className="row">
                        <div className="col-md-12"> */}
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label"
                      ></label>
                      <div>
                        <div>
                          <ul>
                            {testing_service_customer &&
                              testing_service_customer.map((item, index) => (
                                <li style={{ margin: "20px" }}>
                                  <input
                                    key={index}
                                    type="text"
                                    value={item}
                                    onChange={(event) =>
                                      handleInputChange(event, index)
                                    }
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>

                        <div className="col-sm-4"></div>
                      </div>
                    </div>

                    <div className="text-center mt20 mb10">
                      <button
                        type="button"
                        className="btn btn-primary mr10"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        onClick={(e) =>
                          settesting_service_customer(testing_service_customer)
                        }
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default EditAccount;
