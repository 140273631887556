/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DataTable from "react-data-table-component";
import React, { useEffect, useContext, useState } from "react";
import noteContext from "../noteContext";
import axios from "axios";
// import subaccountsModel from "../../backend/models/subaccount.js";
// import subaccountsModel from "../../backend/models/subaccount";
//import { validate } from "../../backend/models/accounts";
import { tableCustomStyles } from "../tableStyle";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const Accounts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [user1, setSubCount] = useState([]);
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [Status, set1Status] = useState({});
  const [AcEdit, setAcEdit] = useState({});
  const [status, setStatus] = useState(AcEdit?.status);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedSubdomain, setSelectedSubdomain] = useState("");
  const [accountId, setAccountId] = useState(""); // For dynamic account_id
  const [emailOptions, setEmailOptions] = useState([]); // State to hold fetched email options
  const [selectedEmails, setSelectedEmails] = useState([]); // To hold selected emails

  const context = useContext(noteContext);



  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Fetch emails from the API
  const fetchEmails = async (accountId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_VARIABLE}/api/account_user/getAllUsers`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ account_id: accountId }),
      });
      const data = await response.json();
      if (data.emailids && data.emailids.length > 0) {
        const options = data.emailids.map((email) => ({
          value: email,
          label: email,
        }));
        setEmailOptions(options); // Set options for the dropdown
      } else {
        setEmailOptions([]); // Empty email list
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  // Formik form for modal
  const formik = useFormik({
    initialValues: {
      subdomain: selectedSubdomain,
      email: "",
      report_name: "",
      selectedEmails: [], // Add selectedEmails as part of the form values
    },
    enableReinitialize: true, // Ensure form re-initializes with selected row
    validationSchema: Yup.object({
      subdomain: Yup.string().required("Subdomain is required"),
      // Validation for the email field
      email: Yup.string()
        .test(
          'at-least-one-email', // Name of the test
          'At least one email must be provided either manually or from the dropdown',
          function (value) {
            const { selectedEmails } = this.parent; // Access selected emails from the form's parent context
            // Check if there's at least one selected email from the dropdown or a manual email entered
            return selectedEmails.length > 0 || (value && value.trim() !== "");
          }
        )
        .test(
          'valid-email-format',
          'Please enter valid email addresses',
          function (value) {
            const { selectedEmails } = this.parent;

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email regex for format validation

            // Validate emails from dropdown
            const areSelectedEmailsValid = selectedEmails.every((email) => emailRegex.test(email.value));

            // Validate manually entered emails
            const manualEmails = value ? value.split(",").map((email) => email.trim()) : [];
            const areManualEmailsValid = manualEmails.every((email) => emailRegex.test(email));

            return areSelectedEmailsValid && areManualEmailsValid;
          }
        ),
      report_name: Yup.string().required("Report Name is required"),
    }),
    onSubmit: async (values) => {
      const emailList = values.selectedEmails.map((email) => email.value);
      // Remove duplicates
      // Split the additional email field by commas and trim the values
      const additionalEmails = values.email
        ? values.email.split(",").map((email) => email.trim())
        : [];
      // Combine the selected emails and additional emails
      const combinedEmails = [...emailList, ...additionalEmails];
      const uniqueEmails = [...new Set(combinedEmails)];
      // Check for duplicate emails
      if (combinedEmails.length !== uniqueEmails.length) {
        alert("Duplicate email IDs found.");
        return;
      }
      // Prepare the API payload
      const requestData = {
        account_id: accountId,
        email_ids: uniqueEmails,
        report_name: values.report_name,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/customeReports/addCustomerReportsDetails`, // Update this URL as necessary
          {
            method: "POST", // Use POST method as per your requirement
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        const res = await response.json(); // Await for the response
        if (response.status === 200) { // Adjust based on your API's success status

          alert(res.message);
          setIsModalOpen(false);
          window.location.reload(); // Refresh the page

        } else {
          alert(res.message);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error sending customer report details");
      }
    },
  });


  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/accountsModel/getSubAccounts`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.subdomain > b.subdomain ? 1 : -1
      );
      setSubCount(strAscending);

      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  const editACid = async (_id) => {
    let id = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/getAcId/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAcEdit(res.account1);

    console.log(AcEdit.account1);
  };

  const handleAcStatus = async (e) => {
    e.preventDefault();
    let id = AcEdit._id;
    // console.log(id);
    // let status = AcEdit.status;
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAc/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        // body: JSON.stringify(AcEdit.status),
        body: JSON.stringify({
          AcEdit,
        }),
      }
    );
    if (response.status === 201) {
      alert("Account updated successfully");
    } else {
      alert("Some error occured");
    }
    // console.log(AcEdit);
  };

  const handleStatus = async (e) => {
    e.preventDefault();
    let id = AcEdit._id;
    // console.log(id);
    // console.log("status:", status);
    //let status = AcEdit.status;
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAc/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        // body: JSON.stringify(AcEdit.status),
        body: JSON.stringify({
          status: status,
        }),
      }
    );
    let res = response.json();
    if (res) {
      setEdit(true);
    }
    if (response.status === 201) {
      setEdit(false);
      alert("Status updated successfully");

      fetchData();
    } else {
      alert("Some error occured");
    }
    // console.log(AcEdit);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = user1.reverse.filter((test) => {
      return (
        test.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.SubAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.LocAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.DeptAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.AssetsCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.UsersCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.TempletsCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setSubCount(strAscending);

    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    formik.handleSubmit();
  };
  useEffect(() => {
    sessionStorage.getItem("token");
  });

  useEffect(() => {
    fetchData();
  }, []);

  // const fetchcount = () => {
  //   return fetch("${process.env.REACT_APP_VARIABLE}/accountsModel/getSubAccounts")
  //         .then((response) => response.json())
  //         .then((subacCount) => setSubCount(subacCount));
  // }

  // useEffect(() => {

  //   fetchcount();
  // },[])
  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.SubAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.LocAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.DeptAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.AssetsCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.UsersCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.TempletsCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setSubCount(strAscending);

    if (strAscending) {
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  console.log("user1", user1);
  // const [inpval, setINP] = useState({
  //   status: "",
  // });
  const columns = [
    {
      name: "Subdomain",
      selector: (row) => <div className="bigtext"> {row.subdomain}</div>,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => <div className="bigtext"> {row.name}</div>,
      sortable: true,
    },
    {
      name: "Subaccounts",
      selector: (row) => <div>{row.SubAcCount}</div>,
      sortable: true,
    },
    {
      name: "Locations",
      selector: (row) => <div>{row.LocAcCount}</div>,
      sortable: true,
    },
    {
      name: "Departments",
      selector: (row) => <div>{row.DeptAcCount}</div>,
      sortable: true,
    },
    {
      name: "Assets",
      selector: (row) => <div>{row.AssetsCount}</div>,
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) => <div>{row.UsersCount}</div>,
      sortable: true,
    },
    {
      name: "Templates",
      selector: (row) => <div>{row.TempletsCount}</div>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <td>
          <a href={row.id} onClick={() => editACid(row.id)}>
            {/* {val.status} */}
            <div className="switch">
              <label className="switch-light">
                {edit ? (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => set1Status(e.target.value)}
                    defaultChecked={row.status === "disable"}
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => set1Status(e.target.value)}
                    checked={row.status === "disable"}
                  />
                )}

                <span>
                  <span
                    id="enable"
                    onClick={(e) => setStatus("enable")}
                    data-toggle="modal"
                    data-target="#enableconfirmation"
                  >
                    Enable{" "}
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => setStatus("disable")}
                    data-toggle="modal"
                    data-target="#disableconfirmation"
                  >
                    Disable{" "}
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          </a>
        </td>
      ),
      sortable: true,
    },
    {
      name: "",
    },
    {
      name: "Customer",
      cell: (row) => (
        <i
          href={row.id}
          // onClick={() => getdata(row.id)}
          className="btn btn-primary"
          class="bi bi-journals"
          data-toggle="modal"
          data-target="#editadminuser"
          onClick={() => {
            setIsModalOpen(true);
            setSelectedSubdomain(row.subdomain); // Set subdomain when icon is clicked
            setAccountId(row._id); // Set the dynamic account_id based on selected row
            setAcEdit(row);
            fetchEmails(row._id);
          }}

        ></i>
      )
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={{
            pathname: `/edit_account/${row.id}`,
            state: { AcEdit: row },
          }}
          onClick={() => editACid(row.id)}
        >
          <i
            href={row.id}
            // onClick={() => getdata(row.id)}
            className="btn btn-primary"
            class="fa fa-edit"
          ></i>
        </Link>
      ),
    },
  ];
  return (
    <>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Accounts</h1>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      {/* <button type="button" class="btn btn-c-outline-primary">
                        <i class="fa fa-download"></i> Download Account Data
                      </button> */}
                      <div class="input-group pr-form-group">
                        <Link to="/add_account">
                          <a
                            href="addNewAssets.html"
                            // onClick={() => getdata(row._id)}
                            type="button"
                            class="btn btn-c-outline-primary"
                          >
                            {" "}
                            + Add Account{" "}
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                  //   className="row"
                  >
                    <div
                      className="borderRadius"
                    //   className="col-sm-12 row-wise"
                    >
                      <DataTable
                        columns={columns}
                        data={user1}
                        pagination
                        paginationPerPage={50} // Default number of rows per page
                        paginationRowsPerPageOptions={[100, 150, 200, 250,300]} // User can select from these options
                        paginationComponentOptions={{
                          rowsPerPageText: 'Rows per page:', // Customize pagination text
                          rangeSeparatorText: 'of',
                          noRowsPerPage: false, // Show or hide the rows per page dropdown
                        }}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th><b>Subdomain</b></th>
                                      <th><b>Name</b></th>
                                      <th><b>Subaccounts</b></th>
                                      <th><b>Locations</b></th>
                                      <th><b>Departments</b></th>
                                      <th><b>Assets</b></th>
                                      <th><b>Users</b></th>
                                      <th><b>Templates</b></th>
                                      <th><b>Status</b></th>
                                      <th></th>
                                      <th><b>Action</b></th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td style={{ textAlign: "center" }} colSpan={11}>No Record Found</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        fixedHeader
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />

                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Modal add subaccount --> */}
            </div>

            {isModalOpen && (
              <div
                className="modal fade show"
                id="editadminuser"
                role="dialog"
                aria-labelledby="editadminuser"
                aria-hidden={!isModalOpen}
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <img
                        src="assets/images/adminLogo.png"
                        alt="Motware Logo"
                        width="40px"
                        className="brand-image"
                      />
                      <h5 className="modal-title w-100 text-center">
                        Customer Report
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hi8dden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      <form onSubmit={handleSubmit}>
                        {/* Subdomain field */}
                        <div className="form-group">
                          <label htmlFor="subdomain">Subdomain *</label>
                          <input
                            type="text"
                            name="subdomain"
                            className="form-control"
                            value={formik.values.subdomain}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter Subdomain"
                            readOnly
                          />
                          {formik.touched.subdomain && formik.errors.subdomain && (
                            <span style={{ color: "red" }}>{formik.errors.subdomain}</span>
                          )}
                        </div>

                        {/* Email field */}
                        <div className="form-group">
                          <label htmlFor="email">Enter Additional Email</label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter email(s), separated by commas"
                          />
                          {formik.touched.email && formik.errors.email && (
                            <span style={{ color: "red" }}>{formik.errors.email}</span>
                          )}
                        </div>

                        {/* Email Field (Multi-select Dropdown) */}
                        <div className="form-group">
                          <label htmlFor="email">Select Existing User</label>
                          <Select
                            isMulti
                            name="email"
                            options={emailOptions}
                            value={formik.values.selectedEmails} // Bind to formik values
                            onChange={(selectedOptions) => formik.setFieldValue("selectedEmails", selectedOptions)} // Update formik values
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select emails"
                          />
                        </div>

                        {/* Report Name field */}
                        <div className="form-group">
                          <label htmlFor="report_name">Report Name *</label>
                          <select
                            name="report_name"
                            className="form-control"
                            value={formik.values.report_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="" label="Select report name" />
                            <option value="Weekly TXIQ Report">Weekly TXIQ Report</option>
                          </select>
                          {formik.touched.report_name && formik.errors.report_name && (
                            <span style={{ color: "red" }}>{formik.errors.report_name}</span>
                          )}
                        </div>

                        {/* Submit button */}
                        <div className="text-center">
                          <button type="submit" className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* status */}
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Account?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Account?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Accounts;
