import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import './style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
const Sidebar = (props) => {
  let { handleSubmitData, fromDate, toDate, setFromDate, setToDate, updateFromDate, updateToDate } = props;
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [fromHours, setFromHours] = useState(fromDate.getHours().toString().padStart(2, '0'));
  const [fromMinutes, setFromMinutes] = useState(fromDate.getMinutes().toString().padStart(2, '0'));
  const [fromSeconds, setFromSeconds] = useState('00');
  const [toHours, setToHours] = useState(toDate.getHours().toString().padStart(2, '0'));
  const [toMinutes, setToMinutes] = useState(toDate.getMinutes().toString().padStart(2, '0'));
  const [toSeconds, setToSeconds] = useState('00');

  const validationSchema = Yup.object().shape({
    toDate: Yup.date()
      .required('To Date is required')
      .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
      .max(new Date(moment(fromDate).add(30, 'days')), 'To Date is exceeding the maximum To Date')
    ,
  });

  const formik = useFormik({
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      toDateWithHours.setHours(toHours, toMinutes, toSeconds);
      const data = {
        fromDate: fromDateWithHours,
        toDate: toDateWithHours,
        // toDate: new Date(toDate),
      };

      handleSubmitData(data);
      updateFromDate(fromDateWithHours);
      updateToDate(toDateWithHours);
      handleCloseFirstModal();
    },
  });

  const handleCloseFirstModal = () => {
    setShowFirstModal(false)
  };
  const handleShowFirstModal = () => {
    formik.setErrors({
      fromDate: '',
      toDate: '',
    });
    formik.setFieldValue("fromDate", fromDate)
    setShowFirstModal(true);
  }

  return (
    <>
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
              title="Filter"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
          </li>
        </ul>
      </div>

      {/* filter modal */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Filter</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
            <div className="form-group">
              <div className='row'>
                <div className='col-8'>
                  <label>From Date *</label>
                  <DatePicker
                    selected={formik.values.fromDate}
                    onChange={(date) => {
                      if (moment(date, "DD-MM-YYYY", true).isValid()) {
                        formik.setFieldValue('fromDate', date);
                        formik.setFieldTouched('fromDate', true);
                        setFromDate(date);
                        const lastDate = new Date(moment(date).add(30, 'days'));
                        formik.setFieldValue('toDate', lastDate);
                        formik.setFieldTouched('toDate', true);
                        setToDate(lastDate);
                      }
                    }}

                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                </div>
                <div className='col-2'>
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={fromHours}
                    onChange={(e) => setFromHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2'>
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={fromMinutes}
                    onChange={(e) => setFromMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2'style={{ display: 'none' }}>
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={fromSeconds}
                    onChange={(e) => setFromSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>
                </div>
                {formik.errors.fromDate && formik.touched.fromDate && (
                  <div className="error-message text-danger">
                    {formik.errors.fromDate}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">

              <div className='row'>
                <div className='col-8'>
                  <label>To Date *</label>

                  <DatePicker
                    selected={formik.values.toDate}
                    onChange={(date) => {
                      if (moment(date, "DD-MM-YYYY", true).isValid()) {
                        formik.setFieldValue("toDate", date);
                        formik.setFieldTouched("toDate", true);
                        setToDate(date);
                      }
                    }}

                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    includeDateIntervals={[
                      {
                        start: new Date(
                          moment(fromDate).subtract(1, "days")
                        ),
                        end: new Date(moment(fromDate).add(30, "days")),
                      },
                    ]}
                    onBlur={(e) => {
                      try {
                        if (
                          moment(
                            e.target.value,
                            "DD-MM-YYYY",
                            true
                          ).isValid()
                        ) {
                          const date = new Date(
                            moment(e.target.value, "DD-MM-YYYY").format(
                              "YYYY-MM-DD"
                            )
                          );
                          formik.setFieldValue("toDate", date);
                          formik.setFieldTouched("toDate", true);
                          setToDate(date);
                        }
                      } catch (error) { }
                    }}
                    maxDate={new Date()}
                    // maxDate={new Date(moment(fromDate).add(15, "days"))}
                    minDate={new Date(moment(fromDate).subtract(30, "days"))}
                  />
                </div>
                <div className='col-2'>
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={toHours}
                    onChange={(e) => setToHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2'>
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={toMinutes}
                    onChange={(e) => setToMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2' style={{ display: 'none' }}>
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={toSeconds}
                    onChange={(e) => setToSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>
                </div>
                {formik.errors.toDate && formik.touched.toDate && (
                  <div className="error-message text-danger">
                    {formik.errors.toDate}
                  </div>
                )}
              </div>
            </div>
            <div className="text-center">
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sidebar