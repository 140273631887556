import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import moment from "moment";
import { useHistory } from "react-router-dom"; // Import useHistory

function ApiLog() {
  const [fromDate, setFromDate] = useState(
    new Date(moment().subtract(24, "hours"))
  );
  const [toDate, setToDate] = useState(new Date(moment()));
  const [resultant, setResultant] = useState({ columns: [], data: [] });
  const [filteredData, setFilteredData] = useState({ columns: [], data: [] });
  const [selectedSolName, setSelectedSolName] = useState("All");
  const [solNames, setSolNames] = useState([]);
  const [noOfReq, setNoOfReq] = useState();
  const [avResTime, setAvResTime] = useState();
  const [maxResTime, setMaxResTime] = useState();
  const [falied, setFalied] = useState();
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const history = useHistory(); // Initialize history

  useEffect(() => {
    fetchSolutions();
    fetchData(fromDate, toDate, "All");
  }, []);

  useEffect(() => {
    setFilteredData(resultant);
  }, [resultant]);

  useEffect(() => {
    searchTable();
  }, [searchText]);

  const fetchData = async (start, end, requestType) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/api/api_logs_summary/get_onehravg_apilogsummary`,
        {
          start_date: moment(start).format("YYYY-MM-DD HH:mm"),
          end_date: moment(end).format("YYYY-MM-DD HH:mm"),
          request_type: requestType,
        }
      );

    //   const response =   {"data":
    //   {
    //     "success": true,
    //     "data": {
    //         "card_data": {
    //             "field": [
    //                 "Date",
    //                 "Hour",
    //                 "Number Of Request",
    //                 "Average Response Time (ms)",
    //                 "Maximum Response Time (ms)",
    //                 "Failed %"
    //             ],
    //             "values": [
    //                 "05-08-2024",
    //                 "16th",
    //                 58,
    //                 46.213768115942024,
    //                 189,
    //                 0
    //             ]
    //         },
    //         "table_data": {
    //             "column": [
    //               "Datetime",
    //                 "Date",
    //                 "Hour",
    //                 "Number Of Request",
    //                 "Average Response Time (ms)",
    //                 "Maximum Response Time (ms)",
    //                 "Failed %"
    //             ],
    //             "rows": [
    //                 [
    //                   "2024-08-01 09:00 - 2024-08-01 10:00",
    //                     "06-08-2024",
    //                     "15th",
    //                     0,
    //                     0,
    //                     0,
    //                     0
    //                 ],
    //                 [
    //                   "2024-08-01 09:00 - 2024-08-01 10:00",
    //                     "06-08-2024",
    //                     "14th",
    //                     0,
    //                     0,
    //                     0,
    //                     0
    //                 ],
    //                 [
    //                   "2024-08-01 09:00 - 2024-08-01 10:00",
    //                     "06-08-2024",
    //                     "13th",
    //                     0,
    //                     0,
    //                     0,
    //                     0
    //                 ],
    //                 [
    //                   "2024-08-01 13:00 - 2024-08-01 14:00",
    //                     "06-08-2024",
    //                     "12th",
    //                     18,
    //                     55.38095238095237,
    //                     205,
    //                     0
    //                 ], 
    //             ]
    //         }
    //     },
    //     "start_date": "05-08-2024 16:11",
    //     "end_date": "06-08-2024 16:11"
    // }
    //   }

      if (response.data.success) {
        // setCardData(response.data.card_ata)
        setNoOfReq(truncateToTwoDigits(response.data.data.card_data.values[3]))
        setAvResTime(truncateToTwoDigits(response.data.data.card_data.values[4]))
        setMaxResTime(truncateToTwoDigits(response.data.data.card_data.values[5]))
        setFalied(truncateToTwoDigits(response.data.data.card_data.values[6]))
        setResultant(transformData(response.data));
      } else if (response.data.success === false) {
        // showFlashMessage("Data Not Available");
        setNoOfReq("-");
        setAvResTime("-");
        setMaxResTime("-");
        setFalied("-");
        setResultant(transformData(response.data));
        // showFlashMessage("Data Successfully Updated", "success");
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchSolutions = async (start, end) => {
    // setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/api/api_logs_summary/get_request_type`
      );
      if (response.data.success) {
        setSolNames(response.data.data);
      } else if (response.data.success === false) {
        setSolNames(response.data.data);
        // showFlashMessage("Data Successfully Updated", "success");
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      // setLoader(false);
    }
  };

  const handleSolNameClick = (solName) => {
    setSelectedSolName(solName);
    fetchData(fromDate, toDate, solName);
  };

  const searchTable = () => {
    if (!searchText) {
      setFilteredData(resultant);
    } else if (resultant && resultant.rows) {
      const filteredResult = resultant.rows.filter((row) => {
        row = Object.values(row);
        const rowValues = row.map((item) =>
          typeof item === "string" ? item.toLowerCase() : item.toString()
        );
        const searchTerm = searchText.toLowerCase();
        return rowValues.some((value) => value.includes(searchTerm));
      });
      setFilteredData((prevOptions) => ({
        ...prevOptions,
        rows: filteredResult,
      }));
    }
  };

  const handleViewClick = (row) => {
    const date = row["Date Time"];
    console.log(date,"date");
    history.push({
      pathname: "/apitable_log",
      state: { date },
    });
  };

  const transformData = (resp) => {
    const columns = resp.data.table_data.column;
    const rows = resp.data.table_data.rows;
  
    // Filter out the "Datetime" column
    const filteredColumns = columns.filter(col => col !== "Date Time");
    const finalColumns = filteredColumns.map((value) => ({
      name: value,
      selector: (row) => row[value.toLowerCase().replace(/_/g, " ")],
      sortable: true,
      cell: (row) => (
        <div className="custom-cell">
          {row[value.toLowerCase().replace(/_/g, " ")] !== ""
            ? truncateToTwoDigits(row[value.toLowerCase().replace(/_/g, " ")])
            : ""}
        </div>
      ),
    }));
  
    // Add a column for the view icon
    finalColumns.push({
      name: "View",
      cell: (row) => (
        <button
          onClick={() => handleViewClick(row)}
          className="btn btn-link"
          title="View Details"
        >
          <i className="fa fa-eye"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    });
  
    const finalRows = rows.map((row) => {
      const rowData = {};
      // Include the "Datetime" column for internal use but exclude it from final columns
      rowData["Date Time"] = row[0]; // Assuming "Datetime" is the first column
      filteredColumns.forEach((columnName, index) => {
        rowData[columnName.toLowerCase().replace(/_/g, " ")] = row[index + 1];
      });
      return rowData;
    });
  
    return { columns: finalColumns, rows: finalRows };
  };
  

  const truncateToTwoDigits = (value) => {
    if (!isNaN(value)) {
      return Math.trunc(value * 100) / 100;
    }
    return value;
  };

  const handleSubmitData = (data) => {
    fetchData(data.fromDate, data.toDate);
  };

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal",
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const NoDataComponent = () => (
    <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
      <thead>
        <tr>
          {filteredData.columns.map((column) => (
            <th key={column.name}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={filteredData.columns.length}>
            <div className="text-center">
              <p>No Data Available</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-light">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav"></ul>

        {/* <!-- Right navbar links --> */}

        <ul className="navbar-nav ml-auto">
          {/* <!-- Messages Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <a
              className="nav-link btn btn-primary"
              data-toggle="dropdown"
              href="#"
            >
              Quick Menu
            </a>

            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <a href="#" className="dropdown-item">
                Raise Request
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                Setup
              </a>
            </div>
          </li>
          {/* <!-- Notifications Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="fa fa-user"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <a href="#" className="dropdown-item">
                <i className="fa fa-lock mr-2"></i>
                <span className="text-muted text-sm">Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <div class="apiLogPg content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Api Logs</h1>
                </div>
                <div class="col-sm-6">
                  <div class="form-inline pr-form-inline float-right">
                    <button
                      type="button"
                      class="btn btn-c-outline-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedSolName}
                    </button>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      {solNames.map((solName) => (
                        <a
                          className="dropdown-item"
                          href=""
                          key={solName}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSolNameClick(solName);
                          }}
                        >
                          {solName}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="apiLog_cardWpr col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-3 col-lg-3">
                  <div className="card pr-card mb30">
                    <div className="card-body">
                      <h6 className="countHeading">Number Of Request</h6>
                      <h5 className="count">{noOfReq}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="card pr-card mb30">
                    <div className="card-body">
                      <h6 className="countHeading">Average Response Time (ms)</h6>
                      <h5 className="count">{avResTime}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="card pr-card mb30">
                    <div className="card-body">
                      <h6 className="countHeading">Maximum Response Time (ms)</h6>
                      <h5 className="count">{maxResTime}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="card pr-card mb30">
                    <div className="card-body">
                      <h6 className="countHeading">Failed %</h6>
                      <h5 className="count">{falied}%</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* table start */}
            <div className="col-md-12 col-lg-12 mb20">
              <div className="table-responsive">
                  {/* <h5>Table</h5> */}
                  <div
                  //   className="row"
                  >
                    <div
                      className="borderRadius"
                      //   className="col-sm-12 row-wise"
                    >
                      {/* <div id="" className="mr10">
                        <label>
                          <input
                            type="search"
                            // value={searchText}
                            className="form-control form-control-sm"
                            placeholder="Search..."
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        </label>
                      </div> */}
                      <DataTable
                          columns={filteredData.columns}
                          data={filteredData.rows}
                          pagination
                          fixedHeader
                          paginationComponentOptions={
                            paginationComponentOptions
                          }
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}
                        />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
              </div>
            </div>
            {/* table end */}
          </div>
        </section>
      </div>
      <Sidebar
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        handleSubmitData={handleSubmitData}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default ApiLog;
