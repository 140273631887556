import * as Yup from "yup";

export const SubaccountFormSchema = Yup.object({
  name: Yup.string().required("Please Enter Subaccount Name"),
  company_name: Yup.string().required("Please Enter Company Name"),
  industry_name: Yup.string().required("Please Select Industry Name"),
  address: Yup.string().required("Please Enter Address"),
  email: Yup.string().email().required("Please Enter Email"),
  contact_no: Yup.string().max(10).required("Please Enter Contact Number"),
});
