import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import BarChart from "./BarChart";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Doughnut } from "react-chartjs-2";
import { FormSchema } from "./Downtime_FormSchema.js";
import { useFormik } from "formik";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
const SuperAdminDashboard = () => {
  const { error1, setError1 } = useState({});
  const [LoadedDashboard, setLoadedDashboard] = useState([]);
  const [testUpload, setTestUpload] = useState([]);
  const [testUploadData, setTestUploadData] = useState([]);
  const [UserActivities, setUserActivities] = useState([]);
  const [UserActivitiesData, setUserActivitiesData] = useState([]);
  const [chartKey, setChartKey] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTest, setSearchTest] = useState("");
  const [total, setTotal] = useState([]);
  const [total1, setTotal1] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 5,
  });
  const [pagination1, setPagination1] = React.useState({
    page1: 1,
    perPage: 5,
  });

  const [LoadedDowntime, setLoadedDowntime] = useState([]);
  let [startTime, setStartTime] = useState(new Date());
  let [endTime, setEndTime] = useState(new Date());
  const [reason, setReason] = useState("");
  const [mail, setMail] = useState("");
  const [description, setDescription] = useState("");
  const [DEdit, setDEdit] = useState({});
  const [defaultDate, setDefaultDate] = useState(getCurrentDate());
  useEffect(() => {
    setDefaultDate(getCurrentDate());
  }, []);
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }
  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/SuperAdmin_Dashboard/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();
      setLoadedDashboard(json?.Dashboard);
      setChartValue(Object.values(json.Dashboard?.chartDate));
      setChartKey(Object.keys(json.Dashboard?.chartDate));

      setUserActivities(json.Dashboard?.UserActivities);
      var test = json.Dashboard?.TestUploaded;
      console.log("test", test);
      var strAscending = [...test].sort((a, b) =>
        a.accountName > b.accountName ? 1 : -1
      );
      console.log(strAscending);
      // setTotal(strAscending);
      setTestUpload(strAscending);
      if (test.length === 0) {
      } else {
        setLoader(false);
      }
      if (json.Dashboard.UserActivities.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchDataAllUser = async (page, perPage) => {
    await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/SuperAdmin_Dashboard/useractivity`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((newData) => {
        // Update your data in state with the new data from the API
        setTotal1(newData);
        setUserActivitiesData(newData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchDataAllUser();
  }, []);
  const [solution, setSolution] = useState([]);
  const fetchSolutionData = async (page, perPage) => {
    await fetch(`${process.env.REACT_APP_VARIABLE}/api/downtime/iotsolution`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((newData) => {
        let iotSol = [];
        // Update your data in state with the new data from the API
        console.log("newData", newData);
        newData.forEach((e) => {
          iotSol.push({ value: e.iot_sol, label: e.iot_sol });
        });
        setSolution([{ value: "all", label: "All" }, ...iotSol]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchSolutionData();
  }, []);
  const fetchDataAllTestStore = async (page, perPage) => {
    await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/SuperAdmin_Dashboard/teststore`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((newData) => {
        // Update your data in state with the new data from the API
        var test = newData;
        console.log("test", test);
        var strAscending = [...test].sort((a, b) =>
          a.accountName > b.accountName ? 1 : -1
        );
        console.log(strAscending);
        setTotal(strAscending);
        setTestUploadData(strAscending);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchDataAllTestStore();
  }, []);
  const handlePageChange = async (page) => {
    setPagination((prevPagination) => ({ ...prevPagination, page }));
    fetchDataPaginationUser(page, pagination.perPage);
  };
  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: currentPage,
      perPage: currentRowsPerPage,
    }));
    fetchDataPaginationUser(currentPage, currentRowsPerPage);
  };
  const fetchDataPaginationUser = async (page, perPage) => {
    await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/SuperAdmin_Dashboard/useractivitypagewise`,
      {
        method: "POST",
        body: JSON.stringify({
          page_no: page,
          pageSize: perPage,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((newData) => {
        // Update your data in state with the new data from the API
        setUserActivities(newData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleRowsPerPageChange1 = (currentRowsPerPage, currentPage) => {
    setPagination1((prevPagination) => ({
      ...prevPagination,
      page: currentPage,
      perPage: currentRowsPerPage,
    }));
    fetchDataPaginationTest(currentPage, currentRowsPerPage);
  };
  const handlePageChange1 = async (page1) => {
    console.log("kalpana");
    console.log(page1);
    setPagination1((prevPagination) => ({ ...prevPagination, page1 }));
    fetchDataPaginationTest(page1, pagination1.perPage);
  };
  const fetchDataPaginationTest = async (page, perPage) => {
    await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/SuperAdmin_Dashboard/teststorepagewise`,
      {
        method: "POST",
        body: JSON.stringify({
          page_no: page,
          pageSize: perPage,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((newData) => {
        // Update your data in state with the new data from the API
        var test = newData;
        console.log("test", test);
        var strAscending = [...test].sort((a, b) =>
          a.accountName > b.accountName ? 1 : -1
        );
        setTestUpload(strAscending);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const result = total1.filter((test) => {
      return (
        test.admin_user_full_name
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.action.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.affected_collection
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        format(new Date(test.created_at), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.details.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    setUserActivities(result);
    if (result) {
    } else {
      setLoader(false);
    }
  }, [search]);
  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.accountName.toLowerCase().match(searchTest.toLocaleLowerCase()) ||
        test.asset_name.toLowerCase().match(searchTest.toLocaleLowerCase()) ||
        test.equipment_name
          ?.toLowerCase()
          .match(searchTest.toLocaleLowerCase()) ||
        test.asset_test_type_name
          ?.toLowerCase()
          .match(searchTest.toLocaleLowerCase()) ||
        format(new Date(test.upload_date), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(searchTest.toLocaleLowerCase()) ||
        format(new Date(test.test_date), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(searchTest.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.accountName > b.accountName ? 1 : -1
    );
    setTestUpload(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [searchTest]);
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    const result = UserActivities.filter((test) => {
      return (
        test.admin_user_full_name
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.action.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.affected_collection.toLowerCase().match(val.toLocaleLowerCase()) ||
        format(new Date(test.created_at), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.details.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    setUserActivities(result);
    if (result) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      handleRowsPerPageChange();
    }
  };
  const handleSearchTest = (e) => {
    let val = e.target.value;
    setSearchTest(val);
    const result = testUpload.filter((test) => {
      return (
        test.accountName.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.asset_name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.equipment_name?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.name?.toLowerCase().match(val.toLocaleLowerCase()) ||
        format(new Date(test.upload_date), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        format(new Date(test.test_date), "MM/dd/yyyy h:mm a")
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.accountName > b.accountName ? 1 : -1
    );
    setTestUpload(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      handleRowsPerPageChange1();
    }
  };

  const [selectedIotSol, setSelectedIotSol] = useState([]);

  const changeIotSol = (props) => {
    let solArray = [];

    if (props) {
      const isAllSelected = props.some((option) => option.value === "all");
      const otherOptions = solution.filter((option) => option.value !== "all");

      if (isAllSelected) {
        solArray = otherOptions.map((e) => e.value);
        setSelectedIotSol(solArray);
        // Update formik values
        setFieldValue("iot_sol", otherOptions);
      } else {
        solArray = props.map((e) => e.value);
        setSelectedIotSol(solArray);
        // Update formik values
        setFieldValue("iot_sol", props);
      }
    } else {
      setSelectedIotSol([]);
      setFieldValue("iot_sol", []);
    }
  };

  const apiLogsCOLORS = ["#0095DA"];
  const columns = [
    {
      name: "Upload Date/Time",
      selector: (row) => (
        <div> {format(new Date(row.upload_date), "MM/dd/yyyy h:mm a")}</div>
      ),
      sortable: true,
    },

    {
      name: "Account Names",
      selector: (row) => <div> {row.accountName}</div>,
      sortable: true,
    },

    {
      name: "Equipments",
      selector: (row) => <div> {row.equipment_name}</div>,
      sortable: true,
    },

    {
      name: "Asset Names",
      selector: (row) => row.asset_name,
      sortable: true,
    },
    {
      name: "Test Type",
      selector: (row) => row.asset_test_type_name,
      sortable: true,
    },
    {
      name: "Test Date/Time",
      selector: (row) => format(new Date(row.test_date), "MM/dd/yyyy h:mm a"),
      sortable: true,
    },
  ];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const columns2 = [
    {
      name: "Full Name",
      selector: (row) => <div> {row.admin_user_full_name}</div>,
      sortable: true,
    },
    {
      name: "Date/Time",
      selector: (row) => (
        <div> {format(new Date(row.created_at), "MM/dd/yyyy h:mm a")}</div>
      ),
      sortable: true,
    },

    {
      name: "Details",
      selector: (row) => <div> {row.details}</div>,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => <div> {row.action}</div>,
      sortable: true,
    },

    {
      name: "Affected Collection",
      selector: (row) => row.affected_collection,
      sortable: true,
    },

    {
      name: "Add After Value",
      selector: (row) => row.add_after_value,
      sortable: true,
    },
    {
      name: "Edit Before Value",
      selector: (row) => row.edit_before_value,
      sortable: true,
    },
    {
      name: "Edit After Value",
      selector: (row) => row.edit_after_value,
      sortable: true,
    },
  ];
  const PLATFORMS = [
    "Active Sites                        ",
    "Inactive Sites (Last 24 hrs) ",
  ];
  const COLORS = ["#67C6F1", "#2673B7"];
  // const HOVERCOLORS = ["rgb(231 76 60 / 83%)", "rgb(52 152 219 / 83%)"];
  const sensor = [
    LoadedDashboard?.ActiveSensor ? LoadedDashboard?.ActiveSensor : 0,
    LoadedDashboard?.DeactiveSensor ? LoadedDashboard?.DeactiveSensor : 0,
  ];
  // const iots = [sales.iot_sensor_master];
  const dataSensor = {
    labels: PLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: COLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: sensor,
      },
    ],
  };
  const options = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      doughnutCenterText: {
        text: [LoadedDashboard?.SensorCount ? LoadedDashboard?.SensorCount : 0],
        color: "#000000",
        font: {
          size: "24",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",

        align: "start",
        labels: {
          align: "end",
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + "    " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };
  const doughnutCenterTextPlugin = {
    id: "doughnutCenterText",
    afterDraw: (chart) => {
      const { ctx, width, height } = chart;
      const pluginOptions = chart.options.plugins.doughnutCenterText;
      const textLines = pluginOptions.text;
      const fontSize = parseInt(pluginOptions.font.size, 10);
      const lineHeight = fontSize * 1.5;
      let yOffset = height / 2 - (textLines.length * lineHeight) / 2;

      ctx.fillStyle = pluginOptions.color;
      ctx.font = `${pluginOptions.font.weight} ${pluginOptions.font.size}px sans-serif`;
      ctx.textAlign = "center";

      textLines.forEach((line) => {
        ctx.fillText(line, width / 2, yOffset);
        yOffset += lineHeight;
      });
    },
  };
  const apiPLATFORMS = [
    "Total Data Used              ",
    "Total Hits                        ",
  ];
  const apiCOLORS = ["#069357", "#FF6873"];
  const apidata = {
    labels: apiPLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: apiCOLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: [
          LoadedDashboard?.ApiData ? LoadedDashboard?.ApiData : 0,
          LoadedDashboard?.ApiHit ? LoadedDashboard?.ApiHit : 0,
        ],
      },
    ],
  };
  const apioptions = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      doughnutCenterText: {
        text: [LoadedDashboard?.ApiHit ? LoadedDashboard?.ApiHit : 0],
        color: "#000000",
        font: {
          size: "24",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",

        align: "start",
        labels: {
          align: "end",
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + "    " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };
  const custLoginPLATFORMS = [
    "Total SignIn                 ",
    "Inactive Customers               ",
  ];
  const custLoginCOLORS = ["#F99A55", "#E0E4E9"];
  const custLogindata = {
    labels: custLoginPLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: custLoginCOLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: [
          LoadedDashboard?.totalsignIn ? LoadedDashboard?.totalsignIn : 0,
          LoadedDashboard?.InactivaUser ? LoadedDashboard?.InactivaUser : 0,
        ],
      },
    ],
  };
  const custLoginoptions = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      doughnutCenterText: {
        text: [LoadedDashboard?.totalsignIn ? LoadedDashboard?.totalsignIn : 0],
        color: "#000000",
        font: {
          size: "20",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",

        align: "start",
        labels: {
          align: "end",
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + "    " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };
  const apiLogsdata = {
    labels: chartKey,
    datasets: [
      {
        label: "Api (Last Seven Day)",
        backgroundColor: apiLogsCOLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: chartValue,
      },
    ],
  };
  const optio = {
    paginationComponentOptions: {
      rowsPerPageText: "Rows per page:",
      rangeSeparatorText: "of",
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: "All",
    },
  };
  const apiLogsoptions = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
        },
      },

      scales: {
        y: {
          min: 0,
          max: 3500,
          ticks: {
            callback: function (value, index, values) {
              return value.toLocaleString(); // format the tick values
            },
          },
        },
      },

      tooltip: {
        callbacks: {
          label: (context) => {
            console.log(context);
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage =
              total > 0 ? Math.round((value / total) * 100) : 0;
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };
  const CustomTimePicker = ({ name, value, onChange }) => {
    const [hour, setHour] = useState(value ? value.split(":")[0] : "12");
    const [minute, setMinute] = useState(
      value ? value.split(":")[1].split(" ")[0] : "00"
    );
    const [period, setPeriod] = useState(value ? value.split(" ")[1] : "AM");

    const handleHourChange = (event) => {
      const newHour = event.target.value;
      setHour(newHour);
      onChange(`${newHour}:${minute} ${period}`);
    };

    const handleMinuteChange = (event) => {
      const newMinute = event.target.value;
      setMinute(newMinute);
      onChange(`${hour}:${newMinute} ${period}`);
    };

    const handlePeriodChange = (event) => {
      const newPeriod = event.target.value;
      setPeriod(newPeriod);
      onChange(`${hour}:${minute} ${newPeriod}`);
    };

    return (
      <div className="custom-time-picker">
        <select
          value={hour}
          onChange={handleHourChange}
          name={`${name}_hour`}
          className="hour-select"
        >
          {Array.from({ length: 13 }, (_, i) => (
            <option key={i} value={String(i).padStart(2, "0")}>
              {String(i).padStart(2, "0")}
            </option>
          ))}
        </select>
        <span>:</span>
        <select
          value={minute}
          onChange={handleMinuteChange}
          name={`${name}_minute`}
          className="minute-select"
        >
          {Array.from({ length: 61 }, (_, i) => (
            <option key={i} value={String(i).padStart(2, "0")}>
              {String(i).padStart(2, "0")}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={handlePeriodChange}
          name={`${name}_period`}
          className="period-select"
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    );
  };
  const formInitialValues = {
    iot_sol: [],
    date: defaultDate,
    from_time: "12:00 AM",
    to_time: "12:00 AM",
    description: "",
  };
  let {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
    handleBlur,
    errors,
  } = useFormik({
    initialValues: formInitialValues,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      try {
        let res = await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/downtime`,
          {
            method: "POST",
            body: JSON.stringify({
              iot_sol: selectedIotSol,
              date: values.date,
              from_time: values.from_time,
              to_time: values.to_time,
              description: values.description,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        let resJson = await res.json();

        if (res.status === 201) {
          alert("Downtime added successfully");
          setSelectedIotSol([]);
          values.date = defaultDate;
          values.from_time = "12:00 AM";
          values.to_time = "12:00 AM";
          values.description = "";
          fetchData();
        } else {
          setSelectedIotSol([]);
          values.date = defaultDate;
          values.from_time = "12:00 AM";
          values.to_time = "12:00 AM";
          values.description = "";
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="wrapper">
        <nav className="main-header navbar navbar-expand navbar-light">
          <ul className="navbar-nav"></ul>

          <form className="form-inline ml-3">
            <div className="input-group input-group-sm">
              <input
                className="form-control form-control-navbar"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link btn btn-primary"
                data-toggle="dropdown"
                href="#"
              >
                Quick Menu
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a href="#" className="dropdown-item">
                  Raise Request
                </a>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/setup">
                  Setup
                </Link>
                <div className="dropdown-divider"></div>
                <a
                  href="#"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#adddowntime"
                >
                  Downtime
                </a>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/api_log_summary">
                  Api Log
                </Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fa fa-user"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a href="#" className="dropdown-item">
                  <i className="fa fa-lock mr-2"></i>
                  <span className="text-muted text-sm">Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </nav>
        {/* <div
          className="modal fade"
          id="adddowntime"
          tabindex="-1"
          role="dialog"
          aria-labelledby="adddowntime"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <img
                  src="assets/images/adminLogo.png"
                  alt="Motware Logo"
                  width="40px"
                  className="brand-image"
                />
                <h5 className="modal-title w-100 text-center">Add Downtime</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form className="downtimeform">
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          IOT Solution *
                        </label>

                        <Select
                          isMulti={true}
                          closeMenuOnSelect={false}
                          options={solution}
                          placeholder="Select IOT Solution"
                          value={solution.filter((option) =>
                            selectedIotSol.includes(option.value)
                          )}
                          onChange={(e) => {
                            changeIotSol(e);
                            setFieldTouched("iot_sol", true);
                            setFieldValue("iot_sol", e);
                          }}
                          onBlur={() => setFieldTouched("iot_sol", true)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          Date *
                        </label>
                        <div>
                          <input
                            type="date"
                            className="form-control"
                            id=""
                            placeholder="Enter Date"
                            name="date"
                            value={values.date || defaultDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.date && touched.date ? (
                            <span style={{ color: "red" }}>{errors.date}</span>
                          ) : null}
                        </div>
                      </div>

                      <label htmlFor="sector" className="col-form-label">
                        From Time *
                      </label>

                      <CustomTimePicker
                        name="from_time"
                        value={values.from_time}
                        onChange={(e) => setFieldValue("from_time", e)}
                      />
                      {errors.from_time && touched.from_time ? (
                        <span style={{ color: "red" }}>{errors.from_time}</span>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          To Time *
                        </label>
                        <CustomTimePicker
                          name="to_time"
                          value={values.to_time}
                          onChange={(e) => setFieldValue("to_time", e)}
                        />
                        {errors.to_time && touched.to_time ? (
                          <span style={{ color: "red" }}>{errors.to_time}</span>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          Description *
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={values.description}
                          className="form-control"
                          id="locName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Description"
                        />
                        {errors.description && touched.description ? (
                          <span style={{ color: "red" }}>
                            {errors.description}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt20 mb10">
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      data-dismiss={
                        values.iot_sol &&
                        values.date &&
                        values.from_time &&
                        values.to_time &&
                        values.description
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="modal fade"
          id="adddowntime"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="adddowntime"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <img
                  src="assets/images/adminLogo.png"
                  alt="Motware Logo"
                  width="40px"
                  className="brand-image"
                />
                <h5 className="modal-title w-100 text-center">Add Downtime</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form className="downtimeform">
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          IOT Solution *
                        </label>

                        <Select
                          isMulti={true}
                          closeMenuOnSelect={false}
                          options={solution}
                          placeholder="Select IOT Solution"
                          value={solution.filter((option) =>
                            selectedIotSol.includes(option.value)
                          )}
                          onChange={(e) => {
                            changeIotSol(e);
                            // setFieldValue("iot_sol", e);
                          }}
                        />
                        {errors.iot_sol && touched.iot_sol ? (
                          <span style={{ color: "red" }}>{errors.iot_sol}</span>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          Date *
                        </label>
                        <div>
                          <input
                            type="date"
                            className="form-control"
                            id=""
                            placeholder="Enter Date"
                            name="date"
                            value={values.date || defaultDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.date && touched.date ? (
                            <span style={{ color: "red" }}>{errors.date}</span>
                          ) : null}
                        </div>
                      </div>

                      <label htmlFor="sector" className="col-form-label">
                        From Time *
                      </label>

                      <CustomTimePicker
                        name="from_time"
                        value={values.from_time}
                        onChange={(e) => setFieldValue("from_time", e)}
                      />
                      {errors.from_time && touched.from_time ? (
                        <span style={{ color: "red" }}>{errors.from_time}</span>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          To Time *
                        </label>
                        <CustomTimePicker
                          name="to_time"
                          value={values.to_time}
                          onChange={(e) => setFieldValue("to_time", e)}
                        />
                        {errors.to_time && touched.to_time ? (
                          <span style={{ color: "red" }}>{errors.to_time}</span>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="sector" className="col-form-label">
                          Description *
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={values.description}
                          className="form-control"
                          id="locName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Description"
                        />
                        {errors.description && touched.description ? (
                          <span style={{ color: "red" }}>
                            {errors.description}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt20 mb10">
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      data-dismiss={
                        values.iot_sol &&
                        values.date &&
                        values.from_time &&
                        values.to_time &&
                        values.description
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <section className="content pt20">
            <div className="container-fluid">
              <div className="countWpr col-md-12 col-lg-12">
                <ul>
                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Total Accounts</h6>
                        <h5 className="count">
                          {LoadedDashboard?.AccountCount
                            ? LoadedDashboard?.AccountCount
                            : "-"}
                        </h5>
                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>
                            <h6>
                              {LoadedDashboard?.ActiveAccount
                                ? LoadedDashboard?.ActiveAccount
                                : "-"}
                            </h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>
                              <h6>
                                {LoadedDashboard?.DeactiveAccount
                                  ? LoadedDashboard?.DeactiveAccount
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Subaccounts</h6>
                        <h5 className="count">
                          {LoadedDashboard?.SubaccountCount
                            ? LoadedDashboard?.SubaccountCount
                            : "-"}
                        </h5>
                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>
                            <h6>
                              {LoadedDashboard?.ActiveSubaccount
                                ? LoadedDashboard?.ActiveSubaccount
                                : "-"}
                            </h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>
                              <h6>
                                {LoadedDashboard?.DeactiveSubaccount
                                  ? LoadedDashboard?.DeactiveSubaccount
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Repo Locations</h6>
                        <h5 className="count">
                          {LoadedDashboard?.RepolocationCount
                            ? LoadedDashboard?.RepolocationCount
                            : "-"}
                        </h5>
                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>
                            <h6>
                              {LoadedDashboard?.ActiveRepolocation
                                ? LoadedDashboard?.ActiveRepolocation
                                : "-"}
                            </h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>
                              <h6>
                                {LoadedDashboard?.DeactiveRepolocation
                                  ? LoadedDashboard?.DeactiveRepolocation
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Repo Departments</h6>
                        <h5 className="count">
                          {LoadedDashboard?.RepodepartmentCount
                            ? LoadedDashboard?.RepodepartmentCount
                            : "-"}
                        </h5>
                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>
                            <h6>
                              {LoadedDashboard?.ActiveRepodepartment
                                ? LoadedDashboard?.ActiveRepodepartment
                                : "-"}
                            </h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>
                              <h6>
                                {LoadedDashboard?.DeactiveRepodepartment
                                  ? LoadedDashboard?.DeactiveRepodepartment
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Assets</h6>
                        <h5 className="count">
                          {LoadedDashboard?.AssetCount
                            ? LoadedDashboard?.AssetCount
                            : "-"}
                        </h5>
                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>
                            <h6>
                              {LoadedDashboard?.ActiveAsset
                                ? LoadedDashboard?.ActiveAsset
                                : "-"}
                            </h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>
                              <h6>
                                {LoadedDashboard?.DeactiveAsset
                                  ? LoadedDashboard?.DeactiveAsset
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">
                          Total Sensors
                        </h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={dataSensor}
                            options={options}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />
                          <canvas
                            //  id="myChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">API</h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={apidata}
                            options={apioptions}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />
                          <canvas
                            //  id="myChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">
                          Customer Login
                        </h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={custLogindata}
                            options={custLoginoptions}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />
                          <canvas
                            //  id="myChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="notiWpr card-body">
                        <h6 className="countHeading mb20">Notifications</h6>
                        <table
                          className="table dtTable dtr-inline"
                          style={{ width: "100%" }}
                        >
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="card pr-card mb30">
                  <div className="card-body">
                    <h6 className="countHeading mb5">API Logs</h6>
                    <p className="subheading">API Hit (Last Seven Day)</p>
                    <div className="myChartDiv">
                      <BarChart
                        data={apiLogsdata}
                        // labels={labels}
                        //backgroundColor={apiLogsCOLORS}
                        options={apiLogsoptions}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="row align-items-center mb10">
                  <div className="col-md-4 col-lg-6 col-xl-6">
                    <h6 className="countHeading">User Activities</h6>
                  </div>
                  <div className="col-md-12 col-lg-6 col-xl-6">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group" id="">
                        <a
                          className="btn btn-c-outline-primary"
                          onClick={(e) =>
                            exportToCSV(UserActivitiesData, "UserActivities")
                          }
                        >
                          {" "}
                          <i className="fa fa-download"></i> XLS
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group mb10">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive mb30">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns2}
                        data={UserActivities}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Full Name</b>
                                      </th>
                                      <th>
                                        <b>Date/Time</b>
                                      </th>
                                      <th>
                                        <b>Details</b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                      <th>
                                        <b>Affected Collection</b>
                                      </th>
                                      <th>
                                        <b>Add After Value</b>
                                      </th>
                                      <th>
                                        <b>Edit Before Value</b>
                                      </th>
                                      <th>
                                        <b>Edit After Value</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={8}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        paginationServer
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                        paginationTotalRows={
                          LoadedDashboard?.totalAudit_log
                            ? LoadedDashboard?.totalAudit_log
                            : 0
                        }
                        paginationPerPage={pagination.perPage}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        {...optio}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12 mb20">
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-sm-12" style={{ display: "block" }}>
                      <div className="row align-items-center mb10">
                        <div className="col-md-4 col-lg-6 col-xl-6">
                          <h6 className="countHeading">Test Uploaded</h6>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-6">
                          <div className="form-inline pr-form-inline float-right">
                            <div className="input-group pr-form-group" id="">
                              <a
                                className="btn btn-c-outline-primary"
                                onClick={(e) =>
                                  exportToCSV(testUploadData, "TestUpload")
                                }
                              >
                                {" "}
                                <i className="fa fa-download"></i> XLS
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-12 col-xl-12">
                        <div class="form-inline pr-form-inline float-right">
                          <div class="input-group pr-form-group mb10">
                            <div id="example_filter" class="dataTables_filter">
                              <a>
                                Search: &nbsp;{" "}
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  placeholder=""
                                  aria-controls="example"
                                  value={searchTest}
                                  onChange={(e) => handleSearchTest(e)}
                                />
                              </a>
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <div
                        //   className="row"
                        >
                          <div className="borderRadius">
                            <DataTable
                              columns={columns}
                              data={testUpload}
                              noDataComponent={
                                loader === false ? (
                                  <div className="table-responsive">
                                    <div className="borderRadius">
                                      <table style={{ width: "100%" }}>
                                        <thead style={{ height: "50px" }}>
                                          <tr>
                                            <th>
                                              <b>Upload Date/Time</b>
                                            </th>
                                            <th>
                                              <b>Account Names</b>
                                            </th>
                                            <th>
                                              <b>Equipments</b>
                                            </th>
                                            <th>
                                              <b>Asset Names</b>
                                            </th>
                                            <th>
                                              <b>Test Type</b>
                                            </th>
                                            <th>
                                              <b>Test Date/Time</b>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ height: "140px" }}>
                                          <tr>
                                            <td
                                              style={{ textAlign: "center" }}
                                              colSpan={6}
                                            >
                                              No Record Found
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              pagination
                              paginationServer
                              fixedHeaderh
                              highlightOnHover
                              customStyles={tableCustomStyles}
                              paginationTotalRows={
                                LoadedDashboard?.totalTest_Store
                                  ? LoadedDashboard?.totalTest_Store
                                  : 0
                              }
                              paginationPerPage={pagination1.perPage}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                              onChangePage={handlePageChange1}
                              onChangeRowsPerPage={handleRowsPerPageChange1}
                              {...optio}
                            />
                            <div class={loader ? "loader" : ""}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SuperAdminDashboard;
