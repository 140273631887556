import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import './style.css'

const ApiTable = () => {
  const location = useLocation();
  const { date, row } = location.state || {}; // Extract date and row from state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedRequestType, setSelectedRequestType] = useState('All'); // Add state for selected request type

  const columns = [
    { name: 'API Name', selector: 'api_name', sortable: true, wrap: true },
    { name: 'API URL', selector: 'url_api', sortable: true, wrap: true },
    { name: 'Number of Requests', selector: 'no_of_req', sortable: true, wrap: true },
    { name: 'Average Response Time (ms)', selector: 'avg_res_time_ms', sortable: true, wrap: true, cell: row => Math.round(row.avg_res_time_ms) },
    { name: 'Maximum Response Time (ms)', selector: 'max_res_time_ms', sortable: true, wrap: true },
    { name: 'Failed %', selector: 'failed_percentage', sortable: true, wrap: true ,cell: row => Math.round(row.failed_percentage)},
    {
      name: 'Report Download',
      cell: row => (
        <button onClick={() => downloadExcel(row.api_name)}>
          <i className="bi bi-download" aria-hidden="true"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // Split the date string into start_date and end_date
  const [startDate, endDate] = date ? date.split(' - ') : [null, null];

  const fetchApiLogs = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/api_logs_summary/get_api_logs_summary`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            start_date: startDate, // Use the start date
            end_date: endDate, // Use the end date
            request_type: selectedRequestType, // Use the selected request type
          }),
        }
      );
      const json = await res.json();
      if (json && json.length > 0) {
        setData(json);
      } else {
        setData([]);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownOptions = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/api_logs_summary/get_request_type`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('404 Not Found');
        } else {
          throw new Error(`Error: ${res.status} ${res.statusText}`);
        }
      }

      const json = await res.json();
      setDropdownOptions(json.data); // Extract data property from response
    } catch (error) {
      setError(error);
      console.error('Error fetching dropdown options:', error);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchApiLogs();
    }
    fetchDropdownOptions();
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate && selectedRequestType) {
      fetchApiLogs();
    }
  }, [selectedRequestType, startDate, endDate]);

  const downloadExcel = async (apiName) => {
    try {
      const apiUrl = `${process.env.REACT_APP_VARIABLE}/api/api_logs_summary/get_excel_data_api_logs`;
      console.log('API URL:', apiUrl);

      const requestBody = {
        api_name: apiName,
        start_date: startDate,
        end_date: endDate,
        request_type: selectedRequestType,
      };
      console.log('Request Body:', requestBody);

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status} ${res.statusText}`);
      }

      const json = await res.json();
      console.log('Response Data:', json);

      if (!Array.isArray(json) || json.length === 0) {
        console.warn('No data received or data is not in expected format');
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(json);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, `API Log ${apiName}.xlsx`);
    } catch (error) {
      console.error('Error downloading Excel:', error.message);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          
          <div className="row">
            
            <div className="col-md-12 col-lg-12" style={{ marginTop: '100px' }}>
              <div className="col-md-12 col-lg-12">
                <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">{date}</li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-sm-6">
                    <h1 className="pageTitle">Api Logs Details</h1>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-inline pr-form-inline float-right">
                      <button
                        type="button"
                        className="btn btn-c-outline-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <div className="dropdown-menu">
                        {dropdownOptions.length > 0 ? (
                          dropdownOptions.map((option, index) => (
                            <a
                              key={index}
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {e.preventDefault();setSelectedRequestType(option)}}
                            >
                              {option}
                            </a>
                          ))
                        ) : (
                          <div className="dropdown-item">Loading...</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card pr-card mb30">
                <div className="card-body">
                  {error && <div>Error: {error.message}</div>}
                  <DataTable
                    columns={columns}
                    data={data.length ? data : [{api_name: 'No Data',}]}
                    pagination
                    fixedHeader
                    customStyles={{
                      headCells: {
                        style: {
                          fontWeight: 'bold',
                          whiteSpace: 'normal',
                        },
                      },
                      cells: {
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          overflow: 'visible',
                        },
                      },
                    }}
                    noDataComponent={<div>No Data Available</div>}
                    progressPending={loading}
                    conditionalRowStyles={[
                      {
                        when: row => row.api_name === 'No Data',
                        style: {
                          display: 'none', // Hide the placeholder row
                        },
                      },
                    ]}
                  />
                   {!loading && data.length === 0 && (
                    <div className='text-center'>No Data Available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApiTable;
