/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { tableCustomStyles } from "../tableStyle.jsx";
import { format, parseISO } from "date-fns";
import Draggable from "react-draggable";
// import { downloadExcel } from "react-export-table-to-excel";
// import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import { CSVLink } from "react-csv";

import "../tableStyle.css";

const columns = [
  {
    name: "Account Name",
    selector: (row) => <div className="bigtext">{row.account}</div>,
    sortable: true,
  },
  {
    name: "No. of Login",
    selector: (row) => <div>{row.sign_in_count}</div>,
    sortable: true,
  },
  {
    name: "Last Login Date",
    selector: (row) => (
      <div>{format(parseISO(row.updated_at), "dd/MM/yyyy")}</div>
    ),
    sortable: true,
  },
];

const columns2 = [
  {
    name: "Accounts",
    selector: (row) => <div className="bigtext">{row.account}</div>,
    sortable: true,
  },
  {
    name: "Solutions",
    selector: (row) => <div>{row.solution}</div>,
    sortable: true,
  },
  {
    name: "Assets",
    selector: (row) => <div className="bigtext">{row.assets}</div>,
    sortable: true,
  },
  {
    name: "Sensors",
    selector: (row) => <div className="bigtext">{row.sensor_uuid}</div>,
    sortable: true,
  },
  {
    name: "Last Update",
    selector: (row) => (
      <div>{format(parseISO(row.last_upload), "dd/MM/yyyy")}</div>
    ),
    sortable: true,
  },
];

const columns3 = [
  {
    name: "Upload Date/Time",
    selector: (row) => (
      <div>{format(parseISO(row.upload_date), "dd/MM/yyyy")}</div>
    ),
    sortable: true,
  },
  {
    name: "Account Names",
    selector: (row) => <div className="bigtext">{row.account}</div>,
    sortable: true,
  },
  {
    name: "Equipments",
    selector: (row) => <div>{row.equip}</div>,
    sortable: true,
  },

  {
    name: "Asset Names",
    selector: (row) => <div>{row.asset}</div>,
    sortable: true,
  },
  {
    name: "Test Type",
    selector: (row) => <div className="bigtext">{row.type}</div>,
    sortable: true,
  },
  {
    name: "Test Date/Time",
    selector: (row) => (
      <div>{format(parseISO(row.test_date), "dd/MM/yyyy")}</div>
    ),
    sortable: true,
  },
];

function SalesDashboard() {
  const [sales, setSales] = useState({});
  const [user, setUser] = useState([]);
  const [iot, setIot] = useState([]);
  const [test, setTest] = useState([]);

  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [loader, setLoader] = useState(true);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Sales/salesDashboard`
      );

      setSales(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function userData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Sales/salesUser`
      );
      var user = response.data;
      var strAscending = [...user].sort((a, b) =>
        a.account > b.account ? 1 : -1
      );
      setUser(strAscending);
    } catch (error) {
      console.error(error);
    }
  }

  async function IotData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Sales/salesIotSensors`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.account > b.account ? 1 : -1
      );
      setIot(strAscending);
    } catch (error) {
      console.error(error);
    }
  }

  async function TestData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Sales/salesTest`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.account > b.account ? 1 : -1
      );
      setTest(strAscending);
      setTotal(strAscending);
      if (res.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
    userData();
    IotData();
    TestData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.account.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.equip?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.asset.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.type?.toLowerCase().match(search.toLocaleLowerCase()) ||
        format(parseISO(test.test_date), "dd/MM/yyyy")
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        format(parseISO(test.upload_date), "dd/MM/yyyy")
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.account > b.account ? 1 : -1
    );
    setTest(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const [tableData, setTableData] = useState(user);
  const [tableColumns, setTableColumns] = useState(columns);

  const [count, setCount] = useState(20);

  // total sensors
  const PLATFORMS = [
    "Active Sites                        ",
    "Inactive Sites (Last 24 hrs)",
  ];
  const COLORS = ["#67C6F1", "#2673B7"];
  // const HOVERCOLORS = ["rgb(231 76 60 / 83%)", "rgb(52 152 219 / 83%)"];
  const sensor = [sales.activeIot, sales.deactiveIot];
  const iots = [sales.iot_sensor_master];
  const dataSensor = {
    labels: PLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: COLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: sensor,
      },
    ],
  };
  const options = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      doughnutCenterText: {
        text: [sales.iot_sensor_master],
        color: "#000000",
        font: {
          size: "24",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",

        align: "start",
        labels: {
          align: "end",
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + "    " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };

  // new Chart("myChart", {
  //   type: "doughnut",
  //   options: options,
  //   data: dataSensor,
  // });

  //api chart

  const apiPLATFORMS = [
    "Total Data Used             ",
    "Total Hits               ",
  ];
  const apiCOLORS = ["#069357", "#FF6873"];
  //const HOVERCOLORS = ['rgb(231 76 60 / 83%)', 'rgb(52 152 219 / 83%)'];

  const apiData = [sales.api_logs, sales.api_total_data];

  const apidata = {
    labels: apiPLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: apiCOLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: apiData,
      },
    ],
  };

  const apioptions = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },

      doughnutCenterText: {
        text: [sales.api_logs],
        color: "#000000", // the color of the text
        font: {
          size: "24",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + " " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };

  // new Chart("apiChart", {
  //   type: "doughnut",
  //   options: apioptions,
  //   data: apidata,
  // });

  //  customer chart
  const custLoginPLATFORMS = ["Inactive Customers"];
  const custLoginCOLORS = ["#F99A55", "#E0E4E9"];
  //const HOVERCOLORS = ['rgb(231 76 60 / 83%)', 'rgb(52 152 219 / 83%)'];

  const signData = [sales.deactiveUser];

  const custLogindata = {
    labels: custLoginPLATFORMS,
    datasets: [
      {
        label: "Devices data",
        backgroundColor: custLoginCOLORS,
        borderWidth: 0,
        //hoverBackgroundColor: HOVERCOLORS,
        data: signData,
      },
    ],
  };

  const custLoginoptions = {
    radius: "100%",
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Platforms used by customers for browsing",
        font: {
          size: 24,
        },
      },
      doughnutCenterText: {
        text: [sales.sign_in_count],
        color: "#000000",
        font: {
          size: "20",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          generateLabels: function (chart) {
            var data = chart.data;
            var labelCount = data.labels.length;
            var legendItems = [];

            for (var i = 0; i < labelCount; i++) {
              var label = data.labels[i];
              var value = data.datasets[0].data[i];
              var labelText = label + "               " + value;
              var legendItem = {
                text: labelText,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: "#fff",
                lineWidth: 1,
                index: i,
              };
              legendItems.push(legendItem);
            }

            return legendItems;
          },
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
  };

  // new Chart("custLoginChart", {
  //   type: "doughnut",
  //   options: custLoginoptions,
  //   data: custLogindata,
  // });

  const dataArray = Object.keys(user).map((key) => ({ id: key, ...user[key] }));

  const doughnutCenterTextPlugin = {
    id: "doughnutCenterText",
    afterDraw: (chart) => {
      const { ctx, width, height } = chart;
      const pluginOptions = chart.options.plugins.doughnutCenterText;
      const textLines = pluginOptions.text;
      const fontSize = parseInt(pluginOptions.font.size, 10);
      const lineHeight = fontSize * 1.5;
      let yOffset = height / 2 - (textLines.length * lineHeight) / 2;

      ctx.fillStyle = pluginOptions.color;
      ctx.font = `${pluginOptions.font.weight} ${pluginOptions.font.size}px sans-serif`;
      ctx.textAlign = "center";

      textLines.forEach((line) => {
        ctx.fillText(line, width / 2, yOffset);
        yOffset += lineHeight;
      });
    },
  };
  // useEffect(() => {
  //   Chart.pluginService.register({
  //     beforeDraw: function (chart) {
  //       if (chart.config.options.plugins.doughnutLabel) {
  //         var labels = chart.config.options.plugins.doughnutLabel.labels;
  //         var ctx = chart.chart.ctx;
  //         var width = chart.chart.width;
  //         var height = chart.chart.height;

  //         ctx.restore();
  //         ctx.font = labels[0].font.size + "px Arial";
  //         ctx.textAlign = "center";
  //         ctx.textBaseline = "middle";
  //         var total = labels[1].text;
  //         var text = total + "\n" + labels[0].text;
  //         var textX = Math.round((width - ctx.measureText(text).width) / 2);
  //         var textY = height / 2;

  //         ctx.fillText(total, textX, textY - 10);
  //         ctx.fillText(labels[0].text, textX, textY + 20);
  //         ctx.save();
  //       }
  //     },
  //   });
  // }, []);

  const handleSearch = (e) => {
    let val = e.target.value;
    var test1 = test;
    setSerach(val);
    var result = test1.reverse.filter((test) => {
      return (
        test.account.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.equip?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.asset.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.type?.toLowerCase().match(val.toLocaleLowerCase()) ||
        format(parseISO(test.test_date), "dd/MM/yyyy")
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        format(parseISO(test.upload_date), "dd/MM/yyyy")
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.account > b.account ? 1 : -1
    );
    setTest(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const optio = {
    paginationComponentOptions: {
      rowsPerPageText: "Rows per page:",
      rangeSeparatorText: "of",
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: "All",
    },
  };
  return (
    <div classNameName="content-wrapper">
      <section classNameName="content pt20">
        {/* <div className="container-fluid"> */}
        {/* <div className="wrapper"> */}
        {/* <!-- Navbar --> */}
        <nav className="main-header navbar navbar-expand navbar-light">
          {/* <!-- Left navbar links --> */}
          <ul className="navbar-nav">
            {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fa fa-bars"></i>
                  </a>
                </li> */}
            {/* <!-- <li className="nav-item d-none d-sm-inline-block">
          <a href="index3.html" className="nav-link">Home</a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="#" className="nav-link">Contact</a>
        </li> --> */}
          </ul>

          {/* <!-- SEARCH FORM --> */}
          <form className="form-inline ml-3">
            <div className="input-group input-group-sm">
              <input
                className="form-control form-control-navbar"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>

          {/* <!-- Right navbar links --> */}

          <ul className="navbar-nav ml-auto">
            {/* <!-- Messages Dropdown Menu --> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link btn btn-primary"
                data-toggle="dropdown"
                href="#"
              >
                Quick Menu
              </a>

              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a href="#" className="dropdown-item">
                  Raise Request
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  Setup
                </a>
              </div>
            </li>
            {/* <!-- Notifications Dropdown Menu --> */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fa fa-user"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a href="#" className="dropdown-item">
                  <i className="fa fa-lock mr-2"></i>
                  <span className="text-muted text-sm">Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </nav>

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content pt20">
            <div className="container-fluid">
              <div className="countWpr col-md-12 col-lg-12">
                <ul>
                  <Draggable>
                    <li>
                      <div className="card pr-card mb30">
                        <div className="card-body">
                          <h6 className="countHeading">Total Accounts</h6>

                          <h5 className="count">{sales.accounts}</h5>

                          <div className="countFtr row">
                            <div className="col-md-6 col-lg-6">
                              <p>Active</p>

                              <h6>{sales.account_status?.enable}</h6>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="deactive">
                                <p>Deactive</p>

                                <h6>{sales.account_status?.disable}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Draggable>
                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Subaccounts</h6>

                        <h5 className="count">{sales.subaccounts}</h5>

                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>

                            <h6>{sales.subaccount_status?.enable}</h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>

                              <h6>{sales.subaccount_status?.disable}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Locations</h6>

                        <h5 className="count">{sales.repo_locations}</h5>

                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>

                            <h6>{sales.repo_locations_status?.enable}</h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>

                              <h6>{sales.repo_locations_status?.disable}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Departments</h6>

                        <h5 className="count">{sales.repo_departments}</h5>

                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>

                            <h6>{sales.repo_department_status?.enable}</h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>

                              <h6>{sales.repo_department_status?.disable}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading">Assets</h6>

                        <h5 className="count">{sales.assets}</h5>

                        <div className="countFtr row">
                          <div className="col-md-6 col-lg-6">
                            <p>Active</p>

                            <h6>{sales.asset_status?.enable}</h6>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <div className="deactive">
                              <p>Deactive</p>

                              <h6>{sales.asset_status?.disable}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">
                          Total Sensors
                        </h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={dataSensor}
                            options={options}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />

                          <canvas
                            //  id="myChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">API</h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={apidata}
                            options={apioptions}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />
                          <canvas
                            //  id="apiChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="card-body">
                        <h6 className="countHeading text-center">
                          Customer Login
                        </h6>
                        <div className="myChartDiv">
                          <Doughnut
                            type="doughnut"
                            data={custLogindata}
                            options={custLoginoptions}
                            // plugins={plugins}
                            plugins={[doughnutCenterTextPlugin]}
                          />
                          <canvas
                            //  id="custLoginChart"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="card pr-card mb30">
                      <div className="notiWpr card-body">
                        <h6 className="countHeading mb20">Notifications</h6>
                        <table
                          className="table dtTable dtr-inline"
                          style={{ width: "100%" }}
                        >
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                          <tr>
                            <th>Equipment Name 1</th>
                            <td>20 hrs ago</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <div className="card pr-card mb30">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col-md-4 col-lg-6 col-xl-6">
                            <h6 className="countHeading">Customer Login</h6>
                          </div>
                          <div className="col-md-12 col-lg-6 col-xl-6">
                            <div className="form-inline pr-form-inline float-right">
                              <div className="input-group pr-form-group" id="">
                                <CSVLink
                                  className="btn btn-c-outline-primary"
                                  data={dataArray}
                                  filename={"customer_login.csv"}
                                >
                                  <i className="fa fa-download"></i>
                                  XLS
                                </CSVLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="custLoginTbl card-body">
                        <div className="table-responsive">
                          <table
                            id=""
                            className="table table-hover dtTable dtr-inline"
                            style={{ width: "100%" }}
                          >
                            <DataTable
                              className="dataTables_wrapper"
                              columns={columns}
                              data={user}
                              pagination
                              noDataComponent
                              fixedHeaderh
                              highlightOnHover
                              customStyles={tableCustomStyles}
                              paginationPerPage={5}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 col-lg-8">
                    <div className="card pr-card mb30">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col-md-4 col-lg-6 col-xl-6">
                            <h6 className="countHeading">
                              IOT Sensors (last 8 hrs)
                            </h6>
                          </div>
                          <div className="col-md-12 col-lg-6 col-xl-6">
                            <div className="form-inline pr-form-inline float-right">
                              <div className="input-group pr-form-group" id="">
                                <CSVLink
                                  className="btn btn-c-outline-primary"
                                  data={iot}
                                  filename={"iot_sensor.csv"}
                                >
                                  <i className="fa fa-download"></i>
                                  XLS
                                </CSVLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iotSensTbl card-body">
                        <div className="table-responsive">
                          <table
                            id=""
                            className="table table-hover dtTable dtr-inline"
                            style={{ width: "100%" }}
                          >
                            <DataTable
                              className="dataTables_wrapper"
                              columns={columns2}
                              data={iot}
                              pagination
                              noDataComponent
                              fixedHeaderh
                              highlightOnHover
                              customStyles={tableCustomStyles}
                              paginationPerPage={5}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <div className="row">
                  <div
                    className="col-sm-12 row-wise"
                    style={{ display: "block;" }}
                  >
                    <div className="row align-items-center mb10">
                      <div className="col-md-4 col-lg-6 col-xl-6">
                        <h6 className="countHeading">Test Uploaded</h6>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-6">
                        <div className="form-inline pr-form-inline float-right">
                          <div className="input-group pr-form-group" id="">
                            <CSVLink
                              className="btn btn-c-outline-primary"
                              data={test}
                              filename={"test_upload.csv"}
                            >
                              <i className="fa fa-download"></i> XLS
                            </CSVLink>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group mb10">
                        <div id="example_filter" className="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="table-wrapper">
                      <div className="borderRadius">
                        <DataTable
                          className="dataTables_wrapper"
                          columns={columns3}
                          data={test}
                          pagination
                          noDataComponent={
                            loader === false ? (
                              <div className="table-responsive">
                                <div className="borderRadius">
                                  <table style={{ width: "100%" }}>
                                    <thead style={{ height: "50px" }}>
                                      <tr>
                                        <th>
                                          <b>Upload Date/Time</b>
                                        </th>
                                        <th>
                                          <b>Account Names</b>
                                        </th>
                                        <th>
                                          <b>Equipments</b>
                                        </th>
                                        <th>
                                          <b>Asset Names</b>
                                        </th>
                                        <th>
                                          <b>Test Type</b>
                                        </th>
                                        <th>
                                          <b>Test Date/Time</b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ height: "140px" }}>
                                      <tr>
                                        <td
                                          style={{ textAlign: "center" }}
                                          colSpan={6}
                                        >
                                          No Record Found
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          }
                          fixedHeaderh
                          highlightOnHover
                          customStyles={tableCustomStyles}
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          {...optio}
                        />
                        <div class={loader ? "loader" : ""}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* </div> */}
        {/* </div> */}
      </section>
    </div>
  );
}

export default SalesDashboard;
