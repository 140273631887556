import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";

const Audit_log = () => {
  const { error1, setError1 } = useState({});
  const [LoadedAuditLog, setLoadedAuditLog] = useState([]);

  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/audit_log/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();
      setTotal(json);
      setLoadedAuditLog(json);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.admin_user_fullname
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.action.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.details.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.affected_collection.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    setLoadedAuditLog(result);
    if (result) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const columns = [
    {
      name: "Details",
      selector: (row) => <div className="bigtext">{row.details}</div>,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => <div>{row.action}</div>,
      sortable: true,
    },
    {
      name: "Affected Collection",
      selector: (row) => <div>{row.affected_collection}</div>,
      sortable: true,
    },
    {
      name: "Add After Value",
      selector: (row) => <div>{row.add_after_value}</div>,
      sortable: true,
    },
    {
      name: "Edit Before Value",
      selector: (row) => <div>{row.edit_before_value}</div>,
      sortable: true,
    },
    {
      name: "Edit After Value",
      selector: (row) => <div>{row.edit_after_value}</div>,
      sortable: true,
    },

    {
      name: " ",
    },
  ];

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedAuditLog.reverse.filter((test) => {
      return (
        test.admin_user_fullname

          ?.toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.action.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.details.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.affected_collection.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    setLoadedAuditLog(result);
    if (result) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <h1 className="pageTitle">Audit Logs</h1>

                <div class="col-md-6 col-lg-12 col-xl-12">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter mb20">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedAuditLog}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Details</b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                      <th>
                                        <b>Affected Collection</b>
                                      </th>
                                      <th>
                                        <b>Add After Value</b>
                                      </th>
                                      <th>
                                        <b>Edit Before Value</b>
                                      </th>
                                      <th>
                                        <b>Edit After Value</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={6}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Audit_log;
