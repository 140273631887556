
import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";

const ServicLog = () => {
  const { error1, setError1 } = useState({});

  const [LoadedServiceLog, setLoadedServiceLog] = useState([]);

  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/service_logs/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.serviceLogs;
      var strAscending = [...json].sort((a, b) => (a.user > b.user ? 1 : -1));
      setLoadedServiceLog(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.user.toLowerCase().match(search.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.user > b.user ? 1 : -1));
    setLoadedServiceLog(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const columns = [
    {
      name: "Users",
      selector: (row) => <div> {row.user}</div>,
      sortable: true,
    },

    {
      name: "Subdomain",
      selector: (row) => <div> {row.subdomain}</div>,
      sortable: true,
    },

    {
      name: "Service_Type",
      selector: (row) => <div> {row.service_type}</div>,
      sortable: true,
    },

    {
      name: "Message",
      selector: (row) => (
        <div>
          <ul>
            <li>subdomain: {row.message.subdomain}</li>
            <li>iot_subaccount: {row.message.iot_subaccount}</li>
            <li>iot_location: {row.message.iot_location} </li>
            <li>iot_department: {row.message.iot_department}</li>
            <li>iot_asset: {row.message.iot_asset}</li>
            <li>device_uuid: {row.message.device_uuid}</li>
            <li>sensor_uuid: {row.message.sensor_uuid}</li>
            <li>Status: {row.message.Status}</li>
            <li>message: {row.message.message}</li>
            <li>
              last_updated_at:
              {row.message.last_updated_at}
            </li>
          </ul>
        </div>
      ),

      sortable: true,
    },

    {
      name: "Datetime",
      selector: (row) => row.datetime,
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <h1 className="pageTitle">Service Logs</h1>

                <div class="col-md-6 col-lg-12 col-xl-12">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter mb20">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedServiceLog}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Users</b>
                                      </th>
                                      <th>
                                        <b>Subdomain</b>
                                      </th>
                                      <th>
                                        <b>Service_Type</b>
                                      </th>
                                      <th>
                                        <b>Message</b>
                                      </th>
                                      <th>
                                        <b>Datetime</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={5}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ServicLog;
