/* eslint-disable no-unused-vars */
import DataTable from "react-data-table-component";
import { Table, Popconfirm, Button, Space, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
// import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { useParams } from "react-router-dom";

import { useLocation, Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, milliseconds, parseISO } from "date-fns";

// import "../tableStyle/tableStyle.css";

const Nameplate = () => {
  //const [nameplate, setnameplate] = useState();
  const [inpval, setINP] = useState({});
  const { id } = useParams();
  console.log(id);

  console.log("inpval", inpval);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setdate_of_RMS_installation(date);
  };
  const [area_of_solar_panels_sq_m, setarea_of_solar_panels_sq_m] = useState();
  const [panel_capacity_kW, setpanel_capacity_kW] = useState();
  const [conversion_efficiency, setconversion_efficiency] = useState();
  const [degradation_factor, setdegradation_factor] = useState();
  const [number_of_panel, setnumber_of_panel] = useState();
  const [battery_discharge_voltage_V, setbattery_discharge_voltage_V] =
    useState();
  const [battery_full_charge_voltage_V, setbattery_full_charge_voltage_V] =
    useState();
  const [battery_capacity_Ahr, setbattery_capacity_Ahr] = useState();
  const [number_of_battery, setnumber_of_battery] = useState();
  const [inverter_capacity_kW, setinverter_capacity_kW] = useState();
  const [date_of_RMS_installation, setdate_of_RMS_installation] = useState(
    inpval?.date_of_RMS_installation || null
  );

  const [cumulative_energy, setcumulative_energy] = useState();
  const [energy_imported, setenergy_imported] = useState();
  const [energy_exported, setenergy_exported] = useState();
  const [prev_active_energy_imported_kwh, setprev_active_energy_imported_kwh] =
    useState();
  const [prev_active_energy_exported_kwh, setprev_active_energy_exported_kwh] =
    useState();
  const [
    prev_reactive_energy_imported_kvarh,
    setprev_reactive_energy_imported_kvarh,
  ] = useState();
  const [
    prev_reactive_energy_exported_kvarh,
    setprev_reactive_energy_exported_kvarh,
  ] = useState();
  const [
    prev_apparent_energy_imported_kvah,
    setprev_apparent_energy_imported_kvah,
  ] = useState();
  const [
    prev_apparent_energy_exported_kvah,
    setprev_apparent_energy_exported_kvah,
  ] = useState();
  const [rated_load, setrated_load] = useState();

  const [hv_connection, sethv_connection] = useState();
  const [voltage_unbalance_limit, setvoltage_unbalance_limit] = useState();
  const [current_unbalance_limit, setcurrent_unbalance_limit] = useState();
  const [energy_rate_rs, setenergy_rate_rs] = useState();
  const [designed_efficiency, setdesigned_efficiency] = useState();
  const [last_hotspot, setlast_hotspot] = useState();
  const [winding_constant, setwinding_constant] = useState();
  const [eddy_loss_per_unit, seteddy_loss_per_unit] = useState();
  const [last_actual_life_hours, setlast_actual_life_hours] = useState();
  const [last_standard_life_hours, setlast_standard_life_hours] = useState();
  const [lv2_connection, setlv2_connection] = useState();
  const [amb_temp, setamb_temp] = useState();
  const [lv1_connection, setlv1_connection] = useState();
  const [phase, setphase] = useState();
  const [winding_material, setwinding_material] = useState();
  const [
    winding_temp_at_winding_resistance,
    setwinding_temp_at_winding_resistance,
  ] = useState();
  const [tap_position, settap_position] = useState(0);
  const [secondary_side, setsecondary_side] = useState();
  const [primary_side, setprimary_side] = useState();
  const [
    max_wind_temp_rise_above_amb_temp,
    setmax_wind_temp_rise_above_amb_temp,
  ] = useState();
  const [
    max_oil_temp_rise_above_amb_temp,
    setmax_oil_temp_rise_above_amb_temp,
  ] = useState();
  const [rated_hotspot, setrated_hotspot] = useState();
  const [rated_top_oil_temp, setrated_top_oil_temp] = useState();
  const [full_load_loss_kw, setfull_load_loss_kw] = useState();
  const [no_load_loss_kw, setno_load_loss_kw] = useState();
  const [rated_copper_loss_kw, setrated_copper_loss_kw] = useState();
  const [Rating_va, setRating_va] = useState();
  const [lv_rated_current, setlv_rated_current] = useState();
  const [lv_connection, setlv_connection] = useState();
  const [designed_load_percent, setdesigned_load_percent] = useState();
  const [load_variation, setload_variation] = useState();
  const [
    prev_cumulative_active_energy_lv,
    setprev_cumulative_active_energy_lv,
  ] = useState();
  const [
    prev_cumulative_reactive_energy_lv,
    setprev_cumulative_reactive_energy_lv,
  ] = useState();
  const [
    prev_cumulative_apparent_energy_lv,
    setprev_cumulative_apparent_energy_lv,
  ] = useState();
  const [
    prev_cumulative_active_energy_lv1,
    setprev_cumulative_active_energy_lv1,
  ] = useState();
  const [
    prev_cumulative_reactive_energy_lv1,
    setprev_cumulative_reactive_energy_lv1,
  ] = useState();
  const [
    prev_cumulative_apparent_energy_lv1,
    setprev_cumulative_apparent_energy_lv1,
  ] = useState();
  const [
    prev_cumulative_active_energy_lv2,
    setprev_cumulative_active_energy_lv2,
  ] = useState();
  const [
    prev_cumulative_reactive_energy_lv2,
    setprev_cumulative_reactive_energy_lv2,
  ] = useState();
  const [
    prev_cumulative_apparent_energy_lv2,
    setprev_cumulative_apparent_energy_lv2,
  ] = useState();
  const [
    prev_cumulative_active_energy_hv,
    setprev_cumulative_active_energy_hv,
  ] = useState();
  const [
    prev_cumulative_reactive_energy_hv,
    setprev_cumulative_reactive_energy_hv,
  ] = useState();
  const [
    prev_cumulative_apparent_energy_hv,
    setprev_cumulative_apparent_energy_hv,
  ] = useState();
  const [lv_cum_energy, setlv_cum_energy] = useState();
  const [hv_cum_energy, sethv_cum_energy] = useState();
  const [cooling_type, setcooling_type] = useState();
  const [oil, setoil] = useState();
  const [voltage_ratio, setvoltage_ratio] = useState();
  const [iotsolution, setIotSolution] = useState("");

  const getdata = async () => {
    let _id = id;
    console.log(id);
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getIotSensorMaster/${_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    console.log(data);
    setIotSolution(data.iot_sol);
    console.log(data.nameplate);
    setINP(data.nameplate);
    // console.log(setINP.IotSensorMasters_name);
    // console.log(data);

    if (res.status === 422 || !data) {
      console.log("error");
    } else {
      setINP(data.nameplate);
      setlv_rated_current(inpval?.lv_rated_current);

      setRating_va(inpval?.Rating_va);
      setamb_temp(inpval?.amb_temp);
      setcooling_type(inpval?.cooling_type);
      setcumulative_energy(inpval?.cumulative_energy);
      setcurrent_unbalance_limit(inpval?.current_unbalance_limit);
      setdesigned_efficiency(inpval?.designed_efficiency);
      setdesigned_load_percent(inpval?.designed_load_percent);

      seteddy_loss_per_unit(inpval?.eddy_loss_per_unit);
      setenergy_exported(inpval?.energy_exported);
      setenergy_imported(inpval?.energy_imported);
      setenergy_rate_rs(inpval?.energy_rate_rs);
      setfull_load_loss_kw(inpval?.full_load_loss_kw);
      sethv_connection(inpval?.hv_connection);
      sethv_cum_energy(inpval?.hv_cum_energy);
      setlast_actual_life_hours(inpval?.last_actual_life_hours);
      setlast_hotspot(inpval?.last_hotspot);
      setlast_standard_life_hours(inpval?.last_standard_life_hours);
      setload_variation(inpval?.load_variation);
      setlv1_connection(inpval?.lv1_connection);
      setlv2_connection(inpval?.lv2_connection);
      setlv_connection(inpval?.lv_connection);
      setlv_cum_energy(inpval?.lv_cum_energy);
      setmax_oil_temp_rise_above_amb_temp(
        inpval?.max_oil_temp_rise_above_amb_temp
      );
      setmax_wind_temp_rise_above_amb_temp(
        inpval?.max_wind_temp_rise_above_amb_temp
      );
      setno_load_loss_kw(inpval?.no_load_loss_kw);

      setoil(inpval?.oil);
      setphase(inpval?.phase);
      setprev_active_energy_exported_kwh(
        inpval?.prev_active_energy_exported_kwh
      );
      setprev_active_energy_imported_kwh(
        inpval?.prev_active_energy_imported_kwh
      );
      setprev_apparent_energy_exported_kvah(
        inpval?.prev_apparent_energy_exported_kvah
      );
      setprev_apparent_energy_imported_kvah(
        inpval?.prev_active_energy_imported_kwh
      );
      setprev_cumulative_active_energy_hv(
        inpval?.prev_cumulative_active_energy_hv
      );
      setprev_cumulative_active_energy_lv(
        inpval?.prev_cumulative_active_energy_lv
      );
      setprev_cumulative_active_energy_lv1(
        inpval?.prev_cumulative_active_energy_lv1
      );
      setprev_cumulative_active_energy_lv2(
        inpval?.prev_cumulative_active_energy_lv2
      );
      setprev_cumulative_apparent_energy_hv(
        inpval?.prev_cumulative_apparent_energy_hv
      );
      setprev_cumulative_apparent_energy_lv(
        inpval?.prev_cumulative_apparent_energy_lv
      );
      setprev_cumulative_apparent_energy_lv1(
        inpval?.prev_cumulative_apparent_energy_lv1
      );
      setprev_cumulative_apparent_energy_lv2(
        inpval?.prev_cumulative_apparent_energy_lv2
      );
      setprev_cumulative_reactive_energy_hv(
        inpval?.prev_cumulative_reactive_energy_hv
      );
      setprev_cumulative_reactive_energy_lv(
        inpval?.prev_cumulative_reactive_energy_lv
      );
      setprev_cumulative_reactive_energy_lv1(
        inpval?.prev_cumulative_reactive_energy_lv1
      );
      setprev_cumulative_reactive_energy_lv2(
        inpval?.prev_cumulative_reactive_energy_lv2
      );
      setprev_reactive_energy_exported_kvarh(
        inpval?.prev_reactive_energy_exported_kvarh
      );
      setprev_reactive_energy_imported_kvarh(
        inpval?.prev_reactive_energy_imported_kvarh
      );
      setprimary_side(inpval?.primary_side);
      setrated_copper_loss_kw(inpval?.rated_copper_loss_kw);
      setrated_hotspot(inpval?.rated_hotspot);
      setrated_load(inpval?.rated_load);
      setrated_top_oil_temp(inpval?.rated_top_oil_temp);
      setsecondary_side(inpval?.secondary_side);

      settap_position(inpval?.tap_position);
      setvoltage_ratio(inpval?.voltage_ratio);
      setvoltage_unbalance_limit(inpval?.voltage_unbalance_limit);
      setwinding_constant(inpval?.winding_constant);
      setwinding_material(inpval?.winding_material);
      setwinding_temp_at_winding_resistance(
        inpval?.winding_temp_at_winding_resistance
      );
      setarea_of_solar_panels_sq_m(inpval?.area_of_solar_panels_sq_m);
      setpanel_capacity_kW(inpval?.panel_capacity_kW);
      setconversion_efficiency(inpval?.conversion_efficiency);
      setdegradation_factor(inpval?.degradation_factor);
      setnumber_of_panel(inpval?.number_of_panel);
      setbattery_discharge_voltage_V(inpval?.battery_discharge_voltage_V);
      setbattery_full_charge_voltage_V(inpval?.battery_full_charge_voltage_V);
      setbattery_capacity_Ahr(inpval?.battery_capacity_Ahr);
      setnumber_of_battery(inpval?.number_of_battery);
      setinverter_capacity_kW(inpval?.inverter_capacity_kW);
      // let date_of_RMS_installation1 = format( inpval?.date_of_RMS_installation, "dd/MM/yyyy");
      // console.log("date_of_RMS_installation1",date_of_RMS_installation1)
      //     const dateObject = new Date(inpval?.date_of_RMS_installation);

      //     // Format the date to "dd/MM/yyyy"
      //     const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
      // console.log(formattedDate);
      setdate_of_RMS_installation(inpval?.date_of_RMS_installation || null);

      // console.log("get data");
    }
  };
  useEffect(() => {
    getdata();
  }, [
    inpval?.lv1_connection,
    inpval?.prev_active_energy_imported_kwh,
    inpval?.date_of_RMS_installation,
  ]);
  console.log("after", inpval);
  console.log(lv_rated_current);
  console.log(inpval.lv_rated_current);
  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      let _id = id;
      // console.log(_id);
      // e.preventDefault();
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/updateNameplate/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nameplate: {
              hv_connection: hv_connection,
              lv_connection: lv_connection,
              lv_rated_current: lv_rated_current,
              Rating_va: Rating_va,
              rated_copper_loss_kw: rated_copper_loss_kw,
              no_load_loss_kw: no_load_loss_kw,
              full_load_loss_kw: full_load_loss_kw,
              rated_top_oil_temp: rated_top_oil_temp,
              rated_hotspot: rated_hotspot,
              max_oil_temp_rise_above_amb_temp:
                max_oil_temp_rise_above_amb_temp,
              max_wind_temp_rise_above_amb_temp:
                max_wind_temp_rise_above_amb_temp,
              primary_side: primary_side,
              secondary_side: secondary_side,
              tap_position: tap_position,
              winding_temp_at_winding_resistance:
                winding_temp_at_winding_resistance,
              winding_material: winding_material,
              phase: phase,
              lv1_connection: lv1_connection,
              amb_temp: amb_temp,
              lv2_connection: lv2_connection,
              last_standard_life_hours: last_standard_life_hours,
              last_actual_life_hours: last_actual_life_hours,
              eddy_loss_per_unit: eddy_loss_per_unit,
              winding_constant: winding_constant,
              last_hotspot: last_hotspot,
              designed_efficiency: designed_efficiency,
              energy_rate_rs: energy_rate_rs,

              designed_load_percent: designed_load_percent,
              load_variation: load_variation,
              prev_cumulative_active_energy_lv:
                prev_cumulative_active_energy_lv,
              prev_cumulative_reactive_energy_lv:
                prev_cumulative_reactive_energy_lv,
              prev_cumulative_apparent_energy_lv:
                prev_cumulative_apparent_energy_lv,
              prev_cumulative_active_energy_lv1:
                prev_cumulative_active_energy_lv1,
              prev_cumulative_reactive_energy_lv1:
                prev_cumulative_reactive_energy_lv1,
              prev_cumulative_apparent_energy_lv1:
                prev_cumulative_apparent_energy_lv1,
              prev_cumulative_active_energy_lv2:
                prev_cumulative_active_energy_lv2,
              prev_cumulative_reactive_energy_lv2:
                prev_cumulative_reactive_energy_lv2,
              prev_cumulative_apparent_energy_lv2:
                prev_cumulative_apparent_energy_lv2,
              prev_cumulative_active_energy_hv:
                prev_cumulative_active_energy_hv,
              prev_cumulative_reactive_energy_hv:
                prev_cumulative_reactive_energy_hv,
              prev_cumulative_apparent_energy_hv:
                prev_cumulative_apparent_energy_hv,
              lv_cum_energy: lv_cum_energy,
              hv_cum_energy: hv_cum_energy,
              current_unbalance_limit: current_unbalance_limit,
              voltage_unbalance_limit: voltage_unbalance_limit,
              cooling_type: cooling_type,
              oil: oil,
              voltage_ratio: voltage_ratio,
            },
          }),
        }
      );

      const data = await res.json();
      // console.log(data);

      if (res.status === 200 || data) {
        // setINP(data);
        alert("successfully Edited");
        window.location = "/iot_sensor_masters";
      } else {
        alert("please insert data");
      }
    },
  });

  const EMSFormik = useFormik({
    enableReinitialize: true,

    onSubmit: async (values) => {
      let _id = id;
      // console.log(_id);
      // e.preventDefault();
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/updateNameplate/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nameplate: {
              phase: phase,
              cumulative_energy: cumulative_energy,

              energy_imported: energy_imported,

              energy_exported: energy_exported,

              prev_active_energy_imported_kwh: prev_active_energy_imported_kwh,

              prev_active_energy_exported_kwh: prev_active_energy_exported_kwh,

              prev_reactive_energy_imported_kvarh:
                prev_reactive_energy_imported_kvarh,

              prev_reactive_energy_exported_kvarh:
                prev_reactive_energy_exported_kvarh,

              prev_apparent_energy_imported_kvah:
                prev_apparent_energy_imported_kvah,

              prev_apparent_energy_exported_kvah:
                prev_apparent_energy_exported_kvah,

              energy_rate_rs: energy_rate_rs,

              current_unbalance_limit: current_unbalance_limit,

              voltage_unbalance_limit: voltage_unbalance_limit,

              rated_load: rated_load,
            },
          }),
        }
      );

      const data = await res.json();
      // console.log(data);

      if (res.status === 200 || data) {
        // setINP(data);
        alert("successfully Edited");
        window.location = "/iot_sensor_masters";
      } else {
        alert("please insert data");
      }
    },
  });
  const SELCOFormik = useFormik({
    enableReinitialize: true,

    onSubmit: async (values) => {
      let _id = id;
      // console.log(_id);
      // e.preventDefault();
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/updateNameplate/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nameplate: {
              area_of_solar_panels_sq_m: area_of_solar_panels_sq_m,
              panel_capacity_kW: panel_capacity_kW,
              conversion_efficiency: conversion_efficiency,
              degradation_factor: degradation_factor,
              number_of_panel: number_of_panel,
              battery_discharge_voltage_V: battery_discharge_voltage_V,
              battery_full_charge_voltage_V: battery_full_charge_voltage_V,
              battery_capacity_Ahr: battery_capacity_Ahr,
              number_of_battery: number_of_battery,
              inverter_capacity_kW: inverter_capacity_kW,
              date_of_RMS_installation: date_of_RMS_installation,
            },
          }),
        }
      );

      const data = await res.json();
      // console.log(data);

      if (res.status === 200 || data) {
        // setINP(data);
        alert("successfully Edited");
        window.location = "/iot_sensor_masters";
      } else {
        alert("please insert data");
      }
    },
  });
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div class="col-sm-6">
              <h1 className="pageTitle">Edit Nameplate</h1>
            </div>
            {iotsolution === "Transformer" && (
              <form className="">
                <div className="row">
                  {/* <div className="col-md-12"> */}

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Hv Connection
                      </label>

                      <select
                        id="ak"
                        name="hv_connection"
                        value={hv_connection}
                        onChange={(e) => sethv_connection(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={hv_connection}>
                          {hv_connection !== "" ? hv_connection : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Star">Star</option>
                        <option value="Delta">Delta</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Lv Connection
                      </label>
                      <select
                        id="ak"
                        name="lv_connection"
                        value={lv_connection}
                        onChange={(e) => setlv_connection(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={lv_connection}>
                          {lv_connection !== "" ? lv_connection : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Star">Star</option>
                        <option value="Delta">Delta</option>
                      </select>
                    </div>
                  </div>

                  {/* // */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Lv Rated Current
                      </label>
                      <input
                        type="number"
                        name="lv_rated_current"
                        value={lv_rated_current}
                        onChange={(e) => setlv_rated_current(e.target.value)}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Rating Va
                      </label>
                      <input
                        type="number"
                        name="Rating_va"
                        value={Rating_va}
                        onChange={(e) => setRating_va(e.target.value)}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Rated Copper Loss Kw
                      </label>
                      <input
                        type="number"
                        name="rated_copper_loss_kw"
                        value={rated_copper_loss_kw}
                        onChange={(e) =>
                          setrated_copper_loss_kw(e.target.value)
                        }
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        No Load Loss Kw
                      </label>
                      <input
                        type="number"
                        value={no_load_loss_kw}
                        name="no_load_loss_kw"
                        onChange={(e) => setno_load_loss_kw(e.target.value)}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        full load Loss Kw
                      </label>
                      <input
                        type="number"
                        value={full_load_loss_kw}
                        name="full_load_loss_kw"
                        onChange={(e) => setfull_load_loss_kw(e.target.value)}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Rated Top Oil Temp
                      </label>
                      <input
                        type="number"
                        value={rated_top_oil_temp}
                        onChange={(e) => setrated_top_oil_temp(e.target.value)}
                        name="rated_top_oil_temp"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Rated Hotspot
                      </label>
                      <input
                        type="number"
                        value={rated_hotspot}
                        onChange={(e) => setrated_hotspot(e.target.value)}
                        name="rated_hotspot"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Max Oil Temp Rise Above Amb Temp
                      </label>
                      <input
                        type="number"
                        value={max_oil_temp_rise_above_amb_temp}
                        onChange={(e) =>
                          setmax_oil_temp_rise_above_amb_temp(e.target.value)
                        }
                        name="max_oil_temp_rise_above_amb_temp"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Max Wind Temp Rise Above Amb Temp
                      </label>
                      <input
                        type="number"
                        value={max_wind_temp_rise_above_amb_temp}
                        name="max_wind_temp_rise_above_amb_temp"
                        onChange={(e) =>
                          setmax_wind_temp_rise_above_amb_temp(e.target.value)
                        }
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Primary Side
                      </label>
                      <select
                        id="ak"
                        name="primary_side"
                        value={primary_side}
                        onChange={(e) => setprimary_side(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={primary_side}>
                          {primary_side !== "" ? primary_side : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="HV">HV</option>
                        <option value="LV">LV</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Secondary Side
                      </label>

                      <select
                        id="ak"
                        name="secondary_side"
                        value={secondary_side}
                        onChange={(e) => setsecondary_side(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={secondary_side}>
                          {secondary_side !== ""
                            ? secondary_side
                            : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="HV">HV</option>
                        <option value="LV">LV</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Tap Position
                      </label>
                      <select
                        id="ak"
                        type="number"
                        name="tap_position"
                        value={tap_position}
                        onChange={(e) =>
                          settap_position(parseInt(e.target.value))
                        }
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={tap_position}>
                          {tap_position !== 0 ? tap_position : "Select One"}
                        </option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Winding Temp At Winding Resistance
                      </label>
                      <input
                        type="number"
                        value={winding_temp_at_winding_resistance}
                        name="winding_temp_at_winding_resistance"
                        onChange={(e) =>
                          setwinding_temp_at_winding_resistance(e.target.value)
                        }
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Winding Material
                      </label>

                      <select
                        id="ak"
                        name="winding_material"
                        value={winding_material}
                        onChange={(e) => setwinding_material(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option
                          disabled
                          selected="true"
                          value={winding_material}
                        >
                          {winding_material !== ""
                            ? winding_material
                            : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Copper">Copper</option>
                        <option value="Aluminium">Aluminium</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Phase
                      </label>
                      <select
                        id="ak"
                        name="phase"
                        value={phase}
                        onChange={(e) => setphase(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={phase}>
                          {phase !== "" ? phase : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Single">Single</option>
                        <option value="Three">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Lv1 Connection
                      </label>

                      <select
                        id="ak"
                        name="lv1_connection"
                        value={lv1_connection}
                        onChange={(e) => setlv1_connection(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={lv1_connection}>
                          {lv1_connection !== ""
                            ? lv1_connection
                            : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Star">Star</option>
                        <option value="Delta">Delta</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Amb Temp
                      </label>
                      <input
                        type="number"
                        value={amb_temp}
                        name="amb_temp"
                        onChange={(e) => setamb_temp(e.target.value)}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Lv2 Connection
                      </label>

                      <select
                        id="ak"
                        name="lv2_connection"
                        value={lv2_connection}
                        onChange={(e) => setlv2_connection(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={lv2_connection}>
                          {lv2_connection !== ""
                            ? lv2_connection
                            : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Star">Star</option>
                        <option value="Delta">Delta</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Last Standard Life Hours
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setlast_standard_life_hours(e.target.value)
                        }
                        value={last_standard_life_hours}
                        name="last_standard_life_hours"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Last Actual Life Hours
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setlast_actual_life_hours(e.target.value)
                        }
                        value={last_actual_life_hours}
                        name="last_actual_life_hours"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Eddy Loss Per Unit
                      </label>
                      <input
                        type="number"
                        onChange={(e) => seteddy_loss_per_unit(e.target.value)}
                        value={eddy_loss_per_unit}
                        name="eddy_loss_per_unit"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Winding Constant
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setwinding_constant(e.target.value)}
                        value={winding_constant}
                        name="winding_constant"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Last Hotspot
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setlast_hotspot(e.target.value)}
                        value={last_hotspot}
                        name="last_hotspot"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Designed Efficiency
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setdesigned_efficiency(e.target.value)}
                        value={designed_efficiency}
                        name="designed_efficiency"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Energy Rate Rs
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setenergy_rate_rs(e.target.value)}
                        value={energy_rate_rs}
                        name="energy_rate_rs"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Current Unbalance Limit
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setcurrent_unbalance_limit(e.target.value)
                        }
                        value={current_unbalance_limit}
                        name="current_unbalance_limit"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Voltage Unbalance Limit
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setvoltage_unbalance_limit(e.target.value)
                        }
                        value={voltage_unbalance_limit}
                        name="voltage_unbalance_limit"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Designed Load Percent
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setdesigned_load_percent(e.target.value)
                        }
                        value={designed_load_percent}
                        name="designed_load_percent"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Load Variation
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setload_variation(e.target.value)}
                        value={load_variation}
                        name="load_variation"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Active Energy Lv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_active_energy_lv(e.target.value)
                        }
                        value={prev_cumulative_active_energy_lv}
                        name="prev_cumulative_active_energy_lv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Reactive Energy Lv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_reactive_energy_lv(e.target.value)
                        }
                        value={prev_cumulative_reactive_energy_lv}
                        name="prev_cumulative_reactive_energy_lv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Apparent Energy Lv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_apparent_energy_lv(e.target.value)
                        }
                        value={prev_cumulative_apparent_energy_lv}
                        name="prev_cumulative_apparent_energy_lv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Active Energy Lv1
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_active_energy_lv1(e.target.value)
                        }
                        value={prev_cumulative_active_energy_lv1}
                        name="prev_cumulative_active_energy_lv1"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Reactive Energy Lv1
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_reactive_energy_lv1(e.target.value)
                        }
                        value={prev_cumulative_reactive_energy_lv1}
                        name="prev_cumulative_reactive_energy_lv1"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Apparent Energy Lv1
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_apparent_energy_lv1(e.target.value)
                        }
                        value={prev_cumulative_apparent_energy_lv1}
                        name="prev_cumulative_apparent_energy_lv1"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Active Energy Lv2
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_active_energy_lv2(e.target.value)
                        }
                        value={prev_cumulative_active_energy_lv2}
                        name="prev_cumulative_active_energy_lv2"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Reactive Energy Lv2
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_reactive_energy_lv2(e.target.value)
                        }
                        value={prev_cumulative_reactive_energy_lv2}
                        name="prev_cumulative_reactive_energy_lv2"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Apparent Energy Lv2
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_apparent_energy_lv2(e.target.value)
                        }
                        value={prev_cumulative_apparent_energy_lv2}
                        name="prev_cumulative_apparent_energy_lv2"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Active Energy Hv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_active_energy_hv(e.target.value)
                        }
                        value={prev_cumulative_active_energy_hv}
                        name="prev_cumulative_active_energy_hv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Reactive Energy Hv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_reactive_energy_hv(e.target.value)
                        }
                        value={prev_cumulative_reactive_energy_hv}
                        name="prev_cumulative_reactive_energy_hv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Cumulative Apparent Energy Hv
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_cumulative_apparent_energy_hv(e.target.value)
                        }
                        value={prev_cumulative_apparent_energy_hv}
                        name="prev_cumulative_apparent_energy_hv"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Lv Cum Energy
                      </label>

                      <select
                        id="ak"
                        name="lv_cum_energy"
                        value={lv_cum_energy}
                        onChange={(e) => setlv_cum_energy(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={lv_cum_energy}>
                          {lv_cum_energy !== "" ? lv_cum_energy : "Select One"}
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Hv Cum Energy
                      </label>

                      <select
                        id="ak"
                        name="hv_cum_energy"
                        value={hv_cum_energy}
                        onChange={(e) => sethv_cum_energy(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={hv_cum_energy}>
                          {hv_cum_energy !== "" ? hv_cum_energy : "Select One"}
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Cooling Type
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setcooling_type(e.target.value)}
                        value={cooling_type}
                        name="cooling_type"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Oil
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setoil(e.target.value)}
                        value={oil}
                        name="oil"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Voltage Ratio
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setvoltage_ratio(e.target.value)}
                        value={voltage_ratio}
                        name="voltage_ratio"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="text-center mt20 mb10">
                  <Link exact to={"/iot_sensor_masters"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}

            {iotsolution === "EMS" && (
              <form>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Phase
                      </label>
                     
                       <select
                        id="ak"
                        name="phase"
                        value={phase}
                        onChange={(e) => setphase(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={phase}>
                          {phase !== "" ? phase : "Select One"}
                        </option>
                        <option value="None">None</option>
                        <option value="Single">Single</option>
                        <option value="Three">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Cumulative Energy
                      </label>
                     
                       <select
                        id="ak"
                        name="cumulative_energy"
                        value={cumulative_energy}
                        onChange={(e) => setcumulative_energy(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={cumulative_energy}>
                          {cumulative_energy !== "" ? cumulative_energy : "Select One"}
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Energy Imported
                      </label>
                     
                       <select
                        id="ak"
                        name="energy_imported"
                        value={energy_imported}
                        onChange={(e) => setenergy_imported(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={energy_imported}>
                          {energy_imported !== "" ? energy_imported : "Select One"}
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Energy Exported
                      </label>
                      <select
                        id="ak"
                        name="energy_exported"
                        value={energy_exported}
                        onChange={(e) => setenergy_exported(e.target.value)}
                        className="js-states form-control "
                        class="form-select"
                      >
                        <option disabled selected="true" value={energy_exported}>
                          {energy_exported !== "" ? energy_exported : "Select One"}
                        </option>

                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Active Energy Imported Kwh
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_active_energy_imported_kwh(e.target.value)
                        }
                        value={prev_active_energy_imported_kwh}
                        name="prev_active_energy_imported_kwh"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Active Energy Exported Kwh
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_active_energy_exported_kwh(e.target.value)
                        }
                        value={prev_active_energy_exported_kwh}
                        name="prev_active_energy_exported_kwh"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Reactive Energy Imported Kvarh
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_reactive_energy_imported_kvarh(e.target.value)
                        }
                        value={prev_reactive_energy_imported_kvarh}
                        name="prev_reactive_energy_imported_kvarh"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Reactive Energy Exported Kvarh
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_reactive_energy_exported_kvarh(e.target.value)
                        }
                        value={prev_reactive_energy_exported_kvarh}
                        name="prev_reactive_energy_exported_kvarh"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Apparent Energy Imported Kvah
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_apparent_energy_imported_kvah(e.target.value)
                        }
                        value={prev_apparent_energy_imported_kvah}
                        name="prev_apparent_energy_imported_kvah"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Prev Apparent Energy Exported Kvah
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setprev_apparent_energy_exported_kvah(e.target.value)
                        }
                        value={prev_apparent_energy_exported_kvah}
                        name="prev_apparent_energy_exported_kvah"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Energy Rate Rs
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setenergy_rate_rs(e.target.value)}
                        value={energy_rate_rs}
                        name="energy_rate_rs"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Current Unbalance Limit
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setcurrent_unbalance_limit(e.target.value)
                        }
                        value={current_unbalance_limit}
                        name="current_unbalance_limit"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Voltage Unbalance Limit
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setvoltage_unbalance_limit(e.target.value)
                        }
                        value={voltage_unbalance_limit}
                        name="voltage_unbalance_limit"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Rated Load
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setrated_load(e.target.value)}
                        value={rated_load}
                        name="rated_load"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt20 mb10">
                  <Link exact to={"/iot_sensor_masters"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={EMSFormik.handleSubmit}
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
            {(iotsolution === "SELCO" || iotsolution === "Solar") && (
              <form>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Area of solar panel sq.m
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setarea_of_solar_panels_sq_m(e.target.value)
                        }
                        value={area_of_solar_panels_sq_m}
                        name="area_of_solar_panels_sq_m"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Panel Capacity kW
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setpanel_capacity_kW(e.target.value)}
                        value={panel_capacity_kW}
                        name="panel_capacity_kW"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Conversion Efficiency
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setconversion_efficiency(e.target.value)
                        }
                        value={conversion_efficiency}
                        name="conversion_efficiency"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Degradation Factor
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setdegradation_factor(e.target.value)}
                        value={degradation_factor}
                        name="degradation_factor"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Number of Panel
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setnumber_of_panel(e.target.value)}
                        value={number_of_panel}
                        name="number_of_panel"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Battery Discharge Voltage V
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setbattery_discharge_voltage_V(e.target.value)
                        }
                        value={battery_discharge_voltage_V}
                        name="battery_discharge_voltage_V"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Battery Full Charge Voltage V
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setbattery_full_charge_voltage_V(e.target.value)
                        }
                        value={battery_full_charge_voltage_V}
                        name="battery_full_charge_voltage_V"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Battery Capacity Ahr
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setbattery_capacity_Ahr(e.target.value)
                        }
                        value={battery_capacity_Ahr}
                        name="battery_capacity_Ahr"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Number of Battery
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setnumber_of_battery(e.target.value)}
                        value={number_of_battery}
                        name="number_of_battery"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Inverter Capacity kW
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setinverter_capacity_kW(e.target.value)
                        }
                        value={inverter_capacity_kW}
                        name="inverter_capacity_kW"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Date of RMS Installation
                      </label>
                      <DatePicker
                        selected={
                          typeof date_of_RMS_installation === "string"
                            ? new Date(date_of_RMS_installation)
                            : date_of_RMS_installation
                        }
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        className="form-control" // Customize date format if needed
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt20 mb10">
                  <Link exact to={"/iot_sensor_masters"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={SELCOFormik.handleSubmit}
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Nameplate;
