/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DataTable from "react-data-table-component";
import React, { useEffect, useContext, useState } from "react";
import noteContext from "../noteContext";
import axios from "axios";
// import subaccountsModel from "../../backend/models/subaccount.js";
// import subaccountsModel from "../../backend/models/subaccount";
//import { validate } from "../../backend/models/accounts";
import { tableCustomStyles } from "../tableStyle";
import { Link, useHistory } from "react-router-dom";
const Accounts = () => {
  const [user1, setSubCount] = useState([]);
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [Status, set1Status] = useState({});
  const [AcEdit, setAcEdit] = useState({});
  const context = useContext(noteContext);
  const [status, setStatus] = useState(AcEdit?.status);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/accountsModel/getSubAccounts`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.subdomain > b.subdomain ? 1 : -1
      );
      setSubCount(strAscending);

      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  // const fetchcount = () => {
  //   return fetch("${process.env.REACT_APP_VARIABLE}/accountsModel/getSubAccounts")
  //         .then((response) => response.json())
  //         .then((subacCount) => setSubCount(subacCount));
  // }

  // useEffect(() => {

  //   fetchcount();
  // },[])
  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.SubAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.LocAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.DeptAcCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.AssetsCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.UsersCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.TempletsCount.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setSubCount(strAscending);

    if (strAscending) {
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  console.log("user1", user1);
  // const [inpval, setINP] = useState({
  //   status: "",
  // });

  const editACid = async (_id) => {
    let id = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/getAcId/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAcEdit(res.account1);

    console.log(AcEdit.account1);
  };

  const handleAcStatus = async (e) => {
    e.preventDefault();
    let id = AcEdit._id;
    // console.log(id);
    // let status = AcEdit.status;
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAc/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        // body: JSON.stringify(AcEdit.status),
        body: JSON.stringify({
          AcEdit,
        }),
      }
    );
    if (response.status === 201) {
      alert("Account updated successfully");
    } else {
      alert("Some error occured");
    }
    // console.log(AcEdit);
  };

  const handleStatus = async (e) => {
    e.preventDefault();
    let id = AcEdit._id;
    // console.log(id);
    // console.log("status:", status);
    //let status = AcEdit.status;
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAc/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        // body: JSON.stringify(AcEdit.status),
        body: JSON.stringify({
          status: status,
        }),
      }
    );
    let res = response.json();
    if (res) {
      setEdit(true);
    }
    if (response.status === 201) {
      setEdit(false);
      alert("Status updated successfully");

      fetchData();
    } else {
      alert("Some error occured");
    }
    // console.log(AcEdit);
  };
 
  const columns = [
    {
      name: "Subdomain",
      selector: (row) => <div className="bigtext"> {row.subdomain}</div>,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => <div className="bigtext"> {row.name}</div>,
      sortable: true,
    },
    {
      name: "Subaccounts",
      selector: (row) => <div>{row.SubAcCount}</div>,
      sortable: true,
    },
    {
      name: "Locations",
      selector: (row) => <div>{row.LocAcCount}</div>,
      sortable: true,
    },
    {
      name: "Departments",
      selector: (row) => <div>{row.DeptAcCount}</div>,
      sortable: true,
    },
    {
      name: "Assets",
      selector: (row) => <div>{row.AssetsCount}</div>,
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) => <div>{row.UsersCount}</div>,
      sortable: true,
    },
    {
      name: "Templates",
      selector: (row) => <div>{row.TempletsCount}</div>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <td>
          <a href={row.id} onClick={() => editACid(row.id)}>
            {/* {val.status} */}
            <div className="switch">
              <label className="switch-light">
                {edit ? (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => set1Status(e.target.value)}
                    defaultChecked={row.status === "disable"}
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => set1Status(e.target.value)}
                    checked={row.status === "disable"}
                  />
                )}

                <span>
                  <span
                    id="enable"
                    onClick={(e) => setStatus("enable")}
                    data-toggle="modal"
                    data-target="#enableconfirmation"
                  >
                    Enable{" "}
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => setStatus("disable")}
                    data-toggle="modal"
                    data-target="#disableconfirmation"
                  >
                    Disable{" "}
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          </a>
        </td>
      ),
      sortable: true,
    },
    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={{
            pathname: `/edit_account/${row.id}`,
            state: { AcEdit: row },
          }}
          onClick={() => editACid(row.id)}
        >
          <i
            href={row.id}
            // onClick={() => getdata(row.id)}
            className="btn btn-primary"
            class="fa fa-edit"
          ></i>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    sessionStorage.getItem("token");
  });

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = user1.reverse.filter((test) => {
      return (
        test.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.SubAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.LocAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.DeptAcCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.AssetsCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.UsersCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.TempletsCount.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setSubCount(strAscending);

    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  
  return (
    <>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Accounts</h1>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      {/* <button type="button" class="btn btn-c-outline-primary">
                        <i class="fa fa-download"></i> Download Account Data
                      </button> */}
                      <div class="input-group pr-form-group">
                        <Link to="/add_account">
                          <a
                            href="addNewAssets.html"
                            // onClick={() => getdata(row._id)}
                            type="button"
                            class="btn btn-c-outline-primary"
                          >
                            {" "}
                            + Add Account{" "}
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                  //   className="row"
                  >
                    <div
                      className="borderRadius"
                      //   className="col-sm-12 row-wise"
                    >
                      <DataTable
                        columns={columns}
                        data={user1}
                        pagination
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Subdomain</b>
                                      </th>
                                      <th>
                                        <b>Name</b>
                                      </th>
                                      <th>
                                        <b>Subaccounts</b>
                                      </th>
                                      <th>
                                        <b>Locations</b>
                                      </th>
                                      <th>
                                        <b>Departments</b>
                                      </th>
                                      <th>
                                        <b>Assets</b>
                                      </th>
                                      <th>
                                        <b>Users</b>
                                      </th>
                                      <th>
                                        <b>Templates</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={11}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Modal add subaccount --> */}
            </div>

            {/* status */}
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Account?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Account?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Accounts;
