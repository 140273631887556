/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function Edit_mdts_template() {
  const [inpval, setINP] = useState({});
  const { state } = useLocation();

  const [row_fields, setRow_fields] = useState(state.MdtsEdit.row_fields);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...row_fields];
    list[index][name] = value;
    setRow_fields(list);
  };

  const [column_fields, setColumn_fields] = useState(
    state.MdtsEdit.column_fields
  );

  const handleInputcolumn = (index, event) => {
    const { name, value } = event.target;
    const list = [...column_fields];
    list[index][name] = value;
    setColumn_fields(list);
  };

  //update mdts template
  const FormSchema = Yup.object({
    // row_fields: Yup.string().required("Please Enter row_fields!"),
    // column_fields: Yup.string().required("Please Enter column_fields!"),
  });
  let FormInitialValues = {
    // device_uuid: state.MdtsEdit.row_fields,
    // sensor_uuid: state.MdtsEdit.column_fields,
  };

  // const updateIot = async (e) => {
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let id = state.MdtsEdit.id;
      console.log(id);
      // e.preventDefault();
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Mdts_Template/updateMdts_Template/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            row_fields,
            column_fields,
          }),
        }
      );

      const data = await res.json();
      // console.log(data);

      if (res.status === 200 || !data) {
        setINP(data);
        alert("successfully Edited");
        window.location = "/mdts_templates";
      } else {
        alert("please insert data");
      }
    },
  });

  // column workings
  const [name, setName] = useState("");
  const [data_type, setData_type] = useState("");

  const handleNamecolumn = (e) => {
    setName(e.target.value);
  };

  // const handleTypecolumn = (e) => {
  //   setData_type(e.target.value);
  // };

  const handleTypecolumn = (e) => {
    const selectedDataType = e.target.value;
    setData_type(selectedDataType);

    if (selectedDataType === "Binary") {
      setBinaryItems1("");
      setBinaryItems2("");
    }
  };

  const handleAddcolumn = () => {
    alert("new column added");
    const newColumn = {
      name: name,
      data_type: data_type,
      temp_array: [],
    };

    setColumn_fields((prevRowFields) => [...prevRowFields, newColumn]);
    setName("");
    setData_type("");
  };

  const deleteColumn = (index) => {
    alert("column has been deleted");
    const list = [...column_fields];
    list.splice(index, 1);
    setColumn_fields(list);
  };

  // store list
  const [listItems, setListItems] = useState("");
  const [binaryItems1, setBinaryItems1] = useState("");
  const [binaryItems2, setBinaryItems2] = useState("");

  const handleAddcolum = () => {
    alert("new column added");
    const listItemsArray = listItems.split(",").map((item) => item.trim());
    let tempArray = data_type === "List" ? listItemsArray : [];

    if (data_type === "Binary") {
      tempArray = [binaryItems1, binaryItems2];
    }

    const newColumn = {
      name: name,
      data_type: data_type,
      temp_array: tempArray,
    };

    setColumn_fields((prevRowFields) => [...prevRowFields, newColumn]);
    setName("");
    setData_type("");
    setListItems("");
    setBinaryItems1("");
    setBinaryItems2("");
  };

  // row workings

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAddRow = () => {
    alert("new row added");
    const newRow = {
      name: name,
      type: "",
    };

    setRow_fields((prevRowFields) => [...prevRowFields, newRow]);
    setName("");
  };

  const deleteRow = (index) => {
    alert("row has been deleted");
    const list = [...row_fields];
    list.splice(index, 1);
    setRow_fields(list);
  };

  const [editIndex, setEditIndex] = useState(null);

  const [edit, setEdit] = useState(null);
  const temp_array = column_fields.map((item) => item.temp_array);
  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        {/* <!-- Main content --> */}
        <section className="content pt20">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 row-wise" style={{ display: "block;" }}>
                <div className="row align-items-center mb20">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="d-flex">
                      <h6
                        className="countHeading mr10"
                        style={{ lineHeight: "30px;" }}
                      >
                        Test Data
                      </h6>
                      {/* <p
                        className="btn btn-primary"
                        id=""
                        style={{ marginBottom: "0", lineHeight: "26px;" }}
                      >
                        {" "}
                        Test Result
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="borderRadius">
                    <div className="table-scroll">
                      <table
                        id=""
                        className="table table-hover dataTable dtr-inline"
                        style={{ width: "100%" }}
                      >
                        {/* without dropdown  */}

                        <thead>
                          <tr>
                            <tr></tr>
                            {column_fields.map((item, index) => {
                              const name = item.name;
                              const data_type = item.data_type;
                              const temp_array = item.temp_array;
                              return (
                                <React.Fragment key={index}>
                                  <th>
                                    <div className="input-group">
                                      <input
                                        key={index}
                                        type="text"
                                        onChange={(event) =>
                                          handleInputcolumn(index, event)
                                        }
                                        value={name}
                                        name="name"
                                        className={`form-control number_input ${
                                          edit === index ? "editing" : ""
                                        }`}
                                        readOnly={edit !== index}
                                      />
                                      <span className="input-group-text">
                                        <i
                                          className="fa fa-pencil"
                                          id="column_edit"
                                          onClick={() => setEdit(index)}
                                        ></i>
                                      </span>
                                      <span
                                        className="input-group-text dropdown"
                                        style={{
                                          borderRadius: "0 0.25rem 0.25rem 0;",
                                          cursor: "pointer;",
                                        }}
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          data-toggle="dropdown"
                                        ></i>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                          <a
                                            className="dropdown-item calculator_icon"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#calculator"
                                          >
                                            <i className="fa fa-calculator "></i>{" "}
                                            Calculator
                                          </a>
                                          <a
                                            className="dropdown-item add_column_modal"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#addColumn"
                                          >
                                            <i className="fa fa-plus"></i> Add
                                            Column
                                          </a>
                                          <a
                                            onClick={() => deleteColumn(index)}
                                            className="dropdown-item delete_column_modal"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#delete_column_modal"
                                            data-backdrop="static"
                                            data-keyboard="false"
                                          >
                                            <i className="fa fa-trash"></i>{" "}
                                            Delete Column
                                          </a>
                                        </div>
                                      </span>
                                    </div>
                                  </th>

                                  {index ===
                                    state.MdtsEdit.column_fields.length - 1 && (
                                    <th></th>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        </thead>

                        <tbody>
                          {row_fields.map((item, index) => {
                            const name = item.name;
                            const tempValue = temp_array[index] || "";
                            return (
                              <tr key={index}>
                                <th>
                                  <div className="input-group">
                                    <input
                                      class="row-text"
                                      key={index}
                                      type="text"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      value={name}
                                      name="name"
                                      className={`form-control number_input ${
                                        editIndex === index ? "editing" : ""
                                      }`}
                                      readOnly={editIndex !== index}
                                    />

                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-pencil"
                                        id="column_edit"
                                        onClick={() => setEditIndex(index)}
                                      ></i>
                                    </span>
                                    <span
                                      className="input-group-text dropdown"
                                      style={{
                                        borderRadius: "0 0.25rem 0.25rem 0;",
                                        cursor: "pointer;",
                                      }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        data-toggle="dropdown"
                                      ></i>
                                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <a
                                          className="dropdown-item add_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#addRow"
                                        >
                                          <i className="fa fa-plus"></i> Add Row
                                        </a>
                                        <a
                                          onClick={() => deleteRow(index)}
                                          className="dropdown-item delete_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#delete_column_modal"
                                          data-backdrop="static"
                                          data-keyboard="false"
                                        >
                                          <i className="fa fa-trash"></i> Delete
                                          Row
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </th>
                                {column_fields.map((column, columnIndex) => {
                                  const { data_type, temp_array } = column;
                                  const columnValue = temp_array[index] || "";
                                  return (
                                    <td key={columnIndex}>
                                      {data_type === "List" ? (
                                        <select
                                          value={temp_array[index] || ""}
                                          onChange={(event) =>
                                            handleInputChange(index, event)
                                          }
                                          className="form-control"
                                          class="form-select"
                                        >
                                          <option value="">Select</option>
                                          {temp_array.map((item, itemIndex) => (
                                            <option
                                              key={itemIndex}
                                              value={item}
                                            >
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        <input
                                          value={temp_array}
                                          placeholder="Enter"
                                          className="form-control"
                                        />
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="text-center mt20 mb10">
                  <Link exact to={"/mdts_templates"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                    data-dismiss={
                      // formik.values.column_fields &&,
                      // formik.values.row_fields &&

                      "modal"
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal add column --> */}
      <div
        class="modal fade"
        id="addColumn"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addColumn"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <img
                src="assets/images/adminLogo.png"
                alt="Motware Logo"
                width="40px"
                class="brand-image"
              />
              <h5 class="modal-title w-100 text-center">Add Column</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="compName" class="col-form-label">
                        Column Name
                      </label>
                      <input
                        value={name}
                        onChange={handleNamecolumn}
                        type="text"
                        class="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="sector" class="col-form-label">
                        Data Type
                      </label>

                      <select
                        value={data_type}
                        onChange={handleTypecolumn}
                        class="form-control"
                        id="ak"
                        name="data_type"
                      >
                        <option value="String">String</option>
                        <option value="Number">Number</option>
                        <option value="List">List</option>
                        <option value="Binary">Binary</option>
                      </select>

                      {data_type === "List" && (
                        <div class="form-group">
                          <label for="listItems" class="col-form-label">
                            List Items
                          </label>
                          <input
                            value={listItems}
                            onChange={(e) => setListItems(e.target.value)}
                            type="text"
                            class="form-control"
                            id="listItems"
                            placeholder="Enter list items separated by commas"
                          />
                        </div>
                      )}

                      {data_type === "Binary" && (
                        <React.Fragment>
                          <div className="form-group">
                            <label
                              htmlFor="binaryItems1"
                              className="col-form-label"
                            >
                              Binary Field 1
                            </label>
                            <input
                              value={binaryItems1}
                              onChange={(e) => setBinaryItems1(e.target.value)}
                              type="text"
                              className="form-control"
                              id="binaryItems1"
                              placeholder="Enter Binary Field 1"
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="binaryItems2"
                              className="col-form-label"
                            >
                              Binary Field 2
                            </label>
                            <input
                              value={binaryItems2}
                              onChange={(e) => setBinaryItems2(e.target.value)}
                              type="text"
                              className="form-control"
                              id="binaryItems2"
                              placeholder="Enter Binary Field 2"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div class="text-center mt20 mb10">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={handleAddcolum}
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal add Row --> */}
      <div
        class="modal fade"
        id="addRow"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addRow"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <img
                src="assets/images/adminLogo.png"
                alt="Motware Logo"
                width="40px"
                class="brand-image"
              />
              <h5 class="modal-title w-100 text-center">Add Row</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="compName" class="col-form-label">
                        Row Name
                      </label>
                      <input
                        value={name}
                        onChange={handleNameChange}
                        type="text"
                        class="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="sector" class="col-form-label">
                        Data Type
                      </label>
                      <select class="form-control">
                        <option>Subheader</option>
                        {/* <option>Number</option>
                        <option>List</option>
                        <option>Binary</option> */}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="text-center mt20 mb10">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Add Row
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit_mdts_template;
