import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Repo_Industry_FormSchema.js";

const Repo_industry = () => {
  const { error1, setError1 } = useState({});
  const [LoadedRepoIndustry, setLoadedRepoIndustry] = useState([]);
  const [name, setName] = useState("");
  const [IEdit, setIEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_industry/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.industries;
      var strAscending = [...json].sort((a, b) => (a.name > b.name ? 1 : -1));
      setLoadedRepoIndustry(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadedRepoIndustry(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const formInitialValues = {
    name: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/repo_industry`,
          {
            method: "POST",

            body: JSON.stringify({
              name: values.name,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              // throw new Error("Industry Already Exist, Could not create");
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            alert("Repository Industry Added successfully");
            values.name = "";
            fetchData();
          })
          .catch((error) => {
            alert(
              error.message +
                ": Repository Industry already exist, could not create."
            );
            values.name = "";
          });
      },
    });

  const editIid = async (_id) => {
    let iid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_industry/get/${iid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setIEdit(res.industries);
  };

  function handleEdit(e) {
    setIEdit({ ...IEdit, [e.target.name]: e.target.value });
  }

  let FormInitialValues = {
    name: IEdit.name,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let iid = IEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_industry/${iid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({ name: values.name }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Repository Industry updated successfully");
        fetchData();
      } else {
        alert(resJson.message);
      }
    },
  });

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedRepoIndustry.reverse.filter((test) => {
      return test.name.toLowerCase().match(val.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadedRepoIndustry(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <div> {row.name}</div>,
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editIid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepoindustry"
          ></i>
        </a>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Repository Industries</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addRepostoryElectricalSymbol.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepoindustry"
                      >
                        {" "}
                        + Add Repository Industry
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                  //   className="row"
                  >
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedRepoIndustry}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Name</b>
                                      </th>

                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={3}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoindustry"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoindustry"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Repository Industry
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={values.name}
                              className="form-control"
                              id="unitId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Industry Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss={values.name ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepoindustry"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepoindustry"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Repository Industry
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Name *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={formik.values.name}
                              id="unitiD"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Industry Name"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.name && formik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                            data-dismiss={formik.values.name ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_industry;
