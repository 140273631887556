import React, { useState, useEffect } from "react";

const Example = () => {
  const [myObject, setMyObject] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from API
        const response = await fetch(
          `${process.env.REACT_APP_VARIABLE}/accountsModel/UpdateAccount`
        );
        const data = await response.json();

        // Update myObject state with fetched data
        setMyObject(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run effect only once

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update myObject with new property
    setMyObject((prevObject) => ({
      ...prevObject,
      [name]: value,
    }));
  };

  return (
    <form>
      <input
        type="text"
        name="firstName"
        value={myObject.firstName || ""}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="lastName"
        value={myObject.lastName || ""}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="age"
        value={myObject.age || ""}
        onChange={handleInputChange}
      />
      {/* Access properties of myObject */}
      <p>First Name: {myObject.firstName}</p>
      <p>Last Name: {myObject.lastName}</p>
      <p>Age: {myObject.age}</p>
    </form>
  );
};

export default Example;
