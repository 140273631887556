import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

function BarChart(props) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const chart = new Chart(canvas, {
      type: "bar",
      data: props.data,
      //{
      //   labels: props.labels,
      //   datasets: [
      //     {
      //       label: props.title,
      //       data: props.data,
      //       backgroundColor: props.backgroundColor,
      //     },
      //   ],
      // },
      options: props.options,
      //   options: {
      //     responsive: true,
      //     maintainAspectRatio: false,
      //   },
    });

    return () => {
      chart.destroy();
    };
  }, [props.data, props.options]);

  return <canvas height="500" ref={canvasRef} />;
}

export default BarChart;
