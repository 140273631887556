/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { tableCustomStyles } from "../tableStyle.jsx";
import { format, parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const columns = [
  {
    name: "Subdomain",
    selector: (row) => <div> {row.data}</div>,
    sortable: true,
  },
  {
    name: "Total ",
    selector: (row) => <div className="bigtext">{row.subdomain}</div>,
    sortable: true,
  },
];

const column2 = [
  // {
  //   name: "Subdomain",
  //   selector: (row) => <div >{row.subdomain}</div>,
  //   sortable: true,
  // },
  {
    name: "Api Name",
    selector: (row) => <div className="bigtext">{row.api_name}</div>,
    sortable: true,
  },
  // {
  //   name: "Url Api",
  //   selector: (row) => (
  //     <div className="bigtext" >
  //       {row.url_api}
  //     </div>
  //   ),
  //   sortable: true,
  // },
  {
    name: "Total",
    selector: (row) => <div className="bigtext">{row.data}</div>,
    sortable: true,
  },
  // {
  //   name: "Created",
  //   cell: (row) => (
  //     <div >
  //       {format(parseISO(row.request_startdate), "dd/MM/yyyy")}
  //    </div>
  //   ),
  //   sortable: true,
  // },
];

function ApiLogs() {
  const [startDate, setStartDate] = useState(new Date("01/03/2023"));

  const [endDate, setEndDate] = useState(new Date());

  const [total, setTotal] = useState([]);
  const [total1, setTotal1] = useState([]);
  const [search, setSerach] = useState("");
  const [api_logs, setapi_logs] = useState([]);
  const [subDomain, setsubDomain] = useState([]);
  const [loader, setLoader] = useState(true);

  async function fetchApi() {
    // e.preventDefault();
    const response = await axios.get(
      `${process.env.REACT_APP_VARIABLE}/ApiLogs/getApiLogs`,
      {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    var res = response.data;
    var strAscending = [...res].sort((a, b) =>
      a.api_name > b.api_name ? 1 : -1
    );
    setapi_logs(strAscending);
    if (res.length === 0) {
    } else {
      setLoader(false);
    }
    setTotal1(strAscending);
    // console.log(response.data);
  }

  async function fetchSubdomain() {
    // e.preventDefault();
    const response = await axios.get(
      `${process.env.REACT_APP_VARIABLE}/ApiLogs/getSubdomain`,
      {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    var res = response.data;
    var strAscending = [...res].sort((a, b) => (a.data > b.data ? 1 : -1));
    setsubDomain(strAscending);

    setTotal(strAscending);
    // console.log(response.data);
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    const response = await axios.get(
      `${process.env.REACT_APP_VARIABLE}/ApiLogs/getApiLogs`,

      {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    var res = response.data;
    var strAscending = [...res].sort((a, b) =>
      a.api_name > b.api_name ? 1 : -1
    );
    setTotal1(strAscending);
    setapi_logs(strAscending);
    if (res.length === 0) {
    } else {
      setLoader(false);
    }
    setTotal(strAscending);
    // console.log(response.data);
  };

  const handleSubdomain = async () => {
    // e.preventDefault();
    const response = await axios.get(
      `${process.env.REACT_APP_VARIABLE}/ApiLogs/getSubdomain`,

      {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    var res = response.data;
    var strAscending = [...res].sort((a, b) => (a.data > b.data ? 1 : -1));
    setsubDomain(strAscending);

    setTotal(strAscending);
    // console.log(response.data);
    handleSubmit();
  };

  useEffect(() => {
    // fetchData();
    fetchSubdomain();
    fetchApi();
  }, []);

  const handleTwo = (e) => {
    e.preventDefault();
    handleSubdomain();
  };

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.data.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.subdomain
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.data > b.data ? 1 : -1));
    setsubDomain(strAscending);

    const result1 = total1.filter((test) => {
      return (
        test.api_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.data.toString().toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending1 = [...result1].sort((a, b) =>
      a.api_name > b.api_name ? 1 : -1
    );
    setapi_logs(strAscending1);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    if (strAscending1) {
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleStartDateChange = (date, e) => {
    e.preventDefault();
    setStartDate(date);
    // Set the minimum selectable date for the end date picker to 3 months after the start date //
    setEndDate(
      new Date(date.getFullYear(), date.getMonth() + 3, date.getDate())
    );
  };

  const handleEndDateChange = (e, date) => {
    e.preventDefault();
    setEndDate(date);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = subDomain.reverse.filter((test) => {
      return (
        test.data.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain.toString().toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.data > b.data ? 1 : -1));
    setsubDomain(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    var result1 = api_logs.reverse.filter((test) => {
      return (
        test.api_name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.data.toString().toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending1 = [...result1].sort((a, b) =>
      a.api_name > b.api_name ? 1 : -1
    );
    setapi_logs(strAscending1);
    if (strAscending1) {
    } else {
      setLoader(false);
    }

    setLoader(false);
    if (e.target.value === "") {
      fetchApi();
      fetchSubdomain();
    }
  };
  return (
    <div className="content-wrapper">
      <section className="content pt20">
        <div className="container-fluid">
          <div className="col-md-12 col-lg-12">
            <div className="row">
              <div className="col-sm-6">
                <h1 className="pageTitle">Api Logs</h1>
              </div>
              {/* <!-- /.col --> */}
            </div>
            <div className="row mb20">
              <div className="col-md-6 col-lg-6 col-xl-6">
                <form onSubmit={handleTwo}>
                  <label>
                    Start date:
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      monthsShown={1} // show 1 months at a time
                    />
                    {/* <input
                  type="date"
                  value={startDate}
                  // onChange={(e) => setStartDate(e.target.value)}
                  onChange={handleStartDateChange}
                /> */}
                  </label>
                  <label>
                    End date:
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate} // set the minimum selectable date for the end date picker
                      monthsShown={1} // show 1 months at a time
                    />
                    {/* <input
                  type="date"
                  value={endDate}
                  // onChange={(e) => setEndDate(e.target.value)}
                  onChange={handleEndDateChange}
                /> */}
                  </label>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="align-items-center">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <div id="example_filter" className="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="table-responsive">
            <div className="row borderRadius">
              <div className="col-md-6">
                <DataTable
                  className="dataTables_wrapper"
                  columns={columns}
                  data={subDomain}
                  pagination
                  noDataComponent={
                    loader === false ? (
                      <div className="table-responsive">
                        <div className="borderRadius">
                          <table style={{ width: "100%" }}>
                            <thead style={{ height: "50px" }}>
                              <tr>
                                <th>
                                  <b>Subdomain</b>
                                </th>
                                <th>
                                  <b>Total</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ height: "140px" }}>
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={2}>
                                  No Record Found
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                  fixedHeaderh
                  highlightOnHover
                  customStyles={tableCustomStyles}
                />
                <div class={loader ? "loader" : ""}></div>
              </div>
              <div className="col-md-6">
                <DataTable
                  className="dataTables_wrapper"
                  columns={column2}
                  data={api_logs}
                  pagination
                  noDataComponent={
                    loader === false ? (
                      <div className="table-responsive">
                        <div className="borderRadius">
                          <table style={{ width: "100%" }}>
                            <thead style={{ height: "50px" }}>
                              <tr>
                                <th>
                                  <b>Api Name</b>
                                </th>
                                <th>
                                  <b>Total</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ height: "140px" }}>
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={2}>
                                  No Record Found
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                  fixedHeaderh
                  highlightOnHover
                  customStyles={tableCustomStyles}
                />
                <div class={loader ? "loader" : ""}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ApiLogs;
