import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle";
import { useFormik } from "formik";
import { FormSchema } from "./Asset_form";

const ApiMaster = () => {
  const [loadedAssetCategory, setLoadedAssetCategory] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [error1, setError1] = useState(null);
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/apilog_master_get/get-apilogs-master`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      let json = await res.json();
      json = json.data; // Adjust based on the actual API response structure
      const sortedData = [...json].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // const strAscending = [...json].sort((a, b) =>
      //   a.apiName > b.apiName ? 1 : -1
      // );
      setLoadedAssetCategory(sortedData);
      setLoader(false);
      setTotal(sortedData.length);
    } catch (error) {
      setError1(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearch(val);
  
    if (val.trim() === "") {
      // If the search input is empty, reset to the original data
      fetchData();  // Fetch the data again to reset the table
    } else {
      // Filter the data based on the search input
      const result = loadedAssetCategory.filter((item) =>
        item.apiName.toLowerCase().includes(val.toLowerCase())
      );
      setLoadedAssetCategory(result);
    }
  };
  

  const columns = [
    { name: "API Name", selector: (row) => row.apiName, sortable: true },
    {
      name: "Collection Involved",
      selector: (row) => row.collectionInvolved,
      sortable: true,
    },
    { name: "Route", selector: (row) => row.route, sortable: true },
    { name: "Request", selector: (row) => row.request, sortable: true },
    { name: "Response", selector: (row) => row.response, sortable: true },
    {
      name: "Standard Response Time",
      selector: (row) => row.standardResponseTime,
      sortable: true,
    },
    { name: "Project Name", selector: (row) => row.projectName, sortable: true },
    { name: "Type of API", selector: (row) => row.typeOfApi, sortable: true },
    { name: "Category Type", selector: (row) => row.categoryType, sortable: true },
    { name: "Comments", selector: (row) => row.comments, sortable: true },
    { name: "Purpose/Goal", selector: (row) => row.purposeGoal, sortable: true },
    { name: "Change Log", selector: (row) => row.changeLog, sortable: true },
  ];

  const formInitialValues = {
    apiName: "",
    collectionInvolved: "",
    route: "",
    request: "",
    response: "",
    standardResponseTime: "",
    projectName: "",
    typeOfApi: "",
    categoryType: "",
    comments: "",
    purposeGoal: "",
    changeLog: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/apilogs_master_add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setLoadedAssetCategory([...loadedAssetCategory, data]);
        setFormVisible(false);
        resetForm();
        fetchData();

        window.$("#addapilog").modal("hide");
      } catch (error) {
        console.error("Failed to submit form", error);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    formik.handleSubmit();
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">API Log Master</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <a
                      href=""
                      type="button"
                      className="btn btn-c-outline-primary"
                      data-toggle="modal"
                      data-target="#addapilog"
                    >
                      + Add API Log
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-12 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <div id="example_filter" className="dataTables_filter">
                        <label>
                          Search: &nbsp;
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={search}
                            onChange={handleSearch}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={loadedAssetCategory}
                      noDataComponent={<div>No Record Found</div>}
                      pagination
                      fixedHeader
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                  </div>
                </div>

                {/* Add modal for adding API logs */}
                <div
                  className="modal fade"
                  id="addapilog"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addapilog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/adminLogo.png"
                          alt="Motware Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Add API Log
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            {Object.keys(formInitialValues).map((key) => {
                              const label = key
                                .replace(/([A-Z])/g, " $1") // Adds space before each uppercase letter
                                .replace(/^./, (str) => str.toUpperCase()); // Capitalizes the first letter

                              if (key === "request" || key === "typeOfApi") {
                                return (
                                  <div
                                    className="form-group col-md-6"
                                    key={key}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label htmlFor={key} className="col-form-label">
                                      {label} *
                                    </label>
                                    <select
                                      id={key}
                                      name={key}
                                      value={formik.values[key]}
                                      className={`form-control ${formik.touched[key] && formik.errors[key]
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    >
                                      <option value="">{`Select ${label}`}</option>
                                      {key === "request" && (
                                        <>
                                          <option value="GET">GET</option>
                                          <option value="POST">POST</option>
                                          <option value="PUT">PUT</option>
                                          <option value="DELETE">DELETE</option>
                                        </>
                                      )}
                                      {key === "typeOfApi" && (
                                        <>
                                          <option value="Public">Public</option>
                                          <option value="Private">Private</option>
                                        </>
                                      )}
                                    </select>
                                    {formik.touched[key] && formik.errors[key] && (
                                      <div className="invalid-feedback">
                                        {formik.errors[key]}
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              return (
                                <div
                                  className="form-group col-md-6"
                                  key={key}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label htmlFor={key} className="col-form-label">
                                    {label} *
                                  </label>
                                  <input
                                    id={key}
                                    name={key}
                                    value={formik.values[key]}
                                    className={`form-control ${formik.touched[key] && formik.errors[key]
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched[key] && formik.errors[key] && (
                                    <div className="invalid-feedback">
                                      {formik.errors[key]}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <div className="text-center mt20 mb10">
                            <button
                              type="button"
                              className="btn btn-secondary mr10"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>

                        </form>

                      </div>
                    </div>
                  </div>
                </div>
                {/* End Add modal */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ApiMaster;
