import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";

import { useFormik } from "formik";
import { FormSchema } from "./Account_User_FormSchema.js";
import PhoneCode from "react-phone-code";

const User = () => {
  const [LoadedAccountUser, setLoadedAccountUser] = useState([]);
  const [LoadedAccount, setLoadedAccount] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [email_service, setEmail_service] = useState();
  const [Status, set1Status] = useState({});
  const [sms_service, setSms_service] = useState();
  const [test_service, setTest_service] = useState();
  const [total, setTotal] = useState([]);
  const [error1, setError1] = useState({});
  const [AUEdit, setAUEdit] = useState({});
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);

  const fetchcount = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/account_user/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();

      var strAscending = [...json].sort((a, b) =>
        a.first_name > b.first_name ? 1 : -1
      );
      setLoadedAccountUser(strAscending);
      console.log("account_User", strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchcount();
  }, []);
  useEffect(() => {
    var result = total.filter((test) => {
      return (
        test.first_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.last_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.subdomain?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.email.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.first_name > b.first_name ? 1 : -1
    );

    setLoadedAccountUser(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedAccountUser.reverse.filter((test) => {
      return (
        test.first_name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.last_name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.subdomain?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.email.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.first_name > b.first_name ? 1 : -1
    );

    setLoadedAccountUser(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchcount();
    }
  };
  useEffect(() => {
    const getAllAccount = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/account_user/account/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setLoadedAccount(res);
    };
    getAllAccount();
  }, []);
  const AData = LoadedAccount.accounts;
  const formInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    decrypted_password: "",
    contact_no: "",
    subdomain: "",
    role: "",
  };
  const { handleSubmit, handleChange, touched, handleBlur, values, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/account_user`,
          {
            method: "POST",
            body: JSON.stringify({
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              decrypted_password: values.decrypted_password,
              email_service: email_service,
              contact_no: countryCode + values.contact_no,
              sms_service: sms_service,
              test_service: test_service,
              role: values.role,
              subdomain: values.subdomain,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            alert("Account User added successfully");
            values.first_name = "";
            values.last_name = "";
            values.email = "";
            values.decrypted_password = "";

            values.contact_no = "";

            values.role = "";
            values.subdomain = "";
            setEmail_service("enable");

            setSms_service("enable");
            setTest_service("enable");

            fetchcount();
          })
          .catch((error) => {
            // handle the error
            alert(
              error.message +
                ": Email already exist, could not create Account User."
            );
            values.first_name = "";
            values.last_name = "";
            values.email = "";
            values.decrypted_password = "";

            values.contact_no = "";

            values.role = "";
            values.subdomain = "";
            setEmail_service("enable");

            setSms_service("enable");
            setTest_service("enable");
          });
      },
    });
  const editAUserid = async (_id) => {
    let auserid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/account_user/get/${auserid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAUEdit(res.accountUsers);
  };
  const handleStatusUpdate = async (e) => {
    e.preventDefault();

    let auserid = AUEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/account_user/${auserid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );

    const res = await response.json();
    if (res) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }

      alert(" Account Users " + StatusText + " successfully");

      fetchcount();
    } else {
      alert(res.message);
    }
  };
  const [status, setStatus] = useState(AUEdit.status);
  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div>
          {row.first_name} {row.last_name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Subdomain",
      selector: (row) => <div>{row.subdomain}</div>,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => <div className="bigtext">{row.email}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row.id} onClick={() => editAUserid(row.id)}>
          {/* {val.status} */}
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
  ];
  const handleCancel = (e) => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Account Users</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addadminuser.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addadminuser"
                      >
                        {" "}
                        + Add Account User{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <div id="example_filter" className="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={LoadedAccountUser}
                      noDataComponent={
                        loader === false ? (
                          <div className="table-responsive">
                            <div className="borderRadius">
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: "50px" }}>
                                  <tr>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Subdomain</b>
                                    </th>
                                    <th>
                                      <b>Email</b>
                                    </th>
                                    <th>
                                      <b>Status</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ height: "140px" }}>
                                  <tr>
                                    <td
                                      style={{ textAlign: "center" }}
                                      colSpan={4}
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      pagination
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />

                    <div class={loader ? "loader" : ""}></div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="addadminuser"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="addadminuser"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/adminLogo.png"
                          alt="Motware Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Add Account User
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="sector" className="col-form-label">
                                  First Name *
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  value={values.first_name}
                                  className="form-control"
                                  onBlur={handleBlur}
                                  id="locName"
                                  onChange={handleChange}
                                  placeholder="Enter First Name"
                                />
                                {errors.first_name && touched.first_name ? (
                                  <span style={{ color: "red" }}>
                                    {errors.first_name}
                                  </span>
                                ) : null}{" "}
                              </div>
                              <div className="form-group">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Last Name *
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  value={values.last_name}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  id="locName"
                                  onChange={handleChange}
                                  placeholder="Enter Last Name"
                                />
                                {errors.last_name && touched.last_name ? (
                                  <span style={{ color: "red" }}>
                                    {errors.last_name}
                                  </span>
                                ) : null}{" "}
                              </div>
                              <div className="form-group">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Email *
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  id="locName"
                                  onChange={handleChange}
                                  placeholder="Enter Email"
                                />
                                {errors.email && touched.email ? (
                                  <span style={{ color: "red" }}>
                                    {errors.email}
                                  </span>
                                ) : null}{" "}
                              </div>
                              <div className="form-group">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Password *
                                </label>

                                <input
                                  type="text"
                                  name="decrypted_password"
                                  value={values.decrypted_password}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  id="locName"
                                  onChange={handleChange}
                                  placeholder="Enter Password"
                                />
                                {errors.decrypted_password &&
                                touched.decrypted_password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.decrypted_password}
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group row">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Contact Number *
                                </label>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                  <PhoneCode
                                    onSelect={(code) => setCountryCode(code)} // required
                                    showFirst={["IN", "US"]}
                                    defaultValue="select county"
                                    id="some-id"
                                    name="countrycode"
                                    className="form-control"
                                    // optionClassName='some option class name'
                                  />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                  <input
                                    type="number"
                                    name="contact_no"
                                    value={values.contact_no}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    id="locName"
                                    onChange={handleChange}
                                    placeholder="Enter Contact Number"
                                  />
                                  {errors.contact_no && touched.contact_no ? (
                                    <span style={{ color: "red" }}>
                                      {errors.contact_no}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Subdomain *
                                </label>
                                <div className="col-sm-10 col-md-10 col-lg-12">
                                  {" "}
                                  <select
                                    id="ak"
                                    name="subdomain"
                                    value={values.subdomain}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="js-states form-control "
                                  >
                                    <option value="" disabled selected="true">
                                      Select Subdomain
                                    </option>

                                    {AData &&
                                      AData.map((am, index) => (
                                        <option
                                          key={index}
                                          value={am.subdomain}
                                        >
                                          {am.subdomain}
                                        </option>
                                      ))}
                                  </select>
                                  {errors.subdomain && touched.subdomain ? (
                                    <span style={{ color: "red" }}>
                                      {errors.subdomain}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  for="contactNo"
                                  className="col-form-label"
                                >
                                  Role *
                                </label>
                                <div className="col-sm-10 col-md-10 col-lg-12">
                                  {" "}
                                  <select
                                    id="ak"
                                    name="role"
                                    value={values.role}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="js-states form-control "
                                  >
                                    <option value="" disabled selected="true">
                                      Select Role
                                    </option>

                                    <option value="account_admin">
                                      account_admin
                                    </option>
                                    <option value="tester">tester</option>
                                  </select>
                                  {errors.role && touched.role ? (
                                    <span style={{ color: "red" }}>
                                      {errors.role}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="switchList">
                                <p for="" className="">
                                  Email Service
                                </p>
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setEmail_service("enable")
                                        }
                                      >
                                        Enable{" "}
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setEmail_service("disable")
                                        }
                                      >
                                        Disable{" "}
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                                <div className="clearfix mb10"></div>
                              </div>
                              <div className="switchList">
                                <p for="" className="">
                                  SMS Service
                                </p>
                                <div>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setSms_service("enable")
                                          }
                                        >
                                          Enable{" "}
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setSms_service("disable")
                                          }
                                        >
                                          Disable{" "}
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </div>
                                <div className="clearfix mb10"></div>
                              </div>
                              <div className="switchList">
                                <p for="" className="">
                                  Test Service
                                </p>
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setTest_service("enable")
                                        }
                                      >
                                        Enable{" "}
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setTest_service("disable")
                                        }
                                      >
                                        Disable{" "}
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </div>
                              <div className="clearfix mb10"></div>
                            </div>
                          </div>
                          <div className="text-center mt20 mb10">
                            <button
                              type="button"
                              className="btn btn-secondary mr10"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              data-dismiss={
                                values.first_name &&
                                values.last_name &&
                                values.decrypted_password &&
                                values.email &&
                                values.contact_no &&
                                values.subdomain &&
                                values.role
                                  ? "modal"
                                  : ""
                              }
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="disableconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/logo v4.png"
                          alt="oil test Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Disable Account User?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => handleCancel(e)}
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handleStatusUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="enableconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/logo v4.png"
                          alt="oil test Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Enable Account User?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => handleCancel(e)}
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handleStatusUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default User;
