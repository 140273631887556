/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import axios from "axios";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../tableStyle.jsx";

function Contracted_Templates() {
  const [temp, setTemp] = useState([]);
  const [inpval, setINP] = useState({});
  const [status, setStatus] = useState(inpval.status);

  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [loader, setLoader] = useState(true);

  // all templates
  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Contracted_Template/getAllContracted_Template`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.test_type?.name > b.test_type?.name ? 1 : -1
      );
      setTemp(strAscending);
      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.test_type?.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.industry?.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.test_type?.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        format(parseISO(test.created_at), "dd/MM/yyyy")
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.test_type?.name > b.test_type?.name ? 1 : -1
    );
    setTemp(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  // view templates
  const getdata = async (_id) => {
    let id = _id;
    // console.log(id);
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Contracted_Template/getContracted_Template/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    setINP(res.templateindividual);
    // console.log(setINP.name);

    if (res.status === 422 || !data) {
      console.log("error");
    } else {
      setINP(data);
      // console.log("get data");
    }
  };

  const updateStatus = async (_id) => {
    let id = inpval._id;
    // console.log(id);
    const res2 = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Contracted_Template/updateContracted_Template/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );

    if (res2.status === 201) {
      // console.log("successful");
      alert("trigger has been edited");
    } else {
      console.log("err");
    }
  };

  const [Status, set1Status] = useState({});
  function handleEdit(e) {
    // console.log(e.target.value);

    set1Status({ ...inpval, [e.target.name]: e.target.value });

    // console.log(Status);
  }

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div>
          <a
            // className="btn btn-primary"
            data-toggle="modal"
            data-target="#viewtemp"
            href={row.id}
            onClick={() => getdata(row.id)}
          >
            {row.name}
          </a>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Test Type",
      selector: (row) => <div className="bigtext">{row.test_type?.name}</div>,
      sortable: true,
    },
    {
      name: "Industry",
      selector: (row) => <div>{row.industry?.name}</div>,
      sortable: true,
    },

    {
      name: "Created",
      cell: (row) => (
        <div>{format(parseISO(row.created_at), "dd/MM/yyyy")}</div>
      ),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <td>
          <a onClick={() => getdata(row.id)}>
            <div className="switch">
              <label className="switch-light">
                <input
                  type="checkbox"
                  name="status"
                  value={Status ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />

                <span>
                  <span
                    id="enable"
                    onClick={(e) => setStatus("enable")}
                    data-toggle="modal"
                    data-target="#enableconfirmation"
                  >
                    Enable
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => setStatus("disable")}
                    data-toggle="modal"
                    data-target="#disableconfirmation"
                  >
                    Disable
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          </a>
        </td>
      ),
      sortable: true,
    },
  ];

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = temp.reverse.filter((test) => {
      return (
        test.test_type?.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.industry?.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.test_type?.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        format(parseISO(test.created_at), "dd/MM/yyyy")
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.test_type?.name > b.test_type?.name ? 1 : -1
    );
    setTemp(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  return (
    <>
      <div class="content-wrapper">
        {/* <!-- Main content --> */}
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Templates List</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    {/* <div className="input-group pr-form-group mr10">
                        <form>
                          <input
                            className="form-control"
                            id="formFileSm"
                            type="file"
                          />
                          <button
                            type="button"
                            className="btn btn-c-outline-primary"
                          >
                            <i className="fa fa-upload"></i>
                            Upload
                          </button>
                        </form>
                      </div>
                      <div className="input-group pr-form-group mr10">
                        <a
                          href="/"
                          type="button"
                          className="btn btn-c-outline-primary"
                        >
                          Export
                        </a>
                      </div> */}
                    <div className="input-group pr-form-group">
                      <a
                        href={require("./test-new.csv")}
                        download="test-file"
                        type="button"
                        className="btn btn-c-outline-primary"
                      >
                        <i className="fa fa-download"></i> Download Sample CSV
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={temp}
                      pagination
                      noDataComponent={
                        loader === false ? (
                          <div className="table-responsive">
                            <div className="borderRadius">
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: "50px" }}>
                                  <tr>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Test Type</b>
                                    </th>
                                    <th>
                                      <b>Industry</b>
                                    </th>
                                    <th>
                                      <b>Created</b>
                                    </th>
                                    <th>
                                      <b>Status</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ height: "140px" }}>
                                  <tr>
                                    <td
                                      style={{ textAlign: "center" }}
                                      colSpan={5}
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                    <div class={loader ? "loader" : ""}></div>
                  </div>
                </div>

                {/* popups */}
                {/* viewtemp */}
                <div
                  div
                  className="modal fade"
                  id="viewtemp"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="viewtemp"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content" style={{ width: "1250px" }}>
                      <div className="modal-header">
                        {/* <img
                          src="assets/images/logo v4.png"
                          alt="oil test Logo"
                          width="40px"
                          className="brand-image"
                        /> */}
                        <h5 className="modal-title w-100 text-center">
                          View Template
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div>
                              <h4>Description:</h4>
                              <p>{inpval.description}</p>
                            </div>
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  {inpval.asset_test_column_fields &&
                                    inpval.asset_test_column_fields.map(
                                      (column) => (
                                        <>
                                          <th>{column.name}</th>

                                          {/* <th>{column.field_type}</th> */}
                                        </>
                                      )
                                    )}
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <th scope="col">
                                    {inpval.asset_test_row_fields &&
                                      inpval.asset_test_row_fields.map(
                                        (row) => (
                                          <>
                                            <p>{row.name}</p>
                                            {/* <p>{row.field_type}</p> */}
                                          </>
                                        )
                                      )}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* switch  */}
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={updateStatus}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={updateStatus}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contracted_Templates;
