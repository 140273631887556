import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Mqtt_broker_FormSchema.js";
import { useFormik } from "formik";

const Mqtt_broker = () => {
  const [error1, setError1] = useState({});
  const [LoadedMqttBroker, setLoadedMqttBroker] = useState([]);
  const [MBEdit, setMBEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [Status, set1Status] = useState({});
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.mqttBrokers;
      var strAscending = [...json].sort((a, b) =>
        a.broker_url > b.broker_url ? 1 : -1
      );
      setLoadedMqttBroker(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.broker_url.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.username.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.password.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.broker_port
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.broker_timeout_reconnect
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.broker_url > b.broker_url ? 1 : -1
    );
    setLoadedMqttBroker(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);
  const formInitialValues = {
    broker_url: "",
    broker_port: "",
    broker_timeout_reconnect: "",
    username: "",
    password: "",
  };
  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,

      onSubmit: async (values) => {
        try {
          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers`,
            {
              method: "POST",
              body: JSON.stringify({
                broker_url: values.broker_url,
                broker_port: values.broker_port,
                broker_timeout_reconnect: values.broker_timeout_reconnect,
                username: values.username,
                password: values.password,
              }),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          let resJson = await res.json();

          if (res.status === 201) {
            alert("Mqtt Broker added successfully");
            values.broker_url = "";
            values.broker_port = "";
            values.broker_timeout_reconnect = "";
            values.username = "";
            values.password = "";

            fetchData();
          } else {
            alert(resJson.message);
          }
        } catch (err) {
          console.log(err);
          values.broker_url = "";
          values.broker_port = "";
          values.broker_timeout_reconnect = "";
          values.username = "";
          values.password = "";
        }
      },
    });
  const editMBid = async (_id) => {
    let mbid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/get/${mbid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setMBEdit(res.mqttBrokers);
  };
  function handleEdit(e) {
    setMBEdit({ ...MBEdit, [e.target.name]: e.target.value });
    set1Status(e.target.value);
  }
  let FormInitialValues = {
    broker_url: MBEdit.broker_url,
    broker_port: MBEdit.broker_port,
    broker_timeout_reconnect: MBEdit.broker_timeout_reconnect,
    username: MBEdit.username,
    password: MBEdit.password,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let mbid = MBEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/${mbid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            broker_url: values.broker_url,
            broker_port: values.broker_port,
            broker_timeout_reconnect: values.broker_timeout_reconnect,
            username: values.username,
            password: values.password,
          }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Mqtt Broker updated successfully");
        fetchData();
      } else {
        alert(resJson.message);
      }
    },
  });
  const handleMBUpdate = async (e) => {
    e.preventDefault();
    let mbid = MBEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/${mbid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(MBEdit),
      }
    );
    let resJson = await response.json();
    if (response.status === 200) {
      alert("Mqtt Broker updated successfully");
      fetchData();
    } else {
      alert(resJson.message);
    }
  };
  const handleStatusUpdate = async (e) => {
    e.preventDefault();

    let mbid = MBEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/${mbid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status }),
      }
    );
    let resJson = await response.json();
    if (resJson) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }

      alert("Mqtt Broker " + StatusText + " successfully");

      fetchData();
    } else {
      alert(resJson.message);
    }
  };
  const [status, setStatus] = useState(MBEdit.status);
  const columns = [
    {
      name: "Broker_url",
      selector: (row) => <div className="bigtext">{row.broker_url}</div>,
      sortable: true,
    },
    {
      name: "Broker_port",
      selector: (row) => <div>{row.broker_port}</div>,
      sortable: true,
    },
    {
      name: "Broker_timeout_reconnect",
      selector: (row) => <div>{row.broker_timeout_reconnect}</div>,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => <div>{row.username}</div>,
      sortable: true,
    },

    {
      name: "Password",
      selector: (row) => <div>{row.password}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row._id} onClick={() => editMBid(row._id)}>
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editMBid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editmqttbroker"
          ></i>
        </a>
      ),
    },
  ];
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedMqttBroker.reverse.filter((test) => {
      return (
        test.broker_url.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.username.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.password.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.broker_port
          .toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.broker_timeout_reconnect
          .toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.broker_url > b.broker_url ? 1 : -1
    );
    setLoadedMqttBroker(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const handleCancel = (e) => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div class="col-sm-6">
                  <h1 className="pageTitle">Mqtt Brokers</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="align-items-center mb20">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="form-inline pr-form-inline float-right">
                        <div className="input-group pr-form-group">
                          <a
                            href="addMqttBroker.html"
                            type="button"
                            className="btn btn-c-outline-primary"
                            data-toggle="modal"
                            data-target="#addmqttbroker"
                          >
                            {" "}
                            + Add Mqtt Broker{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedMqttBroker}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Broker_url</b>
                                      </th>
                                      <th>
                                        <b>Broker_port</b>
                                      </th>
                                      <th>
                                        <b>Broker_timeout_reconnect</b>
                                      </th>
                                      <th>
                                        <b>Username</b>
                                      </th>
                                      <th>
                                        <b>Password</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={8}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addmqttbroker"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addmqttbroker"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Mqtt Broker
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Url *
                            </label>
                            <input
                              type="text"
                              name="broker_url"
                              value={values.broker_url}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Broker Url"
                            />{" "}
                            {errors.broker_url && touched.broker_url ? (
                              <span style={{ color: "red" }}>
                                {errors.broker_url}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Port *
                            </label>
                            <input
                              type="number"
                              name="broker_port"
                              value={values.broker_port}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Broker Port"
                            />{" "}
                            {errors.broker_port && touched.broker_port ? (
                              <span style={{ color: "red" }}>
                                {errors.broker_port}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Timeout Reconnect *
                            </label>
                            <input
                              type="number"
                              name="broker_timeout_reconnect"
                              value={values.broker_timeout_reconnect}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Broker Timeout Reconnect"
                            />{" "}
                            {errors.broker_timeout_reconnect &&
                            touched.broker_timeout_reconnect ? (
                              <span style={{ color: "red" }}>
                                {errors.broker_timeout_reconnect}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Username *
                            </label>
                            <input
                              type="text"
                              name="username"
                              value={values.username}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Username"
                            />{" "}
                            {errors.username && touched.username ? (
                              <span style={{ color: "red" }}>
                                {errors.username}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Password *
                            </label>
                            <input
                              type="text"
                              name="password"
                              value={values.password}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Password"
                            />{" "}
                            {errors.password && touched.password ? (
                              <span style={{ color: "red" }}>
                                {errors.password}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          data-dismiss={
                            !errors.broker_port &&
                            values.broker_port &&
                            !errors.broker_timeout_reconnect &&
                            values.broker_timeout_reconnect &&
                            values.broker_url &&
                            !errors.broker_url &&
                            values.password &&
                            values.username
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editmqttbroker"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editmqttbroker"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Mqtt Broker
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Url *
                            </label>
                            <input
                              type="text"
                              name="broker_url"
                              value={formik.values.broker_url}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Broker Url"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.broker_url &&
                            formik.touched.broker_url ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.broker_url}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Port *
                            </label>
                            <input
                              type="number"
                              name="broker_port"
                              value={formik.values.broker_port}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Broker Port"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.broker_port &&
                            formik.touched.broker_port ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.broker_port}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Broker Timeout Reconnect *
                            </label>
                            <input
                              type="number"
                              name="broker_timeout_reconnect"
                              value={formik.values.broker_timeout_reconnect}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Broker Timeout Reconnect"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.broker_timeout_reconnect &&
                            formik.touched.broker_timeout_reconnect ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.broker_timeout_reconnect}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Username *
                            </label>
                            <input
                              type="text"
                              name="username"
                              value={formik.values.username}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Username"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.username &&
                            formik.touched.username ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.username}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Password *
                            </label>
                            <input
                              type="text"
                              name="password"
                              value={formik.values.password}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Password"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.password &&
                            formik.touched.password ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.password}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={formik.handleSubmit}
                          data-dismiss={
                            !formik.errors.broker_port &&
                            !formik.errors.broker_timeout_reconnect &&
                            !formik.errors.broker_url &&
                            formik.values.broker_port &&
                            formik.values.broker_timeout_reconnect &&
                            formik.values.broker_url &&
                            formik.values.password &&
                            formik.values.username
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Mqtt Broker?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Mqtt Broker?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Mqtt_broker;
