const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "15px",
    },
  },
  allowRowEvents: {
    style: {
      fontSize: "15px",
    },
  },
};
export { tableCustomStyles };
