/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useState } from "react";

const Add_Mdts_Template = (props) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("text");
  const [addRC, setaddRC] = useState("");
  const [rows, setRows] = useState([{ name: "", type: "" }]);
  const [columns, setColumns] = useState([{ name: "", type: "" }]);

  const cancel = () => {
    setName("");
    setType("text");
    setaddRC("");
  };

  const deleteRow = (index) => {
    if (index !== 0) {
      alert("row has been deleted");
      const list = [...rows];
      list.splice(index, 1);
      setRows(list);
    } else alert("row canot been deleted");
  };

  const deleteColumn = (index) => {
    if (index !== 0) {
      alert("column has been deleted");
      const list = [...columns];
      list.splice(index, 1);
      setColumns(list);
    } else alert("column canot been deleted");
  };

  const onRCSubmit = async () => {
    let row = [];
    let col = [];
    if (addRC === "addColumn") {
      col = [...columns, { name, type }];
      setColumns(col);
      alert("new column has been added");
    }

    if (addRC === "addRow") {
      row = [...rows, { name, type }];
      setRows(row);
      alert("new row has been added");
    }

    // const url = `http://localhost:5000/Mdts_Template/createMdts_Template`;
    // const response = await axios.post(url,{ test_type:'',
    // row_fields:row,
    // column_fields:col,
    // test_attributes:[],
    // });
    // alert(response.data.msg)
    //  console.log(response.data);
    cancel();
  };

  const onSubmit = async () => {
    const url = `${process.env.REACT_APP_VARIABLE}/Mdts_Template/createMdts_Template`;
    const response = await axios.post(url, {
      test_type: "",
      row_fields: rows,
      column_fields: columns,
      test_attributes: [],
    });
    alert(response.data.msg);
    console.log(response.data);
  };

  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        {/* <!-- Main content --> */}
        <section className="content pt20">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 row-wise" style={{ display: "block;" }}>
                <div className="row align-items-center mb20">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="d-flex">
                      <h6
                        className="countHeading mr10"
                        style={{ lineHeight: "30px;" }}
                      >
                        Test Data
                      </h6>
                      {/* <p
                        className="btn btn-primary"
                        id=""
                        style={{ marginBottom: "0", lineHeight: "26px;" }}
                      >
                        {" "}
                        Test Result
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="borderRadius">
                    <div className="table-scroll">
                      <table
                        id=""
                        className="table table-hover dataTable dtr-inline"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th></th>

                            {columns &&
                              columns.map((column, index) => (
                                <th>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      value={column.name}
                                      className="form-control number_input"
                                    />
                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-pencil"
                                        id="column_edit"
                                      ></i>
                                    </span>
                                    <span
                                      className="input-group-text dropdown"
                                      style={{
                                        borderRadius: "0 0.25rem 0.25rem 0;",
                                        cursor: "pointer;",
                                      }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        data-toggle="dropdown"
                                      ></i>
                                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <a
                                          className="dropdown-item calculator_icon"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#calculator"
                                        >
                                          <i className="fa fa-calculator "></i>{" "}
                                          Calculator
                                        </a>
                                        <a
                                          className="dropdown-item add_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#addColumn"
                                          onClick={() => setaddRC("addColumn")}
                                        >
                                          <i className="fa fa-plus"></i> Add
                                          Column
                                        </a>
                                        <a
                                          className="dropdown-item delete_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#delete_column_modal"
                                          data-backdrop="static"
                                          data-keyboard="false"
                                          onClick={() => deleteColumn(index)}
                                        >
                                          <i className="fa fa-trash"></i> Delete
                                          Column
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                  {/* <div className="input-group min_max mt10">
                                <input
                                  value=""
                                  placeholder="Min"
                                  className="form-control"
                                />
                                <input
                                  value=""
                                  placeholder="Max"
                                  className="form-control"
                                />
                              </div> */}
                                  {/* <div className="input-group scale_unit mt10">
                                <select className="form-control select2 scales select2-hidden-accessible">
                                  <option>df</option>
                                  <option>d</option>
                                  <option>k</option>
                                  <option>df</option>
                                </select>
                                <select className="form-control select2 units select2-hidden-accessible">
                                  <option>s/m</option>
                                  <option>v/m</option>
                                  <option>kv</option>
                                  <option>mv</option>
                                </select>
                              </div>
                                    

                                  {/* {
                                    columns && columns.map((col,index) => <div id= {index}> 
                                    <div> {col.name} </div>
                                    <div> {col.type} </div>
                                    </div> )
                                  } */}
                                </th>
                              ))}
                            {/* -------------------------------------------------------------------------- */}
                          </tr>
                        </thead>
                        <tbody>
                          {rows &&
                            rows.map((row, index) => (
                              <tr>
                                <th>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      value={row.name}
                                      className="form-control number_input"
                                    />
                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-pencil"
                                        id="column_edit"
                                      ></i>
                                    </span>
                                    <span
                                      className="input-group-text dropdown"
                                      style={{
                                        borderRadius: "0 0.25rem 0.25rem 0;",
                                        cursor: "pointer;",
                                      }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        data-toggle="dropdown"
                                      ></i>
                                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <a
                                          className="dropdown-item add_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#addColumn"
                                          onClick={() => setaddRC("addRow")}
                                        >
                                          <i className="fa fa-plus"></i> Add Row
                                        </a>
                                        <a
                                          className="dropdown-item delete_column_modal"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#delete_column_modal"
                                          data-backdrop="static"
                                          data-keyboard="false"
                                          onClick={() => deleteRow(index)}
                                        >
                                          <i className="fa fa-trash"></i> Delete
                                          Row
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </th>

                                {columns &&
                                  columns.map((column, index) => (
                                    <td>
                                      <input
                                        value=""
                                        placeholder="Enter"
                                        className="form-control"
                                      />
                                    </td>
                                  ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div class="text-center mt20 mb10">
                      <button
                        type="button"
                        class="btn btn-secondary mr10"
                        data-dismiss="modal"
                        onClick={cancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={onSubmit}
                      >
                        Submit
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal add column --> */}
      <div
        class="modal fade"
        id="addColumn"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addColumn"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <img
                src="assets/images/adminLogo.png"
                alt="Motware Logo"
                width="40px"
                class="brand-image"
              />
              <h5 class="modal-title w-100 text-center">Add Column</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="compName" class="col-form-label">
                        Column Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={name}
                        id="compName"
                        placeholder=""
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div class="form-group">
                      <label for="sector" class="col-form-label">
                        Data Type
                      </label>
                      <select
                        class="form-control"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="text">String</option>
                        <option value="number">Number</option>
                        <option value="select">List</option>
                        <option value="switch">Binary</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="text-center mt20 mb10">
                  <button
                    type="button"
                    class="btn btn-secondary mr10"
                    data-dismiss="modal"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    class="btn btn-primary"
                    onClick={onRCSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add_Mdts_Template;
