import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Repo_Asset_FormSchema.js";
import { useFormik } from "formik";
import { CategoryFormSchema } from "./Repo_Asset_FormSchema category.js";
import { ManufacturerFormSchema } from "./Repo_Asset_FormSchema manufacturer.js";
const Repo_assets = () => {
  const [LoadedAssetManufacturer, setLoadedAssetManufacturer] = useState({});
  const [LoadedAssetCategory, setLoadedAssetCategory] = useState({});
  const [nodata, setNodata] = useState(false);
  const [AEdit, setAEdit] = useState({});
  const [name, setName] = useState("");
  const [issearch, setIsSearch] = useState(false);
  const [category, setCategory] = useState(" ");
  const [manufacturer, setManufacturer] = useState(" ");
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [total, setTotal] = useState([]);
  const [data1, setRepoAsset] = useState([]);
  var   [data, setData] = useState([]);
  const [data1Length, setData1Length] = useState([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 10,
  });
  const [edit, setEdit] = useState(false);
  const fetchcountAll = async () => {
    try {
      // setLoader(true);
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.RepoAssets1;
      var strAscending = [...json].sort((a, b) => (a.name > b.name ? 1 : -1));

      if (json.length === 0) {
      } else {
        setLoader(false);

      }
    } catch (error) {
      console.log(error);
    }
    if(issearch){
      setTotal(strAscending);
    }
   else{
    setData(strAscending);
    fun();
   }
  };
  useEffect(() => {
    fetchcountAll();
  }, []);
  const fetchcount = async (page, perPage) => {
    try {
      // setLoader(true);
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset/allpagewise`,
        {
          method: "POST",
          body: JSON.stringify({
            page_no: page,
            pageSize: perPage,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      setData1Length(json.RepoAssets1_length);
      json = json.RepoAssets1;
      var strAscending = [...json].sort((a, b) => (a.name > b.name ? 1 : -1));
      setRepoAsset(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchcount();
  }, []);
  const fetchcount1 = async (page, perPage) => {
    setIsSearch(false);
    try {
      // setLoader(true);
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset/allpagewise`,
        {
          method: "POST",
          body: JSON.stringify({
            page_no: page,
            pageSize: perPage,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();

      json = json.RepoAssets1;
      var strAscending = [...json].sort((a, b) => (a.name > b.name ? 1 : -1));
      setRepoAsset(strAscending);
     
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  var fun = (page_no, pageSize) => {
    const pageSize1 = parseInt(pageSize) || 10;
    const currentPage = parseInt(page_no) || 1;
    const startIndex = (currentPage - 1) * pageSize1;
    data = data.slice(startIndex, startIndex + pageSize1);
    setRepoAsset(data);
  };
  console.log(data1);
  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.manufacturer_name
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.category_name?.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setData(strAscending);
    setIsSearch(true);
    setData1Length(strAscending.length);
    fun();
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const getAllManufacturer = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_manufacturer/all`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setLoadedAssetManufacturer(res);
  };
  useEffect(() => {
    getAllManufacturer();
  }, []);
  const AMData = LoadedAssetManufacturer.assetManufacturers;
  const getAllCategory = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/all`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setLoadedAssetCategory(res);
  };
  useEffect(() => {
    getAllCategory();
  }, []);
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    setIsSearch(true);
    var result = total.reverse.filter((test) => {
      return (
        test.name.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.manufacturer_name?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.category_name?.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setData(strAscending);
    setData1Length(strAscending.length);
    fun();
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchcount1();
    }
  };
  const handlePageChange = async (page) => {
    setPagination((prevPagination) => ({ ...prevPagination, page }));

    if (issearch) {
      fun(page, pagination.perPage);
    } 
      fetchcount1(page, pagination.perPage);
    
  };
  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    console.log(currentRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: currentPage,
      perPage: currentRowsPerPage,
    }));
    if (issearch) {
      fun(currentPage, currentRowsPerPage);
    }
      fetchcount1(currentPage, currentRowsPerPage);
  };
  const ACData = LoadedAssetCategory.assetCategories;
  let formInitialValues = {
    name: "",
    manufacturer: "",
    category: "",
  };
  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(`${process.env.REACT_APP_VARIABLE}/api/repo_asset`, {
          method: "POST",
          body: JSON.stringify({
            name: values.name,
            manufacturer: values.manufacturer,
            category: values.category,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            alert("Asset added successfully");
            values.name = "";
            values.manufacturer = "";
            values.category = "";
            fetchcount();
          })
          .catch((error) => {
            // handle the error
            alert(error.message);
            values.name = "";
            values.manufacturer = "";
            values.category = "";
          });
      },
    });
  const editAid = async (_id) => {
    console.log("_id",_id);
    let aid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset/get/${aid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    console.log("res",res);
    setAEdit(res.assets);
    setManufacturer(res.assets.manufacturer);
    setCategory(res.assets.category);
  };
  const formInitialValuesCategory = {
    name: "",
  };
  let categoryFormik = useFormik({
    initialValues: formInitialValuesCategory,
    validationSchema: CategoryFormSchema,
    onSubmit: async (values) => {
      return await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category`,
        {
          method: "POST",
          body: JSON.stringify({
            name: values.name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          // process the response data
          alert("Asset Category added successfully");
          values.name = "";
          getAllCategory();
        })
        .catch((error) => {
          // handle the error
          alert(
            error.message + ": Asset Categry already exist, could not create."
          );
          values.name = "";
        });
    },
  });
  const formInitialValuesManufacturer = {
    name: "",
  };
  let MFormik = useFormik({
    initialValues: formInitialValuesManufacturer,
    validationSchema: ManufacturerFormSchema,
    onSubmit: async (values) => {
      return await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_manufacturer`,
        {
          method: "POST",
          body: JSON.stringify({
            name: values.name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          alert("Asset Manufacturer added successfully");
          values.name = "";
          getAllManufacturer();
        })
        .catch((error) => {
          alert(
            error.message +
              ": Asset Manufacturer already exist, could not create."
          );
          values.name = "";
        });
    },
  });
  let FormInitialValues = {
    name: AEdit.name,
    manufacturer: manufacturer,
    category: category,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let aid = AEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset/${aid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            name: values.name,
            manufacturer: values.manufacturer,
            category: values.category,
          }),
        }
      );
      let resJson = await response.json();
      console.log(resJson);
      if (response.status === 200) {
         fetchcountAll();
        alert("Asset updated successfully");
        values.name = "";
        values.manufacturer = "";
        values.category = "";
       
      } else {
        alert(resJson.message);
        values.name = "";
        values.manufacturer = "";
        values.category = "";
      }
    },
  });
  const handleStatusUpdate = async (e) => {
    setIsSearch(true);
    e.preventDefault();
    let aid = AEdit._id;
    console.log("aid",aid);

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset/status/${aid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          status: status,
        }),
      }
    );
    let resJson = await response.json();
    console.log("resJson",resJson);
    if (resJson) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("Asset " + StatusText + " successfully");

      fetchcountAll()
     
    } else {
      alert(resJson.message);
    }
  };
  const [status, setStatus] = useState(AEdit.status);
  const [Status, set1Status] = useState({});
  const columns = [
    {
      name: "Names",
      selector: (row) => <div className="bigtext">{row.name}</div>,
      sortable: true,
    },
    {
      name: "Manufacturer",
      selector: (row) => (
        <div className="bigtext"> {row.manufacturer_name}</div>
      ),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => <div className="bigtext"> {row.category_name}</div>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <a href={row._id} onClick={() => editAid(row._id)}>
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editAid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepoassets"
          ></i>
        </a>
      ),
    },
  ];
  const optio = {
    paginationComponentOptions: {
      rowsPerPageText: "Rows per page:",
      rangeSeparatorText: "of",
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: "All",
    },
  };
  const handleCancel = () => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div class="col-sm-6">
                  <h1 className="pageTitle">Repository Assets</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6">
                  <div className="form-inline pr-form-inline mb10 float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addRepostoryAsset.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepoassets"
                      >
                        {" "}
                        + Add Repository Asset{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={data1}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Names</b>
                                      </th>
                                      <th>
                                        <b>Manufacturer</b>
                                      </th>
                                      <th>
                                        <b>Category</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={6}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        paginationServer
                        fixedHeaderh
                        highlightOnHover
                        //customStyles={tableCustomStyles}
                        paginationTotalRows={data1Length}
                        paginationPerPage={pagination.perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        {...optio}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoassets"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoassets"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Repository Asset
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={values.name}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Asset Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group row">
                            <label htmlFor="sector" className=" col-form-label">
                              Manufacturer *
                            </label>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                              {" "}
                              <select
                                id="ak"
                                name="manufacturer"
                                value={values.manufacturer}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control "
                              >
                                {MFormik.values.name ? (
                                  <option
                                    value={
                                      (values.manufacturer =
                                        MFormik.values.name)
                                    }
                                    selected="true"
                                  >
                                    {MFormik.values.name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Manufacturer
                                  </option>
                                )}

                                {AMData &&
                                  AMData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))}
                              </select>
                              {MFormik.errors.name &&
                              errors.manufacturer &&
                              touched.manufacturer ? (
                                <span style={{ color: "red" }}>
                                  {errors.manufacturer}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2">
                              <div className="input-group pr-form-group">
                                <button
                                  type="button"
                                  className="btn btn-c-outline-primary btnAdd"
                                  data-toggle="modal"
                                  data-target="#addrepoassetmanufacturer"
                                >
                                  {" "}
                                  <b>+</b>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="sector" className="col-form-label">
                              Category *
                            </label>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                              <select
                                id="ak"
                                name="category"
                                value={values.category}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control "
                              >
                                {categoryFormik.values.name ? (
                                  <option
                                    value={
                                      (values.category =
                                        categoryFormik.values.name)
                                    }
                                    selected={
                                      categoryFormik.values.name ? true : false
                                    }
                                  >
                                    {categoryFormik.values.name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Category
                                  </option>
                                )}

                                {ACData &&
                                  ACData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))}
                              </select>
                              {categoryFormik.errors.name &&
                              errors.category &&
                              touched.category ? (
                                <span style={{ color: "red" }}>
                                  {errors.category}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2">
                              <div className="input-group pr-form-group">
                                <button
                                  type="button"
                                  className="btn btn-c-outline-primary btnAdd"
                                  data-toggle="modal"
                                  data-target="#addrepoassetcategory"
                                >
                                  {" "}
                                  <b>+</b>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss={
                              values.category &&
                              values.manufacturer &&
                              values.name
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepoassets"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepoassets"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Edit Repository Asset
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={formik.values.name}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder="Enter Asset Name"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.name && formik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="sector" className="col-form-label">
                            Manufacturer *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-10">
                            <select
                              id="ak"
                              name="manufacturer"
                              value={formik.values.manufacturer}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className="js-states form-control "
                            >
                              {MFormik.values.name ? (
                                <option
                                  value={
                                    (formik.values.manufacturer =
                                      MFormik.values.name)
                                  }
                                  selected={MFormik.values.name ? true : false}
                                >
                                  {MFormik.values.name}
                                </option>
                              ) : (
                                <option value={manufacturer} selected="true">
                                  {manufacturer}
                                </option>
                              )}
                              {AMData &&
                                AMData.map((am, index) => (
                                  <option key={index} value={am.name}>
                                    {am.name}
                                  </option>
                                ))}
                            </select>
                            {MFormik.errors.name &&
                            formik.errors.manufacturer &&
                            formik.touched.manufacturer ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.manufacturer}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btn btn-c-outline-primary btnAdd"
                                data-toggle="modal"
                                data-target="#addrepoassetmanufacturer"
                              >
                                {" "}
                                <b>+</b>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="sector" className="col-form-label">
                            Category *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-10">
                            {" "}
                            <select
                              id="ak"
                              name="category"
                              value={formik.values.category}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className="js-states form-control "
                            >
                              {categoryFormik.values.name ? (
                                <option
                                  value={
                                    (formik.values.category =
                                      categoryFormik.values.name)
                                  }
                                  selected={
                                    categoryFormik.values.name ? true : false
                                  }
                                >
                                  {categoryFormik.values.name}
                                </option>
                              ) : (
                                <option value={category} selected="true">
                                  {category}
                                </option>
                              )}
                              {ACData &&
                                ACData.map((am, index) => (
                                  <option key={index} value={am.name}>
                                    {am.name}
                                  </option>
                                ))}
                            </select>
                            {categoryFormik.errors.name &&
                            formik.errors.category &&
                            formik.touched.category ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.category}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btn btn-c-outline-primary btnAdd"
                                data-toggle="modal"
                                data-target="#addrepoassetcategory"
                              >
                                {" "}
                                <b>+</b>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                            data-dismiss={
                              formik.values.category &&
                              formik.values.manufacturer &&
                              formik.values.name
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addrepoassetcategory"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoassetcategory"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Asset Category
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Category Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={categoryFormik.values.name}
                              className="form-control"
                              id="locName"
                              onBlur={categoryFormik.handleBlur}
                              onChange={categoryFormik.handleChange}
                              placeholder="Enter Asset Category Name"
                            />
                            {categoryFormik.errors.name &&
                            categoryFormik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {categoryFormik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={categoryFormik.handleSubmit}
                            data-dismiss={
                              categoryFormik.values.name ? "modal" : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoassetmanufacturer"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoassetmanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Asset Manufacturer
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Manufacturer Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={MFormik.values.name}
                              onBlur={MFormik.handleBlur}
                              className="form-control"
                              id="locName"
                              onChange={MFormik.handleChange}
                              placeholder="Enter Asset Manufacturer Name"
                            />
                            {MFormik.errors.name && MFormik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {MFormik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={MFormik.handleSubmit}
                            data-dismiss={MFormik.values.name ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Repository Asset?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Repository Asset?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_assets;
