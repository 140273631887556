/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DataTable from "react-data-table-component";
import { useState, useEffect, useContext } from "react";
import noteContext from "../noteContext";
import React from "react";
import axios from "axios";

import { tableCustomStyles } from "../tableStyle";
import { Link, useHistory } from "react-router-dom";

function Mddts_Template(props) {
  // eslint-disable-next-line no-unused-vars
  let history = useHistory();
  const [user1, setSubCount] = useState([]);
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");

  const [MdtsEdit, setMdtsEdit] = useState({});
  // eslint-disable-next-line no-unused-vars
  const context = useContext(noteContext);
  const [loader, setLoader] = useState(true);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Mdts_Template/getAllMdts_Template`
      );
      let json = response.data;

      var strAscending = [...json].sort((a, b) =>
        a.test_type > b.test_type ? 1 : -1
      );

      setSubCount(strAscending);
      setTotal(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.test_type?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.repo_category?.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.test_type > b.test_type ? 1 : -1
    );
    setSubCount(strAscending);

    if (strAscending) {
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getdata = async (mdts_id) => {
    let id = mdts_id;
    // console.log(mdts_id);
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Mdts_Template/getMdts_Template/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setMdtsEdit(res.account1);
    // console.log(MdtsEdit);

    if (res.status === 422 || !res) {
      console.log("error");
    } else {
      setMdtsEdit(res);
      // console.log("get data");
    }
  };

  // eslint-disable-next-line no-unused-vars
  function handleEdit(e) {
    // console.log(e.target.name);
    // console.log(e.target.value);
    setMdtsEdit({ ...MdtsEdit, [e.target.name]: e.target.value });
  }

  const columns = [
    {
      name: "Test Type",
      selector: (row) => <div className="bigtext"> {row.test_type}</div>,
      sortable: true,
    },
    {
      name: "Asset Category",
      selector: (row) => <div>{row.repo_category}</div>,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <Link
          to={{
            pathname: `/edit_mdts_template/${row.id}`,
            state: { MdtsEdit: row },
          }}
          onClick={() => getdata(row.id)}
        >
          <i
            href={row._id}
            // onClick={() => getdata(row.id)}
            className="btn btn-primary"
            class="fa fa-edit"
          ></i>
        </Link>
      ),
    },
  ];
  useEffect(() => {
    sessionStorage.getItem("token");
  });

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = user1.reverse.filter((test) => {
      return (
        test.test_type?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.repo_category?.toLowerCase().match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.test_type > b.test_type ? 1 : -1
    );
    setSubCount(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  return (
    <>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Mdts Template</h1>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      {/* <button type="button" class="btn btn-c-outline-primary">
                        <i class="fa fa-download"></i> Download Account Data
                      </button> */}
                      <div class="input-group pr-form-group">
                        <Link to="/add_mdts_template">
                          <a
                            href="addNewAssets.html"
                            // onClick={() => getdata(row._id)}
                            type="button"
                            class="btn btn-c-outline-primary"
                          >
                            + Add Mdts Template{" "}
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={user1}
                        pagination
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Test Type</b>
                                      </th>

                                      <th>
                                        <b>Asset Category</b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={3}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Mddts_Template;
