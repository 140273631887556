import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { tableCustomStyles } from "../tableStyle.jsx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function Triggers() {
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [trigger, setTrigger] = useState([]);
  const [loader, setLoader] = useState(true);

  const [inpval, setINP] = useState({});
  const [Status, set1Status] = useState(false);

  const [accounts, setAccounts] = useState({});
  const [iot_sol, setsolution] = useState(inpval.iot_sol);
  const [company_name, setcompany_name] = useState(inpval.company_name);
  const [asset_name, setasset_name] = useState(inpval.asset_name);

  const [assets, setAssets] = useState([]);
  const [selectedSol, setSelectedSol] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [sensors, setSensors] = useState([]);

  const [params, setParams] = useState([]);
  const [selectedParam, setSelectedParam] = useState({
    para_name: "",
    data_type: "",
  });

  const [IotsolutionMasters, setIotsolutionMasters] = useState({});
  const [edit, setEdit] = useState(false);

  // status
  const [get_email_service, setGetEmail_service] = useState();
  const [get_sms_service, setGetSms_service] = useState();
  const [email_service, setemail_service] = useState(inpval.email_service);
  const [sms_service, setsms_service] = useState(inpval.sms_service);
  const [emailServiceEnabled, setEmailServiceEnabled] =
    useState(get_email_service);
  const [smsServiceEnabled, setSmsServiceEnabled] = useState(get_sms_service);

  async function fetchData() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/triggers/get_trigger`
      );
      var res = response.data.data;
      var strAscending = [...res].sort((a, b) =>
        a.trigger_name > b.trigger_name ? 1 : -1
      );
      setTrigger(strAscending);
      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.trigger_name?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.company_name?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.iot_sol?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.repo_asset?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.device_uuid?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.sensor_uuid?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.param_name?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.min?.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.max?.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.result_val
          ?.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.start_time?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.end_time?.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.trigger_name > b.trigger_name ? 1 : -1
    );
    setTrigger(strAscending);
    if (strAscending.length === 0) {
      setLoader(false);
    }
  }, [search]);

  const FormSchema = Yup.object({
    trigger_name: Yup.string().required("Please Enter Trigger Name!"),
    iot_sol: Yup.string().required("Please Enter solution!"),
    sensor_uuid: Yup.string().required("Please Enter composite sensor uuid!"),
    device_uuid: Yup.string().required("Please Enter device sensor uuid!"),
    param_name: Yup.string().required("Please Enter param name!"),
    // result_val: Yup.string().required("Please Enter result value!"),
    // min: Yup.string().required("Please Enter min!"),
    // max: Yup.string().required("Please Enter max!"),
    start_time: Yup.string().required("Please Enter start time!"),
    end_time: Yup.string().required("Please Enter end time!"),
    sms: Yup.string().required("Please Enter sms!"),
    alert_frequency: Yup.string().required("Please Enter alert frequency!"),

    company_name: Yup.string().required("Please Enter company name!"),
    asset_name: Yup.string().required("Please Enter Asset Name!"),
  });

  const formInitialValues = {
    trigger_name: "",
    iot_sol: "",
    device_uuid: "",
    sensor_uuid: "",
    param_name: "",
    result_val: "",
    min: "",
    max: "",
    start_time: "",
    end_time: "",
    sms: "",
    alert_frequency: "",
    company_name: "",
    asset_name: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        try {
          let payload = {
            trigger_name: values.trigger_name,
            company_name: values.company_name,
            iot_sol: values.iot_sol,
            device_uuid: values.device_uuid,
            sensor_uuid: values.sensor_uuid,
            param_name: values.param_name,
            min: values.min,
            max: values.max,
            start_time: values.start_time,
            end_time: values.end_time,
            sms: values.sms,
            alert_frequency: values.alert_frequency,
            email_service,
            sms_service,
            asset_name: values.asset_name,
          };

          // Add result_val if the data_type is "string"
          if (selectedParam && selectedParam.data_type === "string") {
            payload.result_val = values.result_val;
          }

          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/triggers/add_trigger`,
            {
              method: "POST",
              body: JSON.stringify(payload),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );

          const data = await res.json();

          if (res.status === 422 || !data) {
            alert("Please insert data");
            alert(data.message);
          } else {
            alert("Trigger successfully added");
            fetchData();
          }
        } catch (err) {
          console.log(err);
        }
      },
    });

  // accounts api

  useEffect(() => {
    const getAllAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Triggers/getAllAccounts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAccounts(res);
    };
    getAllAccounts();
  }, []);

  const AccountData = accounts.accounts;

  //isl
  // useEffect(() => {
  const getAllIotSolutionMaster = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllIotSolutionMasters`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setIotsolutionMasters(res);
  };
  // getAllIotSolutionMaster();
  // }, []);
  const IotsolM = IotsolutionMasters.IotsolutionMasters;

  const getAllAssets = async (solutionName) => {
    setSelectedSol(solutionName);
    try {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/triggers/get_assets`,
        {
          method: "POST",
          body: JSON.stringify({
            sol_name: solutionName,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAssets(res.data);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const getAllDevices = async (assetName) => {
    setSelectedAsset(assetName);
    try {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/triggers/get_devices`,
        {
          method: "POST",
          body: JSON.stringify({
            sol_name: selectedSol,
            asset_name: assetName,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setDevices(res.data);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const getAllSensors = async (deviceName) => {
    setSelectedDevice(deviceName);
    try {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/triggers/get_sensors`,
        {
          method: "POST",
          body: JSON.stringify({
            sol_name: selectedSol,
            asset_name: selectedAsset,
            device_name: deviceName,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setSensors(res.data);
    } catch (error) {
      console.error("Error fetching sensors:", error);
    }
  };

  const getAllParams = async (sensorName) => {
    try {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/triggers/get_parameters`,
        {
          method: "POST",
          body: JSON.stringify({
            sol_name: selectedSol,
            sensor_name: sensorName,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setParams(res.data);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  const handleParamChange = (e) => {
    const selectedParaName = e.target.value;
    const selectedParamObj = params.find(
      (param) => param.para_name === selectedParaName
    ) || { para_name: "", data_type: "" };
    setSelectedParam(selectedParamObj);
    handleChange(e);
  };

  const handleEditParamChange = (e) => {
    const selectedParaName = e.target.value;
    const selectedParamObj = params.find(
      (param) => param.para_name === selectedParaName
    ) || { para_name: "", data_type: "" };
    setSelectedParam(selectedParamObj);
    formik.handleChange(e);
  };
  const getdata = async (id) => {
    let _id = id;
    // if (window.confirm("Are you sure you want to edit trigger?")) {
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/triggers/get_trigger_by_id/${_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    setINP(data.data[0]);
    getAllIotSolutionMaster();
    getAllAssets(data.data[0].iot_sol);
    getAllDevices(data.data[0].asset_name, data.data[0].iot_sol);
    setGetEmail_service(data.data[0].email_service);
    setGetSms_service(data.data[0].sms_service);

    // setStatus(false);
    if (res.status === 422 || !data) {
      console.log("error");
    } else {
      setINP(data.data[0]);
    }
  };

  let FormInitialValues = {
    trigger_name: inpval.trigger_name,
    sensor_uuid: inpval.sensor_uuid,
    device_uuid: inpval.device_uuid,
    param_name: inpval.param_name,
    min: inpval.min,
    max: inpval.max,
    start_time: inpval.start_time,
    end_time: inpval.end_time,
    sms: inpval.sms,
    alert_frequency: inpval.alert_frequency,
    result_val: inpval.result_val,
    iot_sol: inpval.iot_sol,
    company_name: inpval.company_name,
    asset_name: inpval.asset_name,
  };

  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      const payload = {
        trigger_name: values.trigger_name,

        sensor_uuid: values.sensor_uuid,
        device_uuid: values.device_uuid,
        param_name: values.param_name,
        min: values.min,
        max: values.max,
        start_time: values.start_time,
        end_time: values.end_time,
        sms: values.sms,
        alert_frequency: values.alert_frequency,
        email_service: email_service,
        sms_service: sms_service,
        status: inpval.status,
        result_val: values.result_val,

        iot_sol: values.iot_sol,
        company_name: values.company_name,
        asset_name: values.asset_name,
      };
      let _id = inpval._id;
      const res2 = await fetch(
        `${process.env.REACT_APP_VARIABLE}/triggers/update_trigger/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (res2.status === 200) {
        alert("trigger has been edited");
        // if (alert("trigger has been edited")) {
        // } else
        // window.location.reload();
        fetchData();
      } else {
        console.log("err");
      }
    },
  });

  const updateStatus = async (e) => {
    e.preventDefault();
    let _id = inpval._id; // Assuming inpval contains the selected trigger data

    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Triggers/updateStatus/${_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: Status ? 1 : 0, // Convert boolean to 1 or 0
        }),
      }
    );

    const res1 = await res.json();
    if (res.status === 201) {
      setEdit(false);
      alert("Trigger status has been updated");
      fetchData(); // Call your data fetching function to refresh the table
    } else {
      console.log("Error updating trigger:", res1);
    }
  };

  const updateEmail = async () => {
    let _id = inpval._id;
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/triggers/update_email_service`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id,
          email_service: emailServiceEnabled ? "enable" : "disable",
        }),
      }
    );
    const res1 = await res.json();
    if (res.status === 200) {
      alert("Email service has been updated");
      getdata(_id);
    } else {
      console.log("Error updating trigger:", res1);
    }
  };

  const handleEmail = () => {
    setEmailServiceEnabled(!emailServiceEnabled);
    updateEmail();
  };

  const updateSms = async () => {
    let _id = inpval._id;
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/triggers/update_sms_service`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id,
          sms_service: smsServiceEnabled ? "enable" : "disable",
        }),
      }
    );
    const res1 = await res.json();
    if (res.status === 200) {
      alert("Sms service has been updated");
      getdata(_id);
    } else {
      console.log("Error updating trigger:", res1);
    }
  };

  const handleSms = () => {
    setSmsServiceEnabled(!smsServiceEnabled);
    updateSms();
  };

  const columns = [
    {
      name: "Trigger Name",
      selector: (row) => <div className="bigtext">{row.trigger_name}</div>,
      sortable: true,
    },
    {
      name: "Account Name",
      selector: (row) => <div className="bigtext">{row.company_name}</div>,
      sortable: true,
    },
    {
      name: "Solution",
      selector: (row) => <div>{row.iot_sol}</div>,
      sortable: true,
    },
    {
      name: "Asset",
      selector: (row) => <div className="bigtext">{row.repo_asset}</div>,
      sortable: true,
    },
    {
      name: "Device UUID",
      selector: (row) => <div className="bigtext">{row.device_uuid}</div>,
      sortable: true,
    },
    {
      name: "Sensor UUID",
      selector: (row) => <div className="bigtext">{row.sensor_uuid}</div>,
      sortable: true,
    },

    {
      name: "Param Name",
      selector: (row) => <div className="bigtext">{row.param_name}</div>,
      sortable: true,
    },
    {
      name: "Min",
      selector: (row) => <div>{row.min}</div>,
      sortable: true,
    },
    {
      name: "Max",
      selector: (row) => <div>{row.max} </div>,
      sortable: true,
    },
    {
      name: "Result",
      selector: (row) => <div>{row.result_val} </div>,
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => <div>{row.start_time}</div>,
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => <div>{row.end_time}</div>,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <td>
          <a href={row._id} onClick={() => getdata(row._id)}>
            <div className="switch">
              <label className="switch-light">
                <input
                  type="checkbox"
                  name="status"
                  checked={row.status === 1}
                  onChange={(e) => set1Status(e.target.checked)}
                  data-toggle="modal"
                  data-target={
                    row.status === 1
                      ? "#disableconfirmation"
                      : "#enableconfirmation"
                  }
                />
                <span>
                  <span id="enable">Enable</span>
                  <span id="disable">Disable</span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          </a>
        </td>
      ),
      sortable: true,
    },
    {},
    {
      name: "Action",
      cell: (row) => (
        <>
          <a>
            <i
              href={row._id}
              onClick={() => getdata(row._id)}
              className="btn btn-primary"
              class="fa fa-edit"
              data-toggle="modal"
              data-target="#edittrigger"
            ></i>
          </a>
        </>
      ),
    },
  ];

  const handleCancel = (e) => {
    setEdit(false);
  };

  // edit status

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = trigger.reverse.filter((test) => {
      return (
        test.trigger_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.company_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.iot_sol.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.repo_asset.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.device_uuid?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.sensor_uuid?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.param_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.min.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.max.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.result_val
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.start_time.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.end_time.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.trigger_name > b.trigger_name ? 1 : -1
    );

    setTrigger(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-sm-6">
                  <h1 className="pageTitle">Triggers</h1>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <a
                          href=""
                          type="button"
                          className="btn btn-c-outline-primary"
                          data-toggle="modal"
                          data-target="#addtrigger"
                        >
                          {" "}
                          + Add Trigger{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <div id="example_filter" className="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      className="dataTables_wrapper"
                      columns={columns}
                      data={trigger}
                      pagination
                      noDataComponent={
                        loader === false ? (
                          <div className="table-responsive">
                            <div className="borderRadius">
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: "50px" }}>
                                  <tr>
                                    <th>
                                      <b>Trigger Name</b>
                                    </th>
                                    <th>
                                      <b>Account Name</b>
                                    </th>
                                    <th>
                                      <b>Solution</b>
                                    </th>
                                    <th>
                                      <b>Asset</b>
                                    </th>
                                    <th>
                                      <b>Device UUID</b>
                                    </th>
                                    <th>
                                      <b>Sensor UUID</b>
                                    </th>
                                    <th>
                                      <b>Param Name</b>
                                    </th>
                                    <th>
                                      <b>Min</b>
                                    </th>
                                    <th>
                                      <b>Max</b>
                                    </th>
                                    <th>
                                      <b>Result</b>
                                    </th>
                                    <th>
                                      <b>Start Time</b>
                                    </th>
                                    <th>
                                      <b>End Time</b>
                                    </th>
                                    <th>
                                      <b>Status</b>
                                    </th>

                                    <th>
                                      <b>Action</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ height: "140px" }}>
                                  <tr>
                                    <td
                                      style={{ textAlign: "center" }}
                                      colSpan={13}
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />

                    <div class={loader ? "loader" : ""}></div>
                  </div>
                </div>
              </div>
            </div>

            {/* /popups */}
            {/* Add Trigger */}
            <div
              className="modal fade"
              id="addtrigger"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtrigger"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Trigger
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Trigger Name
                            </label>
                            <input
                              type="text"
                              name="trigger_name"
                              value={values.trigger_name}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.trigger_name && touched.trigger_name ? (
                              <span style={{ color: "red" }}>
                                {errors.trigger_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Account Name
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="company_name"
                                value={values.company_name}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e); // Update formik values
                                  getAllIotSolutionMaster();
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.company_name}
                                </option>
                                {AccountData &&
                                  AccountData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {errors.company_name && touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Solution
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="iot_sol"
                                value={values.iot_sol}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const selectedSolution = e.target.value;
                                  handleChange(e); // Update formik values
                                  if (selectedSolution) {
                                    getAllAssets(selectedSolution); // Fetch assets based on selected solution
                                  }
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.iot_sol}
                                </option>
                                {IotsolM &&
                                  IotsolM.map((am, index) => (
                                    <option key={index} value={am.iot_sol}>
                                      {am.iot_sol}
                                    </option>
                                  ))}
                              </select>
                              {errors.iot_sol && touched.iot_sol ? (
                                <span style={{ color: "red" }}>
                                  {errors.iot_sol}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Asset
                            </label>
                            <div>
                              <span>
                                <select
                                  id="ak"
                                  name="asset_name"
                                  value={values.asset_name}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const selectedAsset = e.target.value;
                                    handleChange(e); // Update formik values
                                    if (selectedAsset) {
                                      getAllDevices(selectedAsset); // Fetch assets based on selected solution
                                    }
                                  }}
                                  className="js-states form-control "
                                  class="form-select"
                                >
                                  <option disabled selected="true">
                                    {values.asset_name}
                                  </option>
                                  {assets &&
                                    assets.map((am, index) => (
                                      <option key={index} value={am}>
                                        {am}
                                      </option>
                                    ))}
                                </select>
                                {errors.asset_name && touched.asset_name ? (
                                  <span style={{ color: "red" }}>
                                    {errors.asset_name}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Device Uuid
                            </label>
                            <span>
                              <select
                                id="ak"
                                name="device_uuid"
                                value={values.device_uuid}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const selectedDevice = e.target.value;
                                  handleChange(e); // Update formik values
                                  if (selectedDevice) {
                                    getAllSensors(selectedDevice); // Fetch assets based on selected solution
                                  }
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.device_uuid}
                                </option>
                                {devices &&
                                  devices.map((am, index) => (
                                    <option key={index} value={am}>
                                      {am}
                                    </option>
                                  ))}
                              </select>

                              {errors.device_uuid && touched.device_uuid ? (
                                <span style={{ color: "red" }}>
                                  {errors.device_uuid}
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Sensor Uuid
                            </label>
                            <span>
                              <select
                                id="ak"
                                name="sensor_uuid"
                                value={values.sensor_uuid}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const selectedSensor = e.target.value;
                                  handleChange(e); // Update formik values
                                  if (selectedSensor) {
                                    getAllParams(selectedSensor); // Fetch assets based on selected solution
                                  }
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.sensor_uuid}
                                </option>
                                {sensors &&
                                  sensors.map((am, index) => (
                                    <option key={index} value={am}>
                                      {am}
                                    </option>
                                  ))}
                              </select>

                              {errors.sensor_uuid && touched.sensor_uuid ? (
                                <span style={{ color: "red" }}>
                                  {errors.sensor_uuid}
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Param Name
                            </label>
                            <span>
                              <select
                                id="ak"
                                name="param_name"
                                value={values.param_name}
                                onBlur={handleBlur}
                                onChange={handleParamChange}
                                className="js-states form-control form-select"
                              >
                                <option disabled selected={!values.param_name}>
                                  {values.param_name}
                                </option>
                                {params.length > 0 &&
                                  params.map((param, index) => (
                                    <option key={index} value={param.para_name}>
                                      {param.para_name}
                                    </option>
                                  ))}
                              </select>

                              {errors.param_name && touched.param_name ? (
                                <span style={{ color: "red" }}>
                                  {errors.param_name}
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        {selectedParam.data_type === "double" ? (
                          <>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="min"
                                  className="col-form-label required-field"
                                >
                                  Min
                                </label>
                                <input
                                  type="number"
                                  name="min"
                                  value={values.min}
                                  className="form-control"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                {errors.min && touched.min && (
                                  <span style={{ color: "red" }}>
                                    {errors.min}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="max"
                                  className="col-form-label required-field"
                                >
                                  Max
                                </label>
                                <input
                                  type="number"
                                  name="max"
                                  value={values.max}
                                  className="form-control"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                {errors.max && touched.max && (
                                  <span style={{ color: "red" }}>
                                    {errors.max}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        ) : selectedParam &&
                          selectedParam.data_type === "string" ? (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="result_val"
                                className="col-form-label required-field"
                              >
                                Enter Result
                              </label>
                              <select
                                name="result_val"
                                value={values.result_val}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control form-select"
                              >
                                <option
                                  disabled
                                  selected={!values.result_val}
                                ></option>
                                <option value="OPERATED">OPERATED</option>
                                <option value="HEALTHY">HEALTHY</option>
                                <option value="HIGH">HIGH</option>
                                <option value="LOW">LOW</option>
                                <option value="UNHEALTHY">UNHEALTHY</option>
                                <option value="UNDERLOADING">
                                  UNDERLOADING
                                </option>
                                <option value="OVERLOADING">OVERLOADING</option>
                                <option value="MIN">MIN</option>
                                <option value="MAX">MAX</option>
                                <option value="THRESHOLD">THRESHOLD</option>
                              </select>
                              {errors.result_val && touched.result_val && (
                                <span style={{ color: "red" }}>
                                  {errors.result_val}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Start time
                            </label>
                            <input
                              type="time"
                              name="start_time"
                              value={values.start_time}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.start_time && touched.start_time ? (
                              <span style={{ color: "red" }}>
                                {errors.start_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              End time
                            </label>
                            <input
                              type="time"
                              name="end_time"
                              value={values.end_time}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.end_time && touched.end_time ? (
                              <span style={{ color: "red" }}>
                                {errors.end_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              SMS
                            </label>
                            <input
                              type="text"
                              name="sms"
                              value={values.sms}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.sms && touched.sms ? (
                              <span style={{ color: "red" }}>{errors.sms}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Alert Frequency
                            </label>
                            <input
                              type="text"
                              name="alert_frequency"
                              value={values.alert_frequency}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.alert_frequency &&
                            touched.alert_frequency ? (
                              <span style={{ color: "red" }}>
                                {errors.alert_frequency}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Email Service
                            </label>
                            <td>
                              <a
                              // href={row.id} onClick={() => getdata(row.id)}
                              >
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultChecked={
                                        inpval.device_status === "disable"
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setemail_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enableconfirmation"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setemail_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disableconfirmation"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Sms Service
                            </label>
                            <td>
                              <a
                              // href={row.id} onClick={() => getdata(row.id)}
                              >
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultChecked={
                                        inpval.sms_service === "disable"
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setsms_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enablesmsservice"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setsms_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disableemailservice"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          data-dismiss={
                            values.trigger_name &&
                            values.iot_sol &&
                            values.sensor_uuid &&
                            values.device_uuid &&
                            values.param_name &&
                            // values.result_val &&
                            // values.min &&
                            // values.max &&
                            values.start_time &&
                            values.end_time &&
                            values.sms &&
                            values.alert_frequency &&
                            values.company_name &&
                            values.asset_name
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* update Trigger */}
            <div
              div
              className="modal fade"
              id="edittrigger"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="edittrigger"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Trigger
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Trigger Name
                            </label>

                            <input
                              type="text"
                              name="trigger_name"
                              value={formik.values.trigger_name}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.trigger_name &&
                            formik.touched.trigger_name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.trigger_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Account Name
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="company_name"
                                value={formik.values.company_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option
                                  value={company_name}
                                  disabled
                                  selected="true"
                                >
                                  {inpval.company_name}
                                </option>
                                {AccountData &&
                                  AccountData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {formik.errors.company_name &&
                              formik.touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Solution
                            </label>
                            <div>
                              <select
                                select
                                id="ak"
                                name="iot_sol"
                                value={formik.values.iot_sol}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  const selectedSolution = e.target.value;
                                  formik.handleChange(e); // Update formik values
                                  if (selectedSolution) {
                                    getAllAssets(selectedSolution); // Fetch assets based on selected solution
                                  }
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option
                                  disabled
                                  selected="true"
                                  value={iot_sol}
                                >
                                  {inpval.iot_sol}
                                </option>
                                {IotsolM &&
                                  IotsolM.map((am, index) => (
                                    <option key={index} value={am.iot_sol}>
                                      {am.iot_sol}
                                    </option>
                                  ))}
                              </select>
                              {formik.errors.iot_sol &&
                              formik.touched.iot_sol ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.iot_sol}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Asset
                            </label>
                            <div>
                              <span>
                                <select
                                  id="ak"
                                  name="asset_name"
                                  value={formik.values.asset_name}
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    const selectedAsset = e.target.value;
                                    formik.handleChange(e); // Update formik values
                                    if (selectedAsset) {
                                      getAllDevices(selectedAsset); // Fetch assets based on selected solution
                                    }
                                  }}
                                  className="js-states form-control "
                                  class="form-select"
                                >
                                  <option
                                    disabled
                                    selected="true"
                                    value={asset_name}
                                  >
                                    {inpval.asset_name}
                                  </option>
                                  {assets &&
                                    assets.map((am, index) => (
                                      <option key={index} value={am}>
                                        {am}
                                      </option>
                                    ))}
                                </select>
                                {formik.errors.asset_name &&
                                formik.touched.asset_name ? (
                                  <span style={{ color: "red" }}>
                                    {formik.errors.asset_name}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Device Uuid
                            </label>
                            <select
                              id="ak"
                              name="device_uuid"
                              value={formik.values.device_uuid}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                const selectedDevice = e.target.value;
                                formik.handleChange(e); // Update formik values
                                if (selectedDevice) {
                                  getAllSensors(selectedDevice); // Fetch assets based on selected solution
                                }
                              }}
                              className="js-states form-control "
                              class="form-select"
                            >
                              <option disabled selected="true">
                                {inpval.device_uuid}
                              </option>
                              {devices &&
                                devices.map((am, index) => (
                                  <option key={index} value={am}>
                                    {am}
                                  </option>
                                ))}
                            </select>
                            {formik.errors.device_uuid &&
                            formik.touched.device_uuid ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.device_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Sensor Uuid
                            </label>
                            <select
                              id="ak"
                              name="sensor_uuid"
                              value={formik.values.sensor_uuid}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                const selectedSensor = e.target.value;
                                formik.handleChange(e); // Update formik values
                                if (selectedSensor) {
                                  getAllParams(selectedSensor); // Fetch assets based on selected solution
                                }
                              }}
                              className="js-states form-control "
                              class="form-select"
                            >
                              <option disabled selected="true">
                                {inpval.sensor_uuid}
                              </option>
                              {sensors &&
                                sensors.map((am, index) => (
                                  <option key={index} value={am}>
                                    {am}
                                  </option>
                                ))}
                            </select>
                            {formik.errors.sensor_uuid &&
                            formik.touched.sensor_uuid ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.sensor_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Param Name
                            </label>
                            <select
                              id="ak"
                              name="param_name"
                              value={formik.values.param_name}
                              onBlur={formik.handleBlur}
                              onChange={handleEditParamChange}
                              className="js-states form-control form-select"
                            >
                              <option disabled selected={!values.param_name}>
                                {inpval.param_name}
                              </option>
                              {params.length > 0 &&
                                params.map((param, index) => (
                                  <option key={index} value={param.para_name}>
                                    {param.para_name}
                                  </option>
                                ))}
                            </select>
                            {formik.errors.param_name &&
                            formik.touched.param_name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.param_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {inpval.result_val ? (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="result_val"
                                className="col-form-label required-field"
                              >
                                Enter Result
                              </label>
                              <select
                                name="result_val"
                                value={formik.values.result_val}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control form-select"
                              >
                                <option
                                  disabled
                                  selected={values.result_val}
                                ></option>
                                <option value="OPERATED">OPERATED</option>
                                <option value="HEALTHY">HEALTHY</option>
                                <option value="HIGH">HIGH</option>
                                <option value="LOW">LOW</option>
                                <option value="UNHEALTHY">UNHEALTHY</option>
                                <option value="UNDERLOADING">
                                  UNDERLOADING
                                </option>
                                <option value="OVERLOADING">OVERLOADING</option>
                                <option value="MIN">MIN</option>
                                <option value="MAX">MAX</option>
                                <option value="THRESHOLD">THRESHOLD</option>
                              </select>
                              {formik.errors.result_val &&
                                formik.touched.result_val && (
                                  <span style={{ color: "red" }}>
                                    {formik.errors.result_val}
                                  </span>
                                )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="min"
                                  className="col-form-label required-field"
                                >
                                  Min
                                </label>
                                <input
                                  type="number"
                                  name="min"
                                  value={formik.values.min}
                                  className="form-control"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.min && formik.touched.min && (
                                  <span style={{ color: "red" }}>
                                    {formik.errors.min}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="max"
                                  className="col-form-label required-field"
                                >
                                  Max
                                </label>
                                <input
                                  type="number"
                                  name="max"
                                  value={formik.values.max}
                                  className="form-control"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.max && formik.touched.max && (
                                  <span style={{ color: "red" }}>
                                    {formik.errors.max}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Start Time
                            </label>
                            <input
                              type="time"
                              name="start_time"
                              value={formik.values.start_time}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.start_time &&
                            formik.touched.start_time ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.start_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              End Time
                            </label>
                            <input
                              type="time"
                              name="end_time"
                              value={formik.values.end_time}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.end_time &&
                            formik.touched.end_time ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.end_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              SMS
                            </label>
                            <input
                              type="text"
                              name="sms"
                              value={formik.values.sms}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.sms && formik.touched.sms ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.sms}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Alert Frequency
                            </label>
                            <input
                              type="text"
                              name="alert_frequency"
                              value={formik.values.alert_frequency}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.alert_frequency &&
                            formik.touched.alert_frequency ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.alert_frequency}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Email Service
                            </label>
                            <td>
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    checked={get_email_service === "disable"}
                                    onChange={handleEmail}
                                  />
                                  <span>
                                    <span id="enable">Enable</span>
                                    <span id="disable">Disable</span>
                                  </span>
                                  <a className="btn btn-primary"></a>
                                </label>
                              </div>
                            </td>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Sms Service
                            </label>
                            <td>
                              <div className="switch">
                                <label className="switch-light">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    checked={get_sms_service === "disable"}
                                    onChange={handleSms}
                                  />
                                  <span>
                                    <span id="enable">Enable</span>
                                    <span id="disable">Disable</span>
                                  </span>
                                  <a className="btn btn-primary"></a>
                                </label>
                              </div>
                            </td>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={formik.handleSubmit}
                          type="button"
                          className="btn btn-primary"
                          data-dismiss={
                            formik.values.trigger_name &&
                            formik.values.iot_sol &&
                            formik.values.sensor_uuid &&
                            formik.values.device_uuid &&
                            formik.values.param_name &&
                            // formik.values.min &&
                            // formik.values.max &&
                            formik.values.start_time &&
                            formik.values.end_time &&
                            formik.values.sms &&
                            formik.values.alert_frequency &&
                            formik.values.company_name &&
                            formik.values.asset_name
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* switch  status*/}
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <p style={{ textAlign: "center" }}>
                          Do you want to enable the trigger?
                        </p>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          onClick={(e) => updateStatus(e)}
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal for disabling trigger */}
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <p style={{ textAlign: "center" }}>
                          Do you want to disable the trigger?
                        </p>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          onClick={(e) => updateStatus(e)}
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* switch  sms*/}
          </div>
        </section>
      </div>
    </>
  );
}

export default Triggers;
