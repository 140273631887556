import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Repo_Oil_Vendor_FormSchema.js";

const Repo_oil_vendor = () => {
  const { error1, setError1 } = useState({});

  const [LoadedOilVendor, setLoadedOilVendor] = useState([]);
  const [oil_vendor, setOil_vendor] = useState("");

  const [OVEdit, setOVEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_oil_vendor/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.oilVendors;
      var strAscending = [...json].sort((a, b) =>
        a.oil_vendor > b.oil_vendor ? 1 : -1
      );
      setLoadedOilVendor(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.oil_vendor.toLowerCase().match(search.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) =>
      a.oil_vendor > b.oil_vendor ? 1 : -1
    );
    setLoadedOilVendor(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const formInitialValues = {
    oil_vendor: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/repo_oil_vendor`,
          {
            method: "POST",
            body: JSON.stringify({
              oil_vendor: values.oil_vendor,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            alert("Oil vendor added successfully");
            values.oil_vendor = "";
            fetchData();
          })
          .catch((error) => {
            alert(
              error.message + ": Oil Vendor already exist, could not create."
            );
            values.oil_vendor = "";
          });
      },
    });

  const editOVid = async (_id) => {
    let ovid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_oil_vendor/get/${ovid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setOVEdit(res.oilVendors);
  };

  function handleEdit(e) {
    setOVEdit({ ...OVEdit, [e.target.name]: e.target.value });
  }

  let FormInitialValues = {
    oil_vendor: OVEdit.oil_vendor,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let ovid = OVEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_oil_vendor/${ovid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({ oil_vendor: values.oil_vendor }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Oil Vendor updated successfully");
        fetchData();
      } else {
        alert(resJson.message);
      }
    },
  });

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    let ovid = OVEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_oil_vendor/${ovid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );
    let resJson = await response.json();
    if (resJson) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("Oil Vendor " + StatusText + " successfully");
      fetchData();
    } else {
      alert(resJson.message);
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedOilVendor.reverse.filter((test) => {
      return test.oil_vendor.toLowerCase().match(val.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) =>
      a.oil_standard > b.oil_vendor ? 1 : -1
    );
    setLoadedOilVendor(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const [status, setStatus] = useState(OVEdit.status);
  const [Status, set1Status] = useState({});
  const columns = [
    {
      name: "Oil Vendor",
      selector: (row) => <div>{row.oil_vendor}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row._id} onClick={() => editOVid(row._id)}>
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editOVid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepooilvendor"
          ></i>
        </a>
      ),
    },
  ];
  const handleCancel = () => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Repository Oil Vendor</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addReposensortype.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepooilvendor"
                      >
                        {" "}
                        + Add Repository Oil Vendor
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedOilVendor}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Oil Vendor</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={4}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepooilvendor"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepooilvendor"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Repository Oil Vendor
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              {" "}
                              Oil Vendor Name *
                            </label>
                            <input
                              type="text"
                              name="oil_vendor"
                              value={values.oil_vendor}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Oil Vendor Name"
                            />
                            {errors.oil_vendor && touched.oil_vendor ? (
                              <span style={{ color: "red" }}>
                                {errors.oil_vendor}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss={values.oil_vendor ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepooilvendor"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepooilvendor"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Repository Oil Vendor
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Oil Vendor Name *
                            </label>
                            <input
                              type="text"
                              name="oil_vendor"
                              value={formik.values.oil_vendor}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Oil Vendor Name"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.oil_vendor &&
                            formik.touched.oil_vendor ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.oil_vendor}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                            data-dismiss={
                              formik.values.oil_vendor ? "modal" : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Repository Oil Vendor?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Repository Oil Vendor?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_oil_vendor;
