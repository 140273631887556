import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <>
      <div className="hold-transition sidebar-mini layout-fixed">
        <div className="wrapper">
          {/* <!-- Navbar --> */}
          <nav className="main-header navbar navbar-expand navbar-light">
            {/* <!-- Left navbar links --> */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="/"
                  role="button"
                >
                  <i className="fa fa-bars"></i>
                </a>
              </li>
              {/* <!-- <li className="nav-item d-none d-sm-inline-block">
            <a href="index3.html" className="nav-link">Sidebar</a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="/" className="nav-link">Contact</a>
          </li> --> */}
            </ul>

            {/* <!-- SEARCH FORM --> */}
            <form className="form-inline ml-3">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </form>

            {/* <!-- Right navbar links --> */}
            <ul className="navbar-nav ml-auto">
              {/* <!-- Messages Dropdown Menu --> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link btn btn-primary"
                  data-toggle="dropdown"
                  href="/"
                >
                  Quick Menu
                </a>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <a href="/" className="dropdown-item">
                    Raise Request
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="/" className="dropdown-item">
                    Setup
                  </a>
                </div>
              </li>
              {/* <!-- Notifications Dropdown Menu --> */}
              <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="/">
                  <i className="fa fa-user"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <a href="/" className="dropdown-item">
                    <i className="fa fa-lock mr-2"></i>
                    <span className="text-muted text-sm">Logout</span>
                  </a>
                </div>
              </li>
            </ul>
          </nav>
          {/* <!-- /.navbar --> */}

          {/* <!-- Main Sidebar Container --> */}
          <aside className="main-sidebar">
            {/* <!-- Brand Logo --> */}
            <a href="/" className="brand-link">
              <img
                src="assets/images/adminLogo.png"
                alt="adminLogo"
                className="brand-image elevation-3"
                style={{ opacity: ".8", borderRadius: "10px" }}
              />
              <span className="brand-text font-weight-light">Motware</span>
            </a>

            {/* <!-- Sidebar --> */}
            <div className="sidebar">
              {/* <!-- Sidebar Menu --> */}
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library --> */}
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                      {/* <a href="/accounts" className="nav-link active"> */}
                      <i className="fa fa-circle nav-icon"></i>
                      <p>
                        Dashboard
                        {/* <!-- <span className="right badge badge-danger">New</span> --> */}
                      </p>
                    </Link>
                  </li>

                  <li className="nav-item has-treeview">
                    <a
                      href="/login"
                      class="nav-link"
                      data-toggle="collapse"
                      data-target=".nav-treeview"
                      aria-expanded="false"
                    >
                      <i className="nav-icon fa fa-tachometer-alt"></i>
                      <p>
                        Masters
                        <i className="right fa fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/accounts" className="nav-link active">
                          {/* <a href="/accounts" className="nav-link active"> */}
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Accounts</p>
                        </Link>
                        {/* </a> */}
                      </li>
                      {/*  */}

                      {/*  */}
                      <li className="nav-item">
                        <Link
                          to="/Contracted_Templates"
                          className="nav-link active"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>Contracted Templates</p>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/Mdts_Templates" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Mdts Templates</p>
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link to={"/triggers"} className="nav-link">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Triggers</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/mqtt_broker" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Mqtt</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-treeview">
                    <a
                      href="/"
                      class="nav-link"
                      data-toggle="collapse"
                      data-target=".nav-treeview"
                      aria-expanded="false"
                    >
                      <i className="nav-icon fa fa-tachometer-alt"></i>
                      <p>
                        Repository
                        <i className="right fa fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/asset_category" className="nav-link active">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Asset Category</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/asset_manufacturer" className="nav-link">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Asset Manufacturer</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/assets" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Assets</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/asset_test_type" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Asset Test Type</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/department" className="nav-link">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Department</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/industry" className="nav-link">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>Industry</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/electrical_symbol" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Electrical Symbol</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/location" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Location</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/oil_standard" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Oil Standard</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/oil_type" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Oil Type</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/oil_vendor" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Oil Vendor</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sensor_manufacturer" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Sensor Manufacturer</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sensor_type" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Sensor Type</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item has-treeview">
                    <a
                      href="/"
                      class="nav-link"
                      data-toggle="collapse"
                      data-target=".nav-treeview"
                      aria-expanded="false"
                    >
                      <i className="nav-icon fa fa-tachometer-alt"></i>
                      <p>
                        IOT
                        <i className="right fa fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/iot_sensor_masters" className="nav-link">
                          <i className="fa fa-circle nav-icon"></i>
                          <p>IOT Sensor Masters</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/iot_solution_masters" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>IOT Solution Masters</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin_user" className="nav-link">
                      <i className="nav-icon fa fa-copy"></i>
                      <p>Admin Users</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account_user" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Account Users</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/alerts" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Alerts</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/setup" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Setup</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/asset_breakdown" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Asset Breakdowns</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/total_sensors" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Total Sensors</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/downtime" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Down Time</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/analytics" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Analytics</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/service_log" className="nav-link">
                      <i className="nav-icon fa fa-chart-pie"></i>
                      <p>Service Logs</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/audit_log" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Audit Logs</p>
                    </Link>
                  </li>
                  <li className="nav-item has-treeview">
                    <a
                      href="/"
                      class="nav-link"
                      data-toggle="collapse"
                      data-target=".nav-treeview"
                      aria-expanded="false"
                    >
                      <i className="far fa-circle nav-icon"></i>
                      <p>
                        Reports
                        <i className="right fa fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/tests" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Tests</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/api" className="nav-link">
                          <i className="nav-icon fa fa-tree"></i>
                          <p>API</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sensor" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Sensor</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/assets" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Assets</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              {/* <!-- /.sidebar-menu --> */}
            </div>
            {/* <!-- /.sidebar --> */}
          </aside>

          {/* <!-- Content Wrapper. Contains page content --> */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
