
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Asset_Category_FormSchema.js";
import { useFormik } from "formik";

const Repo_asset_category = () => {
  const { error1, setError1 } = useState({});
  const [LoadedAssetCategory, setLoadedAssetCategory] = useState([]);

  const [ACEdit, setACEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.assetCategories;
      var strAscending = [...json].sort((a, b) => (a.name > b.name ? 1 : -1));
      setLoadedAssetCategory(strAscending);
      if (json.length===0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadedAssetCategory(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const formInitialValues = {
    name: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category`,
          {
            method: "POST",
            body: JSON.stringify({
              name: values.name,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            alert("Asset Category added successfully");
            values.name = "";
            fetchData();
          })
          .catch((error) => {
            alert(
              error.message +
                ": Asset Category already exist, could not create."
            );
            values.name = "";
          });
      },
    });

  const editACid = async (_id) => {
    let cid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/get/${cid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setACEdit(res.assetCategories);
  };

  let FormInitialValues = {
    name: ACEdit.name,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let cid = ACEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/${cid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({ name: values.name }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Asset category updated successfully");
        fetchData();
      } else {
        alert(resJson.message);
      }
    },
  });

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    let cid = ACEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/${cid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );
    let resJson = await response.json();
    if (resJson) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("Asset category " + StatusText + " successfully");

      fetchData();
    } else {
      alert(resJson.message);
    }
  };
  const [status, setStatus] = useState(ACEdit.status);
  const [Status, set1Status] = useState({});
  const columns = [
    {
      name: "Names",

      selector: (row) => <div>{row.name} </div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row._id} onClick={() => editACid(row._id)}>
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editACid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepoassetcategory"
          ></i>
        </a>
      ),
    },
  ];
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedAssetCategory.reverse.filter((test) => {
      return test.name.toLowerCase().match(val.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadedAssetCategory(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const handleCancel = () => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Repository Asset Categories</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addRepoassetcategories.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepoassetcategory"
                      >
                        {" "}
                        + Add Asset Category{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedAssetCategory}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Names</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={4}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoassetcategory"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoassetcategory"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Asset Category
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Category Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={values.name}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Category Name"
                            />{" "}
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss={values.name ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepoassetcategory"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepoassetcategory"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Edit Asset Category
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Category Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={formik.values.name}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Category Name"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.name && formik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                            data-dismiss={formik.values.name ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Repository Asset Category?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Repository Asset Category?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_asset_category;
