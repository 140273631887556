/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
// import DataTable from "react-data-table-component";
// import Button from "react-bootstrap/Button";
// import axios from "axios";
import React, { useState, useEffect } from "react";
// import { tableCustomStyles } from "../tableStyle.jsx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

function AddIotSensorMaster() {
  const [inpval, setINP] = useState({});
  const [iotsolution, setIotSolution] = useState("");

  // const [batteryfield, setbatteryfield] = useState([{}]);

  ///// batteryfield ///////
  // const addInputField = (e) => {
  //   e.preventDefault();
  //   setbatteryfield([
  //     ...batteryfield,
  //     {
  //       total_batteries: "",
  //     },
  //   ]);
  // };

  // const removebatteryfield = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...batteryfield];
  //   rows.splice(index, 1);
  //   setbatteryfield(rows);
  // };

  // const handleChanged = (index, e) => {

  //   const { value } = e.target;
  //   const list = [...batteryfield];
  //   list[index] = value;
  //   setbatteryfield(list);
  // };

  // ///// tapsfield//////
  // const [taps, settaps] = useState([{}]);

  // const addInputtaps = (e) => {
  //   e.preventDefault();
  //   settaps([...taps, {}]);
  // };

  // const removetap = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...taps];
  //   rows.splice(index, 1);
  //   settaps(rows);
  // };

  // const handletap = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...taps];
  //   list[index][name] = value;
  //   settaps(list);
  // };

  //////// add nameplate///////

  // const handlenameplate = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...nameplate];
  //   list[index][name] = value;
  //   setnameplate(list);
  // };

  const [nameplate, setnameplate] = useState({});
  const [hv_connection, sethv_connection] = useState("");
  const [voltage_unbalance_limit, setvoltage_unbalance_limit] = useState("");
  const [current_unbalance_limit, setcurrent_unbalance_limit] = useState("");
  const [energy_rate_rs, setenergy_rate_rs] = useState("");
  const [designed_efficiency, setdesigned_efficiency] = useState("");
  const [last_hotspot, setlast_hotspot] = useState("");
  const [winding_constant, setwinding_constant] = useState("");
  const [eddy_loss_per_unit, seteddy_loss_per_unit] = useState("");
  const [last_actual_life_hours, setlast_actual_life_hours] = useState("");
  const [last_standard_life_hours, setlast_standard_life_hours] = useState("");
  const [lv2_connection, setlv2_connection] = useState("");
  const [amb_temp, setamb_temp] = useState("");
  const [lv1_connection, setlv1_connection] = useState("");
  const [phase, setphase] = useState("");
  const [winding_material, setwinding_material] = useState("");
  const [
    winding_temp_at_winding_resistance,
    setwinding_temp_at_winding_resistance,
  ] = useState("");
  const [tap_position, settap_position] = useState("");
  const [secondary_side, setsecondary_side] = useState("");
  const [primary_side, setprimary_side] = useState("");
  const [
    max_wind_temp_rise_above_amb_temp,
    setmax_wind_temp_rise_above_amb_temp,
  ] = useState("");
  const [
    max_oil_temp_rise_above_amb_temp,
    setmax_oil_temp_rise_above_amb_temp,
  ] = useState("");
  const [rated_hotspot, setrated_hotspot] = useState("");
  const [rated_top_oil_temp, setrated_top_oil_temp] = useState("");
  const [full_load_loss_kw, setfull_load_loss_kw] = useState("");
  const [no_load_loss_kw, setno_load_loss_kw] = useState("");
  const [rated_copper_loss_kw, setrated_copper_loss_kw] = useState("");
  const [Rating_va, setRating_va] = useState("");
  const [lv_rated_current, setlv_rated_current] = useState("");
  const [lv_connection, setlv_connection] = useState("");
  const [designed_load_percent, setdesigned_load_percent] = useState("");
  const [load_variation, setload_variation] = useState("");
  const [
    prev_cumulative_active_energy_lv,
    setprev_cumulative_active_energy_lv,
  ] = useState("");
  const [
    prev_cumulative_reactive_energy_lv,
    setprev_cumulative_reactive_energy_lv,
  ] = useState("");
  const [
    prev_cumulative_apparent_energy_lv,
    setprev_cumulative_apparent_energy_lv,
  ] = useState("");
  const [
    prev_cumulative_active_energy_lv1,
    setprev_cumulative_active_energy_lv1,
  ] = useState("");
  const [
    prev_cumulative_reactive_energy_lv1,
    setprev_cumulative_reactive_energy_lv1,
  ] = useState("");
  const [
    prev_cumulative_apparent_energy_lv1,
    setprev_cumulative_apparent_energy_lv1,
  ] = useState("");
  const [
    prev_cumulative_active_energy_lv2,
    setprev_cumulative_active_energy_lv2,
  ] = useState("");
  const [
    prev_cumulative_reactive_energy_lv2,
    setprev_cumulative_reactive_energy_lv2,
  ] = useState("");
  const [
    prev_cumulative_apparent_energy_lv2,
    setprev_cumulative_apparent_energy_lv2,
  ] = useState("");
  const [
    prev_cumulative_active_energy_hv,
    setprev_cumulative_active_energy_hv,
  ] = useState("");
  const [
    prev_cumulative_reactive_energy_hv,
    setprev_cumulative_reactive_energy_hv,
  ] = useState("");
  const [
    prev_cumulative_apparent_energy_hv,
    setprev_cumulative_apparent_energy_hv,
  ] = useState("");
  const [lv_cum_energy, setlv_cum_energy] = useState("");
  const [hv_cum_energy, sethv_cum_energy] = useState("");
  const [cooling_type, setcooling_type] = useState("");
  const [oil, setoil] = useState("");
  const [voltage_ratio, setvoltage_ratio] = useState("");

  ///////// add sensor ///////
  // const [sensor_fields, setsensor_fields] = useState([{}]);

  // const addsensorFields = (e) => {
  //   e.preventDefault();
  //   setsensor_fields([...sensor_fields, {}]);
  // };

  // const removesensorFields = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...sensor_fields];
  //   rows.splice(index, 1);
  //   setsensor_fields(rows);
  // };

  // const handlesensorFields = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...sensor_fields];
  //   list[index][name] = value;
  //   setsensor_fields(list);
  // };

  ///////calculated fields ///////

  // const [para_name, setpara_name] = useState("");
  // const [data_type, setdata_type] = useState("");
  // const [calculated_fields, setcalculated_fields] = useState([{}]);

  // const addcalculatedFields = (e) => {
  //   e.preventDefault();
  //   setcalculated_fields([...calculated_fields, {}]);
  // };

  // const removecalculatedFields = (e, index) => {
  //   e.preventDefault();
  //   const rows = [...calculated_fields];
  //   rows.splice(index, 1);
  //   setcalculated_fields(rows);
  // };

  // const handlecalculatedFields = (index, evn) => {
  //   const { name, value } = evn.target;
  //   const list = [...calculated_fields];
  //   list[index][name] = value;
  //   setcalculated_fields(list);
  // };

  const [status, set1Status] = useState({
    pf_correction: "",
    calculation: "",
  });

  function handleEdit(e) {
    setINP({ ...inpval, [e.target.name]: e.target.value });
    set1Status(e.target.value);
  }
  // for add

  const [iot_sol, setiot_sol] = useState("");
  const [device_uuid, setdevice_uuid] = useState("");
  const [sensor_uuid, setsensor_uuid] = useState("");
  const [iot_subaccount, setiot_subaccount] = useState("");
  const [iot_location, setiot_location] = useState("");
  const [iot_department, setiot_department] = useState("");
  const [iot_asset, setiot_asset] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [data_freq_min, setdata_freq_min] = useState("");
  const [mqtt, setmqtt] = useState("No");

  const [pf_correction, setpf_correction] = useState("disable");
  const [calculation, setcalculation] = useState("disable");

  const [iot_asset_category, setiot_asset_category] = useState("");
  const [iot_asset_manufacturer, setiot_asset_manufacturer] = useState("");
  const [iot_sr_no, setiot_sr_no] = useState("");
  const [iot_manufacturing_yr, setiot_manufacturing_yr] = useState("");
  const [seq_id, setseq_id] = useState("");
  const [seq_tag, setseq_tag] = useState("");
  const [sub_asset, setsub_asset] = useState("");
  const [ups_bank, setups_bank] = useState("");
  const [efficiency, setefficiency] = useState("");
  const [name, setname] = useState("");
  // const [total_batteries, settotal_batteries] = useState();
  // const [battery, setbatteries] = useState([]);
  // const [device_battery_status, setdevice_battery_status] = useState(1);
  const [total_fields, settotal_fields] = useState("");
  const [taps, setTaps] = useState();

  // const [company_name, setcompany_name] = useState("");
  const [accounts, setAccounts] = useState({});
  // const [subaccount_company_name, setsubaccount_company_name] = useState("");

  const [subaccount_name, setsubaccount_name] = useState("");
  const [subaccounts, setsubaccount] = useState([]);

  const [iot_solution, setiot_solution] = useState("");
  const [IotsolutionMasters, setIotsolutionMasters] = useState({});

  const [RepoCategory, setRepoCategory] = useState("");
  const [categories, setRepoCategories] = useState({});

  const [RepoManufacturer, setRepoManufacturer] = useState("");
  const [manufacturers, setmanufacturers] = useState({});

  const [assets, setAssets] = useState([]);
  const [asset_name, setasset_name] = useState("");

  const [location, setlocation] = useState("");
  const [locations, setlocations] = useState({});
  const [department_name, setdepartment_name] = useState("");
  const [departments, setdepartments] = useState([]);
  const [subdomain, setsubdomain] = useState("");
  const [subdomains, setsubdomains] = useState({});
  const [asset_type, setasset_type] = useState("");
  const [asset_types, setasset_types] = useState({});
  const [isRequired, setIsRequired] = useState(false);
  const createSchema = (isRequired) => {
    return Yup.object({
      company_name: Yup.string().required("Please Enter company name!"),
      subaccount_name: Yup.string().required("Please Enter subaccount name!"),
      // iot_solution: Yup.string().required("Please Enter iot solution!"),
      asset_name: Yup.string().required("Please Enter asset name!"),
      location: Yup.string().required("Please Enter location!"),
      department_name: Yup.string().required("Please Enter department name!"),
      device_uuid: Yup.string().required("Please Enter device uuid!"),
      sensor_uuid: Yup.string().required("Please Enter sensor uuid!"),
      subdomain: Yup.string().required("Please Enter subdomain!"),
      // asset_type: Yup.string().required("Please Enter asset type!"),
      // seq_id: Yup.string().required("Please Enter seq id!"),
      // seq_tag: Yup.string().required("Please Enter seq tag!"),
      // sub_asset: Yup.string().required("Please Enter sub asset!"),
      latitude: Yup.string().required("Please Enter latitude!"),
      longitude: Yup.string().required("Please Enter longitude!"),
      data_freq_min: Yup.string().required("Please Enter data freq min!"),
      // ups_bank: Yup.string().required("Please Enter ups bank!"),
      //efficiency: Yup.string().required("Please Enter efficiency!"),
      broker_url: isRequired
        ? Yup.string().required("Please Select broker url!")
        : Yup.string(),
      topic: isRequired
        ? Yup.string().required("Please Enter topic!")
        : Yup.string(),
    });
  };
  const handleevent = (e) => {
    setiot_solution(e.target.value);
  };
  const formInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  const FormSchema = createSchema(isRequired);
  let transformerFormik = useFormik({
    initialValues: formInitialValues,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify({
            device_uuid: values.device_uuid,
            sensor_uuid: values.sensor_uuid,
            subdomain: values.subdomain,
            taps: taps,
            latitude: values.latitude,
            longitude: values.longitude,
            data_freq_min: values.data_freq_min,
            company_name: values.company_name,
            subaccount_name: values.subaccount_name,
            iot_solution: iot_solution,
            asset_name: values.asset_name,
            location: values.location,
            department_name: values.department_name,
            mqtt: mqtt,
            calculation: calculation,
            broker_url: isRequired ? values.broker_url : "",
            topic: isRequired ? values.topic : "",
          }),

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (res.status === 500) {
        alert("Sensor uuid already exist.");
      }
      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        setIsRequired(false);
        window.location = "/iot_sensor_masters";
      }
    },
  });
  const AQIQformInitialValues = {
    device_uuid: transformerFormik.values.device_uuid,
    sensor_uuid: transformerFormik.values.sensor_uuid,
    subdomain: transformerFormik.values.subdomain,
    latitude: transformerFormik.values.latitude,
    longitude: transformerFormik.values.longitude,
    data_freq_min: transformerFormik.values.data_freq_min,
    company_name: transformerFormik.values.company_name,
    subaccount_name: transformerFormik.values.subaccount_name,
    iot_solution: iot_solution,
    asset_name: transformerFormik.values.asset_name,
    location: transformerFormik.values.location,
    department_name: transformerFormik.values.department_name,
  };
  let AQIQFormik = useFormik({
    initialValues: AQIQformInitialValues,
    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.iot_solution = iot_solution;

      data1.mqtt = mqtt;
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();
      //console.log("data", data);
      if (res.status === 500) {
        alert("Sensor uuid already exist.");
      }
      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        window.location = "/iot_sensor_masters";
      }
    },
  });
  const HVACformInitialValues = {
    device_uuid: transformerFormik.values.device_uuid,
    asset_type: asset_type,
    subdomain: transformerFormik.values.subdomain,
    latitude: transformerFormik.values.latitude,
    longitude: transformerFormik.values.longitude,
    data_freq_min: transformerFormik.values.data_freq_min,
    company_name: transformerFormik.values.company_name,
    subaccount_name: transformerFormik.values.subaccount_name,
    iot_solution: iot_solution,
    asset_name: transformerFormik.values.asset_name,
    location: transformerFormik.values.location,
    department_name: transformerFormik.values.department_name,
    no_of_circuit: null,
    no_of_energy_meter: null,
    total_sub_assets: null,
  };
  const [isReq, setIsReq] = useState(false);
  const [isReq1, setIsReq1] = useState(false);
  const createHVACSchema = (isReq, isReq1) => {
    console.log("schema", isReq, isReq1);
    return Yup.object({
      // // iot_solution: Yup.string().required("Please Enter iot solution!"),
      // subdomain: Yup.string().required("Please Enter subdomain!"),
      // asset_type: Yup.string().required("Please Enter asset type!"),
      no_of_circuit: isReq
        ? Yup.number().required("Please Enter No. of Circuit!")
        : Yup.number().notRequired(),
      no_of_energy_meter: isReq
        ? Yup.number().required("Please Enter No. of Energy Meter!")
        : Yup.number().notRequired(),
      total_sub_assets: isReq1
        ? Yup.number().required("Please Enter No. of Sub Asset!")
        : Yup.number().notRequired(),
    });
  };

  const HVACFormSchema = createHVACSchema(isReq,isReq1);
  console.log("HVACFormSchema", HVACFormSchema);
  let HVACFormik = useFormik({
    initialValues: HVACformInitialValues,
    validationSchema: HVACFormSchema,
    onSubmit: async (values) => {
      console.log("kalpanarathod", values);
      let data1 = transformerFormik.values;
      data1.iot_solution = iot_solution;
      data1.mqtt = mqtt;
      data1.asset_type = asset_type;
      data1.no_of_circuit = isReq ? values.no_of_circuit : null;
      data1.no_of_energy_meter = isReq ? values.no_of_energy_meter : null;
      data1.total_sub_assets = isReq1 ? values.total_sub_assets : null;
      data1.calculation = calculation;
      console.log("data1", data1);
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");

        setIsRequired(false);
        values.no_of_circuit = null;
        values.no_of_energy_meter = null;
        values.total_sub_assets = null;
        setasset_type("");

        window.location = "/iot_sensor_masters";
      }
    },
  });
  const UPSformInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",

    latitude: "",
    longitude: "",
    data_freq_min: "",

    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  let UPSFormik = useFormik({
    initialValues: UPSformInitialValues,

    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.iot_solution = iot_solution;

      data1.mqtt = mqtt;
      data1.calculation = calculation;
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        window.location = "/iot_sensor_masters";
      }
    },
  });
  const EMSformInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    sub_asset: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  let EMSFormik = useFormik({
    initialValues: EMSformInitialValues,
    // validationSchema: EMSFormSchema,
    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.sub_asset = HVACFormik.values.sub_asset;
      data1.iot_solution = iot_solution;

      data1.mqtt = mqtt;
      data1.calculation = calculation;
      data1.total_sub_assets = HVACFormik.values.total_sub_assets;
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        window.location = "/iot_sensor_masters";
      }
    },
  });
  const OtherformInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  let OtherFormik = useFormik({
    initialValues: OtherformInitialValues,
    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.sub_asset = HVACFormik.values.sub_asset;
      data1.iot_solution = iot_solution;
      data1.mqtt = mqtt;

      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");

        window.location = "/iot_sensor_masters";
      }
    },
  });
  const SELCOformInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  let SELCOFormik = useFormik({
    initialValues: SELCOformInitialValues,

    // validationSchema: MotorFormSchema,
    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.iot_solution = iot_solution;

      data1.mqtt = mqtt;
      data1.calculation = calculation;
      data1.total_sub_assets = HVACFormik.values.total_sub_assets;
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        window.location = "/iot_sensor_masters";
      }
    },
  });
  const MotorformInitialValues = {
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    company_name: "",
    subaccount_name: "",
    iot_solution: iot_solution,
    asset_name: "",
    location: "",
    department_name: "",
  };
  let MotorFormik = useFormik({
    initialValues: MotorformInitialValues,

    // validationSchema: MotorFormSchema,

    onSubmit: async (values) => {
      let data1 = transformerFormik.values;
      data1.iot_solution = iot_solution;

      data1.mqtt = mqtt;
      data1.calculation = calculation;
      data1.total_sub_assets = HVACFormik.values.total_sub_assets;
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/createIotSensorMaster`,
        {
          method: "POST",
          body: JSON.stringify(data1),

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 422 || !data) {
        alert("please insert data");
      } else {
        setINP(data);
        alert("successfully added");
        window.location = "/iot_sensor_masters";
      }
    },
  });
  useEffect(() => {
    const getAllAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllAccounts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAccounts(res);
    };
    getAllAccounts();
  }, []);
  const AccountData = accounts.accounts;
  // asset api
 
  //isl
  useEffect(() => {
    const getAllIotSolutionMaster = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllIotSolutionMasters`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setIotsolutionMasters(res);
    };
    getAllIotSolutionMaster();
  }, []);
  const IotsolM = IotsolutionMasters.IotsolutionMasters;

 
    const getAllSubAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllSubAccounts`,
        {
          method: "POST",
          body: JSON.stringify({
            subdomain: transformerFormik.values.subdomain,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setsubaccount(res);
      
    };
  
  const subData = subaccounts.subaccounts;
  const getAllLocations = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllLocations`,
      {
        method: "POST",
        body: JSON.stringify({
          subaccount_name: transformerFormik.values.subaccount_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setlocations(res);
  };
  const locationData = locations.Repo_location;
  const [assettype, setAssetType] = useState([]);

 
    const getAllDepartments = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllDepartment`,
        {
          method: "POST",
          body: JSON.stringify({
            location_name: transformerFormik.values.location,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setdepartments(res);
    };
   
  const departmentData = departments.departments;
 
    const getAllAssets = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllAssets`,
        {
          method: "POST",
          body: JSON.stringify({
            department_name: transformerFormik.values.department_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAssets(res);
    };
  
 
  const AssetData = assets.assets;
  const [mqttbroker, setMqttBroker] = useState([]);
  useEffect(() => {
    const getAllMqttBroker = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/mqtt_brokers/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setMqttBroker(res);
    };
    getAllMqttBroker();
  }, []);
  const mqttbrokerData = mqttbroker.mqttBrokers;
  useEffect(() => {
    const getAllcategories = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllcategories`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setRepoCategories(res);
    };
    getAllcategories();
  }, []);
  const categoryData = categories.categories;
  useEffect(() => {
    const getAllmanufacturers = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllmanufacturers`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setmanufacturers(res);
    };
    getAllmanufacturers();
  }, []);
  const manufaturerData = manufacturers.manufacturers;
  const getAllSubdomains = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllSubdomain`,
      {
        method: "POST",
        body: JSON.stringify({
          company_name: transformerFormik.values.company_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setsubdomains(res);
  };
  const subdomainData = subdomains.Repo_subdomain;
  const getAllAsset = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getAllAsset`,
      {
        method: "POST",
        body: JSON.stringify({
          iot_sol: iot_solution,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setasset_types(res);
  };
  const assetDataS = asset_types.asset_type;
  return (
    <>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-sm-6">
                  <h1 className="pageTitle">Add Iot Sensor Master</h1>
                </div>
              </div>

              <form className="">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Iot Solution Master Name
                      </label>
                      <div>
                        <select
                          id="ak"
                          name="iot_solution"
                          type="text"
                          value={iot_solution}
                          onChange={(e) => handleevent(e)}
                          //onBlur={handleBlur}
                          //onChange={handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true">
                            {iot_solution}
                          </option>
                          {IotsolM &&
                            IotsolM.map((am, index) => (
                              <option key={index} value={am.iot_sol}>
                                {am.iot_sol}
                              </option>
                            ))}
                        </select>
                        {/* {errors.iot_solution && touched.iot_solution ? (
                          <span style={{ color: "red" }}>
                            {errors.iot_solution}
                          </span>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Subdomain
                      </label>
                      <div>
                        <span onClick={getAllSubdomains}>
                          <select
                            id="ak"
                            name="subdomain"
                            value={transformerFormik.values.subdomain}
                            onBlur={transformerFormik.handleBlur}
                            onChange={transformerFormik.handleChange}
                            className="js-states form-control "
                            class="form-select"
                          >
                            <option
                              value={transformerFormik.values.subdomain}
                              disabled
                            >
                              {transformerFormik.values.subdomain}
                            </option>

                            {subdomainData &&
                              subdomainData.map((am, index) => (
                                <option key={index} defaultValue={am.subdomain}>
                                  {am.subdomain}
                                </option>
                              ))}
                            {AccountData &&
                              AccountData.map((am, index) => (
                                <option key={index} value={am.subdomain}>
                                  {am.subdomain}
                                </option>
                              ))}
                          </select>
                          {transformerFormik.errors.subdomain &&
                          transformerFormik.touched.subdomain ? (
                            <span style={{ color: "red" }}>
                              {transformerFormik.errors.subdomain}
                            </span>
                          ) : null}
                        </span>
                      </div>

                      {/* <input
                        type="text"
                        name="subdomain"
                        value={values.subdomain}
                        className="form-control"
                        id="compName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder=""
                      /> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Subaccount
                      </label>
                      <div>
                      <span onClick={getAllSubAccounts}>
                        <select
                          id="ak"
                          name="subaccount_name"
                          value={transformerFormik.values.subaccount_name}
                          onBlur={transformerFormik.handleBlur}
                          onChange={transformerFormik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true">
                            {transformerFormik.values.subaccount_name}
                          </option>
                          {subData &&
                            subData.map((am, index) => (
                              <option key={index} value={am.subaccount_name}>
                                {am.subaccount_name}
                              </option>
                            ))}
                        </select></span>
                        {transformerFormik.errors.subaccount_name &&
                        transformerFormik.touched.subaccount_name ? (
                          <span style={{ color: "red" }}>
                            {transformerFormik.errors.subaccount_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Location
                      </label>
                      <div>
                        <span onClick={getAllLocations}>
                          <select
                            id="ak"
                            name="location"
                            value={transformerFormik.values.location}
                            onBlur={transformerFormik.handleBlur}
                            onChange={transformerFormik.handleChange}
                            className="js-states form-control "
                            class="form-select"
                          >
                            <option disabled selected="true">
                              {transformerFormik.values.location}
                            </option>
                            {locationData &&
                              locationData.map((am, index) => (
                                <option key={index} value={am.location}>
                                  {am.location}
                                </option>
                              ))}
                          </select>
                          {transformerFormik.errors.location &&
                          transformerFormik.touched.location ? (
                            <span style={{ color: "red" }}>
                              {transformerFormik.errors.location}
                            </span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Department
                      </label>
                      <div>
                      <span onClick={getAllDepartments}>
                        <select
                          id="ak"
                          name="department_name"
                          value={transformerFormik.values.department_name}
                          onBlur={transformerFormik.handleBlur}
                          onChange={transformerFormik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true">
                            {transformerFormik.values.department_name}
                          </option>
                          {departmentData &&
                            departmentData.map((am, index) => (
                              <option key={index} value={am.department}>
                                {am.department}
                              </option>
                            ))}
                        </select></span>
                        {transformerFormik.errors.department_name &&
                        transformerFormik.touched.department_name ? (
                          <span style={{ color: "red" }}>
                            {transformerFormik.errors.department_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Asset Name
                      </label>
                      <div>
                      <span onClick={getAllAssets}>
                        <select
                          id="ak"
                          name="asset_name"
                          value={transformerFormik.values.asset_name}
                          onBlur={transformerFormik.handleBlur}
                          onChange={transformerFormik.handleChange}
                          className="js-states form-control "
                          class="form-select"
                        >
                          <option disabled selected="true">
                            {transformerFormik.values.asset_name}
                          </option>
                          {AssetData &&
                            AssetData.map((am, index) => (
                              <option key={index} value={am.asset}>
                                {am.asset}
                              </option>
                            ))}
                        </select></span>
                        {transformerFormik.errors.asset_name &&
                        transformerFormik.touched.asset_name ? (
                          <span style={{ color: "red" }}>
                            {transformerFormik.errors.asset_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="compName" className="col-form-label">
                        Iot Sol
                      </label>
                      <input
                        // value={inpval.device_uuid}
                        value={inpval.iot_sol}
                        onChange={(e) => setiot_sol(e.target.value)}
                        type="text"
                        name="iot_sol"
                        className="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div> */}
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Device Battery Status
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={status ? 1 : 0}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={
                                  inpval.device_battery_status === 0
                                }
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => setdevice_battery_status(1)}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Enable
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => setdevice_battery_status(0)}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  Disable
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div> */}
                  </div>
                  {iot_solution !== "AQIQ" &&
                    iot_solution !== "Transformer" &&
                    iot_solution !== "UPS" &&
                    iot_solution !== "EMS" &&
                    iot_solution !== "Motor" &&
                    iot_solution !== "Other" && iot_solution !== "SELCO" &&  iot_solution !== "Solar" && (
                      <div className="col-md-3">
                        {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Subaccount
                      </label>
                      <input
                        value={inpval.iot_subaccount}
                        onChange={(e) => setiot_subaccount(e.target.value)}
                        type="text"
                        name="iot_subaccount"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}

                        <div className="form-group">
                          <label
                            htmlFor="contactNo"
                            className="col-form-label required-field"
                          >
                            Asset Type
                          </label>
                          <div>
                            <span onClick={getAllAsset}>
                              <select
                                id="ak"
                                name="asset_type"
                                // defaultValue={inpval.asset_type}
                                onBlur={HVACFormik.handleBlur}
                                onChange={(e) => {
                                  setasset_type(e.target.value);
                                  console.log(e.target.value);
                                  if (e.target.value === "Chiller") {
                                    setIsReq(true);
                                  } else {
                                    setIsReq1(true);
                                  }
                                }}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option
                                // disabled
                                //  selected="true"
                                >
                                  {inpval.asset_type}
                                </option>

                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[0]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[1]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[2]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[3]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[4]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[5]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[6]}</li>
                                    </option>
                                  ))}
                                {assetDataS &&
                                  assetDataS.map((am, index) => (
                                    <option
                                      key={index}
                                      defaultValue={am.asset_types}
                                    >
                                      <li>{am.asset_types[7]}</li>
                                    </option>
                                  ))}
                              </select>
                              {/* {errors.asset_type && touched.asset_type ? (
                            <span style={{ color: "red" }}>
                              {errors.asset_type}
                            </span>
                          ) : null} */}
                            </span>
                          </div>

                          {/* <input
                        type="text"
                        name="asset_type"
                        value={values.asset_type}
                        className="form-control"
                        id="compName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder=""
                      /> */}
                        </div>
                      </div>
                    )}
                  {asset_type === "Chiller" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label required-field"
                        >
                          No. of Circuits
                        </label>
                        <input
                          type="number"
                          name="no_of_circuit"
                          value={HVACFormik.values.no_of_circuit}
                          className="form-control"
                          id="compName"
                          onBlur={HVACFormik.handleBlur}
                          onChange={HVACFormik.handleChange}
                          placeholder=""
                        />
                        {HVACFormik.errors.no_of_circuit &&
                        HVACFormik.touched.no_of_circuit ? (
                          <span style={{ color: "red" }}>
                            {HVACFormik.errors.no_of_circuit}
                          </span>
                        ) : null}
                      </div>
                      {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Location
                      </label>
                      <input
                        value={inpval.iot_location}
                        onChange={(e) => setiot_location(e.target.value)}
                        type="text"
                        name="iot_location"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                    </div>
                  )}
                  {asset_type === "Chiller" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label required-field"
                        >
                          No. of Energy Meters
                        </label>
                        <input
                          type="number"
                          name="no_of_energy_meter"
                          value={HVACFormik.values.no_of_energy_meter}
                          className="form-control"
                          id="compName"
                          onBlur={HVACFormik.handleBlur}
                          onChange={HVACFormik.handleChange}
                          placeholder=""
                        />
                        {HVACFormik.errors.no_of_energy_meter &&
                        HVACFormik.touched.no_of_energy_meter ? (
                          <span style={{ color: "red" }}>
                            {HVACFormik.errors.no_of_energy_meter}
                          </span>
                        ) : null}
                      </div>
                      {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Location
                      </label>
                      <input
                        value={inpval.iot_location}
                        onChange={(e) => setiot_location(e.target.value)}
                        type="text"
                        name="iot_location"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                    </div>
                  )}
                  {asset_type !== "Chiller" && asset_type !== "" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="contactNo"
                          className="col-form-label required-field"
                        >
                          No. of Subassets
                        </label>
                        <input
                          type="number"
                          name="total_sub_assets"
                          value={HVACFormik.values.total_sub_assets}
                          onChange={HVACFormik.handleChange}
                          onBlur={HVACFormik.handleBlur}
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                        {HVACFormik.errors.total_sub_assets &&
                        HVACFormik.touched.total_sub_assets ? (
                          <span style={{ color: "red" }}>
                            {HVACFormik.errors.total_sub_assets}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="compName"
                        className="col-form-label required-field"
                      >
                        Device Uuid
                      </label>
                      <input
                        type="text"
                        name="device_uuid"
                        value={transformerFormik.values.device_uuid}
                        className="form-control"
                        id="compName"
                        onBlur={transformerFormik.handleBlur}
                        onChange={transformerFormik.handleChange}
                        placeholder=""
                      />
                      {transformerFormik.errors.device_uuid &&
                      transformerFormik.touched.device_uuid ? (
                        <span style={{ color: "red" }}>
                          {transformerFormik.errors.device_uuid}
                        </span>
                      ) : null}
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Location
                      </label>
                      <input
                        value={inpval.iot_location}
                        onChange={(e) => setiot_location(e.target.value)}
                        type="text"
                        name="iot_location"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                  </div>
                  {iot_solution !== "HVAC" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="contactNo"
                          className="col-form-label required-field"
                        >
                          Sensor Uuid
                        </label>
                        <input
                          type="text"
                          name="sensor_uuid"
                          value={transformerFormik.values.sensor_uuid}
                          className="form-control"
                          id="compName"
                          onBlur={transformerFormik.handleBlur}
                          onChange={transformerFormik.handleChange}
                          placeholder=""
                        />
                        {transformerFormik.errors.sensor_uuid &&
                        transformerFormik.touched.sensor_uuid ? (
                          <span style={{ color: "red" }}>
                            {transformerFormik.errors.sensor_uuid}
                          </span>
                        ) : null}
                      </div>
                      {/* <div className="form-group">
                      <label htmlFor="compName" className="col-form-label">
                        Iot Department
                      </label>
                      <input
                        value={inpval.iot_department}
                        onChange={(e) => setiot_department(e.target.value)}
                        type="text"
                        name="iot_department"
                        className="form-control"
                        id="compName"
                        placeholder=""
                      />
                    </div> */}
                    </div>
                  )}
                  {/* <div className="col-md-3"> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Iot Asset
                      </label>
                      <input
                        value={inpval.iot_asset}
                        onChange={(e) => setiot_asset(e.target.value)}
                        type="text"
                        name="iot_asset"
                        className="form-control"
                        id="contactNo"
                        placeholder=""
                      />
                    </div> */}
                  {/* </div> */}

                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Latitude
                      </label>
                      <input
                        type="number"
                        name="latitude"
                        value={transformerFormik.values.latitude}
                        className="form-control"
                        id="compName"
                        onBlur={transformerFormik.handleBlur}
                        onChange={transformerFormik.handleChange}
                        placeholder=""
                      />
                      {transformerFormik.errors.latitude &&
                      transformerFormik.touched.latitude ? (
                        <span style={{ color: "red" }}>
                          {transformerFormik.errors.latitude}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Longitude
                      </label>
                      <input
                        type="number"
                        name="longitude"
                        value={transformerFormik.values.longitude}
                        className="form-control"
                        id="compName"
                        onBlur={transformerFormik.handleBlur}
                        onChange={transformerFormik.handleChange}
                        placeholder=""
                      />
                      {transformerFormik.errors.longitude &&
                      transformerFormik.touched.longitude ? (
                        <span style={{ color: "red" }}>
                          {transformerFormik.errors.longitude}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="contactNo"
                        className="col-form-label required-field"
                      >
                        Data Frequency Min
                      </label>
                      <input
                        type="number"
                        name="data_freq_min"
                        value={transformerFormik.values.data_freq_min}
                        className="form-control"
                        id="compName"
                        onBlur={transformerFormik.handleBlur}
                        onChange={transformerFormik.handleChange}
                        placeholder=""
                      />
                      {transformerFormik.errors.data_freq_min &&
                      transformerFormik.touched.data_freq_min ? (
                        <span style={{ color: "red" }}>
                          {transformerFormik.errors.data_freq_min}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {iot_solution !== "AQIQ" &&
                    iot_solution !== "UPS" &&
                    iot_solution !== "HVAC" &&
                    iot_solution !== "EMS" &&
                    iot_solution !== "Motor" &&
                    iot_solution !== "Other" && iot_solution !== "SELCO" && iot_solution !== "Solar" &&(
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="contactNo" className="col-form-label">
                            Total Taps
                          </label>
                          <input
                            value={inpval.taps}
                            onChange={(e) => setTaps(e.target.value)}
                            type="number"
                            name="total_fields"
                            className="form-control"
                            id="contactNo"
                            placeholder=""
                          />
                        </div>
                      </div>
                    )}
                  {iot_solution !== "AQIQ" && iot_solution !== "Other" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="contactNo" className="col-form-label">
                          Calculation
                        </label>
                        <td>
                          <a
                          // href={row.id} onClick={() => getdata(row.id)}
                          >
                            <div className="switch">
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  name="status"
                                  value={status ? "enable" : "disable"}
                                  onChange={(e) => set1Status(e.target.value)}
                                  defaultChecked={
                                    inpval.email_service === "disable"
                                  }
                                />

                                <span>
                                  <span
                                    id="enable"
                                    onClick={(e) => setcalculation("enable")}
                                    data-toggle="modal"
                                    // data-target="#enablemailservice"
                                  >
                                    Enable
                                  </span>
                                  <span
                                    id="disable"
                                    onClick={(e) => setcalculation("disable")}
                                    data-toggle="modal"
                                    // data-target="#disableemailservice"
                                  >
                                    Disable
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                          </a>
                        </td>
                      </div>
                    </div>
                  )}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Mqtt
                      </label>
                      <td>
                        <a
                        // href={row.id} onClick={() => getdata(row.id)}
                        >
                          <div className="switch">
                            <label className="switch-light">
                              <input
                                type="checkbox"
                                name="status"
                                value={status ? "Yes" : "No"}
                                onChange={(e) => set1Status(e.target.value)}
                                defaultChecked={inpval.mqtt !== "Yes"}
                              />

                              <span>
                                <span
                                  id="enable"
                                  onClick={(e) => {
                                    setmqtt("Yes");
                                    setIsRequired(true);
                                  }}
                                  data-toggle="modal"
                                  // data-target="#enablemailservice"
                                >
                                  Yes
                                </span>
                                <span
                                  id="disable"
                                  onClick={(e) => {
                                    setmqtt("No");
                                    setIsRequired(false);
                                  }}
                                  data-toggle="modal"
                                  // data-target="#disableemailservice"
                                >
                                  No
                                </span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div>
                        </a>
                      </td>
                    </div>
                  </div>
                  {mqtt === "Yes" && mqtt !== "No" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label required-field"
                        >
                          Broker Url
                        </label>
                        <div>
                          <select
                            id="ak"
                            name="broker_url"
                            value={transformerFormik.values.broker_url}
                            onBlur={transformerFormik.handleBlur}
                            onChange={transformerFormik.handleChange}
                            className="js-states form-control "
                            class="form-select"
                          >
                            <option disabled selected="true">
                              {transformerFormik.values.broker_url}
                            </option>
                            {mqttbrokerData &&
                              mqttbrokerData.map((am, index) => (
                                <option key={index} value={am.broker_url}>
                                  {am.broker_url}
                                </option>
                              ))}
                          </select>
                          {transformerFormik.errors.broker_url &&
                          transformerFormik.touched.broker_url ? (
                            <span style={{ color: "red" }}>
                              {transformerFormik.errors.broker_url}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  {mqtt === "Yes" && mqtt !== "No" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="compName"
                          className="col-form-label required-field"
                        >
                          Topic
                        </label>
                        <div>
                          <input
                            id="ak"
                            name="topic"
                            value={transformerFormik.values.topic}
                            onBlur={transformerFormik.handleBlur}
                            onChange={transformerFormik.handleChange}
                            className="form-control "
                          />
                          {transformerFormik.errors.topic &&
                          transformerFormik.touched.topic ? (
                            <span style={{ color: "red" }}>
                              {transformerFormik.errors.topic}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="col-md-3"> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Total Batteries
                      </label>
                      <span data-toggle="modal" data-target="#addbattery">
                        <input
                          // value={batteryfield}
                          onChange={(e) => setbatteryfield(batteryfield)}
                          type="text"
                          name="total_batteries"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                  {/* <div className="form-group">
                      <label htmlFor="contactNo" className="col-form-label">
                        Calculated Fields
                      </label>
                      <span data-toggle="modal" data-target="#calculatedfields">
                        <input
                          // value={calulated_fields}
                          onChange={(e) =>
                            setcalculated_fields(calculated_fields)
                          }
                          type="text"
                          name="calulated_fields"
                          className="form-control"
                          id="contactNo"
                          placeholder=""
                        />
                      </span>
                    </div> */}
                  {/* </div> */}
                </div>

                <div className="text-center mt20 mb10">
                  <Link exact to={"/iot_sensor_masters"}>
                    <button
                      type="button"
                      className="btn btn-secondary mr10"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                  {iot_solution !== "Transformer" &&
                    iot_solution !== "UPS" &&
                    iot_solution !== "EMS" &&
                    iot_solution !== "HVAC" &&
                    iot_solution !== "AQIQ" &&
                    iot_solution !== "Motor" &&
                    iot_solution !== "Other" &&
                    iot_solution !== "SELCO" && iot_solution !== "Solar" && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={transformerFormik.handleSubmit}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    )}
                  {iot_solution === "Transformer" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={transformerFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {iot_solution === "AQIQ" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={AQIQFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {iot_solution === "UPS" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={UPSFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}{" "}
                  {iot_solution === "HVAC" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={HVACFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        HVACFormik.values.asset_type &&
                        HVACFormik.values.sub_asset &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {iot_solution === "EMS" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={EMSFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        HVACFormik.values.sub_asset &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {iot_solution === "Other" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={OtherFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        HVACFormik.values.sub_asset &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {iot_solution === "Motor" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={MotorFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                  {(iot_solution === "SELCO" ||iot_solution === "Solar")&&(
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={SELCOFormik.handleSubmit}
                      data-dismiss={
                        transformerFormik.values.device_uuid &&
                        transformerFormik.values.sensor_uuid &&
                        transformerFormik.values.subdomain &&
                        transformerFormik.values.latitude &&
                        transformerFormik.values.longitude &&
                        transformerFormik.values.data_freq_min &&
                        transformerFormik.values.company_name &&
                        transformerFormik.values.subaccount_name &&
                        iot_solution &&
                        transformerFormik.values.asset_name &&
                        transformerFormik.values.location &&
                        transformerFormik.values.department_name
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  )}
                 
                </div>
              </form>
            </div>

           
            <div
              className="modal fade"
              id="addnameplate"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addnameplate"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Nameplate
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                
                                  <div
                                    className="row"
                                  
                                  >
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Hv Connection
                                        </label>
                                        <input
                                          type="text"
                                         
                                          value={hv_connection}
                                          onChange={(e) =>
                                            sethv_connection(e.target.value)
                                          }
                                          name="hv_connection"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Lv Connection
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlv_connection(e.target.value)
                                          }
                                          value={lv_connection}
                                          name="lv_connection"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Lv Rated Current
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlv_rated_current(e.target.value)
                                          }
                                          value={lv_rated_current}
                                          name="lv_rated_current"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Rating Va
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setRating_va(e.target.value)
                                          }
                                          value={Rating_va}
                                          name="Rating_va"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Rated Copper Loss Kw
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setrated_copper_loss_kw(
                                              e.target.value
                                            )
                                          }
                                          value={rated_copper_loss_kw}
                                          name="rated_copper_loss_kw"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          No Load Loss Kw
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setno_load_loss_kw(e.target.value)
                                          }
                                          value={no_load_loss_kw}
                                          name="no_load_loss_kw"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          full load Loss Kw
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setfull_load_loss_kw(e.target.value)
                                          }
                                          value={full_load_loss_kw}
                                          name="full_load_loss_kw"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Rated Top Oil Temp
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setrated_top_oil_temp(
                                              e.target.value
                                            )
                                          }
                                          value={rated_top_oil_temp}
                                          name="rated_top_oil_temp"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Rated Hotspot
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setrated_hotspot(e.target.value)
                                          }
                                          value={rated_hotspot}
                                          name="rated_hotspot"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Max Oil Temp Rise Above Amb Temp
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setmax_oil_temp_rise_above_amb_temp(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            max_oil_temp_rise_above_amb_temp
                                          }
                                          name="max_oil_temp_rise_above_amb_temp"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Max Wind Temp Rise Above Amb Temp
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setmax_wind_temp_rise_above_amb_temp(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            max_wind_temp_rise_above_amb_temp
                                          }
                                          name="max_wind_temp_rise_above_amb_temp"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Primary Side
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprimary_side(e.target.value)
                                          }
                                          value={primary_side}
                                          name="primary_side"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Secondary Side
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setsecondary_side(e.target.value)
                                          }
                                          value={secondary_side}
                                          name="secondary_side"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Tap Position
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            settap_position(e.target.value)
                                          }
                                          value={tap_position}
                                          name="tap_position"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Winding Temp At Winding Resistance
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setwinding_temp_at_winding_resistance(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            winding_temp_at_winding_resistance
                                          }
                                          name="winding_temp_at_winding_resistance"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          winding_material
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setwinding_material(e.target.value)
                                          }
                                          value={winding_material}
                                          name="winding_material"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Phase
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setphase(e.target.value)
                                          }
                                          value={phase}
                                          name="phase"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Lv1 Connection
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlv1_connection(e.target.value)
                                          }
                                          value={lv1_connection}
                                          name="lv1_connection"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Amb Temp
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setamb_temp(e.target.value)
                                          }
                                          value={amb_temp}
                                          name="amb_temp"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Lv2 Connection
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlv2_connection(e.target.value)
                                          }
                                          value={lv2_connection}
                                          name="lv2_connection"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Last Standard Life Hours
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlast_standard_life_hours(
                                              e.target.value
                                            )
                                          }
                                          value={last_standard_life_hours}
                                          name="last_standard_life_hours"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Last Actual Life Hours
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlast_actual_life_hours(
                                              e.target.value
                                            )
                                          }
                                          value={last_actual_life_hours}
                                          name="last_actual_life_hours"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Eddy Loss Per Unit
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            seteddy_loss_per_unit(
                                              e.target.value
                                            )
                                          }
                                          value={eddy_loss_per_unit}
                                          name="eddy_loss_per_unit"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Winding Constant
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setwinding_constant(e.target.value)
                                          }
                                          value={winding_constant}
                                          name="winding_constant"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Last Hotspot
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlast_hotspot(e.target.value)
                                          }
                                          value={last_hotspot}
                                          name="last_hotspot"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Designed Efficiency
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setdesigned_efficiency(
                                              e.target.value
                                            )
                                          }
                                          value={designed_efficiency}
                                          name="designed_efficiency"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Energy Rate Rs
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setenergy_rate_rs(e.target.value)
                                          }
                                          value={energy_rate_rs}
                                          name="energy_rate_rs"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Current Unbalance Limit
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setcurrent_unbalance_limit(
                                              e.target.value
                                            )
                                          }
                                          value={current_unbalance_limit}
                                          name="current_unbalance_limit"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Voltage Unbalance Limit
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setvoltage_unbalance_limit(
                                              e.target.value
                                            )
                                          }
                                          value={voltage_unbalance_limit}
                                          name="voltage_unbalance_limit"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Designed Load Percent
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setdesigned_load_percent(
                                              e.target.value
                                            )
                                          }
                                          value={designed_load_percent}
                                          name="designed_load_percent"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Load Variation
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setload_variation(e.target.value)
                                          }
                                          value={load_variation}
                                          name="load_variation"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Active Energy Lv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_active_energy_lv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_active_energy_lv
                                          }
                                          name="prev_cumulative_active_energy_lv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Reactive Energy Lv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_reactive_energy_lv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_reactive_energy_lv
                                          }
                                          name="prev_cumulative_reactive_energy_lv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Apparent Energy Lv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_apparent_energy_lv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_apparent_energy_lv
                                          }
                                          name="prev_cumulative_apparent_energy_lv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Active Energy Lv1
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_active_energy_lv1(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_active_energy_lv1
                                          }
                                          name="prev_cumulative_active_energy_lv1"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Reactive Energy Lv1
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_reactive_energy_lv1(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_reactive_energy_lv1
                                          }
                                          name="prev_cumulative_reactive_energy_lv1"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Apparent Energy Lv1
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_apparent_energy_lv1(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_apparent_energy_lv1
                                          }
                                          name="prev_cumulative_apparent_energy_lv1"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Active Energy Lv2
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_active_energy_lv2(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_active_energy_lv2
                                          }
                                          name="prev_cumulative_active_energy_lv2"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Reactive Energy Lv2
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_reactive_energy_lv2(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_reactive_energy_lv2
                                          }
                                          name="prev_cumulative_reactive_energy_lv2"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Apparent Energy Lv2
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_apparent_energy_lv2(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_apparent_energy_lv2
                                          }
                                          name="prev_cumulative_apparent_energy_lv2"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Active Energy Hv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_active_energy_hv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_active_energy_hv
                                          }
                                          name="prev_cumulative_active_energy_hv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Reactive Energy Hv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_reactive_energy_hv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_reactive_energy_hv
                                          }
                                          name="prev_cumulative_reactive_energy_hv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Prev Cumulative Apparent Energy Hv
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setprev_cumulative_apparent_energy_hv(
                                              e.target.value
                                            )
                                          }
                                          value={
                                            prev_cumulative_apparent_energy_hv
                                          }
                                          name="prev_cumulative_apparent_energy_hv"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Lv Cum Energy
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setlv_cum_energy(e.target.value)
                                          }
                                          value={lv_cum_energy}
                                          name="lv_cum_energy"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Hv Cum Energy
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            sethv_cum_energy(e.target.value)
                                          }
                                          value={hv_cum_energy}
                                          name="hv_cum_energy"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Cooling Type
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setcooling_type(e.target.value)
                                          }
                                          value={cooling_type}
                                          name="cooling_type"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Oil
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setoil(e.target.value)
                                          }
                                          value={oil}
                                          name="oil"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="contactNo"
                                          className="col-form-label"
                                        >
                                          Voltage Ratio
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setvoltage_ratio(e.target.value)
                                          }
                                          value={voltage_ratio}
                                          name="voltage_ratio"
                                          className="form-control"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* ); */}
                                  {/* })} */}
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) =>
                            setnameplate({
                              hv_connection,
                              lv_connection,
                              lv_rated_current,
                              Rating_va,
                              rated_copper_loss_kw,
                              no_load_loss_kw,
                              full_load_loss_kw,
                              rated_top_oil_temp,
                              rated_hotspot,
                              max_oil_temp_rise_above_amb_temp,
                              max_wind_temp_rise_above_amb_temp,
                              primary_side,
                              secondary_side,
                              tap_position,
                              winding_temp_at_winding_resistance,
                              winding_material,
                              phase,
                              lv1_connection,
                              amb_temp,
                              lv2_connection,
                              last_standard_life_hours,
                              last_actual_life_hours,
                              eddy_loss_per_unit,
                              winding_constant,
                              last_hotspot,
                              designed_efficiency,
                              energy_rate_rs,
                              current_unbalance_limit,
                              voltage_unbalance_limit,
                              designed_load_percent,
                              load_variation,
                              prev_cumulative_active_energy_lv,
                              prev_cumulative_reactive_energy_lv,
                              prev_cumulative_apparent_energy_lv,
                              prev_cumulative_active_energy_lv1,
                              prev_cumulative_reactive_energy_lv1,
                              prev_cumulative_apparent_energy_lv1,
                              prev_cumulative_active_energy_lv2,
                              prev_cumulative_reactive_energy_lv2,
                              prev_cumulative_apparent_energy_lv2,
                              prev_cumulative_active_energy_hv,
                              prev_cumulative_reactive_energy_hv,
                              prev_cumulative_apparent_energy_hv,
                              lv_cum_energy,
                              hv_cum_energy,
                              cooling_type,
                              oil,
                              voltage_ratio,
                            })
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddIotSensorMaster;
