import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../components/auth-context";
import logo from "../images/adminLogo.png";

import { FormSchema } from "./Admin_User_Login_FormSchema";

const Admin_user_login = () => {
  const history = useHistory();

  const auth = useContext(AuthContext);

  const formInitialValues = {
    email: "",
    decrypted_password: "",
  };
  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        console.log(values);
        console.log(process.env.REACT_APP_VARIABLE);
        try {
          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/api/admin_user/signin`,
            {
              method: "POST",
              body: JSON.stringify({
                email: values.email,
                decrypted_password: values.decrypted_password,
              }),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          auth.login(res._id);
          let resJson = await res.json();
          console.log(resJson);
          if (res.status === 201) {
            auth.login(res._id);
            history.push("/accounts");
          } else {
            alert(resJson.message);
          }
        } catch (err) {
          console.log(err);
        }
      },
    });

  return (
    <React.Fragment>
      <div className="loginBg">
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "0" }}>
              <div style={{ margin: "auto" }}>
                <img
                  src={logo}
                  alt="Motware Logo"
                  width="120px"
                  className="brand-image mx-5 my-3"
                />
              </div>
            </div>

            <div className="modal-body">
              <div>
                <h3 className="modal-title w-100 mt-4 col-md-12 text-center">
                  Welcome to Motware
                </h3>
                <h6 className=" modal-title w-100 col-md-12 text-center">
                  Dear user, Login to access the Admin Consol
                </h6>
              </div>
              <form className="">
                <div className="row mt-1">
                  <div className="col-md-12 "></div>
                </div>

                <div className="row">
                  <div className="col-md-12 ">
                    <div className="form-group">
                      <div className="form-group">
                        <h6
                          htmlFor="sector"
                          className="col-form-label mx-5 mt-3 "
                        >
                          User Name/Email*
                        </h6>
                        <input
                          type="email"
                          name="email"
                          className="form-control mx-5"
                          style={{ width: "380px" }}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="email"
                          aria-describedby="email"
                          placeholder="Enter Motwane Email"
                        />
                        {errors.email && touched.email ? (
                          <span className="mx-5" style={{ color: "red" }}>
                            {errors.email}
                          </span>
                        ) : null}{" "}
                      </div>
                      <div className="form-group">
                        <h6
                          htmlFor="sector"
                          className="col-form-label mx-5 mt-3"
                        >
                          Password*
                        </h6>
                        <input
                          type="password"
                          name="decrypted_password"
                          className="form-control mx-5"
                          value={values.decrypted_password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="decrypted_password"
                          style={{ width: "380px" }}
                          placeholder="Enter Password"
                        />
                        {errors.decrypted_password &&
                        touched.decrypted_password ? (
                          <span className="mx-5" style={{ color: "red" }}>
                            {errors.decrypted_password}
                          </span>
                        ) : null}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt20 mb10">
                    <button
                      type="submit"
                      className="btn btn-primary form-control mx-5 mb-5"
                      style={{ width: "380px" }}
                      onClick={handleSubmit}
                      data-dismiss={
                        values.decrypted_password && values.email ? "modal" : ""
                      }
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admin_user_login;
