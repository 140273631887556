import * as Yup from "yup";

export const FormSchema = Yup.object().shape({
  apiName: Yup.string().required("API Name is required"),
  collectionInvolved: Yup.string().required("Collection Involved is required"),
  route: Yup.string().required("Route is required"),
  request: Yup.string()
    .oneOf(["GET", "POST", "PUT", "DELETE"], "Invalid request type")
    .required("Request type is required"),
  response: Yup.string().required("Response format is required"),
  standardResponseTime: Yup.number()
    .required("Standard Response Time is required")
    .positive("Must be a positive number"),
  projectName: Yup.string().required("Project Name is required"),
  typeOfApi: Yup.string()
    .oneOf(["Public", "Private"], "Invalid API type")
    .required("API type is required"),
  categoryType: Yup.string().required("Category Type is required"),
  comments: Yup.string().required("Comments are required"),
  purposeGoal: Yup.string().required("Purpose/Goal is required"),
  changeLog: Yup.string().required("Change Log is required"),
});
