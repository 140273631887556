import * as Yup from "yup";

export const FormSchema = Yup.object({
  broker_url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Invalid Url"
    )
    .required("Please Enter Broker Url"),
  broker_port: Yup.string()
    .max(4, "Broker Port Must be max 4 digit long")
    .required("Please Enter Broker Port"),
  broker_timeout_reconnect: Yup.number()
    .min(0)

    .required("Please Enter Broker Timeout Reconnect"),
  username: Yup.string().required("Please Enter Username"),
  password: Yup.string().required("Please Enter Password"),
});
