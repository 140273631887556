/* eslint-disable no-unused-vars */
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";

const Asset = ({
  account_name,
  subaccount_name,
  location_name,
  department_name,
}) => {
  const [LoadedAccount, setLoadedAccount] = useState({});
  const [LoadedSubaccount, setLoadedSubaccount] = useState({});
  const [LoadedLocation, setLoadedLocation] = useState({});
  const [LoadedDeaprtment, setLoadedDepartment] = useState({});
  const [LoadedIndustry, setLoadedIndustry] = useState({});
  // const [account_name, setAccount_name] = useState("");
  // const [subaccount_name, setSubaccount_name] = useState("");
  const [name, setName] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [contact_no, setContact_no] = useState("");
  const [industry_name, setIndustry_name] = useState("");
  const [Status1, setStatus1] = useState("");
  const [AEdit, setAEdit] = useState({});
  const [AMEdit, setAMEdit] = useState({});
  const [ACEdit, setACEdit] = useState({});
  // const [location_name, setLocation_name] = useState("");
  //const [department_name, setDepartment_name] = useState("");
  const [category, setCategory] = useState(" ");
  const [manufacturer, setManufacturer] = useState(" ");
  const [search, setSearch] = useState("");

  //   console.log("asset");
  //   useEffect(() => {
  //     const getAllAccount = async () => {
  //       const reqdata = await fetch(
  //         `http://localhost:5000/api/account_user/account/all`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       const res = await reqdata.json();
  //       setLoadedAccount(res);
  //       //console.log(AMEdit);
  //     };
  //     getAllAccount();
  //   }, []);

  //   const ACData = LoadedAccount.accounts;
  //   console.log("ACData ", ACData);

  //   useEffect(() => {
  //     const getAllSubaccount = async () => {
  //       const reqdata = await fetch(
  //         `http://localhost:5000/api/setup/all/subaccount`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       const res = await reqdata.json();
  //       setLoadedSubaccount(res);
  //       //console.log(AMEdit);
  //     };
  //     getAllSubaccount();
  //   }, []);

  //   const SCData = LoadedSubaccount.subaccounts;
  //   console.log("SCData ", SCData);

  //   useEffect(() => {
  //     const getAllLocation = async () => {
  //       const reqdata = await fetch(
  //         `http://localhost:5000/api/repo_location/all`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       const res = await reqdata.json();
  //       setLoadedLocation(res);
  //       //console.log(AMEdit);
  //     };
  //     getAllLocation();
  //   }, []);

  //   const LData = LoadedLocation.locations;
  //   console.log("LData ", LData);

  //   useEffect(() => {
  //     const getAllDepartment = async () => {
  //       const reqdata = await fetch(
  //         `http://localhost:5000/api/repo_department/all`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       const res = await reqdata.json();
  //       setLoadedDepartment(res);
  //       //console.log(AMEdit);
  //     };
  //     getAllDepartment();
  //   }, []);

  //   const DData = LoadedDeaprtment.departments;
  //   console.log("DData ", DData);

  //   useEffect(() => {
  //     const getAllIndustry = async () => {
  //       const reqdata = await fetch(
  //         `http://localhost:5000/api/repo_industry/all`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       const res = await reqdata.json();
  //       setLoadedIndustry(res);
  //       //console.log(AMEdit);
  //     };
  //     getAllIndustry();
  //   }, []);

  //   const IData = LoadedIndustry.industries;
  //   console.log("IData ", IData);

  //   const handleSubaccountSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       let res = await fetch("http://localhost:5000/api/setup/subaccount", {
  //         method: "POST",
  //         body: JSON.stringify({
  //           account_name: account_name,
  //           name: name,
  //           company_name: company_name,
  //           industry_name: industry_name,
  //           address: address,
  //           email: email,
  //           contact_no: contact_no,
  //         }),
  //         headers: {
  //           Accept: "application/json",
  //           "Content-type": "application/json",
  //         },
  //       });
  //       let resJson = await res.json();
  //       console.log(resJson);
  //       if (res.status === 201) {
  //         setAccount_name("");
  //         setName("");
  //         setCompany_name("");
  //         setIndustry_name("Select Industry");
  //         setAddress("");
  //         setEmail("");
  //         setContact_no("");
  //         alert("Subaccount added successfully");
  //       } else {
  //         alert("Some error occured");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   const handleLocationSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       let res = await fetch("http://localhost:5000/api/setup/location", {
  //         method: "POST",
  //         body: JSON.stringify({
  //           account_name: account_name,
  //           name: location_name,
  //           subaccount_name: subaccount_name,
  //         }),
  //         headers: {
  //           Accept: "application/json",
  //           "Content-type": "application/json",
  //         },
  //       });
  //       let resJson = await res.json();
  //       console.log(resJson);
  //       if (res.status === 201) {
  //         setAccount_name("");
  //         setLocation_name("");
  //         setSubaccount_name("");

  //         alert("Location added successfully");
  //       } else {
  //         alert("Some error occured");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   const handleDepartmentSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       let res = await fetch("http://localhost:5000/api/setup/department", {
  //         method: "POST",
  //         body: JSON.stringify({
  //           account_name: account_name,
  //           name: department_name,
  //           location_name: location_name,
  //         }),
  //         headers: {
  //           Accept: "application/json",
  //           "Content-type": "application/json",
  //         },
  //       });
  //       let resJson = await res.json();
  //       console.log(resJson);
  //       if (res.status === 201) {
  //         setAccount_name("");
  //         setLocation_name("");
  //         setSubaccount_name("");
  //         setDepartment_name("");

  //         alert("Department added successfully");
  //       } else {
  //         alert("Some error occured");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h1 className="pageTitle">Add New Asset</h1>
            <div className="row align-items-center mb20">
              <div className="col-md-12 col-lg-12 col-xl-12"></div>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label for="" className="col-form-label col-md-4">
                        Account Name:
                      </label>
                      <div className="readOnly col-md-8">
                        <p className="">
                          <b>{account_name}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label for="" className="col-form-label col-md-4">
                        Subaccount Name:
                      </label>
                      <div className="readOnly col-md-8">
                        <p className="">
                          <b>{subaccount_name}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label for="" className="col-form-label col-md-4">
                        Location Name:
                      </label>
                      <div className="readOnly col-md-8">
                        <p className="">
                          <b>{location_name}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label for="" className="col-form-label col-md-4">
                        Department Name:
                      </label>
                      <div className="readOnly col-md-8">
                        <p className="">
                          <b>{department_name}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt20 mb10">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* <div
            className="modal fade"
            id="addsubaccount"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addsubaccount"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img
                    src="assets/images/logo v4.png"
                    alt="oil test Logo"
                    width="40px"
                    class="brand-image"
                  />
                  <h5 classname="modal-title w-100 text-center">
                    Add New Subaccount
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Account Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{account_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="sector" class="col-form-label">
                            Industry
                          </label>
                          <select
                            id="ak"
                            onChange={(e) => setIndustry_name(e.target.value)}
                            className="js-states form-control "
                          >
                            <option disabled selected="true">
                              Select Industry
                            </option>

                            {IData &&
                              IData.map((am, index) => (
                                <option key={index} value={am.name}>
                                  {am.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label for="compName" className="col-form-label">
                            Name
                          </label>
                          <input
                            type="text"
                            value={name}
                            className="form-control"
                            id="locName"
                            onChange={(e) => setName(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label for="compName" className="col-form-label">
                            Company Name
                          </label>
                          <input
                            type="text"
                            value={company_name}
                            className="form-control"
                            id="locName"
                            onChange={(e) => setCompany_name(e.target.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <label for="email" className="col-form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            value={email}
                            className="form-control"
                            id="locName"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label for="contactNo" className="col-form-label">
                            Contact No
                          </label>
                          <input
                            type="text"
                            value={contact_no}
                            className="form-control"
                            id="contactNo"
                            onChange={(e) => setContact_no(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label for="email" className="col-form-label">
                            Address
                          </label>
                          <textarea
                            value={address}
                            className="form-control"
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt20 mb10">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubaccountSubmit}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="addlocation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addlocation"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img
                    src="assets/images/logo v4.png"
                    alt="oil test Logo"
                    width="40px"
                    class="brand-image"
                  />
                  <h5 classname="modal-title w-100 text-center">
                    Add New Location
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Account Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{account_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Subaccount Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{subaccount_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="compName" className="col-form-label">
                            Location Name*
                          </label>
                          <input
                            type="text"
                            value={location_name}
                            className="form-control"
                            id="locName"
                            onChange={(e) => setLocation_name(e.target.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt20 mb10">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleLocationSubmit}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="adddepartment"
            tabindex="-1"
            role="dialog"
            aria-labelledby="adddepartment"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img
                    src="assets/images/logo v4.png"
                    alt="oil test Logo"
                    width="40px"
                    class="brand-image"
                  />
                  <h5 classname="modal-title w-100 text-center">
                    Add New Department
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Account Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{account_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Subaccount Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{subaccount_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label for="" className="col-form-label col-md-4">
                            Location Name:
                          </label>
                          <div className="readOnly col-md-8">
                            <p className="">
                              <b>{location_name}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="compName" className="col-form-label">
                            Department Name*
                          </label>
                          <input
                            type="text"
                            value={department_name}
                            className="form-control"
                            id="locName"
                            onChange={(e) => setDepartment_name(e.target.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt20 mb10">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDepartmentSubmit}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Asset;
