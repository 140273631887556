import * as Yup from "yup";

export const LocationFormSchema = Yup.object({
  // account_name: Yup.string().required("Please Select Account Name"),
  name: Yup.string().required("Please Enter Location Name"),
  contact_person_name: Yup.string().required(
    "Please Enter Location Contact Person Name"
  ),
  contact_no: Yup.string().required("Please Enter Mobile Number"),
  email: Yup.string().email().required("Please Enter Email"),
  address: Yup.string().required("Please Enter Location Address"),
  latitude: Yup.string().required("Please Enter Latitude"),
  longitude: Yup.string().required("Please Enter Longitude"),
});
