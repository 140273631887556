import * as Yup from "yup";

export const AssetFormSchema = Yup.object({
  asset_name: Yup.string().required("Please Enter Asset Name"),
  critical: Yup.string().required("Please Select Critical"),
  sr_no: Yup.string().required("Please Enter Sr No."),
  rating: Yup.string().required("Please Enter Rating"),
  manufacturing_yr: Yup.string().required("Please Enter Manufacturer Year"),
  rated_current: Yup.string().required("Please Enter Rated Current"),
  latitude: Yup.string().required("Please Enter Latitude"),
  longitude: Yup.string().required("Please Enter Longitute"),
  category: Yup.string().required("Please Select Category Name"),
  manufacturer: Yup.string().required("Please Select Manufacturer Name"),
});
