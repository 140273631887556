import * as Yup from "yup";

export const FormSchema = Yup.object({
  full_name: Yup.string().required("Please Enter Full Name"),
  email: Yup.string().required("Please Enter Email"),
  decrypted_password: Yup.string()
    .min(8, "Password must be at least 8 character long")
    .required("Please Enter Password"),
  department: Yup.string().required("Please Enter Department"),
  role: Yup.string().required("Please Enter Role"),
});
