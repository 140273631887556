
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";

const Repo_asset_type = () => {
  const { error1, setError1 } = useState({});

  const [LoadedSensorType, setLoadedSensorType] = useState([]);
  const [name, setName] = useState("");

  const [STEdit, setSTEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_sensor_type/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();
      setLoadedSensorType(json.sensorTypes);
      setTotal(json.sensorTypes);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setLoadedSensorType(result);
  }, [search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_sensor_type`,
        {
          method: "POST",
          body: JSON.stringify({
            name: name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      let resJson = await res.json();

      if (res.status === 201) {
        setName("");
        alert("Sensor type added successfully");
        fetchData();
      } else {
        alert(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editSTid = async (_id) => {
    let stid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_sensor_type/get/${stid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setSTEdit(res.sensorTypes);
  };

  function handleEdit(e) {
    setSTEdit({ ...STEdit, [e.target.name]: e.target.value });
  }

  const handleSTUpdate = async (e) => {
    e.preventDefault();
    let stid = STEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_sensor_type/${stid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(STEdit),
      }
    );
    let resJson = await response.json();
    if (response.status === 200) {
      alert("Sensor Type updated successfully");
      fetchData();
    } else {
      alert(resJson.message);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    let stid = STEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_sensor_type/${stid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );
    let resJson = await response.json();
    if (response.status === 200) {
      alert("Sensor Type updated successfully");
      fetchData();
    } else {
      alert(resJson.message);
    }
  };
  const [status, setStatus] = useState(STEdit.status);
  const [Status, set1Status] = useState({});
  const columns = [
    {
      name: "Names",
      selector: (row) => <div> {row.name}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row._id} onClick={() => editSTid(row._id)}>
          <div className="switch">
            <label className="switch-light">
              <input
                type="checkbox"
                name="status"
                value={Status === true ? "enable" : "disable"}
                onChange={(e) => set1Status(e.target.value)}
                defaultChecked={row.status === "disable"}
              />

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editSTid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editreposensortype"
          ></i>
        </a>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <h1 className="pageTitle">Repository Sensor Type</h1>
                <div className="row align-items-center mb20">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <a
                          href="addReposensortype.html"
                          type="button"
                          className="btn btn-c-outline-primary"
                          data-toggle="modal"
                          data-target="#addreposensortype"
                        >
                          {" "}
                          + Add Repository Sensor Type
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div
                      className="col-sm-12 row-wise"
                      style={{
                        display: "block;",
                        borderSpacing: "5px",
                        background: "#fff",
                        borderRadius: "25px",
                      }}
                    >
                      <DataTable
                        columns={columns}
                        data={LoadedSensorType}
                        noDataComponent=""
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      {LoadedSensorType.length === 0 && (
                        <div class="loader"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addreposensortype"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addreposensortype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Add Repository Sensor Type
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Sensor Type Name
                            </label>
                            <input
                              type="text"
                              value={name}
                              className="form-control"
                              id="locName"
                              onChange={(e) => setName(e.target.value)}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editreposensortype"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensortype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Edit Repository Sensor Type
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Sensor Type Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={STEdit.name}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={(e) => handleEdit(e)}
                            />
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSTUpdate}
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Repository Sensor Type?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={handleStatusUpdate}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Repository Sensor Type?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={handleStatusUpdate}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_asset_type;
