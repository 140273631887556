/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Formik, useFormik, Field } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function AddAccount() {
  // phone number

  const [inpval, setINP] = useState({});

  const [Status, set1Status] = useState({});

  const [addOilCustomer, setaddOilCustomer] = useState([{}]);
  // const [oil_customer, setoil_customer] = useState([{}]);

  ///// addOilCustomer ///////
  const addInputField = (e) => {
    e.preventDefault();
    setaddOilCustomer([...addOilCustomer, {}]);
  };

  const removeaddOilCustomer = (e, index) => {
    e.preventDefault();
    const rows = [...addOilCustomer];
    rows.splice(index, 1);
    setaddOilCustomer(rows);
  };

  const handleChanged = (index, e) => {
    // console.log("added");
    const { value } = e.target;
    const list = [...addOilCustomer];
    list[index] = value;
    setaddOilCustomer(list);
  };

  const [test_service_custom, settest_service_custom] = useState([{}]);
  // const [testing_service_customer, settesting_service_customer] = useState([
  //   {},
  // ]);

  ///// addOilCustomer ///////
  const addtesting_service_customer = (e) => {
    e.preventDefault();
    settest_service_custom([...test_service_custom, {}]);
  };

  const removetesting_service_customer = (e, index) => {
    e.preventDefault();
    const rows = [...test_service_custom];
    rows.splice(index, 1);
    settest_service_custom(rows);
  };

  const handletesting_service_customer = (index, e) => {
    // console.log("added");
    const { value } = e.target;
    const list = [...test_service_custom];
    list[index] = value;
    settest_service_custom(list);
  };

  function handleEdit(e) {
    setINP({ ...inpval, [e.target.name]: e.target.value });
  }

  const [subdomain, setsubdomain] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");

  const [contact_no, setcontact_no] = useState("");
  const handlePhoneChange = (value, country) => {
    setcontact_no(value);
  };

  const [sector, setsector] = useState("");
  const [contact_person_name, setcontact_person_name] = useState("");

  const [contact_person_contact_no, setcontact_person_contact_no] =
    useState("");
  const handlecontact_noChange = (value, country) => {
    setcontact_person_contact_no(value);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [db_name, setdb_name] = useState("");
   const [db_url, setdb_url] = useState("");
  const [logo_url, setlogo_url] = useState("");
  const [landing_url, setlanding_url] = useState("");

  // const [forward_integration, setforward_integration] = useState("enable");
  const [ble_module, setble_module] = useState("enable");
  // const [complaints_module, setcomplaints_module] = useState("enable");
  //const [sensor_module, setsensor_module] = useState("enable");
  const [addon, setaddon] = useState("enable");
  // const [advanced_analysis, setadvanced_analysis] = useState("enable");
  const [asset_breakdown_addon, setasset_breakdown_addon] = useState("enable");
  const [asset_components, setasset_components] = useState("enable");
  const [chiller_device, setchiller_device] = useState("enable");
  const [components, setcomponents] = useState("enable");
  const [conduct_test, setconduct_test] = useState("enable");
  const [custom_report_builder_addon, setcustom_report_builder_addon] =
    useState("enable");
  const [dashboard, setdashboard] = useState("enable");
  const [mdts, setMDTS] = useState("enable");
  const [departmnet_components, setdepartmnet_components] = useState("enable");
  const [download_device_excel, setdownload_device_excel] = useState("enable");
  const [device_sensors, setdevice_sensors] = useState("enable");
  const [email_service, setemail_service] = useState("enable");
  const [ems_device, setems_device] = useState("enable");
  const [location_components, setlocation_components] = useState("enable");
  const [mis_report_analysis, setmis_report_analysis] = useState("enable");
  const [motor_device, setmotor_device] = useState("enable");
  const [pivot_report_analysis, setpivot_report_analysis] = useState("enable");
  const [report_anaysis, setreport_anaysis] = useState("enable");
  const [setup, setsetup] = useState("enable");
  const [sms_service, setsms_service] = useState("enable");
  const [subaccount_components, setsubaccount_components] = useState("enable");
  const [test_repoprt_analysis, settest_repoprt_analysis] = useState("enable");
  const [transformer_device, settransformer_device] = useState("enable");
  const [air, setair] = useState("enable");
  const [download_range, setdownload_range] = useState("enable");
  // const [ems_addon, setems_addon] = useState("enable");
  const [eqipment_components, seteqipment_components] = useState("enable");
  const [reports, setreports] = useState("enable");
  const [template_components, settemplate_components] = useState("enable");
  const [water, setwater] = useState("enable");
  const [iot, setiot] = useState("enable");
  const [email_pack, setemail_pack] = useState("enable");
  const [sms_pack, setsms_pack] = useState("enable");
  const [oiltest_lab, setoiltest_lab] = useState("enable");
  const [oil_admin, setoil_admin] = useState("enable");

  // array

  const [gst_no, setgst_no] = useState("enable");
  const [testing_service, settesting_service] = useState("enable");
  const [testing_service_customer_flag, settesting_service_customer_flag] =
    useState("enable");
  const [testing_service_admin, settesting_service_admin] = useState("enable");

  const [status, setstatus] = useState("enable");
  const [is_address_mandatory, setis_address_mandatory] = useState(true);
  const [device_sensor, setdevice_sensor] = useState("enable");

  const FormSchema = Yup.object({
    subdomain: Yup.string().required("Please Enter subdomain!"),
    company_name: Yup.string().required("Please Enter company name!"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please Enter email!"),
    address: Yup.string().required("Please Enter address!"),
    // contact_no: Yup.string().required("Please Enter contact no!"),
    contact_person_name: Yup.string().required(
      "Please Enter contact person name!"
    ),
    // contact_person_contact_no: Yup.string().required(
    //   "Please Enter contact person contact no!"
    // ),
    db_name: Yup.string().required("Please Enter db name!"),
     db_url: Yup.string().required("Please Enter db url!"),
    download_range: Yup.string().required("Please Enter download range!"),
    // email_pack: Yup.string().required("Please Enter email pack!"),
    email_pack: Yup.number()
      .integer("Please Enter a valid integer value for email pack!")
      .required("Please Enter email pack!"),
    //  sms_pack: Yup.number().required("Please Enter sms pack!"),
    sms_pack: Yup.number()
      .integer("Please Enter a valid integer value for SMS pack!")
      .required("Please Enter SMS pack!"),
  });

  const formInitialValues = {
    subdomain: "",
    company_name: "",
    email: "",
    address: "",
    // contact_no: "",
    contact_person_name: "",
    // contact_person_contact_no: "",
    db_name: "",
     db_url: "",
    download_range: "",
    email_pack: null,
    sms_pack: null,
    landing_url: "",
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        const formData = new FormData();
        formData.append("image", selectedImage);

        formData.append("subdomain", values.subdomain);
        formData.append("company_name", values.company_name);
        formData.append("email", values.email);
        formData.append("address", values.address);
        formData.append("contact_person_name", values.contact_person_name);
        formData.append("contact_person_contact_no", contact_person_contact_no);
        formData.append("db_name", values.db_name);
        formData.append("download_range", values.download_range);
        formData.append("sector", sector);
        formData.append("sms_pack", values.sms_pack);
        formData.append("contact_no", contact_no);
         formData.append("db_url", values.db_url);
        formData.append("email_pack", values.email_pack);
        formData.append("landing_url", values.landing_url);
        formData.append("is_address_mandatory", is_address_mandatory);
        formData.append("ble_module", ble_module);
        formData.append("addon", addon);
        formData.append("asset_breakdown_addon", asset_breakdown_addon);
        formData.append("asset_components", asset_components);
        formData.append("components", components);
        formData.append("departmnet_components", departmnet_components);
        formData.append("device_sensors", device_sensors);
        formData.append("dashboard", dashboard);
        formData.append("mdts", mdts);
        formData.append(
          "custom_report_builder_addon",
          custom_report_builder_addon
        );
        formData.append("conduct_test", conduct_test);
        formData.append("chiller_device", chiller_device);
        formData.append("motor_device", motor_device);
        formData.append("mis_report_analysis", mis_report_analysis);
        formData.append("location_components", location_components);
        formData.append("ems_device", ems_device);
        formData.append("download_device_excel", download_device_excel);
        formData.append("email_service", email_service);
        formData.append("pivot_report_analysis", pivot_report_analysis);
        formData.append("setup", setup);
        formData.append("report_anaysis", report_anaysis);
        formData.append("sms_service", sms_service);
        formData.append("subaccount_components", subaccount_components);
        formData.append("test_repoprt_analysis", test_repoprt_analysis);
        formData.append("transformer_device", transformer_device);
        formData.append("air", air);
        formData.append("eqipment_components", eqipment_components);
        formData.append("reports", reports);
        formData.append("template_components", template_components);
        formData.append("water", water);
        formData.append("iot", iot);
        formData.append("oiltest_lab", oiltest_lab);
        formData.append("oil_admin", oil_admin);
        formData.append("gst_no", gst_no);
        formData.append("oil_customer", addOilCustomer);
        formData.append("testing_service_admin", testing_service_admin);
        formData.append(
          "testing_service_customer_flag",
          testing_service_customer_flag
        );
        formData.append("testing_service", testing_service);
        formData.append("testing_service_customer", test_service_custom);
        await fetch(
          `${process.env.REACT_APP_VARIABLE}/accountsModel/AddAccount`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            setINP(data);
            alert("account successfully added");
            window.location = "/accounts";
          })
          .catch((error) => {
            // handle the error
            alert(
              error.message + ": Subdomain Exist, could not create account."
            );
          });
      },
    });

  return (
    <>
      <div class="content-wrapper">
        {/* <!-- Main content --> */}
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Add Account</h1>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-md-6 col-lg-6 col-xl-6 mb20"></div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="horizontal_tab">
                {/* <!-- Custom Tabs --> */}

                <div class="tab-content">
                  <div class="tab-pane active" id="tab_1">
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="card pr-card mb30">
                          <div class="card-body">
                            <div class="col-md-12">
                              <h6>
                                <b>Account details</b>
                              </h6>
                            </div>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Subdomain:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>Account 1</b></p> --> */}
                                    <input
                                      type="text"
                                      name="subdomain"
                                      value={values.subdomain}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.subdomain && touched.subdomain ? (
                                      <span style={{ color: "red" }}>
                                        {errors.subdomain}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Company Name:
                                  </label>
                                  <div class="col-md-12 col-lg-8 r">
                                    {/* <!-- <p class=""><b>Tata</b></p> --> */}
                                    <input
                                      type="text"
                                      name="company_name"
                                      value={values.company_name}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.company_name &&
                                    touched.company_name ? (
                                      <span style={{ color: "red" }}>
                                        {errors.company_name}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Address
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>Mumbai </b></p> --> */}
                                    <input
                                      type="text"
                                      name="address"
                                      value={values.address}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.address && touched.address ? (
                                      <span style={{ color: "red" }}>
                                        {errors.address}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Email:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                                    <input
                                      type="text"
                                      name="email"
                                      value={values.email}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.email && touched.email ? (
                                      <span style={{ color: "red" }}>
                                        {errors.email}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Db Name:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                                    <input
                                      type="text"
                                      name="db_name"
                                      value={values.db_name}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.db_name && touched.db_name ? (
                                      <span style={{ color: "red" }}>
                                        {errors.db_name}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Contact No.:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8 ">
                                    {/* <!-- <p class=""><b>98123456786</b></p> --> */}
                                    <PhoneInput
                                      country={"in"}
                                      // value={phoneNumber}
                                      name="contact_no"
                                      value={inpval.contact_no}
                                      id="compName"
                                      // onChange={(e) =>
                                      //   setcontact_no(e.target.value)
                                      // }
                                      // onBlur={handleBlur}
                                      // onChange={handleChange}
                                      placeholder=""
                                      onChange={handlePhoneChange}
                                    />

                                    {/* <input
                                      type="number"
                                      name="contact_no"
                                      value={values.contact_no}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    /> */}
                                    {/* {errors.contact_no && touched.contact_no ? (
                                      <span style={{ color: "red" }}>
                                        {errors.contact_no}
                                      </span>
                                    ) : null} */}
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Contact Person:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>adsejr jnkjnjnk</b></p> --> */}
                                    <input
                                      type="text"
                                      name="contact_person_name"
                                      value={values.contact_person_name}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.contact_person_name &&
                                    touched.contact_person_name ? (
                                      <span style={{ color: "red" }}>
                                        {errors.contact_person_name}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Contact Person No.:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>9923456789</b></p> --> */}
                                    <PhoneInput
                                      country={"in"}
                                      // value={phoneNumber}
                                      name="contact_person_contact_no"
                                      value={inpval.contact_person_contact_no}
                                      id="compName"
                                      // onChange={(e) =>
                                      //   setcontact_person_contact_no(e.target.value)
                                      // }
                                      // onBlur={handleBlur}

                                      placeholder=""
                                      onChange={handlecontact_noChange}
                                    />
                                    {/* <input
                                      type="number"
                                      name="contact_person_contact_no"
                                      value={values.contact_person_contact_no}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.contact_person_contact_no &&
                                    touched.contact_person_contact_no ? (
                                      <span style={{ color: "red" }}>
                                        {errors.contact_person_contact_no}
                                      </span>
                                    ) : null} */}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Db Url:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    <input
                                      type="text"
                                      name="db_url"
                                      value={values.db_url}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.db_url && touched.db_url ? (
                                      <span style={{ color: "red" }}>
                                        {errors.db_url}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label"
                                  >
                                    Landing Page:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                                    {/* <input
                                      value={inpval.landing_url}
                                      onChange={(e) =>
                                        setlanding_url(e.target.value)
                                      }
                                      type="text"
                                      class="form-control"
                                      id=""
                                      placeholder=""
                                    /> */}
                                    <select
                                      id="ak"
                                      name="landing_url"
                                      value={values.landing_url}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      className="js-states form-control "
                                    >
                                      <option value="" disabled selected="true">
                                        Select Landing Page
                                      </option>

                                      <option value="Dashboard">
                                        Dashboard
                                      </option>
                                      <option value="Setup">Setup</option>
                                      <option value="Conduct Test">
                                        Conduct Test
                                      </option>
                                      <option value="Test Reports">
                                        Test Reports
                                      </option>
                                      <option value="MIS Reports">
                                        MIS Reports
                                      </option>
                                      <option value="Test Analysis">
                                        Test Analysis
                                      </option>
                                      <option value="Pivot Tables">
                                        Pivot Tables
                                      </option>
                                      <option value="Download">Download</option>
                                      <option value="Transformer">
                                        Transformer
                                      </option>
                                      <option value="Chiller Device">
                                        Chiller Device
                                      </option>
                                      <option value="Motor Device">
                                        Motor Device
                                      </option>
                                      <option value="EMS Device">
                                        EMS Device
                                      </option>
                                      <option value="Subaccounts">
                                        Subaccounts
                                      </option>
                                      <option value="Locations">
                                        Locations
                                      </option>
                                      <option value="Departments">
                                        Departments
                                      </option>
                                      <option value="Assets">Assets</option>
                                      <option value="Asset Breakdowns">
                                        Asset Breakdowns
                                      </option>
                                      <option value="Equipments">
                                        Equipments
                                      </option>
                                      <option value="Templates">
                                        Templates
                                      </option>
                                      <option value="IoT">IoT</option>
                                      <option value="MDTS">MDTS</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Download Range: <br /> (in days)
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>98123456786</b></p> --> */}
                                    <input
                                      type="number"
                                      name="download_range"
                                      value={values.download_range}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.download_range &&
                                    touched.download_range ? (
                                      <span style={{ color: "red" }}>
                                        {errors.download_range}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Email Pack:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>adsejr jnkjnjnk</b></p> --> */}
                                    <input
                                      type="number"
                                      name="email_pack"
                                      value={values.email_pack}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.email_pack && touched.email_pack ? (
                                      <span style={{ color: "red" }}>
                                        {errors.email_pack}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label required-field"
                                  >
                                    Sms Pack:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>9923456789</b></p> --> */}
                                    <input
                                      type="number"
                                      name="sms_pack"
                                      value={values.sms_pack}
                                      className="form-control"
                                      id="compName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder=""
                                    />
                                    {errors.sms_pack && touched.sms_pack ? (
                                      <span style={{ color: "red" }}>
                                        {errors.sms_pack}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label"
                                  >
                                    Sector:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    {/* <!-- <p class=""><b>abc@motware.com</b></p> --> */}
                                    <input
                                      value={inpval.sector}
                                      onChange={(e) =>
                                        setsector(e.target.value)
                                      }
                                      type="text"
                                      class="form-control"
                                      id=""
                                      placeholder=""
                                    />
                                  </div>
                                </div>
                                <div class="row form-group">
                                  <label
                                    for=""
                                    class="col-md-12 col-lg-4 col-form-label"
                                  >
                                    Upload Logo:
                                  </label>
                                  <div class="readOnly col-md-12 col-lg-8">
                                    <input
                                      type="file"
                                      onChange={handleImageUpload}
                                    />

                                    {/*<input
                                      value={inpval.logo_url}
                                      onChange={(e) =>
                                        setlogo_url(e.target.value)
                                      }
                                      type="text"
                                      class="form-control"
                                      id=""
                                      placeholder=""
                                    />*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="switchListWpr">
                      <div class="col-lg-12 col-md-12">
                        <div class="row">
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Email Service</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setemail_service("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setemail_service("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Sms Service</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setsms_service("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setsms_service("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Is Address Mandatory</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? true : false}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setis_address_mandatory(true)
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          True
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setis_address_mandatory(false)
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          False
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Dashboard</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setdashboard("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setdashboard("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Setup</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setsetup("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setsetup("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Conduct Test</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setconduct_test("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setconduct_test("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setcomponents("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setcomponents("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Subaccount Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setsubaccount_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setsubaccount_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Location Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setlocation_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setlocation_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Department Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setdepartmnet_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setdepartmnet_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Asset Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setasset_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setasset_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Eqiupment Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            seteqipment_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            seteqipment_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Template Components</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settemplate_components("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settemplate_components("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Reports</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setreports("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setreports("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Report Analysis</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setreport_anaysis("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setreport_anaysis("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Test Report Analysis</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settest_repoprt_analysis("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settest_repoprt_analysis("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Mis Report Analysis</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setmis_report_analysis("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setmis_report_analysis("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Pivot Report Analysis</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setpivot_report_analysis("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setpivot_report_analysis("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Addon</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setaddon("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setaddon("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Asset Breakdown Addon</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setasset_breakdown_addon("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setasset_breakdown_addon("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Custom Report Builder Addon</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setcustom_report_builder_addon(
                                              "enable"
                                            )
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setcustom_report_builder_addon(
                                              "disable"
                                            )
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Ble Module</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setble_module("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setble_module("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Iot</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setiot("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setiot("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Device Sensors</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setdevice_sensors("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setdevice_sensors("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Device Sensor</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setdevice_sensor("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setdevice_sensor("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Transformer Device</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settransformer_device("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settransformer_device("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Motor Device</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setmotor_device("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setmotor_device("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Chiller Device</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setchiller_device("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setchiller_device("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Ems Device</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setems_device("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setems_device("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Air</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setair("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setair("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Water</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setwater("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setwater("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>MDTS</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setMDTS("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setMDTS("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Download Device Excel</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setdownload_device_excel("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setstatus("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Oiltest Lab</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setoiltest_lab("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setoiltest_lab("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Oil Admin</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setoil_admin("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setoil_admin("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body row addCustWpr">
                                <div class="col-md-4 col-sm-12">
                                  <p>Oil Customer</p>
                                </div>
                                <div
                                  class="col-md-8 col-sm-12"
                                  data-toggle="modal"
                                  data-target="#addoilcustomer"
                                >
                                  <input
                                    // value={addOilCustomer}
                                    onChange={(e) =>
                                      setaddOilCustomer(addOilCustomer)
                                    }
                                    type="text"
                                    name="total_batteries"
                                    className="form-control"
                                    id="contactNo"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Testing Service Customer Flag</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settesting_service_customer_flag(
                                              "enable"
                                            )
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settesting_service_customer_flag(
                                              "disable"
                                            )
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Testing Service Admin</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settesting_service_admin("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settesting_service_admin("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body row addCustWpr">
                                <div class="col-md-4 col-sm-12">
                                  <p>Testing Service Customer</p>
                                </div>
                                <div
                                  class="col-md-8 col-sm-12"
                                  data-toggle="modal"
                                  data-target="#testing_service_customer"
                                >
                                  <input
                                    // value={test_service_custom}
                                    onChange={(e) =>
                                      settest_service_custom(
                                        test_service_custom
                                      )
                                    }
                                    type="text"
                                    name="total_batteries"
                                    className="form-control"
                                    id="contactNo"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Testing Service</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            settesting_service("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            settesting_service("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Forwared Integration</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setforward_integration("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setforward_integration("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}

                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Complaints Module</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setcomplaints_module("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setcomplaints_module("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}
                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Sensor Module</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setsensor_module("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setsensor_module("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}

                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Advanced Analysis</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setadvanced_analysis("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setadvanced_analysis("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}

                          {/* //// */}

                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Ems Addon</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) =>
                                            setems_addon("enable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) =>
                                            setems_addon("disable")
                                          }
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> */}

                          {/* <div class="col-md-4 col-sm-12">
                            <div class="card pr-card">
                              <div class="card-body switchList">
                                <p>Gst No</p>
                                <a>
                                  <div className="switch">
                                    <label className="switch-light">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        value={Status ? "enable" : "disable"}
                                        onChange={(e) =>
                                          set1Status(e.target.value)
                                        }
                                        defaultChecked={
                                          inpval.device_status === "disable"
                                        }
                                      />

                                      <span>
                                        <span
                                          id="enable"
                                          onClick={(e) => setgst_no("enable")}
                                          data-toggle="modal"
                                          // data-target="#enableconfirmation"
                                        >
                                          Enable
                                        </span>
                                        <span
                                          id="disable"
                                          onClick={(e) => setgst_no("disable")}
                                          data-toggle="modal"
                                          // data-target="#disableconfirmation"
                                        >
                                          Disable
                                        </span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt20">
                    <Link exact to={"/accounts"}>
                      <button
                        type="button"
                        className="btn btn-secondary mr10 mb10"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary mb10"
                      onClick={handleSubmit}
                      data-dismiss={
                        values.subdomain &&
                        values.company_name &&
                        values.email &&
                        values.address &&
                        // values.contact_no &&
                        values.contact_person_name &&
                        // values.contact_person_contact_no &&
                        values.db_name &&
                         values.db_url &&
                        values.download_range &&
                        values.email_pack &&
                        values.sms_pack
                          ? "modal"
                          : ""
                      }
                    >
                      Submit
                    </button>
                  </div>

                  {/* <!-- /.tab-pane --> */}
                </div>
                {/* <!-- ./tab-content --> */}
              </div>
              {/* <!-- /.col-12 --> */}
            </div>

            {/* popup */}
            {/* oil customer */}
            <div
              className="modal fade"
              id="addoilcustomer"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addoilcustomer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Oil Customer
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      {/* <div className="row">
                        <div className="col-md-12"> */}
                      <div className="form-group">
                        {/* <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label> */}
                        {/* <div className="container"> */}
                        {/* <div className="row"> */}
                        <div>
                          {addOilCustomer.map((data, index) => {
                            const { total_batteries } = data;

                            return (
                              <div className="row" key={index}>
                                <div className="col-md-10">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      value={total_batteries}
                                      onChange={(e) => handleChanged(index, e)}
                                      name="total_batteries"
                                      className="form-control"
                                      placeholder=""
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  {addOilCustomer.length !== 1 ? (
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={removeaddOilCustomer}
                                    >
                                      x
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          <div className="row">
                            <div className="col-sm-12">
                              <button
                                className="btn btn-outline-primary "
                                onClick={addInputField}
                              >
                                Add New
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="col-sm-4"></div> */}
                        {/* </div> */}
                      </div>
                      {/* </div>
                      </div> */}
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) => setaddOilCustomer(addOilCustomer)}
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* testing service */}
            <div
              className="modal fade"
              id="testing_service_customer"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="testing_service_customer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Testing Service Customer
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      {/* <div className="row">
                        <div className="col-md-12"> */}
                      <div className="form-group">
                        {/* <label
                              htmlFor=""
                              className="col-form-label"
                            ></label> */}
                        {/* <div className="container"> */}
                        {/* <div className="row"> */}
                        <div>
                          {test_service_custom.map((data, index) => {
                            const { testing_service_customer } = data;

                            return (
                              <div className="row" key={index}>
                                <div className="col-md-10">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      value={testing_service_customer}
                                      onChange={(e) =>
                                        handletesting_service_customer(index, e)
                                      }
                                      name="testing_service_customer"
                                      className="form-control"
                                      placeholder=""
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  {test_service_custom.length !== 1 ? (
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={removetesting_service_customer}
                                    >
                                      x
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          <div className="row">
                            <div className="col-sm-12">
                              <button
                                className="btn btn-outline-primary "
                                onClick={addtesting_service_customer}
                              >
                                Add New
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="col-sm-4"></div> */}
                        {/* </div> */}
                      </div>
                      {/* </div>
                      </div> */}
                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-primary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={(e) =>
                            settest_service_custom(test_service_custom)
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddAccount;
