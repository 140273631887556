import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Repo_Electrical_FormSchema.js";

const Repo_electrical_symbol = () => {
  const { error1, setError1 } = useState({});
  const [LoadedElectricalSymbols, setLoadedElectricalSymbols] = useState([]);
  const [unit, setUnit] = useState("");
  const [ESEdit, setESEdit] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_electrical_symbol/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      var json = await res.json();
      json = json.electricalSymbols;
      var strAscending = [...json].sort((a, b) => (a.unit > b.unit ? 1 : -1));
      setLoadedElectricalSymbols(strAscending);
      if (json.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(strAscending);
    } catch (error) {
      setError1(error1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return test.unit.toLowerCase().match(search.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.unit > b.unit ? 1 : -1));
    setLoadedElectricalSymbols(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const formInitialValues = {
    unit: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/repo_electrical_symbol`,
          {
            method: "POST",
            body: JSON.stringify({
              unit: values.unit,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            alert("Electrical symbol added successfully");
            values.unit = "";
            fetchData();
          })
          .catch((error) => {
            // handle the error
            alert(
              error.message +
                ": Electrical Symblol already exist, could not create."
            );
            values.unit = "";
          });
      },
    });

  const editESid = async (_id) => {
    let eid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_electrical_symbol/get/${eid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setESEdit(res.electricalSymbols);
  };

  let FormInitialValues = {
    unit: ESEdit.unit,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let eid = ESEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_electrical_symbol/${eid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({ unit: values.unit }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Electrical symbol updated successfully");

        fetchData();
      } else {
        alert(resJson.message);
      }
    },
  });

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedElectricalSymbols.reverse.filter((test) => {
      return test.unit.toLowerCase().match(val.toLocaleLowerCase());
    });
    var strAscending = [...result].sort((a, b) => (a.unit > b.unit ? 1 : -1));
    setLoadedElectricalSymbols(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const columns = [
    {
      name: "Unit",
      selector: (row) => <div>{row.unit}</div>,
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row._id}
            onClick={() => editESid(row._id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepoelectsym"
          ></i>
        </a>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Repository Electrical Symbol</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addRepostoryElectricalSymbol.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepoelectsym"
                      >
                        {" "}
                        + Add New Electrical Symbol
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedElectricalSymbols}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Unit</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={3}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoelectsym"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoelectsym"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add New Electrical Symbol
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Unit *
                            </label>
                            <input
                              type="text"
                              name="unit"
                              value={values.unit}
                              className="form-control"
                              id="unitId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Unit"
                            />
                            {errors.unit && touched.unit ? (
                              <span style={{ color: "red" }}>
                                {errors.unit}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-dismiss={values.unit ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepoelectsym"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepoelectsym"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Electrical Symbol
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Unit *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="unit"
                              value={formik.values.unit}
                              id="unitiD"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Unit"
                              onChange={formik.handleChange}
                            />{" "}
                            {formik.errors.unit && formik.touched.unit ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.unit}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                            data-dismiss={formik.values.unit ? "modal" : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_electrical_symbol;
