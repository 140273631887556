/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import logo from "../images/adminLogo.png";
// import user from "../assets/user.jpg";
import "./Sidebar.css";

import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";

/**
 * @author
 * @function SideMenu
 **/

// added more menuItems for testing
export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-window-sidebar",
  },
  {
    name: "Masters",
    exact: true,
    // to: ``,
    iconClassName: "bi bi-speedometer",
    subMenus: [
      { name: "Accounts", to: "/accounts" },
      { name: "Triggers", to: "/triggers" },
      { name: "Mqtt", to: "/mqtt_broker" },
      { name: "Iot Solution", to: "/iot_solution_masters" },
      { name: "Iot Sensor", to: "/iot_sensor_masters" },
    ],
  },
  {
    name: "Repository",
    exact: true,
    // to: ``,
    iconClassName: "bi bi-vector-pen",
    subMenus: [
      { name: "API Masters", to: "/api_master" },
      { name: "Assets", to: "/assets" },
      { name: "Asset Category", to: "/asset_category" },
      { name: "Asset Manufacturer", to: "/asset_manufacturer" },
      { name: "Asset Test Type", to: "/asset_test_type" },
      { name: "Department", to: "/department" },
      { name: "Industry", to: "/industry" },
      { name: "Electrical Symbol", to: "/electrical_symbol" },
      { name: "Location", to: "/location" },
      { name: "Oil Standard", to: "/oil_standard" },
      { name: "Oil Type", to: "/oil_type" },
      { name: "Oil Vendor", to: "/oil_vendor" },
      // { name: "Sensor Manufacturer", to: "/sensor_manufacturer" },
      // { name: "Sensor Type", to: "/sensor_type" },
    ],
  },
  { name: "Setup", to: `/setup`, iconClassName: "bi bi-gear" },
  {
    name: "Users",
    exact: true,
    // to: ``,
    iconClassName: "bi bi-people",
    subMenus: [
      { name: "Admin Users", to: "/admin_user" },
      { name: "Account Users", to: "/account_user" },
    ],
  },
  // {
  //   name: "References",
  //   exact: true,
  //   // to: ``,
  //   iconClassName: "bi bi-speedometer2",
  //   subMenus: [
  //     { name: "Down Time", to: "/downtime" },
  //     { name: "Analytics", to: "/analytics" },
  //   ],
  // },

  {
    name: "Reports",
    exact: true,
    // to: ``,
    iconClassName: "bi bi-journals",
    subMenus: [
      { name: "Contracted Templates", to: "/Contracted_Templates" },
      { name: "Mdts Templates", to: "/mdts_templates" },
      { name: "Alerts", to: "/alerts" },
      { name: "Api Logs", to: "/api_logs" },
      { name: "Audit Logs", to: "/audit_logs" },
      { name: "Service Logs", to: "service_logs" },
    ],
  },
];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }
    props.onCollapse(inactive);
  }, [inactive]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  /*just a little improvement over click function of menuItem
    Now no need to use expand state variable in MenuItem component
  */
  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        console.log(next);
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function logout() {
    // alert("Do you realy want to logout");
    var txt;
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you really want to logout")) {
      sessionStorage.removeItem("login");
      setIsLoggedIn(false);
      window.location = "/";
    } else {
    }
    document.getElementById("demo").innerHTML = txt;
  }

  return (
    <>
      <aside className="main-sidebar">
        <div className={`side-menu ${inactive ? "inactive" : ""}`}>
          <div className="top-section">
            <div className="logo">
              <img src={logo} alt="webscript" />
            </div>
            {/* <Link to={"/"}> */} {/* </Link> */}
            {/* <h4 className="motware">Motware</h4> */}
            <div
              onClick={() => setInactive(!inactive)}
              className="toggle-menu-btn"
            >
              {inactive ? (
                <i class="bi bi-justify"></i>
              ) : (
                <i class="bi bi-justify"></i>
              )}
            </div>
          </div>

          {/* <div className="divider"></div> */}

          <div className="main-menu">
            <ul>
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  name={menuItem.name}
                  exact={menuItem.exact}
                  to={menuItem.to}
                  subMenus={menuItem.subMenus || []}
                  iconClassName={menuItem.iconClassName}
                  onClick={(e) => {
                    if (inactive) {
                      setInactive(false);
                    }
                  }}
                />
              ))}
              <li>
                <a className="menu-item" onClickCapture={logout}>
                  <div className="menu-icon">
                    <i class="bi bi-door-closed"></i>
                  </div>
                  <span>Logout</span>
                </a>
              </li>

              {/* <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-speedometer2"></i>
              </div>
              <span>Dashboard</span>
            </a>
          </li>

          <MenuItem
            name={"Content"}
            subMenus={[{ name: "Courses" }, { name: "Videos" }]}
          />
          <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-vector-pen"></i>
              </div>
              <span>Design</span>
            </a>
          </li> */}
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default SideMenu;
