/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useFormik } from "formik";
import React, { useEffect, useState, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhoneCode from "react-phone-code";
import Map from "./Map";
import { Link } from "react-router-dom";
//import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
//import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutoComplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import Asset from "./Asset";
import { AssetFormSchema } from "./Asset_FormSchema ";
import { DepartmentFormSchema } from "./Department_FormSchema ";
import { LocationFormSchema } from "./Location_FormSchema ";
import { SubaccountFormSchema } from "./Subaccount_FormSchema";
import { AccountNameFormSchema } from "./AccountName_FormSchema ";
import { LocationNameFormSchema } from "./LocationName_FormSchema";
import { SubaccountNameFormSchema } from "./SubaccountName_FormSchema ";
import { DepartmentNameFormSchema } from "./DepartmentName_FormSchema";
import { AssetNameFormSchema } from "./AssetName_FormSchema ";

const Setup = () => {
  const [LoadedAccount, setLoadedAccount] = useState({});
  const [LoadedSubaccount, setLoadedSubaccount] = useState({});
  const [LoadedLocation, setLoadedLocation] = useState({});
  const [LoadedDeaprtment, setLoadedDepartment] = useState({});
  const [LoadedAsset, setLoadedAsset] = useState({});
  const [LoadedIndustry, setLoadedIndustry] = useState({});
  const [LoadedManufacturer, setLoadedManufacturer] = useState({});
  const [LoadedCategory, setLoadedCategory] = useState({});
  const [selected, setSelected] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    const getAllAccount = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/account_user/account/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      if (res) {
        setLoadedAccount(res);
        SubaccountNameFormik.values.subaccount_name = "";
        LocationNameFormik.values.location_name = "";
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      } else {
        setLoadedAccount();
        SubaccountNameFormik.values.subaccount_name = "";
        LocationNameFormik.values.location_name = "";
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      }
      setLoadedSubaccount();
      setLoadedLocation();
      setLoadedDepartment();
      setLoadedAsset();
    };
    getAllAccount();
  }, []);
  const ACData = LoadedAccount.accounts;
  const accountNameFormInitialValues = {
    account_name: "",
  };
  let AccountNameFormik = useFormik({
    initialValues: accountNameFormInitialValues,
    validationSchema: AccountNameFormSchema,
    onSubmit: async (values) => {
      console.log("values.account_name", values.account_name);
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/all/subaccount`,
        {
          method: "POST",
          body: JSON.stringify({
            account_name: values.account_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      console.log("Subaccount", res);
      if (res.subaccounts) {
        setLoadedSubaccount(res);
        LocationNameFormik.values.location_name = "";
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      } else {
        setLoadedSubaccount();
        LocationNameFormik.values.location_name = "";
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      }

      setLoadedLocation();
      setLoadedDepartment();
      setLoadedAsset();
    },
  });
  const SCData = LoadedSubaccount?.subaccounts;
  const subaccountNameFormInitialValues = {
    subaccount_name: "",
  };
  let SubaccountNameFormik = useFormik({
    initialValues: subaccountNameFormInitialValues,
    validationSchema: SubaccountNameFormSchema,
    onSubmit: async (values) => {
      console.log(" values.subaccount_name", values.subaccount_name);
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/all/location`,
        {
          method: "POST",
          body: JSON.stringify({
            subaccount_name: values.subaccount_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      console.log("Location", res);
      if (res.RepoLocation) {
        setLoadedLocation(res);
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      } else {
        setLoadedLocation();
        DepartmentNameFormik.values.department_name = "";
        AssetNameFormik.values.asset_name = "";
      }

      setLoadedDepartment();
      setLoadedAsset();
    },
  });
  const LData = LoadedLocation?.RepoLocation;
  const locationNameFormInitialValues = {
    location_name: "",
  };
  let LocationNameFormik = useFormik({
    initialValues: locationNameFormInitialValues,
    validationSchema: LocationNameFormSchema,
    onSubmit: async (values) => {
      console.log("values.location_name", values.location_name);
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/all/department`,
        {
          method: "POST",
          body: JSON.stringify({
            location_name: values.location_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      console.log("Department", res);
      if (res.RepoDeaprtment) {
        setLoadedDepartment(res);
        AssetNameFormik.values.asset_name = "";
      } else {
        setLoadedDepartment();
        AssetNameFormik.values.asset_name = "";
      }

      setLoadedAsset();
    },
  });
  const DData = LoadedDeaprtment?.RepoDeaprtment;
  const departmentNameFormInitialValues = {
    department_name: "",
  };
  let DepartmentNameFormik = useFormik({
    initialValues: departmentNameFormInitialValues,
    validationSchema: DepartmentNameFormSchema,
    onSubmit: async (values) => {
      console.log("values.department_name", values.department_name);
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/all/asset`,
        {
          method: "POST",
          body: JSON.stringify({
            department_name: values.department_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      console.log("Asset", res);
      if (res.RepoAsset) {
        setLoadedAsset(res);
      } else {
        setLoadedAsset();
      }
    },
  });
  const AData = LoadedAsset?.RepoAsset;
  const assetNameFormInitialValues = {
    asset_name: "",
  };
  let AssetNameFormik = useFormik({
    initialValues: assetNameFormInitialValues,
    validationSchema: AssetNameFormSchema,
    onSubmit: async (values) => {},
  });
  const handleAccountError = () => {
    if (
      AccountNameFormik.errors.account_name ||
      AccountNameFormik.values.account_name
    ) {
      alert("Please Select Account Name");
    }
  };
  useEffect(() => {
    const getAllIndustry = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_industry/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setLoadedIndustry(res);
    };
    getAllIndustry();
  }, []);
  const IData = LoadedIndustry.industries;
  useEffect(() => {
    const getAlLManufacturer = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_manufacturer/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setLoadedManufacturer(res);
    };
    getAlLManufacturer();
  }, []);
  const AMData = LoadedManufacturer.assetManufacturers;

  useEffect(() => {
    const getAllCategory = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_category/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setLoadedCategory(res);
    };
    getAllCategory();
  }, []);

  const CData = LoadedCategory.assetCategories;

  const year = new Date().getFullYear();

  const formInitialValues = {
    account_name: "",
    name: "",
    company_name: "",
    industry_name: "",
    address: "",
    email: "",
    contact_no: "",
  };

  let SubaccountFormik = useFormik({
    initialValues: formInitialValues,
    validationSchema: SubaccountFormSchema,
    onSubmit: async (values) => {
      return await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/subaccount`,
        {
          method: "POST",
          body: JSON.stringify({
            account_name: AccountNameFormik.values.account_name,
            name: values.name,
            company_name: values.company_name,
            industry_name: values.industry_name,
            address: values.address,
            email: values.email,
            contact_no: countryCode + values.contact_no,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          // process the response data
          alert("Subaccount added successfully");

          values.name = "";
          values.company_name = "";
          values.industry_name = "";
          values.address = "";
          values.email = "";
          values.contact_no = "";
          AccountNameFormik.handleSubmit();
        })
        .catch((error) => {
          // handle the error
          alert(
            error.message + ": Subaccount Already Exist, could not create."
          );
          values.name = "";
          values.company_name = "";
          values.industry_name = "";
          values.address = "";
          values.email = "";
          values.contact_no = "";
        });
    },
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  console.log(isLoaded);
  const handleMapUnmount = () => {
    console.log("Map has unmounted.");
  };
  const Center = { lat: 19.971585310167512, lng: 73.83378789650354 };
  const handleSearchClick = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          LocationFormik.values.address
        )}&key=${"AIzaSyBj3vNZTpjBQ_4EMcgJoXLKDKpLQ83aRSw"}`
      );
      const data = await response.json();
      console.log(data);
      const result = data.results[0];
      console.log(result.geometry.location);
      const lat = result.geometry.location.lat;
      const lng = result.geometry.location.lng;

      console.log(lng);

      if (data.status === "OK") {
        LocationFormik.values.latitude = lat;
        LocationFormik.values.longitude = lng;
        setSelected({ lat, lng });
      } else {
        console.error("Geocoding failed. Status:", data.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const locationFormInitialValues = {
    account_name: "",
    name: "",
    subaccount_name: "",
    contact_person_name: "",
    contact_no: "",
    email: "",
    address: "",
    latitude: "",
    longitude: "",
  };
  let LocationFormik = useFormik({
    initialValues: locationFormInitialValues,
    validationSchema: LocationFormSchema,
    onSubmit: async (values) => {
      return await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/location`,
        {
          method: "POST",
          body: JSON.stringify({
            account_name: AccountNameFormik.values.account_name,
            name: values.name,
            subaccount_name: SubaccountNameFormik.values.subaccount_name,
            contact_person_name: values.contact_person_name,
            contact_no: countryCode + values.contact_no,
            email: values.email,
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          // process the response data
          alert("Location added successfully");
          values.name = "";
          values.contact_person_name = "";
          values.contact_no = "";
          values.email = "";
          values.address = "";
          values.latitude = "";
          values.longitude = "";
          SubaccountNameFormik.handleSubmit();
        })
        .catch((error) => {
          // handle the error
          alert(error.message + ": Location Already Exist, could not create.");
          values.name = "";
        });
    },
  });
  const departmentFormInitialValues = {
    account_name: "",
    name: "",
    location_name: "",
  };
  let DepartmentFormik = useFormik({
    initialValues: departmentFormInitialValues,
    validationSchema: DepartmentFormSchema,
    onSubmit: async (values) => {
      console.log(values);
      return await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/setup/department`,
        {
          method: "POST",
          body: JSON.stringify({
            account_name: AccountNameFormik.values.account_name,
            name: values.name,
            location_name: LocationNameFormik.values.location_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          alert("Department added successfully");

          values.name = "";

          LocationNameFormik.handleSubmit();
        })
        .catch((error) => {
          // handle the error
          alert(
            error.message + ": Department Already Exist, could not create."
          );
          values.name = "";
        });
    },
  });
  // const yearList = allYears.map((x) => {
  //   return <option key={x}>{x}</option>;
  // });
  const AssetFormInitialValues = {
    account_name: "",
    asset_name: "",
    critical: "",
    sr_no: "",
    rating: "",
    manufacturing_yr: "",
    rated_current: "",
    latitude: "",
    longitude: "",

    subaccount_name: "",
    department_name: "",
    location_name: "",
    category: "",
    manufacturer: "",
  };
  console.log("selected", selected);
  let AssetFormik = useFormik({
    initialValues: AssetFormInitialValues,
    validationSchema: AssetFormSchema,
    onSubmit: async (values) => {
      console.log(values);
      return await fetch(`${process.env.REACT_APP_VARIABLE}/api/setup/asset`, {
        method: "POST",
        body: JSON.stringify({
          account_name: AccountNameFormik.values.account_name,
          asset_name: values.asset_name,
          critical: values.critical,
          sr_no: values.sr_no,
          rating: values.rating,
          manufacturing_yr: values.manufacturing_yr,
          rated_current: values.rated_current,

          latitude: values.latitude,
          longitude: values.longitude,

          subaccount_name: SubaccountNameFormik.values.subaccount_name,
          department_name: DepartmentNameFormik.values.department_name,
          location_name: LocationNameFormik.values.location_name,
          category: values.category,
          manufacturer: values.manufacturer,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          alert("Asset added successfully");

          values.asset_name = "";
          values.critical = "";
          values.sr_no = "";
          values.rating = "";
          values.manufacturing_yr = "";
          values.rated_current = "";

          values.latitude = "";
          values.longitude = "";

          values.category = "";
          values.manufacturer = "";
        })
        .catch((error) => {
          // handle the error
          alert(error.message + ": Asset Already Exist, could not create.");
          values.asset_name = "";
          values.critical = "";
          values.sr_no = "";
          values.rating = "";
          values.manufacturing_yr = "";
          values.rated_current = "";

          values.latitude = "";
          values.longitude = "";

          values.category = "";
          values.manufacturer = "";
        });
    },
  });
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <h1 className="pageTitle">Setup</h1>
                <div className="row align-items-center mb20">
                  <div className="col-md-12 col-lg-12 col-xl-12"></div>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            for="subaccount"
                            className="col-sm-12 col-md-12 col-lg-4 col-form-label"
                          >
                            Select Account *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-7">
                            <select
                              id="ak"
                              name="account_name"
                              value={AccountNameFormik.values.account_name}
                              onBlur={AccountNameFormik.handleBlur}
                              onChange={AccountNameFormik.handleChange}
                              className="js-states form-control "
                            >
                              <option value="" disabled selected="true">
                                Select Account
                              </option>

                              {ACData ? (
                                ACData.map((am, index) => (
                                  <option key={index} value={am.company_name}>
                                    {am.company_name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled selected="true">
                                  Select Account
                                </option>
                              )}
                            </select>{" "}
                            {AccountNameFormik.errors.account_name &&
                            AccountNameFormik.touched.account_name ? (
                              <span style={{ color: "red" }}>
                                {AccountNameFormik.errors.account_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            for="subaccount"
                            className="col-sm-12 col-md-12 col-lg-4 col-form-label"
                          >
                            Select Subaccount *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-7">
                            <span
                              onClick={() =>
                                AccountNameFormik.values.account_name &&
                                AccountNameFormik.handleSubmit()
                              }
                            >
                              {" "}
                              <select
                                id="ak"
                                name="subaccount_name"
                                value={
                                  SubaccountNameFormik.values.subaccount_name
                                }
                                onChange={SubaccountNameFormik.handleChange}
                                onBlur={SubaccountNameFormik.handleBlur}
                                className="js-states form-control"
                              >
                                {SubaccountFormik.values.name ? (
                                  <option
                                    value={
                                      (SubaccountNameFormik.values.subaccount_name =
                                        SubaccountFormik.values.name)
                                    }
                                    selected={
                                      SubaccountFormik.values.name
                                        ? true
                                        : false
                                    }
                                  >
                                    {SubaccountFormik.values.name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Subaccount
                                  </option>
                                )}

                                {SCData ? (
                                  SCData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Subaccount
                                  </option>
                                )}
                              </select>{" "}
                              {SubaccountFormik.errors.name &&
                              SubaccountNameFormik.errors.subaccount_name &&
                              SubaccountNameFormik.touched.subaccount_name ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountNameFormik.errors.subaccount_name}
                                </span>
                              ) : null}
                            </span>
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            <div className="input-group pr-form-group">
                              <span>
                                <button
                                  type="button"
                                  className="btnAdd btn btn-c-outline-primary"
                                  data-toggle="modal"
                                  data-target={"#addsubaccount"}
                                >
                                  {" "}
                                  <b>+</b>{" "}
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            for="location"
                            className="col-sm-12 col-md-12 col-lg-4 col-form-label"
                          >
                            Select Location *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-7">
                            <span
                              onClick={() =>
                                SubaccountNameFormik.values.subaccount_name &&
                                SubaccountNameFormik.handleSubmit()
                              }
                            >
                              <select
                                id="ak"
                                name="location_name"
                                value={LocationNameFormik.values.location_name}
                                onBlur={LocationNameFormik.handleBlur}
                                onChange={LocationNameFormik.handleChange}
                                className="js-states form-control"
                              >
                                {LocationFormik.values.name ? (
                                  <option
                                    value={
                                      (LocationNameFormik.values.location_name =
                                        LocationFormik.values.name)
                                    }
                                    selected={
                                      LocationFormik.values.name ? true : false
                                    }
                                  >
                                    {LocationFormik.values.name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Location
                                  </option>
                                )}

                                {LData ? (
                                  LData.map((am, index) => (
                                    <option key={index} value={am.location}>
                                      {am.location}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Location
                                  </option>
                                )}
                              </select>{" "}
                              {LocationFormik.errors.name &&
                              LocationNameFormik.errors.location_name &&
                              LocationNameFormik.touched.location_name ? (
                                <span style={{ color: "red" }}>
                                  {LocationNameFormik.errors.location_name}
                                </span>
                              ) : null}
                            </span>
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btnAdd btn btn-c-outline-primary"
                                data-toggle="modal"
                                data-target={"#addlocation"}
                              >
                                {" "}
                                <b>+</b>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            for="department"
                            className="col-sm-12 col-md-12 col-lg-4 col-form-label"
                          >
                            Select Department *
                          </label>
                          <div className="col-sm-10 col-md-10 col-lg-7">
                            <span
                              onClick={() =>
                                LocationNameFormik.values.location_name &&
                                LocationNameFormik.handleSubmit()
                              }
                            >
                              <select
                                id="ak"
                                name="department_name"
                                value={
                                  DepartmentNameFormik.values.department_name
                                }
                                onChange={DepartmentNameFormik.handleChange}
                                onBlur={DepartmentNameFormik.handleBlur}
                                className="js-states form-control "
                              >
                                {DepartmentFormik.values.name ? (
                                  <option
                                    value={
                                      (DepartmentNameFormik.values.department_name =
                                        DepartmentFormik.values.name)
                                    }
                                    selected={
                                      DepartmentFormik.values.name
                                        ? true
                                        : false
                                    }
                                  >
                                    {DepartmentFormik.values.name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Department
                                  </option>
                                )}

                                {DData ? (
                                  DData.map((am, index) => (
                                    <option key={index} value={am.department}>
                                      {am.department}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Department
                                  </option>
                                )}
                              </select>
                              {DepartmentFormik.errors.name &&
                              DepartmentNameFormik.errors.department_name &&
                              DepartmentNameFormik.touched.department_name ? (
                                <span style={{ color: "red" }}>
                                  {DepartmentNameFormik.errors.department_name}
                                </span>
                              ) : null}
                            </span>
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btn btn-c-outline-primary btnAdd"
                                data-toggle="modal"
                                data-target={"#adddepartment"}
                              >
                                {" "}
                                <b>+</b>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            for="asset"
                            className="col-sm-12 col-md-12 col-lg-4 col-form-label"
                          >
                            Asset *
                          </label>
                          <div className="col-sm-12 col-md-10 col-lg-7">
                            <span
                              onClick={() =>
                                DepartmentNameFormik.values.department_name &&
                                DepartmentNameFormik.handleSubmit()
                              }
                            >
                              <select
                                id="ak"
                                name="asset_name"
                                value={AssetNameFormik.values.asset_name}
                                onBlur={AssetNameFormik.handleBlur}
                                onChange={AssetNameFormik.handleChange}
                                className="js-states form-control "
                              >
                                {" "}
                                {AssetFormik.values.asset_name ? (
                                  <option
                                    value={
                                      (AssetNameFormik.values.asset_name =
                                        AssetFormik.values.asset_name)
                                    }
                                    selected={
                                      AssetFormik.values.asset_name
                                        ? true
                                        : false
                                    }
                                  >
                                    {AssetFormik.values.asset_name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Asset
                                  </option>
                                )}
                                {AData ? (
                                  AData.map((am, index) => (
                                    <option key={index} value={am.asset}>
                                      {am.asset}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Asset
                                  </option>
                                )}
                              </select>
                              {AssetFormik.errors.asset_name &&
                              AssetNameFormik.errors.asset_name &&
                              AssetNameFormik.touched.asset_name ? (
                                <span style={{ color: "red" }}>
                                  {AssetNameFormik.errors.asset_name}
                                </span>
                              ) : null}
                            </span>
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btn btn-c-outline-primary btnAdd"
                                data-toggle="modal"
                                data-target={"#addasset"}
                              >
                                {" "}
                                <b>+</b>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt20 mb10">
                      <Link to="/dashboard">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </Link>
                      <button type="button" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div
                className="modal fade"
                id="addsubaccount"
                tabindex="-1"
                role="dialog"
                aria-labelledby="addsubaccount"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <img
                        src="assets/images/adminLogo.png"
                        alt="Motware Logo"
                        width="40px"
                        class="brand-image"
                      />
                      <h5 class="modal-title w-100 text-center">
                        Add New Subaccount
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Account Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>{AccountNameFormik.values.account_name}</b>
                                </p>
                                {AccountNameFormik.values.account_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {AccountNameFormik.errors.account_name ||
                                      "Please Select Account Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Industry *
                              </label>
                              <select
                                id="ak"
                                name="industry_name"
                                value={SubaccountFormik.values.industry_name}
                                onBlur={SubaccountFormik.handleBlur}
                                onChange={SubaccountFormik.handleChange}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Industry
                                </option>

                                {IData &&
                                  IData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))}
                              </select>
                              {SubaccountFormik.errors.industry_name &&
                              SubaccountFormik.touched.industry_name ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountFormik.errors.industry_name}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label for="compName" className="col-form-label">
                                Name *
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={SubaccountFormik.values.name}
                                className="form-control"
                                id="locName"
                                onBlur={SubaccountFormik.handleBlur}
                                onChange={SubaccountFormik.handleChange}
                                placeholder="Enter Subaccount Name"
                              />
                              {SubaccountFormik.errors.name &&
                              SubaccountFormik.touched.name ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountFormik.errors.name}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label for="compName" className="col-form-label">
                                Company Name *
                              </label>
                              <input
                                type="text"
                                name="company_name"
                                value={SubaccountFormik.values.company_name}
                                className="form-control"
                                id="locName"
                                onBlur={SubaccountFormik.handleBlur}
                                onChange={SubaccountFormik.handleChange}
                                placeholder="Enter Company Name"
                              />
                              {SubaccountFormik.errors.company_name &&
                              SubaccountFormik.touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountFormik.errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-group">
                              <label for="email" className="col-form-label">
                                Email *
                              </label>
                              <input
                                type="email"
                                name="email"
                                value={SubaccountFormik.values.email}
                                className="form-control"
                                id="locName"
                                onBlur={SubaccountFormik.handleBlur}
                                onChange={SubaccountFormik.handleChange}
                                placeholder="Enter Email"
                              />
                              {SubaccountFormik.errors.email &&
                              SubaccountFormik.touched.email ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountFormik.errors.email}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group row">
                              <label for="contactNo" className="col-form-label">
                                Contact No *
                              </label>

                              <div className="col-sm-12 col-md-12 col-lg-4">
                                <PhoneCode
                                  onSelect={(code) => setCountryCode(code)} // required
                                  showFirst={["IN", "US"]}
                                  defaultValue="select county"
                                  id="some-id"
                                  name="countrycode"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-8">
                                <input
                                  type="number"
                                  name="contact_no"
                                  value={SubaccountFormik.values.contact_no}
                                  className="form-control"
                                  id="contactNo"
                                  onBlur={SubaccountFormik.handleBlur}
                                  onChange={SubaccountFormik.handleChange}
                                  placeholder="Enter Contact Number"
                                />

                                {SubaccountFormik.errors.contact_no &&
                                SubaccountFormik.touched.contact_no ? (
                                  <span style={{ color: "red" }}>
                                    {SubaccountFormik.errors.contact_no}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group">
                              <label for="email" className="col-form-label">
                                Address *
                              </label>
                              <textarea
                                name="address"
                                value={SubaccountFormik.values.address}
                                className="form-control"
                                onChange={SubaccountFormik.handleChange}
                                onBlur={SubaccountFormik.handleBlur}
                              ></textarea>
                              {SubaccountFormik.errors.address &&
                              SubaccountFormik.touched.address ? (
                                <span style={{ color: "red" }}>
                                  {SubaccountFormik.errors.address}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={SubaccountFormik.handleSubmit}
                            data-dismiss={
                              AccountNameFormik.values.account_name &&
                              !SubaccountFormik.errors.contact_no &&
                              !SubaccountFormik.errors.email &&
                              AccountNameFormik.values.account_name &&
                              SubaccountFormik.values.address &&
                              SubaccountFormik.values.company_name &&
                              SubaccountFormik.values.contact_no &&
                              SubaccountFormik.values.email &&
                              SubaccountFormik.values.industry_name &&
                              SubaccountFormik.values.name
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="modal fade"
                id="addlocation"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                role="dialog"
                aria-labelledby="addlocation"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <img
                        src="assets/images/adminLogo.png"
                        alt="Motware Logo"
                        width="40px"
                        class="brand-image"
                      />

                      <h5 class="modal-title w-100 text-center">
                        Add New Location
                      </h5>

                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div class="modal-body">
                      <form
                        id="add_location_form"
                        // onSubmit={handleSubmit}
                      >
                        <input
                          type="hidden"
                          name="location_account"
                          // value={locationAccount}
                        />

                        <input
                          type="hidden"
                          name="location_subaccount_id"
                          // value={locationSubaccountId}
                        />

                        {/* <input type="hidden" name="location_latitude" value={locationLatitude} /> */}

                        {/* <input type="hidden" name="location_longitude" value={locationLongitude} /> */}
                      </form>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <label
                                  for=""
                                  className="col-form-label col-sm-6 col-md-6 col-md-3"
                                >
                                  Account Name:
                                </label>
                                <div className="readOnly col-sm-6 col-md-6 col-md-9">
                                <p className="">
                                  <b>{AccountNameFormik.values.account_name}</b>
                                  {AccountNameFormik.values.account_name ===
                                  "" ? (
                                    <span style={{ color: "red" }}>
                                      {AccountNameFormik.errors.account_name ||
                                        "Please Select Account Name"}
                                    </span>
                                  ) : null}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <label
                                  for=""
                                  className="col-form-label col-sm-6 col-md-6 col-md-3"
                                >
                                  Subaccount Name:
                                </label>

                                <div className="readOnly col-sm-6 col-md-6 col-md-9">
                                  <p className="">
                                    <b>
                                      {
                                        SubaccountNameFormik.values
                                          .subaccount_name
                                      }
                                    </b>
                                  
                                  {SubaccountNameFormik.values
                                    .subaccount_name === "" ? (
                                    <span style={{ color: "red" }}>
                                      {SubaccountNameFormik.errors
                                        .subaccount_name ||
                                        "Please Select Subaccount Name"}
                                    </span>
                                  ) : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="" class="col-form-label">
                                  Address
                                </label>
                                <textarea
                                  name="address"
                                  type="text"
                                  className="form-control"
                                  value={LocationFormik.values.address}
                                  id="locName"
                                  onBlur={LocationFormik.onBlur}
                                  onChange={LocationFormik.handleChange}
                                  placeholder="Enter Location Address"
                                />
                                {LocationFormik.errors.address &&
                                LocationFormik.touched.address ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.address}
                                  </span>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  id="check_address"
                                  onClick={handleSearchClick}
                                >
                                  Search
                                </button>
                              </div>
                              <div style={{ height: "400px", width: "100%" }}>
                                {isLoaded ? (
                                  <>
                                    <GoogleMap
                                      zoom={12}
                                      center={!selected ? Center : selected}
                                      mapContainerClassName="map-container"
                                      onLoad={(map) => {
                                        console.log("Map loaded!", map);
                                      }}
                                    >
                                      {selected ? (
                                        <Marker position={selected} />
                                      ) : (
                                        <Marker position={Center} />
                                      )}
                                    </GoogleMap>
                                  </>
                                ) : (
                                  <div>Loading...</div>
                                )}
                                ;
                              </div>
                              {/* <Map /> */}
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="" className="col-form-label">
                                  Location Name *
                                </label>

                                <input
                                  type="text"
                                  name="name"
                                  value={LocationFormik.values.name}
                                  className="form-control"
                                  id="locName"
                                  onBlur={LocationFormik.onBlur}
                                  onChange={LocationFormik.handleChange}
                                  placeholder="Enter Location Name"
                                />
                                {LocationFormik.errors.name &&
                                LocationFormik.touched.name ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.name}
                                  </span>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label for="" className="col-form-label">
                                  Location Contact Person *
                                </label>

                                <input
                                  type="text"
                                  name="contact_person_name"
                                  className="form-control"
                                  value={
                                    LocationFormik.values.contact_person_name
                                  }
                                  id="locName"
                                  onBlur={LocationFormik.onBlur}
                                  onChange={LocationFormik.handleChange}
                                  placeholder="Enter Location Contact Person"
                                />
                                {LocationFormik.errors.contact_person_name &&
                                LocationFormik.touched.contact_person_name ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.contact_person_name}
                                  </span>
                                ) : null}
                              </div>

                              <div className="mobile form-group">
                                <label for="" className="col-form-label">
                                  Location Contact *
                                </label>

                                <div className="row">
                                  <div className=" col-sm-4 col-md-4 col-lg-4">
                                    <PhoneCode
                                      onSelect={(code) => setCountryCode(code)} // required
                                      showFirst={["IN", "US"]}
                                      defaultValue="select county"
                                      id="some-id"
                                      name="countrycode"
                                      className="form-control"
                                    />
                                  </div>

                                  <div className=" col-sm-12 col-md-12 col-lg-8">
                                    <input
                                      type="tel"
                                      name="contact_no"
                                      className="form-control"
                                      placeholder="Enter Mobile no"
                                      pattern="[0-9]{10}"
                                      value={LocationFormik.values.contact_no}
                                      id="contactNo"
                                      onBlur={LocationFormik.handleBlur}
                                      onChange={LocationFormik.handleChange}
                                    />{" "}
                                    {LocationFormik.errors.contact_no &&
                                    LocationFormik.touched.contact_no ? (
                                      <span style={{ color: "red" }}>
                                        {LocationFormik.errors.contact_no}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label for="" class="col-form-label">
                                  Location Email *
                                </label>

                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  value={LocationFormik.values.email}
                                  id="contactNo"
                                  onBlur={LocationFormik.handleBlur}
                                  onChange={LocationFormik.handleChange}
                                />
                                {LocationFormik.errors.email &&
                                LocationFormik.touched.email ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.email}
                                  </span>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label for="" class="col-form-label">
                                  Latitude *
                                </label>

                                <input
                                  type="text"
                                  name="latitude"
                                  className="form-control"
                                  placeholder="Latitude"
                                  value={LocationFormik.values.latitude}
                                  id="contactNo"
                                  onBlur={LocationFormik.handleBlur}
                                  onChange={LocationFormik.handleChange}
                                />
                                {LocationFormik.errors.latitude &&
                                LocationFormik.touched.latitude ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.latitude}
                                  </span>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label for="" class="col-form-label">
                                  Longitude *
                                </label>

                                <input
                                  type="text"
                                  name="longitude"
                                  className="form-control"
                                  placeholder="Longitude"
                                  value={LocationFormik.values.longitude}
                                  id="contactNo"
                                  onBlur={LocationFormik.handleBlur}
                                  onChange={LocationFormik.handleChange}
                                />
                                {LocationFormik.errors.longitude &&
                                LocationFormik.touched.longitude ? (
                                  <span style={{ color: "red" }}>
                                    {LocationFormik.errors.longitude}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="text-center mt20 mb10">
                            <button
                              type="button"
                              className="btn btn-secondary mr10"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={LocationFormik.handleSubmit}
                              data-dismiss={
                                LocationFormik.values.name &&
                                AccountNameFormik.values.account_name &&
                                SubaccountNameFormik.values.subaccount_name &&
                                LocationFormik.values.contact_person_name &&
                                LocationFormik.values.contact_no &&
                                LocationFormik.values.email &&
                                LocationFormik.values.latitude &&
                                LocationFormik.values.longitude &&
                                LocationFormik.values.address
                                  ? "modal"
                                  : ""
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="modal fade"
                id="addlocation"
                tabindex="-1"
                role="dialog"
                aria-labelledby="addlocation"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <img
                        src="assets/images/logo v4.png"
                        alt="oil test Logo"
                        width="40px"
                        class="brand-image"
                      /> */}
              {/* <h5 classname="modal-title w-100 text-center">
                        Add New Location
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Account Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>{AccountNameFormik.values.account_name}</b>
                                </p>
                                {AccountNameFormik.values.account_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {AccountNameFormik.errors.account_name ||
                                      "Please Select Account Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Subaccount Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>
                                    {
                                      SubaccountNameFormik.values
                                        .subaccount_name
                                    }
                                  </b>
                                </p>
                                {SubaccountNameFormik.values.subaccount_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {SubaccountNameFormik.errors
                                      .subaccount_name ||
                                      "Please Select Subaccount Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label for="compName" className="col-form-label">
                                Location Name *
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={LocationFormik.values.name}
                                className="form-control"
                                id="locName"
                                onBlur={LocationFormik.onBlur}
                                onChange={LocationFormik.handleChange}
                                placeholder="Enter Location Name"
                              />
                              {LocationFormik.errors.name &&
                              LocationFormik.touched.name ? (
                                <span style={{ color: "red" }}>
                                  {LocationFormik.errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={LocationFormik.handleSubmit}
                            data-dismiss={
                              LocationFormik.values.name &&
                              AccountNameFormik.values.account_name &&
                              SubaccountNameFormik.values.subaccount_name
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                className="modal fade"
                id="adddepartment"
                tabindex="-1"
                role="dialog"
                aria-labelledby="adddepartment"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <img
                        src="assets/images/adminLogo.png"
                        alt="Motware Logo"
                        width="40px"
                        class="brand-image"
                      />
                      <h5 class="modal-title w-100 text-center">
                        Add New Department
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Account Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>{AccountNameFormik.values.account_name}</b>
                                </p>{" "}
                                {AccountNameFormik.values.account_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {AccountNameFormik.errors.account_name ||
                                      "Please Select Account Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Subaccount Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>
                                    {
                                      SubaccountNameFormik.values
                                        .subaccount_name
                                    }
                                  </b>
                                </p>{" "}
                                {SubaccountNameFormik.values.subaccount_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {SubaccountNameFormik.errors
                                      .subaccount_name ||
                                      "Please Select Subaccount Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-4">
                                Location Name *
                              </label>
                              <div className="readOnly col-md-8">
                                <p className="">
                                  <b>
                                    {LocationNameFormik.values.location_name}
                                  </b>
                                </p>{" "}
                                {LocationNameFormik.values.location_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {LocationNameFormik.errors.location_name ||
                                      "Please Select Location Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label for="compName" className="col-form-label">
                                Department Name *
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={DepartmentFormik.values.name}
                                className="form-control"
                                id="locName"
                                onBlur={DepartmentFormik.onBlur}
                                onChange={DepartmentFormik.handleChange}
                                placeholder="Enter Department Name"
                              />{" "}
                              {DepartmentFormik.errors.name &&
                              DepartmentFormik.touched.name ? (
                                <span style={{ color: "red" }}>
                                  {DepartmentFormik.errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={DepartmentFormik.handleSubmit}
                            data-dismiss={
                              AccountNameFormik.values.account_name &&
                              SubaccountNameFormik.values.subaccount_name &&
                              DepartmentFormik.values.name &&
                              LocationNameFormik.values.location_name
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="addasset"
                tabindex="-1"
                role="dialog"
                aria-labelledby="addasset"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <img
                        src="assets/images/adminLogo.png"
                        alt="Motware Logo"
                        width="40px"
                        class="brand-image"
                      />
                      <h5 class="modal-title w-100 text-center">
                        Add New Asset
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group row">
                              <label for="" className="col-form-label col-md-3">
                                Department Name *
                              </label>
                              <div className="readOnly col-md-9">
                                <p className="">
                                  <b>
                                    {" "}
                                    {
                                      DepartmentNameFormik.values
                                        .department_name
                                    }
                                  </b>
                                </p>
                                {DepartmentNameFormik.values.department_name ===
                                "" ? (
                                  <span style={{ color: "red" }}>
                                    {DepartmentNameFormik.values
                                      .department_name ||
                                      "Please Select Department Name"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group ">
                              <label for="compName" className="col-form-label">
                                Asset Name *
                              </label>
                              <input
                                type="text"
                                name="asset_name"
                                value={AssetFormik.values.asset_name}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.onBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Asset Name"
                              />
                              {AssetFormik.errors.asset_name &&
                              AssetFormik.touched.asset_name ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.asset_name}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group ">
                              <label for="compName" className="col-form-label">
                                Critical Asset *
                              </label>
                              <select
                                id="ak"
                                name="critical"
                                value={AssetFormik.values.critical}
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Critical Asset
                                </option>

                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {AssetFormik.errors.critical &&
                              AssetFormik.touched.critical ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.critical}
                                </span>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Manufacturer *
                              </label>
                              <select
                                id="ak"
                                name="manufacturer"
                                value={AssetFormik.values.manufacturer}
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Manufacturer
                                </option>

                                {AMData &&
                                  AMData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))}
                              </select>
                              {AssetFormik.errors.manufacturer &&
                              AssetFormik.touched.manufacturer ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.manufacturer}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Manufacturering Year *
                              </label>

                              <select
                                name="manufacturing_yr"
                                value={AssetFormik.values.manufacturing_yr}
                                onBlur={AssetFormik.onBlur}
                                onChange={AssetFormik.handleChange}
                                className="form-control"
                              >
                                {Array.from(new Array(100), (v, i) => (
                                  <option key={i} value={year - i}>
                                    {year - i}
                                  </option>
                                ))}
                              </select>
                              {AssetFormik.errors.manufacturing_yr &&
                              AssetFormik.touched.manufacturing_yr ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.manufacturing_yr}
                                </span>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Category *
                              </label>
                              <select
                                id="ak"
                                name="category"
                                value={AssetFormik.values.category}
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Category
                                </option>

                                {CData &&
                                  CData.map((am, index) => (
                                    <option key={index} value={am.name}>
                                      {am.name}
                                    </option>
                                  ))}
                              </select>
                              {AssetFormik.errors.category &&
                              AssetFormik.touched.category ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.category}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Asset Sr. No *
                              </label>
                              <input
                                type="text"
                                name="sr_no"
                                value={AssetFormik.values.sr_no}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Asset Sr Number"
                              />{" "}
                              {AssetFormik.errors.sr_no &&
                              AssetFormik.touched.sr_no ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.sr_no}
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Rating *
                              </label>
                              <input
                                type="text"
                                name="rating"
                                value={AssetFormik.values.rating}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Rating"
                              />{" "}
                              {AssetFormik.errors.rating &&
                              AssetFormik.touched.rating ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.rating}
                                </span>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Rated Current *
                              </label>
                              <input
                                type="text"
                                name="rated_current"
                                value={AssetFormik.values.rated_current}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Rated Current"
                              />{" "}
                              {AssetFormik.errors.rated_current &&
                              AssetFormik.touched.rated_current ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.rated_current}
                                </span>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Longitude *
                              </label>
                              <input
                                type="number"
                                name="longitude"
                                value={AssetFormik.values.longitude}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Longitude"
                              />
                              {/* {AssetFormik.errors.longitude &&
                              AssetFormik.touched.longitude ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.longitude}
                                </span>
                              ) : null} */}
                            </div>
                            <div className="form-group">
                              <label for="sector" class="col-form-label">
                                Latitude *
                              </label>
                              <input
                                type="number"
                                name="latitude"
                                value={AssetFormik.values.latitude}
                                className="form-control"
                                id="locName"
                                onBlur={AssetFormik.handleBlur}
                                onChange={AssetFormik.handleChange}
                                placeholder="Enter Latitude"
                              />
                              {/* {AssetFormik.errors.latitude &&
                              AssetFormik.touched.latitude ? (
                                <span style={{ color: "red" }}>
                                  {AssetFormik.errors.latitude}
                                </span>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-secondary mr10"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={AssetFormik.handleSubmit}
                            data-dismiss={
                              AccountNameFormik.values.account_name &&
                              AssetFormik.values.asset_name &&
                              AssetFormik.values.category &&
                              AssetFormik.values.critical &&
                              DepartmentNameFormik.values.department_name &&
                              LocationNameFormik.values.location_name &&
                              AssetFormik.values.manufacturer &&
                              AssetFormik.values.manufacturing_yr &&
                              AssetFormik.values.rated_current
                                ? "modal"
                                : ""
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Setup;
