/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
const Map = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  });
  console.log(isLoaded);

  const handleMapUnmount = () => {
    console.log("Map has unmounted.");
  };
  const Center = useMemo(
    () => ({
      lat: 40.7128,
      lng: -74.006,
    }),
    []
  );
  return (
    <div style={{ height: "400px", width: "100%" }}>
      {isLoaded ? (
        <>
          <GoogleMap
            zoom={12}
            center={Center}
            mapContainerClassName="map-container"
            onLoad={(map) => {
              console.log("Map loaded!", map);
            }}
          >
            <Marker position={Center} />
          </GoogleMap>
        </>
      ) : (
        <div>Loading...</div>
      )}
      ;
    </div>
  );
};

export default Map;
