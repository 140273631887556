import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { Link } from "react-router-dom";
import { FormSchema } from "./IotSolution_FormSchema.js";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

const Iot_solution_master = () => {
  const history = useHistory();
  const { error1, setError1 } = useState({});
  const [LoadedIotSolMaster, setLoadedIotSolMaster] = useState([]);
  const [showSelectList, setShowSelectList] = useState();
  const [ISMEdit, setISMEdit] = useState({});
  const [AQI, setAQI] = useState({});
  const [id, setId] = useState("");
  const [param_alert_array, setParam_alert_array] = useState();
  const [param_alert_array1, setParam_alert_array1] = useState({});
  const [iot_sol, setIot_sol] = useState("");
  const [data_fields, setData_fields] = useState();
  const [err_codes, setErr_codes] = useState();
  const [remove_trend, setRemove_trend] = useState();
  const [aqi, setAqi] = useState();
  const [battery_status, setBattery_status] = useState([]);
  const [load_percent_status, setLoad_percent_status] = useState([]);
  const [mog_alarm, setMog_alarm] = useState([]);
  const [oti_trip, setOti_trip] = useState([]);
  const [wti_alarm_lv, setWti_alarm_lv] = useState([]);
  const [wti_trip_lv, setWti_trip_lv] = useState([]);
  const [buchholz_alarm, setBuchholz_alarm] = useState([]);
  const [prv_trip, setPrv_trip] = useState([]);
  const [lv_voltage_unbalance_status, setLv_voltage_unbalance_status] =
    useState([]);
  const [lv_current_unbalance_status, setLv_current_unbalance_status] =
    useState([]);
  const [hv_voltage_unbalance_status, setHv_voltage_unbalance_status] =
    useState([]);
  const [hv_current_unbalance_status, setHv_current_unbalance_status] =
    useState([]);
  const [copper_loss_status, setCopper_loss_status] = useState([]);
  const [oti_alarm, setOti_alarm] = useState([]);
  const [efficiency_status, setEfficiency_status] = useState([]);
  const [Category, setCategory] = useState([]);
  const [Bypass_Status, setBypass_Status] = useState([]);
  const [remove_alert, setRemove_alert] = useState();
  const [newfield, setNewField] = useState([{}]);
  const [asset_types, setAsset_types] = useState();
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/iot_solution_masters/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();
      console.log("json", json);
      let iotsol = json.newIotSolutionObject;
      var strAscending = [...iotsol].sort((a, b) =>
        a?.iot_sol > b?.iot_sol ? 1 : -1
      );
      setTotal(strAscending);
      setLoadedIotSolMaster(strAscending);
      if (iotsol.length === 0) {
      } else {
        setLoader(false);
      }
    } catch (error) {
      // setError1(error1);
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.iot_sol.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.dataFieldCount
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.errorCodeCount
          .toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      ); //||
      // test.removeTrendCount,
      // toString().toLowerCase().match(search.toLocaleLowerCase())
      // ); //||
      //   test.removeAlertCount
      //     .toString()
      //     .toLowerCase()
      //     .match(search.toLocaleLowerCase()) ||
      // test.aqiCount
      //   .toString()
      //   .toLowerCase()
      //   .match(search.toLocaleLowerCase()) ||
      // test.assetTypesCount
      //   .toString()
      //   .toLowerCase()
      //   .match(search.toLocaleLowerCase()) ||
      // test.paramalertarrayCount
      //   .toString()
      //   .toLowerCase()
      //   .match(search.toLocaleLowerCase())
    });
    var strAscending = [...result].sort((a, b) =>
      a.iot_sol > b.iot_sol ? 1 : -1
    );
    setLoadedIotSolMaster(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  function handleButtonClick() {
    setShowSelectList(true);
  }
  const [tapsfield, settapsfield] = useState([{}]);

  const addInputtaps = (e) => {
    e.preventDefault();
    settapsfield([...tapsfield, {}]);
  };

  const removetap = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield];
    rows.splice(index, 1);
    settapsfield(rows);
  };

  const handletap = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...tapsfield];
    list[index][name] = value;
    settapsfield(list);
    setData_fields(list);
  };

  const [tapsfield24, settapsfield24] = useState([{}]);
  const [props, setProps] = useState();
  const [val, setVal] = useState();

  const addInputtaps24 = (e) => {
    e.preventDefault();
    settapsfield24([...tapsfield24, {}]);
  };

  const removetap24 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield24];
    rows.splice(index, 1);
    settapsfield24(rows);
  };

  const handletap24 = (index, evnt24) => {
    const { name, value } = evnt24.target;
    const list = [...tapsfield24];
    list[index][name] = value;
    settapsfield24(list);
    setNewField(list);
  };

  const [tapsfield5, settapsfield5] = useState([{}]);

  const addInputtaps5 = (e) => {
    e.preventDefault();
    settapsfield5([...tapsfield5, {}]);
  };

  const removetap5 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield5];
    rows.splice(index, 1);
    settapsfield5(rows);
  };

  const handletap5 = (index, evnt5) => {
    const { name, value } = evnt5.target;
    const list = [...tapsfield5];
    list[index][name] = value;
    settapsfield5(list);
    setAqi(list);
  };
  const [tapsfield6, settapsfield6] = useState([{}]);

  const handletap6 = (index, evnt6) => {
    const { name, value } = evnt6.target;
    const list = [...tapsfield6];
    list[index][name] = value;
    settapsfield6(list);
    setParam_alert_array1(list);
  };

  const [tapsfield4, settapsfield4] = useState([{}]);

  const addInputtaps4 = (e) => {
    e.preventDefault();
    settapsfield4([...tapsfield4, {}]);
  };

  const removetap4 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield4];
    rows.splice(index, 1);
    settapsfield4(rows);
  };

  const handletap4 = (index, evnt4) => {
    const { name, value } = evnt4.target;
    const list = [...tapsfield4];
    list[index][name] = value;
    settapsfield4(list);
    setErr_codes(list);
  };

  const [tapsfield1, settapsfield1] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps1 = (e) => {
    e.preventDefault();
    settapsfield1([
      ...tapsfield1,
      {
        val: "",
      },
    ]);
  };

  const removetap1 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield1];
    rows.splice(index, 1);
    settapsfield1(rows);
  };

  const handletap1 = (index, evnt1) => {
    const { value } = evnt1.target;
    const list = [...tapsfield1];
    list[index] = value;
    settapsfield1(list);
    setRemove_trend(list);
  };

  const [tapsfield7, settapsfield7] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps7 = (e) => {
    e.preventDefault();
    settapsfield7([
      ...tapsfield7,
      {
        val: "",
      },
    ]);
  };

  const removetap7 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield7];
    rows.splice(index, 1);
    settapsfield7(rows);
  };

  const handletap7 = (index, evnt7) => {
    const { value } = evnt7.target;
    const list = [...tapsfield7];
    list[index] = value;
    settapsfield7(list);
    setBattery_status(list);
  };

  const [tapsfield8, settapsfield8] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps8 = (e) => {
    e.preventDefault();
    settapsfield8([
      ...tapsfield8,
      {
        val: "",
      },
    ]);
  };

  const removetap8 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield8];
    rows.splice(index, 1);
    settapsfield8(rows);
  };

  const handletap8 = (index, evnt8) => {
    const { value } = evnt8.target;

    const list = [...tapsfield8];
    list[index] = value;
    settapsfield8(list);
    setLoad_percent_status(list);
  };

  const [tapsfield9, settapsfield9] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps9 = (e) => {
    e.preventDefault();
    settapsfield9([
      ...tapsfield9,
      {
        val: "",
      },
    ]);
  };

  const removetap9 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield9];
    rows.splice(index, 1);
    settapsfield7(rows);
  };

  const handletap9 = (index, evnt9) => {
    const { value } = evnt9.target;

    const list = [...tapsfield9];
    list[index] = value;
    settapsfield9(list);
    setMog_alarm(list);
  };
  const [tapsfield10, settapsfield10] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps10 = (e) => {
    e.preventDefault();
    settapsfield10([
      ...tapsfield10,
      {
        val: "",
      },
    ]);
  };

  const removetap10 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield10];
    rows.splice(index, 1);
    settapsfield10(rows);
  };

  const handletap10 = (index, evnt10) => {
    const { value } = evnt10.target;

    const list = [...tapsfield10];
    list[index] = value;
    settapsfield10(list);
    setOti_trip(list);
  };
  const [tapsfield11, settapsfield11] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps11 = (e) => {
    e.preventDefault();
    settapsfield11([
      ...tapsfield11,
      {
        val: "",
      },
    ]);
  };

  const removetap11 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield11];
    rows.splice(index, 1);
    settapsfield11(rows);
  };

  const handletap11 = (index, evnt11) => {
    const { value } = evnt11.target;

    const list = [...tapsfield11];
    list[index] = value;
    settapsfield11(list);
    setWti_alarm_lv(list);
  };
  const [tapsfield12, settapsfield12] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps12 = (e) => {
    e.preventDefault();
    settapsfield12([
      ...tapsfield12,
      {
        val: "",
      },
    ]);
  };

  const removetap12 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield12];
    rows.splice(index, 1);
    settapsfield12(rows);
  };

  const handletap12 = (index, evnt12) => {
    const { value } = evnt12.target;

    const list = [...tapsfield12];
    list[index] = value;
    settapsfield12(list);
    setWti_trip_lv(list);
  };
  const [tapsfield13, settapsfield13] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps13 = (e) => {
    e.preventDefault();
    settapsfield13([
      ...tapsfield13,
      {
        val: "",
      },
    ]);
  };

  const removetap13 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield13];
    rows.splice(index, 1);
    settapsfield13(rows);
  };

  const handletap13 = (index, evnt13) => {
    const { value } = evnt13.target;

    const list = [...tapsfield13];
    list[index] = value;
    settapsfield13(list);
    setBuchholz_alarm(list);
  };
  const [tapsfield14, settapsfield14] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps14 = (e) => {
    e.preventDefault();
    settapsfield14([
      ...tapsfield14,
      {
        val: "",
      },
    ]);
  };

  const removetap14 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield14];
    rows.splice(index, 1);
    settapsfield14(rows);
  };

  const handletap14 = (index, evnt14) => {
    const { value } = evnt14.target;

    const list = [...tapsfield14];
    list[index] = value;
    settapsfield14(list);
    setPrv_trip(list);
  };
  const [tapsfield15, settapsfield15] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps15 = (e) => {
    e.preventDefault();
    settapsfield15([
      ...tapsfield15,
      {
        val: "",
      },
    ]);
  };

  const removetap15 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield15];
    rows.splice(index, 1);
    settapsfield15(rows);
  };

  const handletap15 = (index, evnt15) => {
    const { value } = evnt15.target;

    const list = [...tapsfield15];
    list[index] = value;
    settapsfield15(list);
    setOti_alarm(list);
  };
  const [tapsfield16, settapsfield16] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps16 = (e) => {
    e.preventDefault();
    settapsfield16([
      ...tapsfield16,
      {
        val: "",
      },
    ]);
  };

  const removetap16 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield16];
    rows.splice(index, 1);
    settapsfield16(rows);
  };

  const handletap16 = (index, evnt16) => {
    const { value } = evnt16.target;

    const list = [...tapsfield16];
    list[index] = value;
    settapsfield16(list);
    setLv_voltage_unbalance_status(list);
  };
  const [tapsfield17, settapsfield17] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps17 = (e) => {
    e.preventDefault();
    settapsfield17([
      ...tapsfield17,
      {
        val: "",
      },
    ]);
  };

  const removetap17 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield17];
    rows.splice(index, 1);
    settapsfield17(rows);
  };

  const handletap17 = (index, evnt17) => {
    const { value } = evnt17.target;

    const list = [...tapsfield17];
    list[index] = value;
    settapsfield17(list);
    setLv_current_unbalance_status(list);
  };
  const [tapsfield18, settapsfield18] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps18 = (e) => {
    e.preventDefault();
    settapsfield18([
      ...tapsfield18,
      {
        val: "",
      },
    ]);
  };

  const removetap18 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield18];
    rows.splice(index, 1);
    settapsfield18(rows);
  };

  const handletap18 = (index, evnt18) => {
    const { value } = evnt18.target;

    const list = [...tapsfield18];
    list[index] = value;
    settapsfield18(list);
    setHv_voltage_unbalance_status(list);
  };
  const [tapsfield19, settapsfield19] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps19 = (e) => {
    e.preventDefault();
    settapsfield19([
      ...tapsfield19,
      {
        val: "",
      },
    ]);
  };

  const removetap19 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield19];
    rows.splice(index, 1);
    settapsfield19(rows);
  };

  const handletap19 = (index, evnt19) => {
    const { value } = evnt19.target;

    const list = [...tapsfield19];
    list[index] = value;
    settapsfield19(list);
    setHv_current_unbalance_status(list);
  };
  const [tapsfield20, settapsfield20] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps20 = (e) => {
    e.preventDefault();
    settapsfield20([
      ...tapsfield20,
      {
        val: "",
      },
    ]);
  };

  const removetap20 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield20];
    rows.splice(index, 1);
    settapsfield20(rows);
  };

  const handletap20 = (index, evnt20) => {
    const { value } = evnt20.target;

    const list = [...tapsfield20];
    list[index] = value;
    settapsfield20(list);
    setCopper_loss_status(list);
  };
  const [tapsfield21, settapsfield21] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps21 = (e) => {
    e.preventDefault();
    settapsfield21([
      ...tapsfield21,
      {
        val: "",
      },
    ]);
  };

  const removetap21 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield21];
    rows.splice(index, 1);
    settapsfield21(rows);
  };

  const handletap21 = (index, evnt21) => {
    const { value } = evnt21.target;

    const list = [...tapsfield21];
    list[index] = value;
    settapsfield21(list);
    setEfficiency_status(list);
  };
  const [tapsfield22, settapsfield22] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps22 = (e) => {
    e.preventDefault();
    settapsfield22([
      ...tapsfield22,
      {
        val: "",
      },
    ]);
  };

  const removetap22 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield22];
    rows.splice(index, 1);
    settapsfield22(rows);
  };

  const handletap22 = (index, evnt22) => {
    const { value } = evnt22.target;

    const list = [...tapsfield22];
    list[index] = value;
    settapsfield22(list);
    setBypass_Status(list);
  };

  const [tapsfield23, settapsfield23] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps23 = (e) => {
    e.preventDefault();
    settapsfield23([
      ...tapsfield23,
      {
        val: "",
      },
    ]);
  };

  const removetap23 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield23];
    rows.splice(index, 1);
    settapsfield23(rows);
  };

  const handletap23 = (index, evnt23) => {
    const { value } = evnt23.target;

    const list = [...tapsfield23];
    list[index] = value;
    settapsfield23(list);
    setCategory(list);
  };

  const [tapsfield2, settapsfield2] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps2 = (e) => {
    e.preventDefault();
    settapsfield2([
      ...tapsfield2,
      {
        val: "",
      },
    ]);
  };

  const removetap2 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield2];
    rows.splice(index, 1);
    settapsfield2(rows);
  };

  const handletap2 = (index, evnt2) => {
    const { value } = evnt2.target;
    const list = [...tapsfield2];
    list[index] = value;
    settapsfield2(list);
    setAsset_types(list);
  };

  const [tapsfield3, settapsfield3] = useState([
    {
      val: "",
    },
  ]);

  const addInputtaps3 = (e) => {
    e.preventDefault();
    settapsfield3([
      ...tapsfield3,
      {
        val: "",
      },
    ]);
  };

  const removetap3 = (e, index) => {
    e.preventDefault();
    const rows = [...tapsfield3];
    rows.splice(index, 1);
    settapsfield3(rows);
  };

  const handletap3 = (index, evnt3) => {
    const { value } = evnt3.target;
    const list = [...tapsfield3];
    list[index] = value;
    settapsfield3(list);
    setRemove_alert(list);
  };

  const formInitialValues = {
    iot_sol: "",
    data_fields: data_fields,
    err_codes: err_codes,
    remove_trend: remove_trend,
    remove_alert: remove_alert,
    param_alert_array1: param_alert_array1,
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        try {
          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/api/iot_solution_masters`,
            {
              method: "POST",
              body: JSON.stringify({
                iot_sol: values.iot_sol,
                data_fields: data_fields,
                err_codes: err_codes,
                remove_trend: remove_trend,
                remove_alert: remove_alert,
                param_alert_array: param_alert_array1,
                newfield: newfield,
                // asset_types: asset_types,
              }),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          let resJson = await res.json();

          if (res.status === 200) {
            setIot_sol("");
            setData_fields([]);
            setErr_codes([]);
            setAsset_types([]);
            setAqi([]);
            setRemove_alert([]);
            setRemove_trend([]);
            setParam_alert_array({});
            alert("Iot Solution Master added successfully");
            fetchData();
          } else {
            alert(resJson.message);
          }
        } catch (err) {
          console.log(err);
        }
      },
    });

  const editISMid = async (_id) => {
    let ismid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/iot_solution_masters/get/${ismid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setISMEdit(res.iotSolutionMasters);
    if (res.iotSolutionMasters._id === _id) {
      setShowSelectList(true);
    } else {
      setShowSelectList(false);
    }
  };

  const fetchData1 = async (_id) => {
    try {
      let ismid = _id;

      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/iot_solution_masters/get/${ismid}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();

      setAQI(json.iotSolutionMasters);
    } catch (error) {
      setError1(error1);
    }
  };
  const fetchData2 = async (_id) => {
    try {
      let ismid = _id;

      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/iot_solution_masters/get/${ismid}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();

      setParam_alert_array(json.iotSolutionMasters.param_alert_array);
    } catch (error) {
      setError1(error1);
    }
  };

  function handleEdit(e) {
    setISMEdit({ ...ISMEdit, [e.target.name]: e.target.value });
  }

  function handleOptionChange(e, _id) {
    const selectedValue = e.target.value;
    if (selectedValue === "data_field") {
      history.push(`/data_field/${_id}`);
    }
    if (selectedValue === "err_codes") {
      history.push(`/err_codes/${_id}`);
    }
    if (selectedValue === "remove_trends") {
      history.push(`/remove_trends/${_id}`);
    }
    if (selectedValue === "remove_alert") {
      history.push(`/remove_alert/${_id}`);
    }
    if (selectedValue === "aqi") {
      history.push(`/aqi/${_id}`);
    }
    if (selectedValue === "asset_type") {
      history.push(`/asset_type/${_id}`);
    }
    if (selectedValue === "param_alert_array") {
      history.push(`/param_alert_array/${_id}`);
    }
  }

  const columns = [
    {
      name: "IOT Solutions",
      selector: (row) => <div>{row.iot_sol}</div>,
      sortable: true,
    },

    {
      name: "Data Fields",
      selector: (row) => row.dataFieldCount,
      sortable: true,
    },
    {
      name: "Error Codes",
      selector: (row) => row.errorCodeCount,
      sortable: true,
    },

    {
      name: "Remove Trend",
      selector: (row) => row.removeTrendCount,

      sortable: true,
    },

    {
      name: "Remove Alert",
      selector: (row) => row.removeAlertCount,

      sortable: true,
    },

    {
      name: "AQI",
      selector: (row) => row.aqiCount,

      sortable: true,
    },

    {
      name: "Asset Types",
      selector: (row) => row.assetTypesCount,

      sortable: true,
    },

    {
      name: "Param Alert Array",
      selector: (row) => row.paramalertarrayCount,

      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-primary"
            onClick={() => setShowSelectList(row._id)}
          >
            View/Edit
          </button>

          {showSelectList === row._id && (
            <select onChange={(e) => handleOptionChange(e, row._id)}>
              <option value="">Select</option>
              <option value="data_field">Data Fields</option>
              <option value="err_codes">Error Codes</option>
              <option value="remove_trends">Remove Trends</option>
              <option value="remove_alert"> Remove Alerts</option>
              <option value="aqi">AQI</option>
              <option value="asset_type">Asset Types</option>
              <option value="param_alert_array">Param alert array</option>
            </select>
          )}
        </div>
        // <div >
        //   <a>
        //     <i
        //       href={row._id}
        //       onClick={() => editISMid(row._id)}
        //       classNameName="btn btn-primary"
        //       className="fa fa-edit"
        //       data-toggle="modal"
        //       data-target="#editiotsolmaster"
        //     ></i>
        //   </a>
        // </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = LoadedIotSolMaster.reverse.filter((test) => {
      return (
        test.iot_sol.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.dataFieldCount
          .toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.errorCodeCount
          .toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
      //   test.removeTrendCount
      //     .toString()
      //     .toLowerCase()
      //     .match(val.toLocaleLowerCase())
      // ); //||
      // test.removeAlertCount
      //   .toString()
      //   .toLowerCase()
      //   .match(val.toLocaleLowerCase()) ||
      // test.aqiCount.toString().toLowerCase().match(val.toLocaleLowerCase()) ||
      // test.assetTypesCount
      //   .toString()
      //   .toLowerCase()
      //   .match(val.toLocaleLowerCase()) ||
      // test.paramalertarrayCount
      //   .toString()
      //   .toLowerCase()
      //   .match(val.toLocaleLowerCase())
    });
    var strAscending = [...result].sort((a, b) =>
      a.iot_sol > b.iot_sol ? 1 : -1
    );
    setLoadedIotSolMaster(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div class="col-sm-6">
                  <h1 className="pageTitle">Iot Solution Masters</h1>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-6 mb20">
                  <div className="align-items-center mb20">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="form-inline pr-form-inline float-right">
                        <div className="input-group pr-form-group">
                          <a
                            href="addIotsolmaster.html"
                            type="button"
                            className="btn btn-c-outline-primary"
                            data-toggle="modal"
                            data-target="#addiotsolmaster"
                          >
                            {" "}
                            + Add Iot Solution Master{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <div id="example_filter" className="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={LoadedIotSolMaster}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>IOT Solutions</b>
                                      </th>
                                      <th>
                                        <b>Data Fields</b>
                                      </th>
                                      <th>
                                        <b>Error Codes</b>
                                      </th>
                                      <th>
                                        <b>Remove Trend</b>
                                      </th>
                                      <th>
                                        <b>Remove Alert</b>
                                      </th>
                                      <th>
                                        <b>AQI</b>
                                      </th>
                                      <th>
                                        <b>Asset Types</b>
                                      </th>
                                      <th>
                                        <b>Param Alert Array</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={10}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addiotsolmaster"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addiotsolmaster"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Add Iot Solution Master
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="sector" className="col-form-label">
                              Iot Solution *
                            </label>
                            <input
                              type="text"
                              name="iot_sol"
                              value={values.iot_sol}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Iot Solution"
                            />
                            {errors.iot_sol && touched.iot_sol ? (
                              <span style={{ color: "red" }}>
                                {errors.iot_sol}
                              </span>
                            ) : null}
                          </div>
                          {/* <div className="form-group">
                            <label for="sector" className="col-form-label">
                              Iot Solution *
                            </label>
                            <input
                              type="text"
                              value={iot_sol}
                              className="form-control"
                              id="locName"
                              onChange={(e) => setIot_sol(e.target.value)}
                              placeholder=""
                            />
                          </div> */}
                          {/* <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Data Fields *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps">
                              {" "}
                              <input
                                type="text"
                                name="data_fields"
                                value={values.data_fields}
                                className="form-control"
                                id="locName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Data Fields"
                              />
                              {errors.data_fields && touched.data_fields ? (
                                <span style={{ color: "red" }}>
                                  {errors.data_fields}
                                </span>
                              ) : null}
                            </span>
                          </div> */}
                          <div className="form-group">
                            <label for="contactNo" className="col-form-label">
                              Data Fields *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps">
                              {" "}
                              <input
                                type="text"
                                value={data_fields}
                                className="form-control"
                                id="locName"
                                onChange={(e) => setData_fields(data_fields)}
                                placeholder="Enter Data Fields"
                              />
                            </span>
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Error Codes *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps4">
                              {" "}
                              <input
                                type="text"
                                name="err_codes"
                                value={values.err_codes}
                                className="form-control"
                                id="locName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Error Codes"
                              />
                              {errors.err_codes && touched.err_codes ? (
                                <span style={{ color: "red" }}>
                                  {errors.err_codes}
                                </span>
                              ) : null}
                            </span>
                          </div> */}
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Error Codes *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps4">
                              {" "}
                              <input
                                type="text"
                                value={err_codes}
                                className="form-control"
                                id="locName"
                                onChange={(e) => setErr_codes([e.target.value])}
                                placeholder="Enter Error Codes"
                              />
                            </span>
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Remove Trend *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps1">
                              {" "}
                              <input
                                type="text"
                                name="remove_trend"
                                value={values.remove_trend}
                                className="form-control"
                                id="locName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Remove Trends"
                              />
                              {errors.remove_trend && touched.remove_trend ? (
                                <span style={{ color: "red" }}>
                                  {errors.remove_trend}
                                </span>
                              ) : null}
                            </span>
                          </div> */}
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Remove Trend *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps1">
                              {" "}
                              <input
                                type="text"
                                value={remove_trend}
                                className="form-control"
                                id="locName"
                                onChange={(e) =>
                                  setRemove_trend([e.target.value])
                                }
                                placeholder="Enter Remove Trend"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Remove Alert *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps3">
                              {" "}
                              <input
                                type="text"
                                name="remove_alert"
                                value={values.remove_alert}
                                className="form-control"
                                id="locName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Remove Alerts"
                              />{" "}
                              {errors.remove_alert && touched.remove_alert ? (
                                <span style={{ color: "red" }}>
                                  {errors.remove_alert}
                                </span>
                              ) : null}
                            </span>
                          </div> */}
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Remove Alert *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps3">
                              {" "}
                              <input
                                type="text"
                                value={remove_alert}
                                className="form-control"
                                id="locName"
                                onChange={(e) =>
                                  setRemove_alert([e.target.value])
                                }
                                placeholder="Enter Remove Alert"
                              />
                            </span>
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Aqi
                            </label>
                            <span data-toggle="modal" data-target="#addtaps5">
                              {" "}
                              <input
                                type="text"
                                value={aqi}
                                className="form-control"
                                id="locName"
                                onChange={(e) => setAqi([e.target.value])}
                                placeholder=""
                              />
                            </span>
                          </div>
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Types
                            </label>
                            <span data-toggle="modal" data-target="#addtaps2">
                              {" "}
                              <input
                                type="text"
                                value={asset_types}
                                className="form-control"
                                id="locName"
                                onChange={(e) =>
                                  setAsset_types([e.target.value])
                                }
                                placeholder=""
                              />
                            </span>
                          </div> */}
                          {/* <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Param Alert Array
                            </label>
                            <span data-toggle="modal" data-target="#addtaps6">
                              {" "}
                              <input
                                type="text"
                                name="param_alert_array1"
                                value={values.param_alert_array1}
                                className="form-control"
                                id="locName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Param Alert Array"
                              />
                              {errors.param_alert_array1 &&
                              touched.param_alert_array1 ? (
                                <span style={{ color: "red" }}>
                                  {errors.param_alert_array1}
                                </span>
                              ) : null}
                            </span>
                          </div> */}

                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Param Alert Array *
                            </label>
                            <span data-toggle="modal" data-target="#addtaps6">
                              {" "}
                              <input
                                type="text"
                                value={param_alert_array1}
                                className="form-control"
                                id="locName"
                                onChange={(e) =>
                                  setParam_alert_array1(param_alert_array1)
                                }
                                placeholder="Enter Param Alert Array"
                              />
                            </span>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div>
                                {tapsfield24.map((data, index) => {
                                  const { props, val } = data;
                                  return (
                                    <div className="row" key={index}>
                                      <div className="col-md-5">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            onChange={(evnt24) =>
                                              handletap24(index, evnt24)
                                            }
                                            value={props}
                                            name="props"
                                            className="form-control"
                                            placeholder="Field"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-5">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            onChange={(evnt24) =>
                                              handletap24(index, evnt24)
                                            }
                                            value={val}
                                            name="val"
                                            className="form-control"
                                            placeholder="Value"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-2">
                                        <div className="form-group">
                                          {tapsfield24.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap24}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                <div className="row">
                                  <div className="col-sm-6">
                                    <button
                                      className="btn btn-outline-success "
                                      onClick={addInputtaps24}
                                    >
                                      Add New
                                    </button>
                                  </div>
                                  {/* <div className="col-sm-6">
                                    <button
                                      className="btn btn-outline-primary "
                                      onClick={(e) =>
                                        setNewField({ props, val })
                                      }
                                      data-dismiss="modal"
                                    >
                                      Submit
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4"></div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit}
                          data-dismiss={values.iot_sol ? "modal" : ""}
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Data Fields
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield.map((data, index) => {
                                    const { para_loc, data_type, para_name } =
                                      data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={para_loc}
                                              name="para_loc"
                                              className="form-control"
                                              placeholder="para_loc"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={data_type}
                                              name="data_type"
                                              className="form-control"
                                              placeholder="data_type"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt) =>
                                                handletap(index, evnt)
                                              }
                                              value={para_name}
                                              name="para_name"
                                              className="form-control"
                                              placeholder="para_name"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) => settapsfield(tapsfield)}
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps1"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps1"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Remove Trends
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield1.map((data, index) => {
                                    const { val } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt1) =>
                                                handletap1(index, evnt1)
                                              }
                                              value={val}
                                              name="val"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield1.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap1}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps1}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield1(tapsfield1)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps2"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps2"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield2.map((data, index) => {
                                    const { val2 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt2) =>
                                                handletap2(index, evnt2)
                                              }
                                              value={val2}
                                              name="val2"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield2.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap2}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps2}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield2(tapsfield2)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps3"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps3"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Remove Alerts
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield3.map((data, index) => {
                                    const { val3 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt3) =>
                                                handletap3(index, evnt3)
                                              }
                                              value={val3}
                                              name="val3"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield3.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap3}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps3}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield3(tapsfield3)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps4"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps4"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Error Codes
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield4.map((data, index) => {
                                    const { ename, desc } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-5">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt4) =>
                                                handletap4(index, evnt4)
                                              }
                                              value={ename}
                                              name="ename"
                                              className="form-control"
                                              placeholder="ename"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt4) =>
                                                handletap4(index, evnt4)
                                              }
                                              value={desc}
                                              name="desc"
                                              className="form-control"
                                              placeholder="description"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield4.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap4}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps4}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield4(tapsfield4)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps5"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps5"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield5.map((data, index) => {
                                    const { category, min, max, desc } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt5) =>
                                                handletap5(index, evnt5)
                                              }
                                              value={category}
                                              name="category"
                                              className="form-control"
                                              placeholder="category"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt5) =>
                                                handletap5(index, evnt5)
                                              }
                                              value={min}
                                              name="min"
                                              className="form-control"
                                              placeholder="min"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt5) =>
                                                handletap5(index, evnt5)
                                              }
                                              value={max}
                                              name="max"
                                              className="form-control"
                                              placeholder="max"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt5) =>
                                                handletap5(index, evnt5)
                                              }
                                              value={desc}
                                              name="desc"
                                              className="form-control"
                                              placeholder="description"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield5.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap5}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={addInputtaps5}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield5(tapsfield5)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addtaps6"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps6"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Param Alert Arrays
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps7"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setBattery_status(e.target.value)
                                          }
                                          value={battery_status}
                                          name="battery_status"
                                          className="form-control"
                                          placeholder="battery_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps8"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setLoad_percent_status(
                                              e.target.value
                                            )
                                          }
                                          value={load_percent_status}
                                          name="load_percent_status"
                                          className="form-control"
                                          placeholder="load_percent_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps9"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setMog_alarm(e.target.value)
                                          }
                                          value={mog_alarm}
                                          name="mog_alarm"
                                          className="form-control"
                                          placeholder="mog_alarm"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps10"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setOti_trip(e.target.value)
                                          }
                                          value={oti_trip}
                                          name="oti_trip"
                                          className="form-control"
                                          placeholder="oti_trip"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps11"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setWti_alarm_lv(e.target.value)
                                          }
                                          value={wti_alarm_lv}
                                          name="wti_alarm_lv"
                                          className="form-control"
                                          placeholder="wti_alarm_lv"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps12"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setWti_trip_lv(e.target.value)
                                          }
                                          value={wti_trip_lv}
                                          name="wti_trip_lv"
                                          className="form-control"
                                          placeholder="wti_trip_lv"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps13"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setBuchholz_alarm(e.target.value)
                                          }
                                          value={buchholz_alarm}
                                          name="buchholz_alarm"
                                          className="form-control"
                                          placeholder="buchholz_alarm"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps14"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setPrv_trip(e.target.value)
                                          }
                                          value={prv_trip}
                                          name="prv_trip"
                                          className="form-control"
                                          placeholder="prv_trip"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps15"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setOti_alarm(e.target.value)
                                          }
                                          value={oti_alarm}
                                          name="oti_alarm"
                                          className="form-control"
                                          placeholder="oti_alarm"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps16"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setLv_voltage_unbalance_status(
                                              e.target.value
                                            )
                                          }
                                          value={lv_voltage_unbalance_status}
                                          name="lv_voltage_unbalance_status"
                                          className="form-control"
                                          placeholder="lv_voltage_unbalance_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps17"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setLv_current_unbalance_status(
                                              e.target.value
                                            )
                                          }
                                          value={lv_current_unbalance_status}
                                          name="lv_current_unbalance_status"
                                          className="form-control"
                                          placeholder="lv_current_unbalance_status"
                                        />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps18"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setHv_voltage_unbalance_status(
                                              e.target.value
                                            )
                                          }
                                          value={hv_voltage_unbalance_status}
                                          name="hv_voltage_unbalance_status"
                                          className="form-control"
                                          placeholder="hv_voltage_unbalance_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps19"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setHv_current_unbalance_status(
                                              e.target.value
                                            )
                                          }
                                          value={hv_current_unbalance_status}
                                          name="hv_current_unbalance_status"
                                          className="form-control"
                                          placeholder="hv_current_unbalance_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps20"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setCopper_loss_status(
                                              e.target.value
                                            )
                                          }
                                          value={copper_loss_status}
                                          name="copper_loss_status"
                                          className="form-control"
                                          placeholder="copper_loss_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps21"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setEfficiency_status(e.target.value)
                                          }
                                          value={efficiency_status}
                                          name="efficiency_status"
                                          className="form-control"
                                          placeholder="efficiency_status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps22"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setBypass_Status(e.target.value)
                                          }
                                          value={Bypass_Status}
                                          name="Bypass_Status"
                                          className="form-control"
                                          placeholder="Bypass_Status"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span
                                        data-toggle="modal"
                                        data-target="#addtaps23"
                                      >
                                        {" "}
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setCategory(e.target.value)
                                          }
                                          value={Category}
                                          name="Category"
                                          className="form-control"
                                          placeholder="Category"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <button
                                      className="btn btn-outline-primary "
                                      onClick={(e) =>
                                        setParam_alert_array1({
                                          battery_status: battery_status,
                                          load_percent_status:
                                            load_percent_status,
                                          mog_alarm: mog_alarm,
                                          oti_trip: oti_trip,
                                          wti_alarm_lv: wti_alarm_lv,
                                          wti_trip_lv: wti_trip_lv,
                                          buchholz_alarm: buchholz_alarm,
                                          prv_trip: prv_trip,
                                          oti_alarm: oti_alarm,
                                          lv_voltage_unbalance_status:
                                            lv_voltage_unbalance_status,
                                          lv_current_unbalance_status:
                                            lv_current_unbalance_status,
                                          hv_voltage_unbalance_status:
                                            hv_voltage_unbalance_status,
                                          hv_current_unbalance_status:
                                            hv_current_unbalance_status,
                                          copper_loss_status:
                                            copper_loss_status,
                                          efficiency_status: efficiency_status,
                                          Bypass_Status: Bypass_Status,
                                          Category: Category,
                                        })
                                      }
                                      data-dismiss="modal"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps7"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps7"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield7.map((data, index) => {
                                    const { val7 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt7) =>
                                                handletap7(index, evnt7)
                                              }
                                              value={val7}
                                              name="val7"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield7.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap7}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps7}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield7(tapsfield7)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps8"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps8"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield8.map((data, index) => {
                                    const { val8 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt8) =>
                                                handletap8(index, evnt8)
                                              }
                                              value={val8}
                                              name="val8"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield8.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap8}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps8}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield8(tapsfield8)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps9"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps9"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield9.map((data, index) => {
                                    const { val9 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt9) =>
                                                handletap9(index, evnt9)
                                              }
                                              value={val9}
                                              name="val9"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield9.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap9}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps9}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield9(tapsfield9)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps10"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps10"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield10.map((data, index) => {
                                    const { val10 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt10) =>
                                                handletap10(index, evnt10)
                                              }
                                              value={val10}
                                              name="val10"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield10.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap10}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps10}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield10(tapsfield10)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps11"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps11"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield11.map((data, index) => {
                                    const { val11 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt11) =>
                                                handletap11(index, evnt11)
                                              }
                                              value={val11}
                                              name="val11"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield11.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap11}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps11}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield11(tapsfield11)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps12"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps12"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield12.map((data, index) => {
                                    const { val12 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt12) =>
                                                handletap12(index, evnt12)
                                              }
                                              value={val12}
                                              name="val12"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield12.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap12}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps12}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield12(tapsfield12)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps13"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps13"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield13.map((data, index) => {
                                    const { val13 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt13) =>
                                                handletap13(index, evnt13)
                                              }
                                              value={val13}
                                              name="val13"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield13.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap13}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps13}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield13(tapsfield13)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps14"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps14"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield14.map((data, index) => {
                                    const { val14 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt14) =>
                                                handletap14(index, evnt14)
                                              }
                                              value={val14}
                                              name="val14"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield14.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap14}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps14}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield14(tapsfield14)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps15"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps15"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield15.map((data, index) => {
                                    const { val15 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt15) =>
                                                handletap15(index, evnt15)
                                              }
                                              value={val15}
                                              name="val15"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield15.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap15}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps15}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield15(tapsfield15)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps16"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps16"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield16.map((data, index) => {
                                    const { val16 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt16) =>
                                                handletap16(index, evnt16)
                                              }
                                              value={val16}
                                              name="val16"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield16.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap16}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps16}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield16(tapsfield16)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps17"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps17"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield17.map((data, index) => {
                                    const { val17 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt17) =>
                                                handletap17(index, evnt17)
                                              }
                                              value={val17}
                                              name="val17"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield17.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap17}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps17}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield17(tapsfield17)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps18"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps18"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield18.map((data, index) => {
                                    const { val18 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt18) =>
                                                handletap18(index, evnt18)
                                              }
                                              value={val18}
                                              name="val18"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield18.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap18}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps18}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield18(tapsfield18)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps19"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps19"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield19.map((data, index) => {
                                    const { val19 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt19) =>
                                                handletap19(index, evnt19)
                                              }
                                              value={val19}
                                              name="val19"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield19.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap19}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps19}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield19(tapsfield19)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps20"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps20"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield20.map((data, index) => {
                                    const { val20 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt20) =>
                                                handletap20(index, evnt20)
                                              }
                                              value={val20}
                                              name="val20"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield20.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap20}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps20}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield20(tapsfield20)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps21"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps21"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield21.map((data, index) => {
                                    const { val21 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt21) =>
                                                handletap21(index, evnt21)
                                              }
                                              value={val21}
                                              name="val21"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield21.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap21}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps21}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield21(tapsfield21)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps22"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps22"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield22.map((data, index) => {
                                    const { val22 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt22) =>
                                                handletap22(index, evnt22)
                                              }
                                              value={val22}
                                              name="val22"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield22.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap22}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps22}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield22(tapsfield22)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="addtaps23"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtaps23"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Add Taps</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label"
                            ></label>
                            <div className="container">
                              <div className="row">
                                <div>
                                  {tapsfield23.map((data, index) => {
                                    const { val23 } = data;
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-7">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              onChange={(evnt23) =>
                                                handletap23(index, evnt23)
                                              }
                                              value={val23}
                                              name="val23"
                                              className="form-control"
                                              placeholder="Value"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          {tapsfield23.length !== 1 ? (
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={removetap23}
                                            >
                                              x
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={addInputtaps23}
                                      >
                                        Add New
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        className="btn btn-outline-primary "
                                        onClick={(e) =>
                                          settapsfield23(tapsfield23)
                                        }
                                        data-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="aqi"
              tabindex="-1"
              role="dialog"
              aria-labelledby="aqi"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <div className="table-responsive">
                      <div className="row">
                        <div
                          className="col-sm-12 row-wise"
                          style={{ display: "block" }}
                        >
                          <table
                            id="example"
                            className="table table-hover dtTable dtr-inline"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Category</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AQI.aqi &&
                                AQI.aqi.map((aqi) => (
                                  <tr>
                                    <td>{aqi.category}</td>
                                    <td>{aqi.min}</td>
                                    <td>{aqi.max}</td>
                                    <td>{aqi.desc}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="paramalertarray"
              tabindex="-1"
              role="dialog"
              aria-labelledby="paramalertarray"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <div className="table-responsive">
                      <div className="row">
                        <div
                          className="col-sm-12 row-wise"
                          style={{ display: "block" }}
                        >
                          <table
                            id="example"
                            className="table table-hover dtTable dtr-inline"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Param Alert</th>
                                <th>Array</th>
                                <th></th>
                                <th></th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {param_alert_array?.battery_status && (
                                <tr>
                                  <td>battery_status</td>

                                  {param_alert_array.battery_status.map(
                                    (pla) => (
                                      <td>
                                        <ul>
                                          <li>{pla}</li>
                                        </ul>
                                      </td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.load_percent_status && (
                                <tr>
                                  <td>load_percent_status</td>
                                  {param_alert_array.load_percent_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.mog_alarm && (
                                <tr>
                                  <td>mog_alarm</td>
                                  {param_alert_array.mog_alarm.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.oti_trip && (
                                <tr>
                                  <td>oti_trip</td>
                                  {param_alert_array.oti_trip.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.wti_alarm_lv && (
                                <tr>
                                  <td>wti_alarm_lv</td>
                                  {param_alert_array.wti_alarm_lv.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.wti_trip_lv && (
                                <tr>
                                  <td>wti_trip_lv</td>
                                  {param_alert_array.wti_trip_lv.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.buchholz_alarm && (
                                <tr>
                                  <td>buchholz_alarm</td>
                                  {param_alert_array.buchholz_alarm.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.prv_trip && (
                                <tr>
                                  <td>prv_trip</td>
                                  {param_alert_array.prv_trip.map((pla) => (
                                    <td>{pla}</td>
                                  ))}{" "}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.oti_alarm && (
                                <tr>
                                  <td>oti_alarm</td>
                                  {param_alert_array.oti_alarm.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.lv_voltage_unbalance_status && (
                                <tr>
                                  <td>lv_voltage_unbalance_status</td>
                                  {param_alert_array.lv_voltage_unbalance_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.lv_current_unbalance_status && (
                                <tr>
                                  <td>lv_current_unbalance_status</td>
                                  {param_alert_array.lv_current_unbalance_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.hv_voltage_unbalance_status && (
                                <tr>
                                  <td>hv_voltage_unbalance_status</td>{" "}
                                  {param_alert_array.hv_voltage_unbalance_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.hv_voltage_unbalance_status && (
                                <tr>
                                  <td>hv_voltage_unbalance_status</td>
                                  {param_alert_array.hv_voltage_unbalance_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.hv_current_unbalance_status && (
                                <tr>
                                  <td>hv_current_unbalance_status</td>
                                  {param_alert_array.hv_current_unbalance_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.copper_loss_status && (
                                <tr>
                                  <td> copper_loss_status</td>
                                  {param_alert_array.copper_loss_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}

                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.efficiency_status && (
                                <tr>
                                  <td>efficiency_status</td>
                                  {param_alert_array.efficiency_status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.Category && (
                                <tr>
                                  <td>Category</td>
                                  {param_alert_array.Category.map((pla) => (
                                    <td>{pla}</td>
                                  ))}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                              {param_alert_array?.Bypass_Status && (
                                <tr>
                                  <td>Bypass_Status</td>
                                  {param_alert_array.Bypass_Status.map(
                                    (pla) => (
                                      <td>{pla}</td>
                                    )
                                  )}
                                  <td></td>
                                  <td>
                                    <a>Edit</a>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Iot_solution_master;
