
import React, { useState, useEffect } from "react";
import { tableCustomStyles } from "../tableStyle.jsx";
import DataTable from "react-data-table-component";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import axios from "axios";

function IotSensorMaster(props) {
  
  const [iotsensormasters, setIotSensorMasters] = useState([]);
  const [search, setSerach] = useState("");
  const [total, setTotal] = useState([]);
  const history = useHistory();
  const { state } = useLocation();
  const [showSelectList, setShowSelectList] = useState();
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState();
  const [Status, set1Status] = useState({});
  const [edit, setEdit] = useState(false);



  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllIotSensorMasters`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.subdomain > b.subdomain ? 1 : -1
      );
      setTotal(strAscending);
      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setIotSensorMasters(strAscending);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  function handleButtonClick() {
    setShowSelectList(true);
  }

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.subdomain.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.device_status?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.iot_sol
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.repo_asset_name?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.sensor_uuid?.toLowerCase().match(search.toLocaleLowerCase()) ||
       
        test.sensor_fields_length
          ?.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.calculated_fields_length
          ?.toString()
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setIotSensorMasters(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const [inpval, setINP] = useState({
    device_status: "",
    account_name: "",
    iot_solution_master_name: "",
    subaccount_name: "",
    location: "",
    department: "",
    asset: "",
    iot_sol: "",
    device_uuid: "",
    sensor_uuid: "",
    subdomain: "",
    iot_subaccount: "",
    iot_location: "",
    iot_department: "",
    iot_asset: "",
    latitude: "",
    longitude: "",
    data_freq_min: "",
    mqtt: "",
    nameplate: "",
    name: "",
    manufacturer: "",
    sr_no: "",
    manufacturing_year: "",
    sensor_fields: "",
    taps: "",
    calculated_fields: "",
    repo_asset_category: "",
    repo_asset_manufacturer: "",
    iot_asset_category: "",
    iot_asset_manufacturer: "",
    calculation: "",
    pf_correction: "",
    total_fields: "",
    total_batteries: "",
  });

 

  
 
 
 
 
 
  function handleOptionChange(e, _id, calculation) {
    const selectedValue = e.target.value;

    if (selectedValue === "taps") {
      history.push(`/taps/${_id}`);
    };
    if(selectedValue === "nameplates" ){
    if (selectedValue === "nameplates" && calculation !== "disable") {
      fetchData();
      history.push(`/nameplates/${_id}`);
    } else {
      alert("Please Enable Calculation.");
      fetchData();
    };}
  }
  const [ISMedit, setISMEdit] = useState();
  const editISMid = async (id) => {
    let _id = id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/getIotSensorMaster/${_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setISMEdit(res);
  };
  const handlePFCorrectionUpdate = async (e) => {
    e.preventDefault();
    let _id = ISMedit.id;
    console.log("pf_correction status", status);
    console.log("pf_correction", _id);
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/updatePFCorrection/${_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pf_correction: status }),
      }
    );
    const data = await response.json();
    if (data) {
      setEdit(true);
    }
    if (response.status === 200 || data) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("PF Correction " + StatusText + " successfully");

      fetchData();
    } else {
      alert(data.message);
    }
  };
  const handleCalculationUpdate = async (e) => {
    e.preventDefault();
    let _id = ISMedit.id;
    console.log("calculation", _id);
    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Iot/updateCalculation/${_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ calculation: status }),
      }
    );
    const data = await response.json();
    if (data) {
      setEdit(true);
    }
    if (response.status === 200 || data) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("Calculation " + StatusText + " successfully");

      fetchData();
    } else {
      alert(data.message);
    }
  };
  const columns = [
    {
      name: "Subdomain",
      selector: (row) => <div className="bigtext"> {row.subdomain}</div>,
      sortable: true,
    },
    {
      name: "Device Status",
      selector: (row) => <div> {row.device_status}</div>,
      sortable: true,
    },
    {
      name: "Solution",
      selector: (row) => <div> {row.iot_sol}</div>,
      sortable: true,
    },
    {
      name: "Asset",
     
      selector: (row) => <div> {row.repo_asset_name} </div>,
      sortable: true,
    },
    {
      name: "Sensor UUID",
      selector: (row) => <div className="bigtext"> {row.sensor_uuid}</div>,
      sortable: true,
    },
   
 
    {
      name: "Sensor Fields",
      selector: (row) => (
        <div
       
        >
          {row.sensor_fields_length}
        
        </div>
      ),
      sortable: true,
    },
    {
      name: "Calculated Fields",
      selector: (row) => (
        <div
      
        >
          {row.calculated_fields_length}
         
        </div>
      ),
      sortable: true,
    },
    {
      name: "PF Correction",
      width: "250px",
      cell: (row) => (
        <a
          href={row.id}
          onClick={() => {
            editISMid(row.id);
          }}
        >
          {(row.iot_sol === "Transformer" ||
            row.iot_sol === "UPS" ||
            row.iot_sol === "HVAC" ||
            row.iot_sol === "Motor" ||
            row.iot_sol === "EMS") && (
            <div className="switch">
              <label className="switch-light">
                {edit ? (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => {
                      set1Status(e.target.value);
                    }}
                    defaultChecked={row.pf_correction === "disable"}
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => {
                      set1Status(e.target.value);
                    }}
                    checked={row.pf_correction === "disable"}
                  />
                )}

                <span>
                  <span
                    id="enable"
                    onClick={(e) => {
                      setStatus("enable");
                    }}
                    data-toggle="modal"
                    data-target="#enableconfirmation"
                  >
                    Enable{" "}
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => {
                      setStatus("disable");
                    }}
                    data-toggle="modal"
                    data-target="#disableconfirmation"
                  >
                    Disable{" "}
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          )}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Calculation",
      width: "250px",
      cell: (row) => (
        <a
          href={row.id}
          onClick={() => {
            editISMid(row.id);
          }}
        >
          {(row.iot_sol === "Transformer" ||
            row.iot_sol === "UPS" ||
            row.iot_sol === "HVAC" ||
            row.iot_sol === "Motor" ||
            row.iot_sol === "EMS" ||
            row.iot_sol === "SELCO" || row.iot_sol === "Solar") && (
            <div className="switch">
              <label className="switch-light">
                {edit ? (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => {
                      set1Status(e.target.value);
                    }}
                    defaultChecked={row.calculation === "disable"}
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? "enable" : "disable"}
                    onChange={(e) => {
                      set1Status(e.target.value);
                    }}
                    checked={row.calculation === "disable"}
                  />
                )}

                <span>
                  <span
                    id="enable"
                    onClick={(e) => {
                      setStatus("enable");
                    }}
                    data-toggle="modal"
                    data-target="#enablecalculationconfirmation"
                  >
                    Enable{" "}
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => {
                      setStatus("disable");
                    }}
                    data-toggle="modal"
                    data-target="#disablecalculationconfirmation"
                  >
                    Disable{" "}
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          )}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          &nbsp;&nbsp;
          <div>
            {(row.iot_sol === "Transformer" ||
              row.iot_sol === "EMS" ||
              row.iot_sol === "SELCO" || row.iot_sol === "Solar") && (
              <a>
                {" "}
                <i
                  className="btn btn-primary"
                  class="fa fa-edit"
                  onClick={() => setShowSelectList(row.id)}
                ></i>
              </a>
            )}

            {showSelectList === row.id && (
              <select
                className="form-select editSelect"
                aria-label="Default select example"
                onChange={(e) => handleOptionChange(e, row.id, row.calculation)}
              >
                <option value="">Select One</option>

                {(row.iot_sol === "Transformer" ||
                  (row.iot_sol === "EMS") |
                    (row.iot_sol === "SELCO" || row.iot_sol === "Solar")) && (
                  <option value="nameplates">Nameplates</option>
                )}
                {row.iot_sol === "Transformer" && (
                  <option value="taps">Taps</option>
                )}
              </select>
            )}
          </div>
        </>

      ),
    },
  ];
  const handleCancel = () => {
    setEdit(false);
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = iotsensormasters.reverse.filter((test) => {
      return (
        test.subdomain.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.device_status?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.iot_sol
          ?.toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.repo_asset_name?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.sensor_uuid?.toLowerCase().match(val.toLocaleLowerCase()) ||
        test.sensor_fields_length?.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase()) ||
        test.calculated_fields_length?.toString()
          .toLowerCase()
          .match(val.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.subdomain > b.subdomain ? 1 : -1
    );
    setIotSensorMasters(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  return (
    <>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div class="col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <h1 class="pageTitle">Iot Sensor Master</h1>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <Link to="/add_iot_sensor_master">
                          <a
                            href="addNewAssets.html"
                            type="button"
                            class="btn btn-c-outline-primary"
                            data-toggle="modal"
                          
                          >
                            + Add Sensor Master
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div class="align-items-center">
                    <div class="form-inline pr-form-inline float-right">
                      <div class="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={iotsensormasters}
                      noDataComponent={
                        loader === false ? (
                          <div className="table-responsive">
                            <div className="borderRadius">
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: "50px" }}>
                                  <tr>
                                    <th>
                                      <b>Subdomain</b>
                                    </th>
                                    <th>
                                      <b>Device Status</b>
                                    </th>
                                    <th>
                                      <b>Solution</b>
                                    </th>
                                    <th>
                                      <b>Asset</b>
                                    </th>
                                    <th>
                                      <b>Sensor UUID</b>
                                    </th>
                                    <th>
                                      <b>Sensor Fields</b>
                                    </th>
                                    <th>
                                      <b>Calculated Fields</b>
                                    </th>
                                    <th>
                                      <b>PF Correction</b>
                                    </th>
                                    <th>
                                      <b>Calculation</b>
                                    </th>
                                    <th>
                                      <b>Action</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ height: "140px" }}>
                                  <tr>
                                    <td
                                      style={{ textAlign: "center" }}
                                      colSpan={10}
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      pagination
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                    <div class={loader ? "loader" : ""}></div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="disableconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Disable PF Correction?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => handleCancel(e)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handlePFCorrectionUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="enableconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Enable PF Correction?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => handleCancel(e)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handlePFCorrectionUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="disablecalculationconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Disable Calculation?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => handleCancel(e)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handleCalculationUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="enablecalculationconfirmation"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="editreposensormanufacturer"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">
                          Confirmation
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => handleCancel(e)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="">
                          <div className="row">
                            <div className="col-md-12"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p style={{ textAlign: "center" }}>
                                  You want to Enable Calculation?
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt20 mb10">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => handleCancel(e)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  handleCalculationUpdate(e);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default IotSensorMaster;
