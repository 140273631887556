/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import DataTable from "react-data-table-component";
import { Table, Popconfirm, Button, Space, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
// import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { useParams } from "react-router-dom";
// import "../tableStyle/tableStyle.css";

const CalculatedFields = () => {
  const { error1, setError1 } = useState({});
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState({});
  const [editingKey, setEditingKey] = useState("");

  const [newer, setNewer] = useState({});
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const { id } = useParams();
  const [form] = Form.useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      let _id = id;
      console.log(id);

      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getIotSensorMaster/${_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const json = await res.json();

      setGridData(json.calculated_fields);
      setLoading(false);
    } catch (error) {
      setError1(error1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log("gridData", gridData);

  const ModifiedData = Object.entries(gridData).map(([key, value]) => ({
    ...value,
    key: key,
  }));

  console.log("ModifiedData", ModifiedData);

  useEffect(() => {
    const result = ModifiedData.filter((test) => {
      return test.para_name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setGridData(result);
  }, [search]);

  const edit = (record) => {
    form.setFieldsValue({
      para_name: "",
      data_type: "",

      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key) => {
    console.log(key);
    try {
      let _id = id;
      console.log(id);
      const row = await form.validateFields();
      const newData = { ...gridData };
      newData[key] = { ...newData[key], ...row };
      const index = Object.keys(newData).findIndex(
        (itemKey) => itemKey === key
      );
      setNewer(newData);
      if (index >= 0) {
        const itemKey = Object.keys(newData)[index];
        const item = newData[itemKey];

        newData[itemKey] = { ...item, ...row };
        setGridData(newData);
      }
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/updateCalculated/${_id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            calculated_fields: newData,
          }),
        }
      );
      setEditingKey("");
      let resJson = await res.json();

      if (res.status === 200) {
        alert("Calculated Fields updated successfully");
      } else {
        alert(resJson.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) => {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: "para_name",
      dataIndex: "para_name",
      // align: "center",
      editable: true,
    },

    {
      title: "data_type",
      dataIndex: "data_type",
      // align: "center",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "actions",
      // align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return ModifiedData.length >= 1 ? (
          <Space>
            {editable ? (
              <span>
                <Space size="middle">
                  <Button
                    onClick={(e) => save(record.key)}
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </Button>
                  <Popconfirm title="Sure to cancel" onConfirm={cancel}>
                    <Button>Cancel</Button>
                  </Popconfirm>
                </Space>
              </span>
            ) : (
              <Button onClick={() => edit(record)} type="primary">
                Edit
              </Button>
            )}
          </Space>
        ) : null;
      },
    },
  ];

  const isEditing = (record) => {
    return record.key === editingKey;
  };

  const onAddDataFields = () => {
    const randomNumber = parseInt(Math.random() * 1000);
    const newKey = Object.keys(gridData).length;
    const newDataField = {
      para_name: "",
      data_type: "",
    };
    setGridData((prevData) => ({
      ...prevData,
      [newKey]: newDataField,
    }));
    alert(
      "New row added at the bottom of table, modify it according to your data"
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div class="col-sm-6">
                <h1 className="pageTitle">Calculated Fields</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6">
                  <div className="align-items-center mb20">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <a
                          type="button"
                          className="btn btn-c-outline-primary"
                          onClick={onAddDataFields}
                        >
                          {" "}
                          + Add Calculated Fields
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <div id="example_filter" class="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div
                      className="col-sm-12 row-wise, col-auto"
                      style={{
                        // display: "block;",
                        // borderSpacing: "5px",
                        // background: "#fff",
                        // borderRadius: "25px",
                      }}
                    >
                      <Form form={form} component={false}>
                        <Table
                          components={{
                            body: {
                              cell: EditableCell,
                            },
                          }}
                          columns={mergedColumns}
                          dataSource={ModifiedData}
                          bordered
                          loading={loading}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default CalculatedFields;
