import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle";
import { useFormik } from "formik";
import { FormSchema } from "./Asset_form";

const ApiMaster = () => {
  const [loadedAssetCategory, setLoadedAssetCategory] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [error1, setError1] = useState(null);
  const [total, setTotal] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [updateFormInitialValues, setUpdateFormInitialValues] = useState({});
  const [showUpdateApiLogModal, setShowUpdateApiLogModal] = useState(false);


  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/apilog_master_get/get-apilogs-master`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      let json = await res.json();
      json = json.data; // Adjust based on the actual API response structure
      const sortedData = [...json].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // const strAscending = [...json].sort((a, b) =>
      //   a.apiName > b.apiName ? 1 : -1
      // );
      setLoadedAssetCategory(sortedData);
      setLoader(false);
      setTotal(sortedData.length);
    } catch (error) {
      setError1(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearch(val);

    if (val.trim() === "") {
      // If the search input is empty, reset to the original data
      fetchData();  // Fetch the data again to reset the table
    } else {
      // Filter the data based on the search input
      const result = loadedAssetCategory.filter((item) =>
        item.apiName.toLowerCase().includes(val.toLowerCase())
      );
      setLoadedAssetCategory(result);
    }
  };


  const columns = [
    { name: "Project Name", selector: (row) => row.projectName, sortable: true },
    { name: "API Name", selector: (row) => row.apiName, sortable: true },
    {
      name: "Collection Involved",
      selector: (row) => row.collectionInvolved,
      sortable: true,
    },
    { name: "Route", selector: (row) => row.route, sortable: true },
    { name: "Request", selector: (row) => row.request, sortable: true },
    { name: "Response", selector: (row) => row.response, sortable: true },
    { name: "Response Type", selector: (row) => row.typeOfApi, sortable: true },
    {
      name: "Standard Response Time (ms)",
      selector: (row) => row.standardResponseTime,
      sortable: true,
    },


    { name: "Category Type", selector: (row) => row.categoryType, sortable: true },
    { name: "Comments", selector: (row) => row.comments, sortable: true },
    { name: "Purpose/Goal", selector: (row) => row.purposeGoal, sortable: true },
    { name: "Change Log", selector: (row) => row.changeLog, sortable: true },
    {
      name: 'Update',
      cell: row => (
        <a
          href=""
          data-toggle="modal"
          data-target="#updateapilog"
          onClick={(e) => {
            e.preventDefault();
            handleRowClick(row);
          }}>
          <i className="fa fa-edit" aria-hidden="true"></i>
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const formInitialValues = {
    apiName: "",
    collectionInvolved: "",
    route: "",
    request: "",
    response: "",
    standardResponseTime: "",
    projectName: "",
    typeOfApi: "",
    categoryType: "",
    comments: "",
    purposeGoal: "",
    changeLog: "",
  };
  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setUpdateFormInitialValues({
      apiName: row.apiName || '',
      collectionInvolved: row.collectionInvolved || '',
      route: row.route || '',
      request: row.request || '',
      response: row.response || '',
      standardResponseTime: row.standardResponseTime || '',
      projectName: row.projectName || '',
      typeOfApi: row.typeOfApi || '',
      categoryType: row.categoryType || '',
      comments: row.comments || '',
      purposeGoal: row.purposeGoal || '',
      changeLog: row.changeLog || '',
      id: row._id,  // Ensure id is also set
    });
    setShowUpdateApiLogModal(true);
  };

  const updateFormik = useFormik({
    initialValues: updateFormInitialValues, // Initialize with form values from state
    enableReinitialize: true,
    validationSchema: FormSchema,           // Validation schema for form validation
    onSubmit: async (values, { resetForm }) => {
      try {
        // Update URL and method based on form submission
        const response = await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/apilogs_master_add/${selectedRowData._id}`, // Ensure the URL is correct
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values), // Pass form values as JSON
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        // Handle successful response
        setShowUpdateApiLogModal(false); // Close the modal after successful update
        resetForm(); // Reset the form to initial values
        fetchData(); // Reload the data to reflect changes

        // Hide the modal using Bootstrap if you are using it
        // Ensure the ID matches the one used in your modal
        window.$("#updateapilog").modal("hide");
      } catch (error) {
        console.error("Failed to update form", error);
        // Optionally set error state to show user-friendly error message
      }
    },
  });


  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/apilogs_master_add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setLoadedAssetCategory([...loadedAssetCategory, data]);
        setFormVisible(false);
        resetForm();
        fetchData();

        window.$("#addapilog").modal("hide");
      } catch (error) {
        console.error("Failed to submit form", error);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    formik.handleSubmit();
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">API Log Master</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <a
                      href=""
                      type="button"
                      className="btn btn-c-outline-primary"
                      data-toggle="modal"
                      data-target="#addapilog"
                    >
                      + Add API Log
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-12 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <div id="example_filter" className="dataTables_filter">
                        <label>
                          Search: &nbsp;
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={search}
                            onChange={handleSearch}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      columns={columns}
                      data={loadedAssetCategory}
                      noDataComponent={<div>No Record Found</div>}
                      pagination
                      fixedHeader
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                  </div>
                </div>

                {/* Add modal for adding API logs */}
                <div
                  className="modal fade"
                  id="addapilog"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addapilog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/adminLogo.png"
                          alt="Motware Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Add API Log
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="projectName" className="col-form-label">
                                Project Name *
                              </label>
                              <input
                                id="projectName"
                                name="projectName"
                                value={formik.values.projectName}
                                className={`form-control ${formik.touched.projectName && formik.errors.projectName ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.projectName && formik.errors.projectName && (
                                <div className="invalid-feedback">
                                  {formik.errors.projectName}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="apiName" className="col-form-label">
                                API Name *
                              </label>
                              <input
                                id="apiName"
                                name="apiName"
                                value={formik.values.apiName}
                                className={`form-control ${formik.touched.apiName && formik.errors.apiName ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.apiName && formik.errors.apiName && (
                                <div className="invalid-feedback">
                                  {formik.errors.apiName}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="collectionInvolved" className="col-form-label">
                                Collection Involved *
                              </label>
                              <input
                                id="collectionInvolved"
                                name="collectionInvolved"
                                value={formik.values.collectionInvolved}
                                className={`form-control ${formik.touched.collectionInvolved && formik.errors.collectionInvolved ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.collectionInvolved && formik.errors.collectionInvolved && (
                                <div className="invalid-feedback">
                                  {formik.errors.collectionInvolved}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="standardResponseTime" className="col-form-label">
                                Standard Response Time(ms) *
                              </label>
                              <input
                                id="standardResponseTime"
                                name="standardResponseTime"
                                value={formik.values.standardResponseTime}
                                className={`form-control ${formik.touched.standardResponseTime && formik.errors.standardResponseTime ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.standardResponseTime && formik.errors.standardResponseTime && (
                                <div className="invalid-feedback">
                                  {formik.errors.standardResponseTime}
                                </div>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label htmlFor="categoryType" className="col-form-label">
                                Category Type *
                              </label>
                              <input
                                id="categoryType"
                                name="categoryType"
                                value={formik.values.categoryType}
                                className={`form-control ${formik.touched.categoryType && formik.errors.categoryType ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.categoryType && formik.errors.categoryType && (
                                <div className="invalid-feedback">
                                  {formik.errors.categoryType}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="comments" className="col-form-label">
                                Comments *
                              </label>
                              <input
                                id="comments"
                                name="comments"
                                value={formik.values.comments}
                                className={`form-control ${formik.touched.comments && formik.errors.comments ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.comments && formik.errors.comments && (
                                <div className="invalid-feedback">
                                  {formik.errors.comments}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="purposeGoal" className="col-form-label">
                                Purpose *
                              </label>
                              <input
                                id="purposeGoal"
                                name="purposeGoal"
                                value={formik.values.purposeGoal}
                                className={`form-control ${formik.touched.purposeGoal && formik.errors.purposeGoal ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.purposeGoal && formik.errors.purposeGoal && (
                                <div className="invalid-feedback">
                                  {formik.errors.purposeGoal}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="changeLog" className="col-form-label">
                                Change Log
                              </label>
                              <input
                                id="changeLog"
                                name="changeLog"
                                value={formik.values.changeLog}
                                className={`form-control ${formik.touched.changeLog && formik.errors.changeLog ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.changeLog && formik.errors.changeLog && (
                                <div className="invalid-feedback">
                                  {formik.errors.changeLog}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="route" className="col-form-label">
                                Route *
                              </label>
                              <input
                                id="route"
                                name="route"
                                value={formik.values.route}
                                className={`form-control ${formik.touched.route && formik.errors.route
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.route && formik.errors.route && (
                                <div className="invalid-feedback">
                                  {formik.errors.route}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="typeOfApi" className="col-form-label">
                                Type Of Request *
                              </label>
                              <select
                                id="typeOfApi"
                                name="typeOfApi"
                                value={formik.values.typeOfApi}
                                className={`form-control ${formik.touched.typeOfApi && formik.errors.typeOfApi
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">{`Select Type Of Request`}</option>
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="DELETE">DELETE</option>
                              </select>
                              {formik.touched.typeOfApi && formik.errors.typeOfApi && (
                                <div className="invalid-feedback">
                                  {formik.errors.typeOfApi}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="request" className="col-form-label">
                                Request *
                              </label>
                              <textarea
                                id="request"
                                name="request"
                                value={formik.values.request}
                                className={`form-control ${formik.touched.request && formik.errors.request
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                rows="4"
                              ></textarea>
                              {formik.touched.request && formik.errors.request && (
                                <div className="invalid-feedback">
                                  {formik.errors.request}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="response" className="col-form-label">
                                Response *
                              </label>
                              <textarea
                                id="response"
                                name="response"
                                value={formik.values.response}
                                className={`form-control ${formik.touched.response && formik.errors.response ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                rows="4"
                              />
                              {formik.touched.response && formik.errors.response && (
                                <div className="invalid-feedback">
                                  {formik.errors.response}
                                </div>
                              )}
                            </div>

                          </div>
                          <div className="text-center mt20 mb10">
                            <button
                              type="button"
                              className="btn btn-secondary mr10"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>

                        </form>

                      </div>
                    </div>
                  </div>
                </div>
                {/* End Add modal */}
                {/* Update modal for adding API logs */}
                <div
                  className="modal fade"
                  id="updateapilog"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="updateapilog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          src="assets/images/adminLogo.png"
                          alt="Motware Logo"
                          width="40px"
                          className="brand-image"
                        />
                        <h5 className="modal-title w-100 text-center">
                          Update API Log
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={updateFormik.handleSubmit}>

                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="projectName" className="col-form-label">
                                Project Name *
                              </label>
                              <input
                                id="projectName"
                                name="projectName"
                                value={updateFormik.values.projectName}
                                className={`form-control ${updateFormik.touched.projectName && updateFormik.errors.projectName ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.projectName && updateFormik.errors.projectName && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.projectName}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="apiName" className="col-form-label">
                                API Name *
                              </label>
                              <input
                                id="apiName"
                                name="apiName"
                                value={updateFormik.values.apiName}
                                className={`form-control ${updateFormik.touched.apiName && updateFormik.errors.apiName ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.apiName && updateFormik.errors.apiName && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.apiName}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="collectionInvolved" className="col-form-label">
                                Collection Involved *
                              </label>
                              <input
                                id="collectionInvolved"
                                name="collectionInvolved"
                                value={updateFormik.values.collectionInvolved}
                                className={`form-control ${updateFormik.touched.collectionInvolved && updateFormik.errors.collectionInvolved ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.collectionInvolved && updateFormik.errors.collectionInvolved && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.collectionInvolved}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="standardResponseTime" className="col-form-label">
                                Standard Response Time(ms) *
                              </label>
                              <input
                                id="standardResponseTime"
                                name="standardResponseTime"
                                value={updateFormik.values.standardResponseTime}
                                className={`form-control ${updateFormik.touched.standardResponseTime && updateFormik.errors.standardResponseTime ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.standardResponseTime && updateFormik.errors.standardResponseTime && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.standardResponseTime}
                                </div>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label htmlFor="categoryType" className="col-form-label">
                                Category Type *
                              </label>
                              <input
                                id="categoryType"
                                name="categoryType"
                                value={updateFormik.values.categoryType}
                                className={`form-control ${updateFormik.touched.categoryType && updateFormik.errors.categoryType ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.categoryType && updateFormik.errors.categoryType && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.categoryType}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="comments" className="col-form-label">
                                Comments *
                              </label>
                              <input
                                id="comments"
                                name="comments"
                                value={updateFormik.values.comments}
                                className={`form-control ${updateFormik.touched.comments && updateFormik.errors.comments ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.comments && updateFormik.errors.comments && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.comments}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="purposeGoal" className="col-form-label">
                                Purpose *
                              </label>
                              <input
                                id="purposeGoal"
                                name="purposeGoal"
                                value={updateFormik.values.purposeGoal}
                                className={`form-control ${updateFormik.touched.purposeGoal && updateFormik.errors.purposeGoal ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.purposeGoal && updateFormik.errors.purposeGoal && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.purposeGoal}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="changeLog" className="col-form-label">
                                Change Log
                              </label>
                              <input
                                id="changeLog"
                                name="changeLog"
                                value={updateFormik.values.changeLog}
                                className={`form-control ${updateFormik.touched.changeLog && updateFormik.errors.changeLog ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.changeLog && updateFormik.errors.changeLog && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.changeLog}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="route" className="col-form-label">
                                Route *
                              </label>
                              <input
                                id="route"
                                name="route"
                                value={updateFormik.values.route}
                                className={`form-control ${updateFormik.touched.route && updateFormik.errors.route
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              />
                              {updateFormik.touched.route && updateFormik.errors.route && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.route}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="typeOfApi" className="col-form-label">
                                Type Of Request *
                              </label>
                              <select
                                id="typeOfApi"
                                name="typeOfApi"
                                value={updateFormik.values.typeOfApi}
                                className={`form-control ${updateFormik.touched.typeOfApi && updateFormik.errors.typeOfApi
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                              >
                                <option value="">{`Select Type Of Request`}</option>
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="DELETE">DELETE</option>
                              </select>
                              {updateFormik.touched.typeOfApi && updateFormik.errors.typeOfApi && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.typeOfApi}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="request" className="col-form-label">
                                Request *
                              </label>
                              <textarea
                                id="request"
                                name="request"
                                value={updateFormik.values.request}
                                className={`form-control ${updateFormik.touched.request && updateFormik.errors.request
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                                rows="4"
                              ></textarea>
                              {updateFormik.touched.request && updateFormik.errors.request && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.request}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="response" className="col-form-label">
                                Response *
                              </label>
                              <textarea
                                id="response"
                                name="response"
                                value={updateFormik.values.response}
                                className={`form-control ${updateFormik.touched.response && updateFormik.errors.response ? "is-invalid" : ""}`}
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                                rows="4"
                              />
                              {updateFormik.touched.response && updateFormik.errors.response && (
                                <div className="invalid-feedback">
                                  {updateFormik.errors.response}
                                </div>
                              )}
                            </div>

                          </div>
                          <div className="text-center mt20 mb10">
                            <button
                              type="button"
                              className="btn btn-secondary mr10"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                            >
                              Update
                            </button>
                          </div>

                        </form>

                      </div>
                    </div>
                  </div>
                </div>
                {/* End Add modal */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ApiMaster;
