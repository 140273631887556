import * as Yup from "yup";

export const FormSchema = Yup.object({
  iot_sol: Yup.string().required("Please Enter Iot Solution Name"),
  // data_fields: Yup.string().required("Please Enter Data Fields"),
  // err_codes: Yup.string().required("Please Enter Error Codes"),
  // remove_trend: Yup.string().required("Please Enter Remove Trends"),
  // remove_alert: Yup.string().required("Please Enter Remove Alerts"),
  // param_alert_array1: Yup.string().required("Please Enter Param Alert Array"),
});
