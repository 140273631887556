import * as Yup from "yup";

export const FormSchema = Yup.object({
  first_name: Yup.string().required("Please Enter First Name"),
  last_name: Yup.string().required("Please Enter Last Name"),
  email: Yup.string().email().required("Please Enter Email"),
  decrypted_password: Yup.string()
    .min(8, "Password must be at least 8 character long")
    .required("Please Enter Password"),
  contact_no: Yup.string().max(10).required("Please Enter Contact Number"),
  subdomain: Yup.string().required("Please Select Subdomain"),
  role: Yup.string().required("Please Select Role"),
});
