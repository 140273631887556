import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { tableCustomStyles } from "../Repo_oil_standard/tableStyle.jsx";
import { FormSchema } from "./Asset_Test_Type_FormSchema.js";
import { useFormik } from "formik";

const Repo_assets = () => {
  const [LoadedAccount, setLoadedAccount] = useState({});
  const [ATEdit, setATEdit] = useState({});
  const [company_name, setCompany_name] = useState(" ");
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [data1, setRepoAssetTest] = useState([]);
  const [total, setTotal] = useState([]);
  const fetchcount = async () => {
    return await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_test_type/all`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((RepoCount1) => {
        var strAscending = [...RepoCount1].sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        setTotal(strAscending);
        setRepoAssetTest(strAscending);
        if (RepoCount1.length === 0) {
        } else {
          setLoader(false);
        }
      });
  };

  useEffect(() => {
    fetchcount();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.account?.company_name
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setRepoAssetTest(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  useEffect(() => {
    const getAllAccount = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/account_user/account/all`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setLoadedAccount(res);
    };
    getAllAccount();
  }, []);

  const ACData = LoadedAccount.accounts;
  const formInitialValues = {
    company_name: "",
    name: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        return await fetch(
          `${process.env.REACT_APP_VARIABLE}/api/repo_asset_test_type`,
          {
            method: "POST",
            body: JSON.stringify({
              company_name: values.company_name,
              name: values.name,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            // process the response data
            alert("Asset Test Type added successfully");
            values.company_name = "";
            values.name = "";
            fetchcount();
          })
          .catch((error) => {
            // handle the error
            alert(
              error.message +
                ": Asset Test Type already exist, could not create."
            );
            values.company_name = "";
            values.name = "";
          });
      },
    });

  const editATid = async (_id) => {
    let tid = _id;

    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_test_type/get/${tid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setATEdit(res.assetTestTypes);
    setCompany_name(res.assetTestTypes.company_name);
  };

  let FormInitialValues = {
    company_name: company_name,
    name: ATEdit.name,
  };
  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let tid = ATEdit._id;
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/repo_asset_test_type/${tid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            company_name: values.company_name,
            name: values.name,
          }),
        }
      );
      let resJson = await response.json();
      if (response.status === 200) {
        alert("Asset Test Type updated successfully");
        fetchcount();
      } else {
        alert(resJson.message);
      }
    },
  });
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    var result = data1.reverse.filter((test) => {
      return (
        test.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.account?.company_name
          .toLowerCase()
          .match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) => (a.name > b.name ? 1 : -1));
    setRepoAssetTest(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchcount();
    }
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    let tid = ATEdit._id;

    const response = await fetch(
      `${process.env.REACT_APP_VARIABLE}/api/repo_asset_test_type/status/${tid}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          status: status,
        }),
      }
    );
    let resJson = await response.json();
    if (resJson) {
      setEdit(true);
    }
    if (response.status === 200) {
      setEdit(false);
      let StatusText = "";
      if (status === "enable") {
        StatusText = "Enabled";
      }
      if (status === "disable") {
        StatusText = "Disabled";
      }
      alert("Asset Test Type " + StatusText + " successfully");

      fetchcount();
    } else {
      alert(resJson.message);
    }
  };
  const [status, setStatus] = useState(ATEdit.status);
  const [Status, set1Status] = useState({});
  const columns = [
    {
      name: "Account Name",
      selector: (row) => (
        <div className="bigtext">{row.account?.company_name}</div>
      ),
      sortable: true,
    },
    {
      name: "Names",
      selector: (row) => <div className="bigtext">{row.name}</div>,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <a href={row.id} onClick={() => editATid(row.id)}>
          <div className="switch">
            <label className="switch-light">
              {edit ? (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  defaultChecked={row.status === "disable"}
                />
              ) : (
                <input
                  type="checkbox"
                  name="status"
                  value={Status === true ? "enable" : "disable"}
                  onChange={(e) => set1Status(e.target.value)}
                  checked={row.status === "disable"}
                />
              )}

              <span>
                <span
                  id="enable"
                  onClick={(e) => setStatus("enable")}
                  data-toggle="modal"
                  data-target="#enableconfirmation"
                >
                  Enable{" "}
                </span>
                <span
                  id="disable"
                  onClick={(e) => setStatus("disable")}
                  data-toggle="modal"
                  data-target="#disableconfirmation"
                >
                  Disable{" "}
                </span>
              </span>
              <a className="btn btn-primary"></a>
            </label>
          </div>
        </a>
      ),
      sortable: true,
    },

    {
      name: " ",
    },
    {
      name: "Action",
      cell: (row) => (
        <a>
          <i
            href={row.id}
            onClick={() => editATid(row.id)}
            className="btn btn-primary"
            class="fa fa-edit"
            data-toggle="modal"
            data-target="#editrepoassettesttypes"
          ></i>
        </a>
      ),
    },
  ];
  const handleCancel = () => {
    setEdit(false);
  };
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <section class="content pt20">
          <div class="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1 className="pageTitle">Repository Asset Test Types</h1>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 mb10">
                  <div className="form-inline pr-form-inline float-right">
                    <div className="input-group pr-form-group">
                      <a
                        href="addRepostoryAsset.html"
                        type="button"
                        className="btn btn-c-outline-primary"
                        data-toggle="modal"
                        data-target="#addrepoassettesttypes"
                      >
                        {" "}
                        + Add Repository Asset Test Type{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-12 col-xl-12 mb10">
                  <div class="form-inline pr-form-inline float-right">
                    <div class="input-group pr-form-group">
                      <div id="example_filter" class="dataTables_filter">
                        <a>
                          Search: &nbsp;{" "}
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="example"
                            value={search}
                            onChange={(e) => handleSearch(e)}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div>
                    <div className="borderRadius">
                      <DataTable
                        columns={columns}
                        data={data1}
                        noDataComponent={
                          loader === false ? (
                            <div className="table-responsive">
                              <div className="borderRadius">
                                <table style={{ width: "100%" }}>
                                  <thead style={{ height: "50px" }}>
                                    <tr>
                                      <th>
                                        <b>Account Name</b>
                                      </th>
                                      <th>
                                        <b>Names</b>
                                      </th>
                                      <th>
                                        <b>Status</b>
                                      </th>
                                      <th>
                                        <b></b>
                                      </th>
                                      <th>
                                        <b>Action</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ height: "140px" }}>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={5}
                                      >
                                        No Record Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        pagination
                        fixedHeaderh
                        highlightOnHover
                        customStyles={tableCustomStyles}
                      />
                      <div class={loader ? "loader" : ""}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addrepoassettesttypes"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addrepoassettesttypes"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Add Repository Asset Test Type
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className=" col-form-label">
                              Account Name *
                            </label>
                            <div className="col-sm-10 col-md-10 col-lg-12">
                              {" "}
                              <select
                                id="ak"
                                name="company_name"
                                value={values.company_name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control "
                              >
                                <option value="" disabled selected="true">
                                  Select Account Name
                                </option>
                                {ACData &&
                                  ACData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {errors.company_name && touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Test Type Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={values.name}
                              className="form-control"
                              id="locName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter Asset Test Type Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          data-dismiss={
                            values.company_name && values.name ? "modal" : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="editrepoassettesttypes"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editrepoassettesttypes"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Edit Repository Asset Test Type
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Account Name *
                            </label>
                            <div className="col-sm-10 col-md-10 col-lg-12">
                              <select
                                id="ak"
                                name="company_name"
                                value={formik.values.company_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control "
                              >
                                {formik.values.company_name !== undefined ? (
                                  <option
                                    value={formik.values.company_name}
                                    disabled
                                    selected="true"
                                  >
                                    {formik.values.company_name}
                                  </option>
                                ) : (
                                  <option value="" disabled selected="true">
                                    Select Account Name
                                  </option>
                                )}
                                {ACData &&
                                  ACData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {formik.errors.company_name &&
                              formik.touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>{" "}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="sector" className="col-form-label">
                              Asset Test Type Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={formik.values.name}
                              className="form-control"
                              id="locName"
                              onBlur={formik.handleBlur}
                              placeholder="Enter Asset Test Type Name"
                              onChange={formik.handleChange}
                            />
                            {formik.errors.name && formik.touched.name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={formik.handleSubmit}
                          data-dismiss={
                            formik.values.company_name && formik.values.name
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="disableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Repository Asset Test Type?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="enableconfirmation"
              tabindex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Repository Asset Test Type?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              handleStatusUpdate(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Repo_assets;
