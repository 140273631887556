
import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { tableCustomStyles } from "../tableStyle.jsx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function Triggers() {
  const [total, setTotal] = useState([]);
  const [search, setSerach] = useState("");
  const [trigger, setTrigger] = useState([]);
  const [loader, setLoader] = useState(true);
  
  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VARIABLE}/Triggers/getAllTriggers`
      );
      var res = response.data;
      var strAscending = [...res].sort((a, b) =>
        a.trigger_name > b.trigger_name ? 1 : -1
      );
      setTrigger(strAscending);
      if (res.length === 0) {
      } else {
        setLoader(false);
      }
      setTotal(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = total.filter((test) => {
      return (
        test.trigger_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.account?.company_name
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.solution?.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.repo_asset?.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.device_sensor_uuid
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.composite_sensor_uuid
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.param_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.min.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.max.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.start_time.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.end_time.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.trigger_name > b.trigger_name ? 1 : -1
    );
    setTrigger(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
  }, [search]);

  const [inpval, setINP] = useState({});

  const [Status, set1Status] = useState({});

  // const [stat, setStat] = useState({
  //   email_service: ,
  //   sms_service: ,
  // });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const getdata = async (id) => {
    let _id = id;
    // console.log(id);
    // if (window.confirm("Are you sure you want to edit trigger?")) {
    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Triggers/getTrigger/${_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    setINP(data);
    // console.log(inpval);
    // console.log(setINP.trigger_name);
    // setStatus(false);
    if (res.status === 422 || !data) {
      console.log("error");
    } else {
      setINP(data);
      // console.log("get data");
    }
    // }
  };

  function handleEdit(e) {
    setINP({ ...inpval, [e.target.name]: e.target.value });
    set1Status(e.target.value);
    // setStat(e.target.value);
  }

  const [status, setStatus] = useState(inpval.status);

  const [trigger_name, settrigger_name] = useState("");

  const [accounts, setAccounts] = useState({});
  const [company_name, setcompany_name] = useState("");

  const [assets, setAssets] = useState({});
  const [name, setasset_name] = useState("");

  const [solution, setsolution] = useState(inpval.solution);
  const [IotsolutionMasters, setIotsolutionMasters] = useState({});

  const [composite_sensor_uuid, setcomposite_sensor_uuid] = useState("");
  const [device_sensor_uuid, setdevice_sensor_uuid] = useState("");

  const [param_name, setparam_name] = useState("");
  const [paras, setPara] = useState({});

  const [min, setmin] = useState("");
  const [max, setmax] = useState("");
  const [start_time, setstart_time] = useState("");
  const [end_time, setend_time] = useState("");
  const [sms, setsms] = useState("");
  const [alert_frequency, setalert_frequency] = useState("");
  const [edit, setEdit] = useState(false);

  // status
  const [email_service, setemail_service] = useState(inpval.email_service);
  const [sms_service, setsms_service] = useState(inpval.sms_service);

  const FormSchema = Yup.object({
    trigger_name: Yup.string().required("Please Enter Trigger Name!"),
    solution: Yup.string().required("Please Enter solution!"),
    composite_sensor_uuid: Yup.string().required(
      "Please Enter composite sensor uuid!"
    ),
    device_sensor_uuid: Yup.string().required(
      "Please Enter device sensor uuid!"
    ),
    param_name: Yup.string().required("Please Enter param name!"),
    min: Yup.string().required("Please Enter min!"),
    max: Yup.string().required("Please Enter max!"),
    start_time: Yup.string().required("Please Enter start time!"),
    end_time: Yup.string().required("Please Enter end time!"),
    sms: Yup.string().required("Please Enter sms!"),
    alert_frequency: Yup.string().required("Please Enter alert frequency!"),

    company_name: Yup.string().required("Please Enter company name!"),
    name: Yup.string().required("Please Enter Asset Name!"),
  });

  const formInitialValues = {
    trigger_name: "",
    solution: "",
    composite_sensor_uuid: "",
    device_sensor_uuid: "",
    param_name: "",
    min: "",
    max: "",
    start_time: "",
    end_time: "",
    sms: "",
    alert_frequency: "",

    company_name: "",
    name: "",
  };

  let { handleSubmit, handleChange, touched, values, handleBlur, errors } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: FormSchema,
      onSubmit: async (values) => {
        // const handleSubmit  = async (e) => {
        //   e.preventDefault();
        try {
          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/Triggers/createTrigger`,
            {
              method: "POST",
              body: JSON.stringify({
                trigger_name: values.trigger_name,
                solution: values.solution,
                composite_sensor_uuid: values.composite_sensor_uuid,
                device_sensor_uuid: values.device_sensor_uuid,
                param_name: values.param_name,
                min: values.min,
                max: values.max,
                start_time: values.start_time,
                end_time: values.end_time,
                sms: values.sms,
                alert_frequency: values.alert_frequency,
                email_service,
                sms_service,

                company_name: values.company_name,
                name: values.name,
              }),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );

          const data = await res.json();

          if (res.status === 422 || !data) {
            alert("please insert data");
            alert(data.message);
          } else {
            // if (alert("trigger successfully added")) {
            // } else window.location.reload();
            alert("trigger successfully added");

            fetchData();
          }
        } catch (err) {
          console.log(err);
        }
      },
    });

  // accounts api

  useEffect(() => {
    const getAllAccounts = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Triggers/getAllAccounts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setAccounts(res);
      // console.log(inpval);
    };
    getAllAccounts();
  }, []);

  const AccountData = accounts.accounts;
  // console.log("AccountData ", AccountData);

  const getAllAssets = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Triggers/getAllAssets`,
      {
        method: "POST",
        body: JSON.stringify({
          company_name: values.company_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAssets(res);
    // console.log(inpval);
  };

  const getAllAssetsEdit = async () => {
    const reqdata = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Triggers/getAllAssets`,
      {
        method: "POST",
        body: JSON.stringify({
          company_name: formik.values.company_name,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    const res = await reqdata.json();
    setAssets(res);
    // console.log(inpval);
  };
  const AssetData = assets.RepoAsset;

  //isl
  useEffect(() => {
    const getAllIotSolutionMaster = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Iot/getAllIotSolutionMasters`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setIotsolutionMasters(res);
      // console.log(inpval);
    };
    getAllIotSolutionMaster();
  }, []);
  const IotsolM = IotsolutionMasters.IotsolutionMasters;

  useEffect(() => {
    const getAllParaName = async () => {
      const reqdata = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Triggers/getAllParaName`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );
      const res = await reqdata.json();
      setPara(res);
      // console.log(inpval);
    };
    getAllParaName();
  }, []);
  const para = paras.sensor_fields;

  let FormInitialValues = {
    trigger_name: inpval.trigger_name,

    composite_sensor_uuid: inpval.composite_sensor_uuid,
    device_sensor_uuid: inpval.device_sensor_uuid,
    param_name: inpval.param_name,
    min: inpval.min,
    max: inpval.max,
    start_time: inpval.start_time,
    end_time: inpval.end_time,
    sms: inpval.sms,
    alert_frequency: inpval.alert_frequency,

    solution: solution,
    company_name: company_name,
    name: name,
  };

  const formik = useFormik({
    initialValues: FormInitialValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      let _id = inpval.id;
      // console.log(inpval);

      const res2 = await fetch(
        `${process.env.REACT_APP_VARIABLE}/Triggers/updatetrigger/${_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            trigger_name: values.trigger_name,

            composite_sensor_uuid: values.composite_sensor_uuid,
            device_sensor_uuid: values.device_sensor_uuid,
            param_name: values.param_name,
            min: values.min,
            max: values.max,
            start_time: values.start_time,
            end_time: values.end_time,
            sms: values.sms,
            alert_frequency: values.alert_frequency,
            email_service: email_service,
            sms_service: sms_service,
            status: inpval.status,

            solution: values.solution,
            company_name: values.company_name,
            name: values.name,
          }),
        }
      );
      // console.log(res2);
      if (res2.status === 201) {
        // console.log("successful");
        alert("trigger has been edited");
        // if (alert("trigger has been edited")) {
        // } else
        // window.location.reload();
        fetchData();
      } else {
        console.log("err");
      }
    },
  });
  const updateStatus = async (e) => {
    e.preventDefault();
    let _id = inpval.id;

    const res = await fetch(
      `${process.env.REACT_APP_VARIABLE}/Triggers/updateStatus/${_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: status }),
      }
    );
    let res1 = res.json();
    if (res1) {
      setEdit(true);
    }

    if (res.status === 201) {
      setEdit(false);
      // console.log("successful");
      alert("trigger has been edited");

      fetchData();
    } else {
      console.log("err");
    }
  };
  const columns = [
    {
      name: "Trigger Name",
      selector: (row) => <div className="bigtext">{row.trigger_name}</div>,
      sortable: true,
    },
    {
      name: "Account Name",
      selector: (row) => (
        <div className="bigtext">{row.account?.company_name}</div>
      ),
      sortable: true,
    },
    {
      name: "Solution",
      selector: (row) => <div>{row.solution}</div>,
      sortable: true,
    },
    {
      name: "Asset",
      selector: (row) => <div className="bigtext">{row.repo_asset?.name}</div>,
      sortable: true,
    },
    {
      name: "Device UUID",
      selector: (row) => (
        <div className="bigtext">{row.device_sensor_uuid}</div>
      ),
      sortable: true,
    },
    {
      name: "Sensor UUID",
      selector: (row) => (
        <div className="bigtext">{row.composite_sensor_uuid}</div>
      ),
      sortable: true,
    },

    {
      name: "Param Name",
      selector: (row) => <div className="bigtext">{row.param_name}</div>,
      sortable: true,
    },
    {
      name: "Min",
      selector: (row) => <div>{row.min}</div>,
      sortable: true,
    },
    {
      name: "Max",
      selector: (row) => <div>{row.max} </div>,
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => <div>{row.start_time}</div>,
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => <div>{row.end_time}</div>,
      sortable: true,
    },
    {
      // defaultChecked={inpval ? inpval.status : false}
      name: "Status",
      cell: (row) => (
        <td>
          <a href={row.id} onClick={() => getdata(row.id)}>
            <div className="switch">
              <label className="switch-light">
                {edit ? (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? 1 : 0}
                    onChange={(e) => set1Status(e.target.value)}
                    defaultChecked={row.status === 0}
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="status"
                    value={Status ? 1 : 0}
                    onChange={(e) => set1Status(e.target.value)}
                    checked={row.status === 0}
                  />
                )}

                <span>
                  <span
                    id="enable"
                    onClick={(e) => setStatus(1)}
                    data-toggle="modal"
                    data-target="#enableconfirmation"
                  >
                    Enable
                  </span>
                  <span
                    id="disable"
                    onClick={(e) => setStatus(0)}
                    data-toggle="modal"
                    data-target="#disableconfirmation"
                  >
                    Disable
                  </span>
                </span>
                <a className="btn btn-primary"></a>
              </label>
            </div>
          </a>
        </td>
      ),
      sortable: true,
    },
    {},
    {
      name: "Action",
      cell: (row) => (
        <>
          <a>
            <i
              href={row.id}
              onClick={() => getdata(row.id)}
              className="btn btn-primary"
              class="fa fa-edit"
              data-toggle="modal"
              data-target="#edittrigger"
            ></i>
          </a>
        </>
      ),
    },
  ];

  const handleCancel = (e) => {
    setEdit(false);
  };
  // edit status

  const handleSearch = (e) => {
    let val = e.target.value;
    setSerach(val);
    var result = trigger.reverse.filter((test) => {
      return (
        test.trigger_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.account?.company_name
          .toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.solution.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.repo_asset?.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.device_sensor_uuid
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.composite_sensor_uuid
          ?.toLowerCase()
          .match(search.toLocaleLowerCase()) ||
        test.param_name.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.min.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.max.toString().toLowerCase().match(search.toLocaleLowerCase()) ||
        test.start_time.toLowerCase().match(search.toLocaleLowerCase()) ||
        test.end_time.toLowerCase().match(search.toLocaleLowerCase())
      );
    });
    var strAscending = [...result].sort((a, b) =>
      a.trigger_name > b.trigger_name ? 1 : -1
    );

    setTrigger(strAscending);
    if (strAscending) {
    } else {
      setLoader(false);
    }
    setLoader(false);
    if (e.target.value === "") {
      fetchData();
    }
  };
  const [Stat, set1Stat] = useState({});

  return (
    <>
      <div className="content-wrapper">
        <section className="content pt20">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-sm-6">
                  <h1 className="pageTitle">Triggers</h1>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-md-6 col-lg-6 col-xl-6 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <a
                          href=""
                          type="button"
                          className="btn btn-c-outline-primary"
                          data-toggle="modal"
                          data-target="#addtrigger"
                        >
                          {" "}
                          + Add Trigger{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-12 col-xl-12 mb20">
                  <div className="align-items-center">
                    <div className="form-inline pr-form-inline float-right">
                      <div className="input-group pr-form-group">
                        <div id="example_filter" className="dataTables_filter">
                          <a>
                            Search: &nbsp;{" "}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="example"
                              value={search}
                              onChange={(e) => handleSearch(e)}
                            />
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="borderRadius">
                    <DataTable
                      className="dataTables_wrapper"
                      columns={columns}
                      data={trigger}
                      pagination
                      noDataComponent={
                        loader === false ? (
                          <div className="table-responsive">
                            <div className="borderRadius">
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: "50px" }}>
                                  <tr>
                                    <th>
                                      <b>Trigger Name</b>
                                    </th>
                                    <th>
                                      <b>Account Name</b>
                                    </th>
                                    <th>
                                      <b>Solution</b>
                                    </th>
                                    <th>
                                      <b>Asset</b>
                                    </th>
                                    <th>
                                      <b>Device UUID</b>
                                    </th>
                                    <th>
                                      <b>Sensor UUID</b>
                                    </th>
                                    <th>
                                      <b>Param Name</b>
                                    </th>
                                    <th>
                                      <b>Min</b>
                                    </th>
                                    <th>
                                      <b>Max</b>
                                    </th>
                                    <th>
                                      <b>Start Time</b>
                                    </th>
                                    <th>
                                      <b>End Time</b>
                                    </th>
                                    <th>
                                      <b>Status</b>
                                    </th>

                                    <th>
                                      <b>Action</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ height: "140px" }}>
                                  <tr>
                                    <td
                                      style={{ textAlign: "center" }}
                                      colSpan={13}
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      fixedHeaderh
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />

                    <div class={loader ? "loader" : ""}></div>
                  </div>
                </div>
              </div>
            </div>

            {/* /popups */}
            {/* Add Trigger */}
            <div
              className="modal fade"
              id="addtrigger"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addtrigger"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Add Trigger
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Trigger Name
                            </label>
                            <input
                              type="text"
                              name="trigger_name"
                              value={values.trigger_name}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.trigger_name && touched.trigger_name ? (
                              <span style={{ color: "red" }}>
                                {errors.trigger_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Account Name
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="company_name"
                                value={values.company_name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.company_name}
                                </option>
                                {AccountData &&
                                  AccountData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {errors.company_name && touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Solution
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="solution"
                                value={values.solution}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option disabled selected="true">
                                  {values.solution}
                                </option>
                                {IotsolM &&
                                  IotsolM.map((am, index) => (
                                    <option key={index} value={am.iot_sol}>
                                      {am.iot_sol}
                                    </option>
                                  ))}
                              </select>
                              {errors.solution && touched.solution ? (
                                <span style={{ color: "red" }}>
                                  {errors.solution}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Asset
                            </label>
                            <div>
                              <span onClick={getAllAssets}>
                                <select
                                  id="ak"
                                  name="name"
                                  value={values.name}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  className="js-states form-control "
                                  class="form-select"
                                >
                                  <option disabled selected="true">
                                    {values.name}
                                  </option>
                                  {AssetData &&
                                    AssetData.map((am, index) => (
                                      <option key={index} value={am.name}>
                                        {am.name}
                                      </option>
                                    ))}
                                </select>
                                {errors.name && touched.name ? (
                                  <span style={{ color: "red" }}>
                                    {errors.name}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Device Uuid
                            </label>
                            <input
                              type="text"
                              name="device_sensor_uuid"
                              value={values.device_sensor_uuid}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.device_sensor_uuid &&
                            touched.device_sensor_uuid ? (
                              <span style={{ color: "red" }}>
                                {errors.device_sensor_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Sensor Uuid
                            </label>
                            <input
                              type="text"
                              name="composite_sensor_uuid"
                              value={values.composite_sensor_uuid}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.composite_sensor_uuid &&
                            touched.composite_sensor_uuid ? (
                              <span style={{ color: "red" }}>
                                {errors.composite_sensor_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Param Name
                            </label>
                            {/* <input
                              type="text"
                              name="param_name"
                              value={values.param_name}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            /> */}
                            <select
                              id="ak"
                              name="param_name"
                              value={values.param_name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className="js-states form-control "
                              class="form-select"
                            >
                              <option disabled selected="true"></option>
                              <option
                              //  disabled selected="true"
                              >
                                {/* {values.param_name} */}
                                PM10
                              </option>
                              <option>NO2</option>
                              <option>SO2</option>
                              <option>average_PM2_5</option>
                              <option>aqi_PM2_5</option>
                              <option>average_PM10</option>
                              <option>aqi_PM10</option>

                              {para &&
                                para.map((am, index) => (
                                  <option key={index} value={am.iot_sol}>
                                    {am.iot_sol}
                                  </option>
                                ))}
                            </select>

                            {errors.param_name && touched.param_name ? (
                              <span style={{ color: "red" }}>
                                {errors.param_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Min
                            </label>
                            <input
                              type="number"
                              name="min"
                              value={values.min}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.min && touched.min ? (
                              <span style={{ color: "red" }}>{errors.min}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Max
                            </label>
                            <input
                              type="number"
                              name="max"
                              value={values.max}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.max && touched.max ? (
                              <span style={{ color: "red" }}>{errors.max}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Start time
                            </label>
                            <input
                              type="time"
                              name="start_time"
                              value={values.start_time}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.start_time && touched.start_time ? (
                              <span style={{ color: "red" }}>
                                {errors.start_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              End time
                            </label>
                            <input
                              type="time"
                              name="end_time"
                              value={values.end_time}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.end_time && touched.end_time ? (
                              <span style={{ color: "red" }}>
                                {errors.end_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              SMS
                            </label>
                            <input
                              type="text"
                              name="sms"
                              value={values.sms}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.sms && touched.sms ? (
                              <span style={{ color: "red" }}>{errors.sms}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Alert Frequency
                            </label>
                            <input
                              type="text"
                              name="alert_frequency"
                              value={values.alert_frequency}
                              className="form-control"
                              id="compName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                            />
                            {errors.alert_frequency &&
                            touched.alert_frequency ? (
                              <span style={{ color: "red" }}>
                                {errors.alert_frequency}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Email Service
                            </label>
                            <td>
                              <a
                              // href={row.id} onClick={() => getdata(row.id)}
                              >
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultChecked={
                                        inpval.device_status === "disable"
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setemail_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enableconfirmation"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setemail_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disableconfirmation"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Sms Service
                            </label>
                            <td>
                              <a
                              // href={row.id} onClick={() => getdata(row.id)}
                              >
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultChecked={
                                        inpval.sms_service === "disable"
                                      }
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setsms_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enablesmsservice"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setsms_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disableemailservice"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          data-dismiss={
                            values.trigger_name &&
                            values.solution &&
                            values.composite_sensor_uuid &&
                            values.device_sensor_uuid &&
                            values.param_name &&
                            values.min &&
                            values.max &&
                            values.start_time &&
                            values.end_time &&
                            values.sms &&
                            values.alert_frequency &&
                            values.company_name &&
                            values.name
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* update Trigger */}
            <div
              div
              className="modal fade"
              id="edittrigger"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="edittrigger"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content" style={{ width: "1250px" }}>
                  <div className="modal-header">
                    <img
                      src="assets/images/adminLogo.png"
                      alt="Motware Logo"
                      width="40px"
                      className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">
                      Edit Trigger
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Trigger Name
                            </label>

                            <input
                              type="text"
                              name="trigger_name"
                              value={formik.values.trigger_name}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.trigger_name &&
                            formik.touched.trigger_name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.trigger_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Account Name
                            </label>
                            <div>
                              <select
                                id="ak"
                                name="company_name"
                                value={formik.values.company_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option
                                  value={company_name}
                                  disabled
                                  selected="true"
                                >
                                  {inpval.company_name}
                                </option>
                                {AccountData &&
                                  AccountData.map((am, index) => (
                                    <option key={index} value={am.company_name}>
                                      {am.company_name}
                                    </option>
                                  ))}
                              </select>
                              {formik.errors.company_name &&
                              formik.touched.company_name ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.company_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Solution
                            </label>
                            <div>
                              <select
                                select
                                id="ak"
                                name="solution"
                                value={formik.values.solution}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="js-states form-control "
                                class="form-select"
                              >
                                <option
                                  disabled
                                  selected="true"
                                  value={solution}
                                >
                                  {inpval.solution}
                                </option>
                                {IotsolM &&
                                  IotsolM.map((am, index) => (
                                    <option key={index} value={am.iot_sol}>
                                      {am.iot_sol}
                                    </option>
                                  ))}
                              </select>
                              {formik.errors.solution &&
                              formik.touched.solution ? (
                                <span style={{ color: "red" }}>
                                  {formik.errors.solution}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Asset
                            </label>
                            <div>
                              <span onClick={getAllAssetsEdit}>
                                <select
                                  id="ak"
                                  name="name"
                                  value={formik.values.name}
                                  onBlur={formik.onBlur}
                                  onChange={formik.handleChange}
                                  className="js-states form-control "
                                  class="form-select"
                                >
                                  <option disabled selected="true" value={name}>
                                    {inpval.name}
                                  </option>
                                  {AssetData &&
                                    AssetData.map((am, index) => (
                                      <option key={index} value={am.name}>
                                        {am.name}
                                      </option>
                                    ))}
                                </select>
                                {formik.errors.name && formik.touched.name ? (
                                  <span style={{ color: "red" }}>
                                    {formik.errors.name}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Device Uuid
                            </label>
                            <input
                              type="text"
                              name="device_sensor_uuid"
                              value={formik.values.device_sensor_uuid}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.device_sensor_uuid &&
                            formik.touched.device_sensor_uuid ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.device_sensor_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Sensor Uuid
                            </label>
                            <input
                              type="text"
                              name="composite_sensor_uuid"
                              value={formik.values.composite_sensor_uuid}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.composite_sensor_uuid &&
                            formik.touched.composite_sensor_uuid ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.composite_sensor_uuid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Param Name
                            </label>
                            <input
                              type="text"
                              name="param_name"
                              value={formik.values.param_name}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.param_name &&
                            formik.touched.param_name ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.param_name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Min
                            </label>
                            <input
                              type="number"
                              name="min"
                              value={formik.values.min}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.min && formik.touched.min ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.min}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              Max
                            </label>
                            <input
                              type="number"
                              name="max"
                              value={formik.values.max}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.max && formik.touched.max ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.max}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Start Time
                            </label>
                            <input
                              type="time"
                              name="start_time"
                              value={formik.values.start_time}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.start_time &&
                            formik.touched.start_time ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.start_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              End Time
                            </label>
                            <input
                              type="time"
                              name="end_time"
                              value={formik.values.end_time}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.end_time &&
                            formik.touched.end_time ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.end_time}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="compName"
                              className="col-form-label required-field"
                            >
                              SMS
                            </label>
                            <input
                              type="text"
                              name="sms"
                              value={formik.values.sms}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.sms && formik.touched.sms ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.sms}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label required-field"
                            >
                              Alert Frequency
                            </label>
                            <input
                              type="text"
                              name="alert_frequency"
                              value={formik.values.alert_frequency}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              id="locName"
                              placeholder=""
                              onChange={formik.handleChange}
                            />
                            {formik.errors.alert_frequency &&
                            formik.touched.alert_frequency ? (
                              <span style={{ color: "red" }}>
                                {formik.errors.alert_frequency}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Email Service
                            </label>
                            <td>
                              <a>
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultUnchecked={inpval.email_service}
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setemail_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enableconfirmation"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setemail_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disableconfirmation"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="contactNo"
                              className="col-form-label"
                            >
                              Sms Service
                            </label>
                            <td>
                              <a>
                                <div className="switch">
                                  <label className="switch-light">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      value={Status ? "enable" : "disable"}
                                      onChange={(e) =>
                                        set1Status(e.target.value)
                                      }
                                      defaultUnchecked={inpval.sms_service}
                                    />

                                    <span>
                                      <span
                                        id="enable"
                                        onClick={(e) =>
                                          setsms_service("enable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#enablesmsservice"
                                      >
                                        Enable
                                      </span>
                                      <span
                                        id="disable"
                                        onClick={(e) =>
                                          setsms_service("disable")
                                        }
                                        data-toggle="modal"
                                        // data-target="#disablesmsservice"
                                      >
                                        Disable
                                      </span>
                                    </span>
                                    <a className="btn btn-primary"></a>
                                  </label>
                                </div>
                              </a>
                            </td>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt20 mb10">
                        <button
                          type="button"
                          className="btn btn-secondary mr10"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={formik.handleSubmit}
                          type="button"
                          className="btn btn-primary"
                          data-dismiss={
                            formik.values.trigger_name &&
                            formik.values.solution &&
                            formik.values.composite_sensor_uuid &&
                            formik.values.device_sensor_uuid &&
                            formik.values.param_name &&
                            formik.values.min &&
                            formik.values.max &&
                            formik.values.start_time &&
                            formik.values.end_time &&
                            formik.values.sms &&
                            formik.values.alert_frequency &&
                            formik.values.company_name &&
                            formik.values.name
                              ? "modal"
                              : ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* switch  */}
            <div
              className="modal fade"
              id="enableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editreposensormanufacturer"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Enable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              updateStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="disableconfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editrepooiltype"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <img
                      src="assets/images/logo v4.png"
                      alt="oil test Logo"
                      width="40px"
                      className="brand-image"
                    /> */}
                    <h5 className="modal-title w-100 text-center">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleCancel(e)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <p style={{ textAlign: "center" }}>
                              You want to Disable Trigger?
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt20 mb10">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => handleCancel(e)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={(e) => {
                              updateStatus(e);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Triggers;
